/**
 * File: case_details.js - page to list all the information for the case details
 * on BPP side and help perform all the case related actions.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu, Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './case_details.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
// Internal Components
import DePlan from '../../doctor/case_details/de_plan';
import InitialPlan from '../../doctor/case_details/initial_plan';
import IrSubmission from '../ir_submission/ir_submission';
import PrSubmission from '../pr_submission/pr_submission';
import CsrSubmission from '../csr_submission/csr_submission';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import NotFound from '../../doctor/404/not_found';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';
import SecurityClearance from '../../components/security/security_clearance';
import SetupContainer from '../case_details/setup/setup_container';
import CADContainer from './cad/cad_container';
import IRCADContainer from './ir_cad/cad_process';
import ProductionContainer from '../case_details/production/production_container';
import ProcessTracker from '../../components/workflow/process_tracker';
import WorkOrderRef from '../../components/workorder_reference/work_order_reference';
import IrProductionContainer from './ir_production/production_container';
import WSWorkflow from './ir_production/ws_workflow/ws_workflow';
import IrShippingContainer from './ir_shipping/shipping_container';
import ShippingContainer from './shipping/shipping_container';
import ProductionTxGuide from '../../components/production_tx_guide/production_tx_guide';
import TemplateContainer from './template_wire_selection/template_container';
import WireManufacturingContainer from './wire_manufacturing/wire_manufacturing_container';

import ConvertCaseGeneration from '../../doctor/case_details/convert_case_generation';
import { getMinDate, getPDFNameTemplate, getSmartWireProgressRecordDate, isValidDate } from '../../common/helpers';
import TextBox from '../../doctor/components/inputs/text_box';
import { getQuillHTMLTextContentLength } from '../../common/functions';
import IrSingleToothIdb from './ir_single_tooth_idb/ir_single_tooth_idb';
import { hasSingleToothSelection, hasArchSegmentSelection } from '../../common/ir_helpers';

// Internal Functions
import {
  configLightBox,
  convertDate,
  convertSex,
  modalClear,
  modalClose,
  removeCaseIdInitialNumber,
  removeIreqIdInitialNumber,
  setTokenHeader,
  sortRawFiles,
  textFieldCheck,
  removeEmoji,
  redirectToCaseDetail,
  isCaseDE,
} from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { clearZipStatus, downloadZip, getCaseLastStatus, onReloadPage, translateCaseStatus, translateStatus, isCaseBlocked } from '../../common/helpers';
import { getCaseIdFromUrl, convertAliasToCaseId } from '../../common/case/case_id';
import { isTodayPastDate } from '../../common/date';
import { isNotBlockedOrInactive } from '../../common/case/case_status';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import { getDoctorAssignedNameFromCaseDetails, getDoctorAssignedIdFromCaseDetails } from '../../common/dso';
import { isAfterSmileDesignApproval } from '../../common/case/case_status';
import moment from 'moment';
// Redux Actions
import { fetchAwb } from '../../redux/actions/awb';
import { setSelectedDDMSubmittedDate, setSelectedDDMReceivedDate, setSelectedProductionReceivedDate } from '../../redux/actions/bpp/shipping/shipping';
import { fetchIfuState } from '../../redux/actions/ifu/ifu_state';
import { fetchDdmState } from '../../redux/actions/ddm/ddm_state';
import { changeReloadThumbnail, fetchSegmentationState } from '../../redux/actions/bpp/case_details/segmentation';
import {
  fetchProductionTxGuide,
  onProductionTxGuideTabSelect,
  setProductionTxGuideTabConfig,
} from '../../redux/actions/bpp/production_tx_guide/production_tx_guide';
import {
  clearTargetDateInputs,
  setApplianceDesignTargetDateInput,
  setPreferredShippingInput,
  setSmileDesignTargetDateInput,
} from '../../redux/actions/bpp/shipping/expedite_details';

// Redux Reducers
import { getAwb } from '../../redux/reducers/awb';
import { getSelectedDDMSubmittedDate, getSelectedDDMReceivedDate, getSelectedProductionReceivedDate } from '../../redux/reducers/bpp/shipping/shipping';
import { getIfuState } from '../../redux/reducers/ifu/ifu_state';
import { getUploadPending } from '../../redux/reducers/ifu/ifu_upload';
import { getSelectedProductionTxGuideTab, getAllowedProductionTxGuideTabs } from '../../redux/reducers/bpp/production_tx_guide/production_tx_guide';
import { getReloadThumbnail, getSegmentationState } from '../../redux/reducers/bpp/case_details/segmentation';
import { getDdmState } from '../../redux/reducers/ddm/ddm_state';

// nomenclature
import { getLookup, new_branding_words } from '../../common/nomenclature';
import {
  getApplianceDesignTargetDateInput,
  getPreferredShippingInput,
  getSmileDesignTargetDateInput,
} from '../../redux/reducers/bpp/shipping/expedite_details';
import { fetchCaseFileData } from '../../redux/actions/ipp/case_details/case_files';
import { getCaseFileList, getCaseFileListLoading } from '../../redux/reducers/ipp/case_details/case_files';
import ClipboardCopy from '../../components/clipboard/clipboard';
import PDFViewerInterface from '../../components/pdf-viewer/pdf_viewer';
import { getWireManufacturingStatus, getWireSelectionStatus } from '../../redux/reducers/bpp/wire_selection/wire_selection';
import { ApiServiceInstance } from '../../common/api_service';
import { setRequestedTreatment } from '../../redux/actions/bpp/production_tx_guide/smile_design';
import { CaseStatus } from '../../common/case/case_details.constants';
import { fetchSingleToothIdb } from '../../redux/actions/bpp/single_tooth_idb/single_tooth_idb';
import { getIsHistoricalSingleToothIdb } from '../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';

import { showFalseDeAction, showUnflagFalseDe, showStagedAction, showUnflagEarlyDe, showEarlyDeAction, showUnflagStaged, getHistoricalStatus, replaceCaseByCaseId } from './case_details_helpers';

const STAGED_REASONS = [
  {
    value: 'extraction',
    text: 'Extraction',
  },
  {
    value: 'space_opening',
    text: 'Space Opening',
  },
  {
    value: 'impacted_primary_tooth',
    text: 'Impacted Tooth/Primary Tooth',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

/**
 * Used to displayed all the content related to a case on the BPP.
 *
 * The case details page is where all the details live for a case and includes
 * item request, progress record, and CSQ information. These are shown in a tabular
 * format. This page also contains all the actionable events to move a case from
 * one status to another that is related to the BPP.
 * @component
 * @alias BusinessCaseDetails
 * @category BPP
 */
class Business_Case extends Component {
  constructor(props) {
    super(props);

    this.state = {
      csq_loading: true,
      loading: true,
      pdf_file: null,
      process_loading: false,
      error: false,
      refresh: false,
      case_details: {
        doctor: {},
        patient: {},
        cases: [],
        item_request: [],
        role: '',
      },
      reload_thumbnail: false,
      disable_undo_button: false,
      selected_address_id: '',
      ir_selected_address_id: '',
      selected_tab: 'case',
      selected_case_id: '',
      selected_request_id: '',
      selected_progress_id: '',
      selected_progress_status: '',
      selected_progress_type: '',
      selected_progress_revision: '',
      selected_file_log: [],
      selected_is_historical: false,
      selected_setup: [],
      selected_setup_log: [],
      selected_tx: [],
      selected_vbpc: [],
      edit_tracking: false,
      edit_address: false,
      csq_loaded: false,
      edit_address_id: '',
      edit_selected_address_id: '',
      expedite_note: '',
      expedite_additional_note: '',
      expedite_ship_ind: false,
      expedite_approval_ship_ind: true,
      expedite_target_ship_date: '',
      ext_override_errors: {
        date: false,
        note: false,
        message: '',
      },
      status_comment: '',
      status_message: '',
      status_type: '',
      item_request_mode: 'view',
      progress_record_mode: 'view',
      initial_expedite_target_ship_date: '',
      initial_expedite_ship_ind: false,
      initial_expedite_additional_note: '',
      initial_smile_design_target_date: '',
      initial_appliance_design_target_date: '',
      initial_preferred_shipping_input: '',
      ir_submit_case_id: '',

      patient_firstname: '',
      patient_lastname: '',
      patient_dob: '',
      patient_sex: '',
      patient_ref: '',
      patient_dob_error: false,

      upload_in_progress: false,
      salesforce_url: '',

      rejectModal: false,
      editPatientDetailsPending: false,
      cancelCasePending: false,
      reverseCancellationPending: false,
      showViewIrInvoice: false,
      showCancelCaseModal: false,
      show_target_date_override_modal: false,
      ShowGen1CaseModel: false,
      ShowGen2CaseModel: false,
      showUploadFileCommentModal: false,
      showStatusModal: false,
      showNotesModal: false,
      showIrNotesModal: false,
      showProgressNotesModal: false,
      showFileCommentsModal: false,
      showSetupCommentsModal: false,
      showApproveFileModal: false,
      showRejectFileConfirmModal: false,
      showApproveItemRequestModal: false,
      showUpdateItemRequestModal: false,
      showDeleteProgressRecordModal: false,
      showUpdateProgressRecordModal: false,
      showDeClarificationModal: false,
      showProgressRecordModal: false,
      showCloseRequestModal: false,
      showCsrAddNotesMoal: false,
      showChangeTabModal: false,
      showUpdateAddressModal: false,
      showFalseDeModal: false,
      showEarlyDeModal: false,
      showRemoveFalseDeModal: false,
      changeTabCaseId: '',
      changeTabDataTab: '',

      setups: [],
      setupPath: '',
      setupMaxCnt: 0,
      showHoldModal: false,
      showReverseCancellationModal: false,
      showReverseHoldModal: false,
      setupComments: {},
      s_date: '',
      showEditPatientDetailsModal: false,
      showEditTreatmentPlanModal: false,
      showExpediteDetailsModal: false,
      showExpediteApproval: false,
      showExpediteReject: false,
      showExpediteCancel: false,
      showExpediteRequest: false,
      showExpediteUpdate: false,
      showActiveAddreses: false,
      new_address_id: '',
      ir_submit_mode: '',
      new_files: '',
      ip_conversion: false,
      hold_reasons: [],
      cancel_reasons: [],
      reason_message: '',
      doctor_note: '',
      all_cst: [],
      selected_cst: '',
      saving_cst: false,
      save_tx_plan_changes: false,
      progress_notes: [],
      user_id: '',
      ir_edit_in_progress: false,
      ir_label_generation_process: false,
      clinical_review: {
        modal: false,
        note: '',
        note_char_count: 0,
        in_progress: false,
        selected_flag: false,
      },
      staged: {
        category: '',
        note: '',
      },
      showStagedModal: false,
      doctor_clarification: {
        modal: false,
        note: '',
        note_char_count: 0,
        in_progress: false,
      },
      gen_update: {
        modal: false,
        note: '',
        note_char_count: 0,
        in_progress: false,
      },
      production_tx_guide_tabs_set: false,
      skip_production_tx_guide_modal: false,
      upload_production_tx_guide_modal: false,
      production_tx_guide_manual_file: [],
      dhr_form_modal: false,
      dhr_form_file_path: '',
      production_assigned_to: [{ label: 'Unassigned', value: '' }],

      templateNo: {
        80001: '',
        80002: '',
        80003: '',
        80004: '',
        80005: '',
        80006: '',
        80009: '',
        80010: '',
        80011: '',
        80012: '',
        80013: '',
        80014: '',
        80027: '',
        80028: '',
      },
      approving: false,
      wire_selection_guide: false,
      ir_ws_status: '',
      attributions: {},
      convert_case_generation_modal: false,
      unable_to_reverse_modal: false,
      unable_to_archive_folder: false,
      smile_id: '',
      external_target_date_override: {
        date: '',
        note: '',
      },
    };
    this.txPlanPendingRequestsCount = 0;
    this.txGuidePendingRequestsCount = 0;

    this.getFilesUndoButton.bind(this);
    this.getSetupUndoButton.bind(this);

    this.getFilesZipButton.bind(this);
    this.getStatusDateForVerification.bind(this);
    this.getProgressZipButton.bind(this);

    this.isLatestCancelCase.bind(this);

    this.onAddressConfirm = this.onAddressConfirm.bind(this);

    this.onRejectClick.bind(this);
    this.onRejectDismiss.bind(this);
    this.onApproveClick.bind(this);
    this.onRejectAccept.bind(this);
    this.onCancelCaseModalClick.bind(this);
    this.onCancelCaseModalDismiss.bind(this);
    this.onHoldModalClick.bind(this);
    this.onEditPatientDetailsClick.bind(this);
    this.onHoldModalDismiss.bind(this);
    this.onReverseCancellationModalClick.bind(this);
    this.onReverseCancellationModalDismiss.bind(this);
    this.onReverseHoldModalClick.bind(this);
    this.onReverseHoldModalDismiss.bind(this);
    this.onSetupModalClick.bind(this);
    this.onSetupModalDismiss.bind(this);
    this.onUpload.bind(this);
    this.onRemove.bind(this);
    this.getFiles.bind(this);
    this.hasDropboxLink.bind(this);
    this.hasAssignTo.bind(this);
    this.onIrApproveConfirm.bind(this);
    this.getShippingAddressFromId.bind(this);
    this.getHeader.bind(this);
    this.getMessage.bind(this);
    this.onIrLinkClick.bind(this);
    this.onDeleteProgressRecord.bind(this);
    this.reloadInformation = this.reloadInformation.bind(this);
    this.onUnflagPrecheckClick = this.onUnflagPrecheckClick.bind(this);
    this.onFlagPrecheckClick = this.onFlagPrecheckClick.bind(this);
    this.onUnflagPrecheckConfirm = this.onUnflagPrecheckConfirm.bind(this);
    this.onFlagPrecheckConfirm = this.onFlagPrecheckConfirm.bind(this);
    this.onFlagPrecheckDismiss = this.onFlagPrecheckDismiss.bind(this);
    this.onUnflagPrecheckDismiss = this.onUnflagPrecheckDismiss.bind(this);
    this.getHoldAndCancelReasons = this.getHoldAndCancelReasons.bind(this);
    this.onFlagGenClick = this.onFlagGenClick.bind(this);
    this.onFlagGenConfirm = this.onFlagGenConfirm.bind(this);
    this.onFlagGenCancel = this.onFlagGenCancel.bind(this);
    this.onProductionTxGuideTabSelect = this.onProductionTxGuideTabSelect.bind(this);
    this.onProductionTxGuideUpload = this.onProductionTxGuideUpload.bind(this);
    this.openDHRFormModal = this.openDHRFormModal.bind(this);
    this.closeDHRFormModal = this.closeDHRFormModal.bind(this);
    this.setProcessInProgress = this.setProcessInProgress.bind(this);
  }

  /**
   * Handles dropdown change in the staged modal
   * @function
   * @param {Object} selectedOption - The selected dropdown option
   */
  onStagedDropdownChange = (e) => {
    const {value} = e.target;
    console.log('value: ', value)
    this.setState({
      staged: {
        ...this.state.staged,
        category: value,
      },
    });
  };

  /**
   * Opens the staged modal
   * @function
   */
  openStagedModal = () => {
    this.setState({
      showStagedModal: true,
      staged: {
        ...this.state.staged,
        category: '',
        note: '',
        note_char_count: 0,
        in_progress: false,
      },
    });
  }

  /**
   * Closes the staged modal
   * @function
   */
  closeStagedModal = () => {
    this.setState({
      showStagedModal: false,
      staged: {
        ...this.state.staged,
        in_progress: false,
        note: '',
        note_char_count: 0,
      },
    });
  }

  /**
   * Handles note changes in the staged modal
   * @function
   * @param {String} content - Contains the HTML version of the content
   * @param {Object} delta - Contains the change made since the last instance of react quill
   * @param {String} source - Identify who made the change, default is user
   * @param {Object} editor - Contains the editor object and its helper functions
   */
  onStagedNoteChange = (e) => {
    const {value} = e.target
    this.setState({
      staged: {
        ...this.state.staged,
        note: value,
      },
    });
  }

  /**
   * On focus function for staged note
   * @function
   */
  onFocusStagedNote = () => {
    this.setState({
      staged: { ...this.state.staged, in_progress: true },
    });
  }

  /**
   * Handles confirm action for the staged modal
   * @function
   */
  onStagedConfirm = () => {
    const data = {
      category: this.state.staged.category,
      note: this.state.staged.note,
    };

    Axios.post(`/apiV3/_de/${this.state.selected_case_id}/`, data)
      .then((res) => {
        this.setState({
          case_details: res.data,
          showStagedModal: false,
          staged: {
            category: '',
            note: '',
          },
        });
      })
      .catch((err) => {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          this.setState({
            refresh: true,
            showStagedModal: false,
          });
        }
      });
  }

  getCaseFiles(case_id) {
    return this.props.case_file_list?.[case_id] ?? {};
  }

  getSelectedSetupByCaseId(case_id) {
    let setup_process = this.getCaseFiles(case_id)?.setup_process ?? [];
    return setup_process;
  }

  getCaseByCaseId(case_details, case_id) {
    let cases = [];

    for (let i = 0; i < case_details?.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        cases = case_details.cases[i];
        break;
      }
    }

    return cases;
  }

  /**
   * Retrieves item request by ireq_id
   * @function
   * @param {string} ireq_id - Item request id
   * @returns {Object} - Item request object
   */
  getIreqByIreqId(ireq_id) {
    for (const ireq of this.state.case_details.item_request) {
      if (ireq.ireq_id === ireq_id) {
        return ireq;
      }
    }
    return null;
  }

  getHeader(prefix) {
    const tab_name = this.state.selected_tab === 'item_request' ? 'Item Request' : 'Case';
    const id =
      this.state.selected_tab === 'item_request'
        ? removeIreqIdInitialNumber(this.state.selected_request_id)
        : removeCaseIdInitialNumber(this.state.selected_case_id);

    if (prefix === 'Hold' || prefix === 'Cancel' || prefix === 'Unhold') {
      return `${prefix} ${tab_name} - ${id}`;
    }
    return `${prefix} ${tab_name}`;
  }

  getReverseCancellationMessage() {
    const show_active_address = this.state.showActiveAddresses;
    const message = show_active_address
      ? this.state.case_details.shipping_addresses && this.state.case_details.shipping_addresses.length > 0
        ? `There is no active address selected for the ${this.state.selected_tab === 'item_request' ? 'item request' : 'case'}. Select address from
    the list below`
        : 'No addresses found for this users account. Please contact your InBrace Rep.'
      : 'Are you sure you want to reverse this cancellation?';

    return message;
  }

  getReverseCancellationPlaceHolderText() {
    const show_active_address = this.state.showActiveAddresses;
    return show_active_address ? 'Please specify reason for reverse cancellation (optional)' : 'Enter comments here (optional)';
  }

  getMessage(prefix) {
    const tab_name = this.state.selected_tab === 'item_request' ? 'item request' : 'case';
    const identifier =
      this.state.selected_tab === 'item_request'
        ? removeIreqIdInitialNumber(this.state.selected_request_id)
        : removeCaseIdInitialNumber(this.state.selected_case_id);
    const patient_name = `${this.state.case_details.patient.first_name} ${this.state.case_details.patient.last_name}`;

    if (prefix === 'hold' || prefix === 'cancel' || prefix === 'unhold') {
      return `Are you sure you want to ${prefix} this ${tab_name}?`;
    }

    return `Are you sure you want to ${prefix} ${tab_name} ${identifier} for ${patient_name.trim()}?`;
  }

  getSelectedFileLogByCaseId(case_details, case_id) {
    let my_case = this.getCaseByCaseId(case_details, case_id);
    return my_case.file_review_process.log;
  }

  getSelectedSetupLogByCaseId(case_files) {
    let log = [];

    _.each(case_files.setup_process, (setup_process) => {
      log = log.concat(setup_process.log);
    });

    return log;
  }

  /**
   * Returns the treatment plan using the selected `case_id`
   * @function
   * @return {Object} The treatment plan or an empty Object if not found
   */
  getSelectedTreatmentPlan() {
    for (let c of this.state.case_details.cases) {
      if (c.case_id === this.state.selected_case_id) {
        const extraFields = {};
        if ('de3_arch_to_treat' in c.submission_process.treatment_plan && 'de3_arch_opposing_treat' in c.submission_process.treatment_plan) {
          extraFields['de3_upper_arch_opposing_treat'] = 'current';
          extraFields['de3_lower_arch_opposing_treat'] = 'current';
          if (c.submission_process.treatment_plan['de3_arch_to_treat'] === 'upper') {
            extraFields['de3_upper_arch_opposing_treat'] = c.submission_process.treatment_plan['de3_arch_opposing_treat'];
          } else if (c.submission_process.treatment_plan['de3_arch_to_treat'] === 'lower') {
            extraFields['de3_lower_arch_opposing_treat'] = c.submission_process.treatment_plan['de3_arch_opposing_treat'];
          }
        }
        return {
          archToTreat: c.submission_process.treatment_arch,
          revision_id: c.submission_process.treatment_plan_revision,
          ...c.submission_process.treatment_plan,
          ...extraFields,
        };
      }
    }
    return {};
  }

  getSelectedSubmissionFiles = () => {
    return this.props.case_file_list[this.state.selected_case_id]?.submission_process?.files ?? [];
  };

  getLatestShipCase(case_details) {
    let case_id = '';
    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].status_code === 'STATUS_SHIP') {
        case_id = case_details.cases[i].case_id;
      }
    }
    return case_id;
  }

  getSelectedTxByCaseId(case_details, case_id) {
    let tx = this.props.case_file_list?.[case_id]?.ship_process?.filter((file) => file.file_type === 'tx') ?? [];

    return tx;
  }

  getSelectedVbpcByCaseId(case_details, case_id) {
    let vbpc = this.props.case_file_list?.[case_id]?.ship_process?.filter((file) => file.file_type === 'bpg') ?? [];

    return vbpc;
  }

  getCaseStatus(translate) {
    let status = '';
    for (let i = 0; i < this.state.case_details.cases.length; i++) {
      if (this.state.case_details.cases[i].case_id === this.state.selected_case_id) {
        const status_code = this.state.case_details.cases[i].status_code;
        status = translate ? translateStatus(status_code) : status_code;
        break;
      }
    }

    return status;
  }

  getCadStatus(case_id) {
    for (let i = 0; i < this.state.case_details.cases.length; i++) {
      if (this.state.case_details.cases[i].case_id === case_id) {
        if (this.state.case_details.cases[i].cad_status_log.length > 0 && this.state.case_details.cases[i].cad_status_log[0].cad_statuses.length > 0) {
          return this.state.case_details.cases[i].cad_status_log[0].cad_statuses[0].cad_status;
        }
      }
    }
    return this.getCaseStatus() === 'CAD Process' ? 'Stage I' : '';
  }

  hasACadStatus = (case_id, status) => {
    for (let i = 0; i < this.state.case_details.cases.length; i++) {
      if (this.state.case_details.cases[i].case_id === case_id) {
        if (this.state.case_details.cases[i].cad_status_log.length > 0 && this.state.case_details.cases[i].cad_status_log[0].cad_statuses.length > 0) {
          for (let j = 0; j < this.state.case_details.cases[i].cad_status_log[0].cad_statuses.length; j++) {
            if (this.state.case_details.cases[i].cad_status_log[0].cad_statuses[j].cad_status === status) {
              return true;
            }
          }
        }
      }
    }

    return false;
  };

  getAddressByCaseId(case_details, case_id) {
    let address = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        address = case_details.cases[i].shipping_address;
        break;
      }
    }

    return address;
  }

  getAddressByIreqId(case_details, ireq_id) {
    let address_id = '';

    for (let i = 0; i < case_details.item_request.length; i++) {
      if (case_details.item_request[i].ireq_id === ireq_id) {
        address_id = case_details.item_request[i].shipping_address__id;
        break;
      }
    }

    return this.getShippingAddressFromId(address_id, this.state.case_details.shipping_addresses);
  }
  getSelectedAddressByCaseId(case_details, case_id) {
    let address_id = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        address_id = case_details.cases[i].shipping_address.id;
        break;
      }
    }

    return address_id;
  }

  getSelectedCaseArch(case_details, case_id) {
    let arch = 'both';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].submission_process && case_details.cases[i].submission_process.treatment_arch) {
          arch = case_details.cases[i].submission_process.treatment_arch.toLowerCase();
        }

        break;
      }
    }

    return arch;
  }

  getProdTxPlanArchInfo = (case_details, case_id) => {
    let arch = 'both';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].treatment_arch) {
          return case_details.cases[i].treatment_arch;
        }

        break;
      }
    }

    return arch;
  };

  getSelectedDDMReceivedDateByCaseId(case_details, case_id) {
    let ddm_received_date = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].ddm_received_date) {
          ddm_received_date = case_details.cases[i].ddm_received_date;
        }
        break;
      }
    }

    return ddm_received_date;
  }

  getSelectedDDMSubmittedDateByCaseId(case_details, case_id) {
    let ddm_submitted_date = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].ddm_submitted_date) {
          ddm_submitted_date = case_details.cases[i].ddm_submitted_date;
        }
        break;
      }
    }

    return ddm_submitted_date;
  }

  getSelectedProductionReceivedDateByCaseId(case_details, case_id) {
    let production_received_date = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].production_received_date) {
          production_received_date = case_details.cases[i].production_received_date;
        }
        break;
      }
    }

    return production_received_date;
  }

  /**
   * Looks for next status that occurs after doctor has approved setup
   *
   * - Approved setup for old workflow is the status "InBrace Manufacturing Case"
   * - Approved setup for new workflow is the status "CAD Process"
   *
   * @function
   * @param {Object} case_details - Case Details object containing status log needed to extract date
   * @param {String} case_id - Contains case id to be used to parse through case details
   * @return {Object} An object that represent a date
   */
  getSelectedApprovedSetupCaseStatusDateByCaseId(case_details, case_id) {
    const currentCase = case_details.cases.find((c) => c.case_id === case_id);
    let approvalDate = currentCase?.approval_date || '';
    if (!approvalDate) {
      const caseStatus = currentCase?.case_statuses.find((s) => s.case_status === CaseStatus.ApplianceDesign);
      approvalDate = caseStatus?.created_date;
    }
    return moment.tz(approvalDate, 'America/Los_Angeles').format();
  }

  getSelectedManufacturingCaseStatusDateByCaseId(case_details, case_id) {
    let manufacturing_case_status_date = '';

    for (let i = 0; i < case_details.cases.length; i++) {
      if (case_details.cases[i].case_id === case_id) {
        if (case_details.cases[i].case_statuses) {
          for (let j = 0; j < case_details.cases[i].case_statuses.length; j++) {
            if (case_details.cases[i].case_statuses[j].case_status === 'INBRACE Manufacturing Case') {
              manufacturing_case_status_date = case_details.cases[i].case_statuses[j].created_date;
              break;
            }
          }
        }
        break;
      }
    }

    manufacturing_case_status_date = moment.tz(manufacturing_case_status_date, 'America/Los_Angeles').format();

    return manufacturing_case_status_date;
  }

  getSelectedAddressByRequestId(case_details, ireq_id) {
    let address_id = '';

    for (let i = 0; i < case_details.item_request.length; i++) {
      if (case_details.item_request[i].ireq_id === ireq_id) {
        address_id = case_details.item_request[i].shipping_address__id;
        break;
      }
    }

    return address_id;
  }

  isLatestCancelCase(cases) {
    return this.state.case_details.cases[this.state.case_details.cases.length - 1].case_id === cases.case_id && cases.status_code === 'STATUS_CANCEL';
  }

  isLatestCancelIr(item_request) {
    // return (
    //   this.state.case_details.item_request[this.state.case_details.item_request.length - 1].ireq_id === item_request.ireq_id &&
    //   item_request.ireq_status === 'Item Request Cancelled'
    // );
    return true;
  }

  isApprovedIr(item_request) {
    let is_approved_item_request = false;
    let current_request_status = [];
    this.state.case_details.item_request_status.forEach((status) => {
      if (status.ireq_id === item_request.ireq_id) {
        current_request_status.push(status);
      }
    });
    current_request_status.forEach((s) => {
      if (s.status === 'INBRACE Approved Item Request' || s.status === 'InBrace Approved Item Request') {
        is_approved_item_request = true;
      }
    });
    return is_approved_item_request;
  }

  UNSAFE_componentWillMount() {
    // handles salesforce alias for case ids
    const pathname = this.props.location.pathname.split('/');
    const case_id = getCaseIdFromUrl(this.props.location.pathname);
    const sf_case_id_regex = /[A-Z0-9]{5,6}-DE$|[A-Z0-9]{5,6}-R$/;
    if (sf_case_id_regex.test(case_id)) {
      pathname.pop();
      pathname.push(convertAliasToCaseId(case_id));
      this.props.history.replace(pathname.join('/'));
    }
  }

  componentDidMount() {
    this.reloadInformation(null, null, true);
    if (window.$('.select-address')) {
      window.$('.select-address').selectpicker();
    }

    window.$('[data-toggle="tooltip"]').tooltip();

    const case_id = getCaseIdFromUrl(this.props.location.pathname);

    this.props.fetchIfuState(case_id);
    this.props.fetchDdmState(case_id);
    this.props.fetchSegmentationState(case_id);
  }

  setMinHeight = (node, min_height) => {
    const thisNode = ReactDOM.findDOMNode(node);
    if (thisNode) {
      thisNode.style.minHeight = min_height;
    }
  };

  setProcessInProgress(state) {
    this.setState({
      process_loading: state,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    window.$('[data-toggle="tooltip"]').tooltip();
    try {
      const address_length = this.state.case_details.shipping_addresses.filter(function (a) {
        return a.is_active;
      }).length;
      const adr_lt = address_length > 3 ? 3 * 100 + '%' : address_length * 100 + '%';

      if (this.state.selected_tab === 'item_request') {
        const ireq_id = this.state.selected_request_id;
        let item_request = this.state.case_details.item_request.filter(function (ir) {
          return ir.ireq_id === ireq_id;
        })[0];
        if (item_request.ireq_status === 'Item Request Shipped' || item_request.shipping_approved_ind) {
          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker('hide');
          }
        } else {
          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker({
              size: address_length,
            });
          }
          this.setMinHeight(document.querySelectorAll('div.dropdown-menu.open')[0], adr_lt);
        }
      } else if (this.state.selected_tab === 'case') {
        const case_id = this.state.selected_case_id;
        let selected_case = this.state.case_details.cases.filter(function (c) {
          return c.case_id === case_id;
        })[0];
        if (selected_case.status_code === 'STATUS_SHIP' || selected_case.shipping_approved_ind) {
          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker('hide');
          }
        } else {
          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker({
              size: address_length,
            });
          }
          this.setMinHeight(document.querySelectorAll('div.dropdown-menu.open')[0], adr_lt);
        }
      }
    } catch (error) {
      // console.log(error);
    }
    if (this.props.reload_thumbnail) {
      this.setState({ reload_thumbnail: true });
      this.props.changeReloadThumbnail(false);
    }

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.refreshInfo &&
      this.props.history.location.state.refreshInfo === 'true'
    ) {
      this.reloadInformation();
      this.props.history.push({
        state: {
          refreshInfo: 'false',
          selectLastestCSR:
            this.props.history.location.state && this.props.history.location.state.selectLastestCSR
              ? this.props.history.location.state.selectLastestCSR
              : 'false',
        },
      });
    }
  }
  /**
   * Update the verbiage for the question texts
   * @param {string|object} input
   * @returns {string|object} updated input
   * @function
   */
  updateVerbiage(input) {
    let csq = input;
    let returnString = false;
    if (typeof input === 'string') {
      returnString = true;
      csq = JSON.parse(input);
    }
    csq.questions
      .filter((obj) => obj.question_text && obj.question_text.indexOf('bonding date') > -1)
      .forEach((obj) => {
        obj.form_text = 'Date of Fitting Appointment';
        obj.question_text = 'Date of Fitting Appointment*';
      });
    return returnString ? JSON.stringify(csq) : csq;
  }

  /**
   * Shows the edit treatment plan modal\
   * @param {boolean} updatedState - flag to check if we should update the state or not
   * @param {boolean} callback - flag to check if callback function should be called
   * @function
   */
  reloadInformation(updatedState, callback, init = false) {
    setTokenHeader();
    const that = this;
    const case_id = convertAliasToCaseId(that.props.match.params.id);
    let selected_request_id = '';
    let selected_progress_id = '';
    let selected_progress_status = this.state.selected_progress_status;
    let selected_progress_type = this.state.selected_progress_type;
    let selected_progress_revision = this.state.selected_progress_revision;

    const current_path = that.props.history.location.pathname;
    let selected_tab = that.state.selected_tab;

    if (current_path.includes('/item')) {
      selected_tab = 'item_request';
    }
    if (current_path.includes('/progress')) {
      selected_tab = 'progress';
    }

    if (
      !that.state.selected_request_id &&
      that.props.history &&
      that.props.history.location &&
      that.props.history.location.state &&
      that.props.history.location.state.selectedReqId
    ) {
      selected_request_id = that.props.history.location.state.selectedReqId;
    } else if (this.state.selected_request_id && selected_tab === 'item_request') {
      selected_request_id = this.state.selected_request_id;
    }

    if (that.props.history && that.props.history.location && that.props.history.location.state && that.props.history.location.state.selectedProgressId) {
      selected_progress_id = parseInt(that.props.history.location.state.selectedProgressId);
    }
    this.props.fetchCaseFileData(case_id);
    if (updatedState) {
      that.setState({
        loading: false,
        case_details: updatedState.case_details,
        selected_setup: updatedState.selected_setup,
        ip_conversion: false,
      });
    } else {
      ApiServiceInstance.fetchCaseDetails(case_id)
        .then((res) => {
          if (
            !selected_request_id ||
            (that.props.history.location.state &&
              that.props.history.location.state.selectLastestCSR &&
              that.props.history.location.state.selectLastestCSR === 'true')
          ) {
            selected_request_id = res.data.item_request.length > 0 ? res.data.item_request[res.data.item_request.length - 1].ireq_id : '';
            if (
              that.props.history.location.state &&
              that.props.history.location.state.selectLastestCSR &&
              that.props.history.location.state.selectLastestCSR === 'true'
            ) {
              that.props.history.push({ state: { refreshInfo: 'false', selectLastestCSR: 'false' } });
            }
          }

          const progress_records =
            res.data.progress_record.length > 0
              ? res.data.progress_record.filter(function (pr) {
                  return !pr.status.includes('Draft');
                })
              : res.data.progress_record;

          if (!selected_progress_id) {
            selected_progress_id = progress_records.length > 0 ? progress_records[progress_records.length - 1].progress_id : '';
            if (selected_progress_id) {
              let progress_record = progress_records.filter(function (pr) {
                return pr.progress_id === parseInt(selected_progress_id);
              })[0];
              if (progress_record) {
                selected_progress_status = progress_record.status;
                selected_progress_type = progress_record.record_type;
                selected_progress_revision = progress_record.revision_id;
              } else {
                selected_progress_id = progress_records.length > 0 ? progress_records[progress_records.length - 1].progress_id : '';
                let progress_record = progress_records.filter(function (pr) {
                  return pr.progress_id === parseInt(selected_progress_id);
                })[0];
                selected_progress_status = progress_record.status;
                selected_progress_type = progress_record.record_type;
                selected_progress_revision = progress_record.revision_id;
              }
            }
          } else if (selected_tab === 'progress' && progress_records.length > 0) {
            let progress_record = progress_records.filter(function (pr) {
              return pr.progress_id === parseInt(selected_progress_id);
            })[0];
            if (progress_record && progress_record !== undefined && progress_record.status) {
              selected_progress_status = progress_record.status;
              selected_progress_type = progress_record.record_type;
              selected_progress_revision = progress_record.revision_id;
            } else {
              selected_progress_id = progress_records.length > 0 ? progress_records[progress_records.length - 1].progress_id : '';
              let progress_record = progress_records.filter(function (pr) {
                return pr.progress_id === parseInt(selected_progress_id);
              })[0];
              selected_progress_status = progress_record.status;
              selected_progress_type = progress_record.record_type;
              selected_progress_revision = progress_record.revision_id;
            }
          }
          if (selected_tab === 'item_request' && res.data.item_request.length === 0) {
            selected_tab = 'case';
          }
          if (selected_tab === 'progress' && res.data.progress_record.length === 0) {
            selected_tab = 'case';
          }

          let progress_notes = [];
          progress_records
            .slice(0)
            .reverse()
            .map((progress_record, index) => {
              let status = '';
              if (progress_record.status === 'Request Closed') {
                status = progress_record.status;
              }
              if (progress_record.notes && progress_record.notes.length > 0) {
                progress_record.notes.forEach((note) =>
                  progress_notes.push({
                    id: note.id,
                    no: res.data.progress_record.length - index,
                    type: progress_record.record_type,
                    note_text: note.note_text,
                    user: note.user,
                    date: note.date,
                    status: status,
                    generic: note.generic,
                    created_date: note.created_date,
                    created_by_id: note.created_by_id,
                  })
                );
              }

              return '';
            });

          progress_notes = _.orderBy(progress_notes, 'created_date', ['desc']);

          let case_details = res.data;
          case_details.progress_record = progress_records;

          let attribution_response = res.data.attribution_response ? res.data.attribution_response : {};
          that.setState({
            loading: false,
            case_details: case_details,
            selected_case_id: case_id,
            selected_is_historical: getHistoricalStatus(res.data, case_id),
            selected_setup: updatedState ? updatedState.selected_setup : that.getSelectedSetupByCaseId(case_id),
            selected_address_id: that.getSelectedAddressByCaseId(res.data, case_id),
            ir_selected_address_id: that.getSelectedAddressByRequestId(res.data, selected_request_id),
            selected_tx: that.getSelectedTxByCaseId(res.data, case_id),
            selected_vbpc: that.getSelectedVbpcByCaseId(res.data, case_id),
            selected_tab: selected_tab,
            selected_request_id: selected_request_id,
            selected_progress_id: selected_progress_id,
            selected_progress_status: selected_progress_status,
            selected_progress_revision: selected_progress_revision,
            selected_progress_type: selected_progress_type,
            ir_submit_case_id: that.getLatestShipCase(res.data),
            ip_conversion: false,
            all_cst: this.addUnassignedToList(res.data.available_cst),
            selected_cst: this.getSelectedCSTByProgressRecord(res.data, selected_progress_id),
            progress_notes: progress_notes,
            reload_finished: true,
            user_id: res.data.request_id,
            ir_edit_in_progress: false,
            attributions: attribution_response,
            smile_id: res.data.smile_id,
          });

          that.props.setSelectedDDMSubmittedDate(that.getSelectedDDMSubmittedDateByCaseId(res.data, case_id));
          that.props.setSelectedDDMReceivedDate(that.getSelectedDDMReceivedDateByCaseId(res.data, case_id));
          that.props.setSelectedProductionReceivedDate(that.getSelectedProductionReceivedDateByCaseId(res.data, case_id));

          if (selected_request_id && selected_tab === 'item_request') {
            this.props.fetchAwb(selected_request_id);
          }

          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker('refresh');
          }

          that.props.fetchProductionTxGuide(case_id);
          that.props.setRequestedTreatment(this.getSelectedRequestedTreatmentByCaseId(res.data, case_id));

          if (!that.state.production_tx_guide_tabs_set) {
            that.props.setProductionTxGuideTabConfig(this.getCaseStatus(), this.getCadStatus(case_id), this.getCaseByCaseId(case_details, case_id));
            that.setState({
              production_tx_guide_tabs_set: true,
            });
          }
        })
        .then(function () {
          if (callback) {
            callback();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
      that.setSaleforceLink(case_id);
    }
  }

  /**
   * Sets sales force link
   * @param {string} case_id - case id string
   * @function
   */
  setSaleforceLink = (case_id) => {
    // let that = this;
    // Axios.get(`/apiV2/salesforce/get_url/case/${case_id}`)
    //   .then(function (res) {
    //     if (res && res.data && res.data.url) {
    //       that.setState({
    //         salesforce_url: res.data.url,
    //       });
    //     }
    //   })
    //   .catch(function (err) {
    //     // do nothing
    //   });
  };

  getReadOnlyCST = () => {
    const selected_cst = this.state.selected_cst;
    let display_value = 'Unassigned';

    if (selected_cst && selected_cst.length > 0 && selected_cst[0] && selected_cst[0].label) {
      display_value = selected_cst[0].label;
    }

    return display_value;
  };

  /**
   * Gets selected cst for progress record
   * @param {array} cases - cases array
   * @param {string} progress_id - progress id string
   * @function
   */
  getSelectedCSTByProgressRecord = (cases, progress_id) => {
    const progress_record = cases.progress_record;
    let selected_cst = {
      label: 'Unassigned',
      value: '',
    };

    for (let i = 0; i < progress_record.length; i++) {
      if (progress_record[i].progress_id === parseInt(progress_id)) {
        selected_cst = progress_record[i].cst;
        break;
      }
    }

    return [selected_cst];
  };

  /**
   * Adds value to the list
   * @param {array} list - list array
   * @function
   * @return {array} returns new list
   */
  addUnassignedToList = (list) => {
    list.unshift({
      label: 'Unassigned',
      value: '',
    });

    return list;
  };

  getFiles(data, fileType, case_id) {
    let files = [];

    if (data && data.files) {
      for (let i = 0; i < data.files.length; i++) {
        if (data.files[i].file_type === fileType && case_id === data.files[i].case_id) {
          files.push(data.files[i]);
        }
      }
    }

    return files;
  }

  /**
   * Cancel button click for modal
   * @param {object} event - button click event
   * @function
   */
  onCancelButtonClick = (event) => {
    const that = this;
    let form_data = new FormData();

    const status_comment = this.state.doctor_note ? this.state.status_comment + ': ' + this.state.doctor_note : this.state.status_comment;
    form_data.append('status_comment', status_comment);
    form_data.append('reason_message', this.state.reason_message);
    const not_De = !isCaseDE(this.getLatestCaseIDFromCases());
    const currentCaseStatus = this.getLatestCaseStatusCode();
    const post_smile_design_approval = isAfterSmileDesignApproval(currentCaseStatus) && currentCaseStatus !== 'Provider Edit Review';
    const email_slugs =
      post_smile_design_approval && not_De
        ? ['case-cancellation-3', 'case-cancellation-4', 'assigned-case-cancellation']
        : ['case-cancellation-1', 'assigned-case-cancellation'];

    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/cancel`, form_data)
        .then((res) => {
          that.setState({
            case_details: res.data,
            showCancelCaseModal: false,
            status_comment: '',
          });

          Axios.post(`/api/email/?slug=ir-cancellation-1&ireqId=${res.data.item_request_id}&method=standard&provider=${window.location.origin}`);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showCancelCaseModal: false,
            });
          }
        });
    } else {
      this.setState(
        {
          cancelCasePending: true,
        },
        () => {
          Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/cancel`, form_data)
            .then((res) => {
              that.setState({
                case_details: res.data,
                showCancelCaseModal: false,
                status_comment: '',
                reason_message: '',
                doctor_note: '',
                unable_to_archive_folder: !res.data.case_folder_archive,
                cancelCasePending: false,
              });

              that.props.setProductionTxGuideTabConfig(
                that.getCaseStatusByCaseId(that.state.selected_case_id),
                that.getCadStatus(that.state.selected_case_id),
                that.getCaseByCaseId(res.data, that.state.selected_case_id)
              );

              email_slugs.forEach((slug) => {
                Axios.post(
                  `/api/email/?slug=${slug}&caseId=${removeCaseIdInitialNumber(that.state.selected_case_id)}&method=standard&provider=${
                    window.location.origin
                  }&doctorId=${that.state.case_details.doctor.id}&post_smile_design_approval=${post_smile_design_approval}`
                );
              });
            })
            .catch((err) => {
              handleHttpRequestError(err, that);

              if (err && err.response && err.response.status === 409) {
                that.setState({
                  showCancelCaseModal: false,
                  cancelCasePending: false,
                });
              }
            });
        }
      );
    }
  };

  onGenNoteChange = (content, delta, source, editor) => {
    this.setState({
      gen_update: {
        ...this.state.gen_update,
        note: editor.getText(content),
        note_char_count: getQuillHTMLTextContentLength(content),
        in_progress: true,
      },
    });
  };

  onFocusGenNote = () => {
    this.setState({
      gen_update: { ...this.state.gen_update, in_progress: true },
    });
  };

  onFlagGenClick = (event) => {
    this.setState({
      gen_update: {
        ...this.state.gen_update,
        modal: true,
      },
    });
  };

  onFlagGenConfirm = (event) => {
    const that = this;
    const flag = this.state.case_details.cases[0].gen_2 ? `unflag_gen2` : `flag_gen2`;
    return Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/${flag}`)
      .then(function (res) {
        let formData = new FormData();
        let note_text =
          `Technology Updated - Switched from ${flag === 'flag_gen2' ? `Gen 1 to Gen 2.0` : `Gen 2.0 to Gen 1`} Reason : ` + that.state.gen_update.note;
        formData.append('note_text', note_text);
        that.onAddNote(formData);
        that.setState({
          case_details: res.data,
          gen_update: {
            modal: false,
            note: '',
            in_progress: false,
            note_char_count: 0,
          },
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({
            gen_update: {
              modal: false,
              note: '',
              in_progress: false,
              note_char_count: 0,
            },
          });
        }
      });
  };

  onAddNote(formData) {
    const that = this;
    Axios.post(`/api/note/?case_id=${this.state.selected_case_id}`, formData)
      .then(function (res) {})
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  onFlagGenCancel = (event) => {
    this.setState({
      gen_update: {
        modal: false,
        note: '',
        in_progress: false,
        note_char_count: 0,
      },
    });
    modalClear();
  };

  /**
   * Assigns new cst for progress record or csr
   * @param {object} event - button click event
   * @param {string} id - id for progress record
   * @function
   */
  onChangeList = (event, id) => {
    let that = this;
    let data = new FormData();

    data.append('assignment_id', event.value);

    that.setState({
      saving_cst: true,
    });

    Axios.post(`/apiV2/praction/${id}/assign_cst`, data).then(function (res) {
      that.setState({ selected_cst: [event], saving_cst: false });
    });
  };

  /**
   * Rejects Case
   * @param {object} event - button click event
   * @function
   */
  onRejectAccept = (event) => {
    const that = this;
    let data = new FormData();
    data.append('status_comment', this.state.status_comment);

    Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/incomplete`, data)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          status_comment: '',
          showRejectFileConfirmModal: false,
          reload_thumbnail: true,
        });

        modalClear();
        that.props.fetchSegmentationState(that.state.selected_case_id);

        Axios.post(
          `/api/email/?slug=action-required-resubmit-case-records-1&caseId=${removeCaseIdInitialNumber(that.state.selected_case_id)}&method=standard&doctorId=${
            that.state.case_details.doctor.id
          }&provider=${window.location.origin}`,
          data
        );
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showRejectFileConfirmModal: false,
          });
        }
      });
  };

  /**
   * Updates CAD workorder reference number
   * @param {string} ref_no - work order reference number string
   * @function
   */
  updateCadWo = (ref_no) => {
    const that = this;
    let form_data = new FormData();
    form_data.append('cad_wo', ref_no);
    Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/update_cad_wo`, form_data)
      .then(function (res) {
        that.setState({
          case_details: res.data,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Updates Production workorder reference number
   * @param {string} ref_no - work order reference number string
   * @function
   */
  updateProductionWo = (ref_no) => {
    const that = this;
    let form_data = new FormData();
    form_data.append('production_wo', ref_no);
    Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/update_production_wo`, form_data)
      .then(function (res) {
        that.setState({
          case_details: res.data,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Sets state for item request update in progress flag
   * @param {string} value - work order reference number string
   * @function
   */
  setIrEditInProgress = (value) => {
    this.setState({
      ir_edit_in_progress: value,
    });
  };

  updateIrProductionWo = (ref_no, ireqdetails_id) => {
    const that = this;
    let form_data = new FormData();
    let trim_ref_no = ref_no.trimEnd();
    form_data.append('production_wo', trim_ref_no);
    Axios.put(`/apiV2/ireqdetails/${ireqdetails_id}/production_wo`, form_data)
      .then(function (res) {
        that.reloadInformation();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Sets state for item request label generation process flag
   * @param {string} value - work order reference number string
   * @function
   */
  irRestartLabelGeneration = (value) => {
    this.setState({
      ir_label_generation_process: value,
    });
  };

  /**
   * Reverses the cancel status for case or item request
   * @param {object} event - button click event
   * @function
   */
  onReverseCancelStatusClick = (event) => {
    const that = this;
    let form_data = new FormData();
    if (this.state.status_comment) {
      form_data.append('status_comment', that.state.status_comment);
    }

    if (this.state.new_address_id) {
      form_data.append('address_id', this.state.new_address_id);
    }

    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/remove_cancel`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            selected_address_id: that.getSelectedAddressByCaseId(res.data, that.state.selected_case_id),
            showReverseCancellationModal: false,
            status_comment: '',
            showActiveAddreses: false,
          });
        })
        .catch(function (err) {
          if (err.response.status === 410) {
            that.setState({
              showReverseCancellationModal: true,
              showActiveAddreses: true,
            });
            window.$('#reverseCancel').modal('show');
          } else {
            handleHttpRequestError(err, that);

            if (err && err.response && err.response.status === 409) {
              that.setState({
                showReverseCancellationModal: false,
              });
            }
          }
        });
    } else {
      that.setState(
        {
          reverseCancellationPending: true,
        },
        () => {
          Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/remove_cancel`, form_data)
            .then(function (res) {
              that.setState({
                case_details: res.data,
                selected_address_id: that.getSelectedAddressByCaseId(res.data, that.state.selected_case_id),
                showReverseCancellationModal: false,
                status_comment: '',
                showActiveAddreses: false,
                unable_to_archive_folder: !res.data.case_folder_archive,
                reverseCancellationPending: false,
              });
              that.reloadInformation();
              that.props.setProductionTxGuideTabConfig(
                that.getCaseStatusByCaseId(that.state.selected_case_id),
                that.getCadStatus(that.state.selected_case_id),
                that.getCaseByCaseId(res.data, that.state.selected_case_id)
              );
              that.sendAssignedUserEmail('assigned-case-cancellation-reversed');
            })
            .catch(function (err) {
              if (err.response && err.response.status === 410) {
                that.setState({
                  showReverseCancellationModal: true,
                  showActiveAddreses: true,
                  reverseCancellationPending: false,
                });
                window.$('#reverseCancel').modal('show');
              } else {
                handleHttpRequestError(err, that);
                if (err && err.response && err.response.status === 409) {
                  that.setState({
                    showReverseCancellationModal: false,
                    reverseCancellationPending: false,
                  });
                }
              }
            });
        }
      );
    }
  };

  /**
   * Reverses the hold status for case or item request
   * @param {object} event - button click event
   * @function
   */
  onReverseHoldStatusClick = (event) => {
    const that = this;
    let form_data = new FormData();
    if (this.state.status_comment) {
      form_data.append('status_comment', that.state.status_comment);
    }

    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/remove_hold`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            showReverseHoldModal: false,
            status_comment: '',
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showReverseHoldModal: false,
            });
          }
        });
    } else {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/remove_hold`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            showReverseHoldModal: false,
            status_comment: '',
          });

          that.sendAssignedUserEmail('assigned-case-resumed');
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showReverseHoldModal: false,
            });
          }
        });
    }
  };

  /**
   * Adds notes for clinical support request
   * @param {object} event - button click event
   * @function
   */
  onAddCsrNotesClick = (event) => {
    const that = this;
    let selected_tab = this.state.selected_tab;
    let form_data = new FormData();
    const status_comment = this.state.status_comment;
    form_data.append('status_comment', status_comment);
    Axios.post(`/apiV2/praction/${this.state.selected_progress_id}/add_notes`, form_data)
      .then(function (res) {
        if (selected_tab === 'progress' && res.data.progress_record.length === 0) {
          selected_tab = 'case';
        }
        if (selected_tab === 'item_request' && res.data.item_request.length === 0) {
          selected_tab = 'case';
        }
        let progress_notes = [];
        res.data.progress_record
          .slice(0)
          .reverse()
          .map((progress_record, index) => {
            let status = '';
            if (progress_record.status === 'Request Closed') {
              status = progress_record.status;
            }
            if (progress_record.notes && progress_record.notes.length > 0) {
              progress_record.notes.forEach((note) =>
                progress_notes.push({
                  id: note.id,
                  no: res.data.progress_record.length - index,
                  type: progress_record.record_type,
                  note_text: note.note_text,
                  user: note.user,
                  date: note.date,
                  status: status,
                  generic: note.generic,
                  created_date: note.created_date,
                  created_by_id: note.created_by_id,
                })
              );
            }

            return '';
          });
        progress_notes = _.orderBy(progress_notes, 'created_date', ['desc']);
        that.setState({
          case_details: res.data,
          showCsrAddNotesMoal: false,
          progress_notes: progress_notes,
        });
        modalClear();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Deletes progress record or clinical support request
   * @param {object} event - button click event
   * @function
   */
  onDeleteClick = (event) => {
    const that = this;
    let selected_progress_id = '';
    let selected_tab = this.state.selected_tab;
    const status_comment = this.state.status_comment;
    let data = {
      status_comment,
    };
    Axios.delete(`/apiV2/praction/${this.state.selected_progress_id}/delete_record`, { data })
      .then(function (res) {
        selected_progress_id = res.data.progress_record.length > 0 ? res.data.progress_record[res.data.progress_record.length - 1].progress_id : '';
        if (selected_tab === 'progress' && res.data.progress_record.length === 0) {
          selected_tab = 'case';
        }
        if (selected_tab === 'item_request' && res.data.item_request.length === 0) {
          selected_tab = 'case';
        }

        let case_id = that.state.selected_case_id;

        that.setState({
          case_details: res.data,
          selected_progress_id: parseInt(selected_progress_id),
          selected_tab: selected_tab,
          selected_address_id: selected_tab === 'case' ? that.getSelectedAddressByCaseId(that.state.case_details, case_id) : that.state.selected_address_id,
          selected_is_historical: selected_tab === 'case' ? that.getHistoricalStatus(that.state.case_details, case_id) : that.state.selected_is_historical,
          selected_setup: selected_tab === 'case' ? that.getSelectedSetupByCaseId(case_id) : that.state.selected_setup,
          selected_tx: selected_tab === 'case' ? that.getSelectedTxByCaseId(that.state.case_details, case_id) : that.state.selected_tx,
          selected_vbpc: selected_tab === 'case' ? that.getSelectedVbpcByCaseId(that.state.case_details, case_id) : that.state.selected_vbpc,
          showDeleteProgressRecordModal: false,
        });

        that.props.setSelectedDDMSubmittedDate(
          selected_tab === 'case' ? that.getSelectedDDMSubmittedDateByCaseId(that.state.case_details, case_id) : that.props.selected_ddm_submitted_date
        );
        that.props.setSelectedDDMReceivedDate(
          selected_tab === 'case' ? that.getSelectedDDMReceivedDateByCaseId(that.state.case_details, case_id) : that.props.selected_ddm_received_date
        );
        that.props.setSelectedProductionReceivedDate(
          selected_tab === 'case'
            ? that.getSelectedProductionReceivedDateByCaseId(that.state.case_details, case_id)
            : that.props.selected_production_received_date
        );

        modalClear();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Sets state to reload thumbnail to false
   * @function
   */
  onThumbnailReLoad = () => {
    this.setState({
      reload_thumbnail: false,
    });
  };

  /**
   * Approves case on button clicked
   * @param {object} event - button click event
   * @function
   */
  onApproveClick = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/complete`)
      .then(function (res) {
        that.props.fetchCaseFileData(that.state.selected_case_id);
        that.setState({
          case_details: res.data,
          selected_setup: that.getSelectedSetupByCaseId(that.state.selected_case_id),
          showApproveFileModal: false,
          reload_thumbnail: true,
        });
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showApproveFileModal: false,
          });
        }
      });
  };

  /**
   * Opens modal for delete of progress record
   * @param {object} event - button click event
   * @function
   */
  onDeleteProgressRecord = (event) => {
    const type = event.target.dataset.type;
    this.setState({
      showDeleteProgressRecordModal: true,
      selected_progress_type: type,
    });
  };

  /**
   * Opens modal for clinical support request notes
   * @param {object} event - button click event
   * @function
   */
  addCsrNote = (event) => {
    this.setState({
      showCsrAddNotesMoal: true,
    });
  };

  /**
   * Opens modal for update progress record
   * @param {object} event - button click event
   * @function
   */
  onUpdateProgressRecordClick = (event) => {
    const type = event.target.dataset.type;
    const revision = event.target.dataset.revision;

    this.setState({
      showUpdateProgressRecordModal: true,
      selected_progress_type: type,
      selected_progress_revision: revision,
    });
  };

  /**
   * Opens modal for update progress record
   * @param {object} event - button click event
   * @function
   */
  onUpdateProgressRecord = (event) => {
    const that = this;
    const type = event.target.dataset.type;
    that.setState({
      loading: true,
    });
    Axios.post(`/apiV2/praction/${this.state.selected_progress_id}/copy_files`)
      .then(function (res) {
        let case_details = res.data;
        case_details.progress_record.forEach((progress_record) => {
          progress_record.csq_data = that.updateVerbiage(progress_record.csq_data);
        });
        that.setState({
          selected_progress_type: type,
          case_details: case_details,
          progress_record_mode: 'update',
          new_files: res.data.new_files,
          loading: false,
          showUpdateProgressRecordModal: false,
        });
        modalClear();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  getProgressRecordPdfUrl = () => {
    const progress_id = this.state.selected_progress_id;
    let url = `PROGRESS_FILES/${progress_id}/${progress_id}_RECORDS/DOCUMENTS/${progress_id}-csq-form.pdf`;

    return url;
  };

  getProgressRecordPdfDate() {
    const progress_id = this.state.selected_progress_id;
    const progress_info = this.state.case_details.progress_record.find((pr) => pr.progress_id === progress_id);
    return progress_info?.modified_date ?? new Date();
  }

  getAnswerFromQuestionnaire = (questions, target_question, target_subquestion = '') => {
    let result = 'N/A';

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].question_text === target_question) {
        result = target_subquestion ? this.getAnswerFromQuestionnaire(questions[i].multiple_question, target_subquestion) : questions[i].answer_text;
        break;
      }
    }

    return result;
  };

  prRecordType(progress_record) {
    let return_text = 'Progress Record';
    if (progress_record.revision_id === 'CSR1.0' && progress_record.csq_data) {
      let csq_data = JSON.parse(progress_record.csq_data);
      let record_type = csq_data.questions.filter(function (a) {
        return a.friendly_name === 'submission_type';
      })[0].answer_text;
      if (record_type) {
        return_text = record_type;
      }
    }
    return return_text;
  }

  /**
   * Displays progress record data
   * @param {object} records - progress record data
   * @function
   */
  getProgressRecordDetailDisplay = (records) => {
    let data = {};

    if (records.csq_data) {
      data = JSON.parse(records.csq_data);
    }

    return (
      <div className="row">
        {records.revision_id === 'CSR1.0' ? (
          <div className="col-lg-12 csr-box">
            <div className="bold-text">Treatment Notes</div>
            <div>
              {this.getAnswerFromQuestionnaire(data.questions, 'Treatment notes from most recent appointment')
                ? this.getAnswerFromQuestionnaire(data.questions, 'Treatment notes from most recent appointment')
                : 'N/A'}
            </div>
          </div>
        ) : null}

        <div className="col-lg-6">
          <div className="bold-text">Date Progress Records were Taken</div>
          <div>{convertDate(records.date_taken)}</div>
        </div>

        {data && (records.record_type === 'CSQ' || records.revision_id === 'CSR1.0') ? (
          <React.Fragment>
            {records.record_type === 'CSQ' ? (
              <div className="col-lg-6 csr-box">
                <div className="bold-text">Date of Next Scheduled Appointment</div>
                <div>
                  {this.getAnswerFromQuestionnaire(data.questions, 'When is the next planned appointment?')
                    ? convertDate(this.getAnswerFromQuestionnaire(data.questions, 'When is the next planned appointment?'))
                    : 'N/A'}
                </div>
              </div>
            ) : (
              <div className="col-lg-6 csr-box">
                <div className="bold-text">Date of Next Scheduled Appointment</div>
                <div>{records.appointment_date ? convertDate(records.appointment_date) : 'N/A'}</div>
              </div>
            )}

            <div className="col-lg-6 csr-box">
              <div className="bold-text">Current Upper Smartwire</div>
              <div>{this.getAnswerFromQuestionnaire(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Upper Smartwire:')}</div>
              {this.getAnswerFromQuestionnaire(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Upper Smartwire:') !== 'None' &&
              getSmartWireProgressRecordDate(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Upper Smartwire:') ? (
                <div>
                  Inserted on{' '}
                  {convertDate(getSmartWireProgressRecordDate(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Upper Smartwire:'))}
                </div>
              ) : null}
            </div>

            <div className="col-lg-6 csr-box">
              <div className="bold-text">Current Lower Smartwire</div>
              {this.getAnswerFromQuestionnaire(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Lower Smartwire:')}
              {this.getAnswerFromQuestionnaire(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Lower Smartwire:') !== 'None' &&
              getSmartWireProgressRecordDate(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Lower Smartwire:') ? (
                <div>
                  Inserted on{' '}
                  {convertDate(getSmartWireProgressRecordDate(data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Lower Smartwire:'))}
                </div>
              ) : null}
            </div>

            {records.record_type === 'CSQ' || records.record_type === 'CSR' ? (
              <div className="col-lg-12">
                <div className="btn-room">
                  <button type="button" className="btn btn-light btn-light-wide" onClick={this.onProgressRecordClick}>
                    View Form
                  </button>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  /**
   * Holds the item request
   * @param {object} event - button click event
   * @function
   */
  onHoldClick = (event) => {
    const that = this;
    let form_data = new FormData();

    const status_comment = this.state.doctor_note ? this.state.status_comment + ': ' + this.state.doctor_note : this.state.status_comment;
    form_data.append('status_comment', status_comment);
    form_data.append('reason_message', this.state.reason_message);

    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/hold`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            showHoldModal: false,
            status_comment: '',
          });
          Axios.post(`/api/email/?slug=ir-on-hold-1&ireqId=${res.data.item_request_id}&method=standard&provider=${window.location.origin}`);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showHoldModal: false,
            });
          }
        });
    } else {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/hold`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            showHoldModal: false,
            status_comment: '',
            reason_message: '',
            doctor_note: '',
          });

          that.sendAssignedUserEmail('assigned-case-on-hold', res.data.note_id);

          Axios.post(
            `/api/email/?slug=case-on-hold-1&caseId=${removeCaseIdInitialNumber(that.state.selected_case_id)}&method=standard&doctorId=${
              that.state.case_details.doctor.id
            }&provider=${window.location.origin}`
          );
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showHoldModal: false,
            });
          }
        });
    }
  };

  /**
   * Approves item reuqest
   * @param {object} event - button click event
   * @function
   */
  onIrApproveConfirm = (event) => {
    const that = this;
    this.setState({ approving: true });

    Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/approve`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          showApproveItemRequestModal: false,
          approving: false,
        });

        Axios.post(`/api/email/?slug=ir-approved-1&ireqId=${res.data.item_request_id}&method=standard&provider=${window.location.origin}`);
        Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
        that.props.fetchSingleToothIdb(that.state.selected_request_id);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showApproveItemRequestModal: false,
            approving: false,
          });
        }
      });
  };

  /**
   * Resets the case files
   * @param {object} event - button click event
   * @function
   */
  onUndoFiles = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.state.selected_case_id}/reset_to_file`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          disable_undo_button: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  /**
   * Sets flag to show item request invoice
   * @param {object} event - button click event
   * @function
   */
  onDownloadInvoice = (event) => {
    this.setState({ showViewIrInvoice: true });
  };

  /**
   * Zips p files from case files for download
   * @param {object} event - button click event
   * @function
   */
  onZipFiles = (event) => {
    const timestamp = Moment().format('YYYY-MM-DD_hh-mm');
    const filename = `${removeCaseIdInitialNumber(this.state.selected_case_id)}_Records_${timestamp}.zip`;
    const that = this;

    if (this.state.status_type !== 'zip') {
      this.setState({ status_type: 'zip', status_message: `Preparing Zip: ${removeCaseIdInitialNumber(this.state.selected_case_id)}` });

      Axios({
        url: `/apiV2/zip/${this.state.selected_case_id}/files`,
        method: 'GET',
      })
        .then((response) => {
          downloadZip(that, response, filename);
        })
        .catch(function (err) {
          clearZipStatus(that);
        });
    }
  };

  /**
   * Zips progress record files for download
   * @param {object} event - button click event
   * @function
   */
  onZipProgressFiles = (event) => {
    const timestamp = Moment().format('YYYY-MM-DD_hh-mm');
    const filename = `${removeCaseIdInitialNumber(this.state.selected_case_id)}_${
      event.target.dataset.type === 'CSQ' ? 'CSQ' : 'Progress_Records'
    }_${timestamp}.zip`;
    const that = this;

    if (this.state.status_type !== 'zip') {
      this.setState({
        status_type: 'zip',
        status_message: `Preparing Zip: ${removeCaseIdInitialNumber(this.state.selected_case_id)}_${
          event.target.dataset.type === 'CSQ' ? 'CSQ' : 'Progress_Records'
        }`,
      });

      Axios({
        url: `/apiV2/prdownloadzip/${this.state.selected_progress_id}/files`,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          that.setState({ status_type: '', status_message: '' });
        })
        .catch(function (err) {
          that.setState({ status_type: '', status_message: '' });
        });
    }
  };

  onUndoFilesEnd = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.state.selected_case_id}/reset_to_file_end`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          disable_undo_button: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  onUndoSetupFiles = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.state.selected_case_id}/reset_to_setup`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          selected_setup: that.getSelectedSetupByCaseId(that.state.selected_case_id),
          disable_undo_button: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  onHoldModalClick = (event) => {
    this.getHoldAndCancelReasons('hold');
    this.setState({
      showHoldModal: true,
    });
  };

  onIrUpdateConfirmDismiss = (event) => {
    this.setState({ showUpdateItemRequestModal: false });
  };

  onUpdateItemRequestModalClick = (event) => {
    this.setState({ showUpdateItemRequestModal: true });
  };

  onProgressRecordClick = (event) => {
    this.setState({ showProgressRecordModal: true });
  };

  /**
   * Handles event when user updates item request
   * @function
   * @param {Object} event - The onClick event Object
   */
  onIrUpdateClick = (event) => {
    const that = this;
    Axios.get(`/apiv3/ireqstatus/${that.state.selected_request_id}/update`)
      .then((res) => {
        that.props.history.push({ state: { updateIr: 'true' } });
        that.setState({ item_request_mode: 'update', showUpdateItemRequestModal: false, update_item_request: that.state.item_request });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  onSubmitIrClick = (event) => {
    this.setState({ item_request_mode: 'create', selected_tab: 'item_request', ir_submit_mode: 'item' });
  };

  onSubmitIrCaseClick = (event) => {
    this.setState({ item_request_mode: 'create', selected_tab: 'item_request', ir_submit_case_id: this.state.selected_case_id, ir_submit_mode: 'case' });
  };

  onIrSubmitCancelClick = (event) => {
    if (this.state.ir_submit_mode && this.state.ir_submit_mode === 'item') {
      this.setState({ item_request_mode: 'view' });
    } else if (this.state.ir_submit_mode && this.state.ir_submit_mode === 'case') {
      this.setState({ selected_tab: 'case', item_request_mode: 'view' });
    } else {
      if (this.state.case_details.item_request && this.state.case_details.item_request.length > 0) {
        this.setState({ item_request_mode: 'view' });
      } else {
        this.setState({ selected_tab: 'case', item_request_mode: 'view' });
      }
    }
  };

  onPrSubmitCancelClick = (event) => {
    if (this.state.progress_record_mode && this.state.progress_record_mode === 'update') {
      this.setState({ progress_record_mode: 'view' });
    }
  };

  onPrSubmitConfirm = (event) => {
    this.setState({
      loading: true,
      progress_record_mode: 'view',
    });
    let selected_tab = this.state.selected_tab;
    let selected_progress_id = this.state.selected_progress_id;
    let that = this;
    ApiServiceInstance.fetchCaseDetails(that.state.selected_case_id)
      .then((res) => {
        if (selected_tab === 'progress' && res.data.progress_record.length === 0) {
          selected_tab = 'case';
        }
        const pr = res.data.progress_record.filter(function (q) {
          return q.progress_id === parseInt(selected_progress_id);
        });
        let exists = true;
        if (pr.length === 0) {
          exists = false;
        }
        if (!exists) {
          selected_progress_id = res.data.progress_record.length > 0 ? res.data.progress_record[res.data.progress_record.length - 1].progress_id : '';
        }
        that.setState({
          case_details: res.data,
          selected_tab: selected_tab,
          progress_record_mode: 'view',
          selected_progress_id: selected_progress_id,
          loading: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  onIRSubmitFinish = (event) => {
    this.setState({ item_request_mode: 'view' });
  };

  onHoldModalDismiss = (event) => {
    this.setState({ showHoldModal: false, false_de: false });
    modalClear();
  };

  onReverseHoldModalClick = (event) => {
    this.setState({ showReverseHoldModal: true });
  };

  onReverseHoldModalDismiss = (event) => {
    this.setState({ showReverseHoldModal: false });
    modalClear();
  };

  onReverseCancellationModalClick = (event) => {
    if (this.state.case_details.another_gen_case_exists && this.state.case_details.converted_case_status !== 'Cancelled') {
      this.setState({ unable_to_reverse_modal: true });
    } else {
      let that = this;
      let BreakException = {};
      let show_active_address = false;

      this.state.case_details.shipping_addresses.forEach(function (address) {
        if (address.id === that.state.selected_address_id) {
          if (!address.is_active) {
            show_active_address = true;
            that.setState({
              showActiveAddreses: true,
            });
          }
        }
      });
      try {
        if (show_active_address) {
          this.state.case_details.shipping_addresses.forEach(function (address) {
            if (address.is_active) {
              that.setState({
                new_address_id: address.id,
              });
              throw BreakException;
            }
          });
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      this.setState({ showReverseCancellationModal: true });
    }
  };

  onReverseCancellationModalDismiss = (event) => {
    this.setState({ showReverseCancellationModal: false });
    modalClear();
  };

  onCancelCaseModalClick = (event) => {
    this.getHoldAndCancelReasons('cancel');
    this.setState({ showCancelCaseModal: true });
  };

  onCancelCaseModalDismiss = (event) => {
    this.setState({ showCancelCaseModal: false });
    modalClear();
  };

  onCloseRequestClick = (event) => {
    this.setState({ showCloseRequestModal: true });
  };

  onRejectClick = (event) => {
    this.setState({
      rejectModal: true,
    });
  };

  onRejectDismiss = (event) => {
    this.setState({ rejectModal: false });
    modalClear();
  };

  onFileApproveClick = (event) => {
    this.setState({
      showApproveFileModal: true,
    });
  };

  onDeleteDismiss = (event) => {
    this.setState({
      showDeleteProgressRecordModal: false,
    });
    modalClear();
  };

  AddCsrNotesDismiss = (event) => {
    this.setState({
      showCsrAddNotesMoal: false,
    });
    modalClear();
  };

  onEditDismiss = (event) => {
    this.setState({
      showUpdateProgressRecordModal: false,
    });
    modalClear();
  };

  onFileApproveDismiss = (event) => {
    this.setState({
      showApproveFileModal: false,
    });
    modalClear();
  };

  onFileRejectConfirmClick = (event) => {
    this.setState({
      showRejectFileConfirmModal: true,
    });
  };

  onFileRejectConfirmDismiss = (event) => {
    this.setState({
      showRejectFileConfirmModal: false,
    });
    modalClear();
  };

  onViewIrInvoicDismiss = (event) => {
    this.setState({
      showViewIrInvoice: false,
    });
    modalClear();
  };

  onFileRejectApproved = (event) => {
    let that = this;
    this.setState({
      showRejectFileConfirmModal: false,
    });

    setTimeout(function () {
      that.setState({
        rejectModal: true,
      });
    }, 1000);
  };

  onFileCommentsClick = (event) => {
    this.setState({
      showFileCommentsModal: true,
    });
  };

  onIrApproveClick = (event) => {
    this.setState({
      showApproveItemRequestModal: true,
    });
  };

  onIrApproveDismiss = (event) => {
    this.setState({
      showApproveItemRequestModal: false,
    });
    modalClear();
  };

  onEditPatientDetailsClick = (event) => {
    this.setState({
      patient_firstname: this.state.case_details.patient.first_name,
      patient_lastname: this.state.case_details.patient.last_name,
      patient_dob: this.state.case_details.patient.dob,
      patient_sex: this.state.case_details.patient.sex,
      patient_ref: this.state.case_details.patient.patient_ref,
      showEditPatientDetailsModal: true,
      patient_dob_error: false,
    });
  };

  onEditPatientDetailsDismiss = (event) => {
    this.setState({
      showEditPatientDetailsModal: false,
    });
    modalClear();
  };

  /**
   * Shows the edit treatment plan modal
   * @function
   * @param {Object} event - The onClick event Object
   */
  onEditTreatmentPlanClick = (event) => {
    this.setState({
      showEditTreatmentPlanModal: true,
      save_tx_plan_changes: false,
    });
  };

  /**
   * Hides the edit treatment plan modal
   * @function
   * @param {Object} event - The onClick event Object
   */
  onEditTreatmentPlanDismiss = (event) => {
    this.setState({
      showEditTreatmentPlanModal: false,
      save_tx_plan_changes: false,
    });
    modalClear();
  };

  /**
   * Opens expedite details modal
   * @function
   * @param {object} case_or_ireq - Selected case or ireq
   * @param {object} target_dates - Internal target dates for selected case or ireq
   */
  onExpediteDetailsClick = (case_or_ireq, target_dates) => {
    this.setState({
      showExpediteDetailsModal: true,
      initial_expedite_target_ship_date: case_or_ireq.internal_target_ship_date,
      initial_expedite_ship_ind: case_or_ireq.expedite_ship_ind,
      initial_expedite_additional_note: this.state.expedite_additional_note,
      edit_address_id: this.state.selected_address_id,
      initial_smile_design_target_date: target_dates.smile_design_target_date,
      initial_appliance_design_target_date: target_dates.appliance_design_target_date,
      initial_preferred_shipping_input: target_dates.preferred_shipping,
    });

    this.props.setSmileDesignTargetDateInput(target_dates.smile_design_target_date);
    this.props.setApplianceDesignTargetDateInput(target_dates.appliance_design_target_date);
    this.props.setPreferredShippingInput(target_dates.preferred_shipping);
  };

  onExpediteDetailsDismiss = (event) => {
    this.setState({
      showExpediteRequest: false,
      showExpediteApproval: false,
      showExpediteUpdate: false,
      showExpediteCancel: false,
      showExpediteReject: false,
      showExpediteDetailsModal: false,
      expedite_note: '',
      expedite_additional_note: '',
      expedite_target_ship_date: '',
    });

    this.props.clearTargetDateInputs();

    modalClear();
    if (window.$('.select-address')) {
      window.$('.select-address').selectpicker('refresh');
    }
  };

  onExpediteDetailsExpediteClick = (event) => {
    this.setState({
      showExpediteRequest: true,
    });
  };

  /**
   * When Submit Expedite Button is clicked it will perform the actions for either case or item request.
   *
   * @param {Object} event - Button Event type object
   * @param {String} type - Provides if the request is for case or item request
   * @function
   *
   */
  onSubmitExpediteClick = (event, type) => {
    let address_id = '';
    if (this.state.selected_tab === 'case') {
      if (this.state.edit_address_id !== this.state.selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    } else {
      if (this.state.edit_address_id !== this.state.ir_selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    }

    const request = {
      expedite_note: this.state.expedite_note,
      expedite_ship_ind: this.state.expedite_ship_ind,
      address_id: address_id,
    };
    let that = this;

    if (type === 'Case') {
      Axios.post(`/apiV2/expediteaction/submission_for_case/${this.state.selected_case_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteRequest: false,
            });

            that.reloadInformation();

            if (window.$('.select-address')) {
              window.$('.select-address').selectpicker('refresh');
            }

            that.props.clearTargetDateInputs();

            // Send email
            Axios.post(`/api/email/?slug=pending-expedite-request&caseId=${that.state.selected_case_id}&method=standard&provider=${window.location.origin}`);
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/expediteaction/submission_for_ir/${this.state.selected_request_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteRequest: false,
            });

            that.reloadInformation();
            if (window.$('.select-address')) {
              window.$('.select-address').selectpicker('refresh');
            }
            // Send email
            Axios.post(
              `/api/email/?slug=ir-pending-expedite-request&ireqId=${that.state.selected_request_id}&method=standard&provider=${window.location.origin}`
            );
            if (address_id) {
              Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
            }
            that.props.clearTargetDateInputs();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  onApproveExpediteClick = (event) => {
    this.setState({
      showExpediteApproval: true,
      showExpediteRequest: false,
    });
    this.props.setApplianceDesignTargetDateInput('');
    this.props.setPreferredShippingInput('');
  };

  onExpediteDetailsReject = (event) => {
    this.setState({
      showExpediteRequest: false,
      showExpediteApproval: false,
      showExpediteDetailsModal: false,
      expedite_note: '',
      expedite_additional_note: '',
      expedite_target_ship_date: '',
      showExpediteReject: true,
    });
  };

  /**
   * Closes the expedite details modal when cancel button is clicked.
   *
   * @function
   */
  onExpediteDetailsCancel = (event) => {
    this.setState({
      showExpediteRequest: false,
      showExpediteApproval: false,
      showExpediteDetailsModal: false,
      expedite_note: '',
      expedite_additional_note: '',
      expedite_target_ship_date: '',
      showExpediteCancel: true,
    });
    if (window.$('.select-address')) {
      window.$('.select-address').selectpicker('refresh');
    }
  };

  onExpeditedCancelAccept = (event, type) => {
    const request = {
      expedite_note: this.state.status_comment,
      expedite_ship_ind: false,
    };
    let that = this;

    if (type === 'case') {
      Axios.post(`/apiV2/expediteaction/cancel_for_case/${this.state.selected_case_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteCancel: false,
              status_comment: '',
            });
            that.reloadInformation();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/expediteaction/cancel_for_ir/${this.state.selected_request_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteCancel: false,
              status_comment: '',
            });
            that.reloadInformation();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  onExpeditedRejectAccept = (event, type) => {
    const request = {
      expedite_note: this.state.status_comment,
      expedite_ship_ind: false,
    };
    let that = this;
    if (type === 'case') {
      Axios.post(`/apiV2/expediteaction/reject_for_case/${this.state.selected_case_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteReject: false,
              status_comment: '',
            });
            // Send email
            Axios.post(
              `/api/email/?slug=case-expedite-request-reject&caseId=${that.state.selected_case_id}&method=standard&provider=${window.location.origin}`
            );
            that.onExpediteDetailsDismiss();
            that.reloadInformation();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/expediteaction/reject_for_ir/${this.state.selected_request_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            that.setState({
              showExpediteReject: false,
              status_comment: '',
            });
            // Send email
            Axios.post(
              `/api/email/?slug=ir-expedite-request-reject&ireqId=${that.state.selected_request_id}&method=standard&provider=${window.location.origin}`
            );
            that.onExpediteDetailsDismiss();
            that.reloadInformation();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  /**
   * Opens the update expedite details modal when update button is clicked.
   *
   * @param {Object} event - Button Event type object
   * @param {String} type - Provides if the request is for case or item request
   * @function
   */
  onShowUpdateExpediteClick = (event, type) => {
    if (type === 'Case') {
      if (this.state.case_details.cases.length > 0) {
        let expedite_process_log = this.state.case_details.cases.filter((c) => {
          return c.case_id === this.state.selected_case_id;
        })[0].expedite_process;

        if (expedite_process_log.log.length > 0) {
          if (expedite_process_log.log[expedite_process_log.log.length - 1].current) {
            let expedite_process = expedite_process_log.log[expedite_process_log.log.length - 1];
            this.setState({
              expedite_target_ship_date: expedite_process.target_ship_date,
              expedite_ship_ind: expedite_process.expedite_ship_ind,
            });
          }
        }
      }
    } else {
      if (this.state.case_details.item_request.length > 0) {
        let expedite_process_log = this.state.case_details.item_request.filter((c) => {
          return c.ireq_id === this.state.selected_request_id;
        })[0].expedite_process;
        if (
          expedite_process_log &&
          expedite_process_log.log &&
          expedite_process_log.log.length > 0 &&
          expedite_process_log.log[expedite_process_log.log.length - 1].current
        ) {
          let ir_expedite_process = expedite_process_log.log[expedite_process_log.log.length - 1];
          this.setState({
            expedite_target_ship_date: ir_expedite_process.target_ship_date,
            expedite_ship_ind: ir_expedite_process.expedite_ship_ind,
          });
        }
      }
    }
    this.setState({
      showExpediteUpdate: true,
    });
  };

  /**
   * Approves the expedited shipping request and closes the modal when approve button is clicked.
   *
   * @param {Object} event - Button Event type object
   * @param {String} type - Provides if the request is for case or item request
   * @function
   */
  onConfirmUpdateExpediteClick = (event, type) => {
    const target_ship_date = this.state.expedite_target_ship_date;
    let address_id = '';
    if (this.state.selected_tab === 'case') {
      if (this.state.edit_address_id !== this.state.selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    } else {
      if (this.state.edit_address_id !== this.state.ir_selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    }
    const request = {
      target_ship_date: target_ship_date,
      expedite_ship_ind: this.state.expedite_ship_ind,
      expedite_note: this.state.expedite_additional_note,
      address_id: address_id,
      smile_design_target_date: this.props.smile_design_target_date_input,
      appliance_design_target_date: this.props.appliance_design_target_date_input,
      preferred_shipping: this.props.preferred_shipping_input,
    };
    let that = this;

    if (type === 'Case') {
      Axios.post(`/apiV2/expediteaction/update_for_case/${this.state.selected_case_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            // Send email
            Axios.post(
              `/api/email/?slug=case-estimated-ship-date-updated&caseId=${that.state.selected_case_id}&estimated_ship_date=${target_ship_date}&method=standard&provider=${window.location.origin}`
            );
            that.reloadInformation();
            if (address_id) {
              if (window.$('.select-address')) {
                window.$('.select-address').selectpicker('refresh');
              }
            }
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/expediteaction/update_for_ir/${this.state.selected_request_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            // Send email
            Axios.post(
              `/api/email/?slug=ir-estimated-ship-date-updated&ireqId=${that.state.selected_request_id}&estimated_ship_date=${target_ship_date}&method=standard&provider=${window.location.origin}`
            );
            that.reloadInformation();

            if (address_id) {
              if (window.$('.select-address')) {
                window.$('.select-address').selectpicker('refresh');
                Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
              }
            }
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
    this.onExpediteDetailsDismiss();
  };

  onApproveConfirmExpediteClick = (event, type) => {
    const target_ship_date = this.state.expedite_target_ship_date;
    let address_id = '';
    if (this.state.selected_tab === 'case') {
      if (this.state.edit_address_id !== this.state.selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    } else {
      if (this.state.edit_address_id !== this.state.ir_selected_address_id) {
        address_id = this.state.edit_address_id;
      }
    }

    const request = {
      target_ship_date: target_ship_date,
      expedite_ship_ind: this.state.expedite_approval_ship_ind,
      expedite_note: this.state.expedite_additional_note,
      expedite_ind: true,
      address_id: address_id,
      appliance_design_target_date: this.props.appliance_design_target_date_input,
      preferred_shipping: this.props.preferred_shipping_input,
    };
    let that = this;

    if (type === 'Case') {
      Axios.post(`/apiV2/expediteaction/approval_for_case/${this.state.selected_case_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            // Send email
            Axios.post(
              `/api/email/?slug=case-expedite-request-approved&caseId=${that.state.selected_case_id}&estimated_ship_date=${target_ship_date}&method=standard&provider=${window.location.origin}`
            );
            that.reloadInformation();
            if (window.$('.select-address')) {
              window.$('.select-address').selectpicker('refresh');
            }
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/expediteaction/approval_for_ir/${this.state.selected_request_id}`, request)
        .then(function (res) {
          if (res && res.data) {
            // Send email
            Axios.post(
              `/api/email/?slug=ir-expedite-request-approved&ireqId=${that.state.selected_request_id}&estimated_ship_date=${target_ship_date}&method=standard&provider=${window.location.origin}`
            );
            that.reloadInformation();
            window.$('.select-address').selectpicker('refresh');
            if (address_id) {
              Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
            }
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
    this.onExpediteDetailsDismiss();
  };

  onExpediteDropdownChange = (event) => {
    let expedite_ship_ind = false;
    if (event.target.value === 'Yes') {
      expedite_ship_ind = true;
    }

    this.setState({
      expedite_ship_ind: expedite_ship_ind,
    });
  };

  onExpediteApprovalDropdownChange = (event) => {
    let expedite_approval_ship_ind = false;
    if (event.target.value === 'Yes') {
      expedite_approval_ship_ind = true;
    }
    this.setState({
      expedite_approval_ship_ind: expedite_approval_ship_ind,
    });
  };

  onFlagPrecheckClick = (event) => {
    this.setState({
      showFlagPrecheckModal: true,
    });
  };

  onFlagPrecheckConfirm = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/flag_precheck`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          showFlagPrecheckModal: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showFlagPrecheckModal: false,
          });
        }
      });
  };

  onFlagPrecheckDismiss = (event) => {
    this.setState({
      showFlagPrecheckModal: false,
    });
    modalClear();
  };

  onUnflagPrecheckClick = (event) => {
    this.setState({
      showUnflagPrecheckModal: true,
    });
  };

  onUnflagPrecheckConfirm = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/unflag_precheck`)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          showUnflagPrecheckModal: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showUnflagPrecheckModal: false,
          });
        }
      });
  };

  /**
   * Opens the clinical review modal
   * @function
   */
  openClinicalReviewModal = () => {
    this.setState({
      clinical_review: {
        ...this.state.clinical_review,
        modal: true,
        selected_flag: this.getSelectedCaseClinicalReview(),
      },
    });
  };

  /**
   * Closes the clinical review modal
   * @function
   */
  closeClinicalReviewModal = () => {
    this.setState({
      clinical_review: {
        ...this.state.clinical_review,
        modal: false,
        in_progress: false,
        note: '',
        note_char_count: 0,
      },
    });
  };

  /**
   * Handles event when user types in note for clinical review
   * @function
   * @param {String} content - Contains the html version of the content
   * @param {Object} delta - Contains the change made since the last instance of react quill
   * @param {String} source - Identify who made the change, default is user
   * @param {Object} editor - Contains the editor object and its helper functions
   */
  onClinicalReviewNoteChange = (content, delta, source, editor) => {
    this.setState({
      clinical_review: {
        ...this.state.clinical_review,
        note: content,
        note_char_count: getQuillHTMLTextContentLength(content),
        in_progress: true,
      },
    });
  };

  /**
   * On focus function for clinical review note
   * @function
   */
  onFocusClinicalReview = () => {
    this.setState({
      clinical_review: { ...this.state.clinical_review, in_progress: true },
    });
  };

  /**
   * Handles event when user clicks confirm on Initiate/Complete Clinical Review modal
   * @function
   */
  onInitiateClinicalReviewConfirm = () => {
    const that = this;
    const set_clinical_review = !this.state.clinical_review.selected_flag;
    const selected_case = that.state?.case_details?.cases.find((c) => c.case_id === that.state.selected_case_id);
    const data = {
      clinical_review: set_clinical_review,
      note: that.state.clinical_review.note,
      early_de: selected_case.early_de
    };
    Axios.put(`/apiv3/case/${that.state.case_details.case_id}/flags/clinicalreview`, data)
      .then(function (res) {
        that.closeClinicalReviewModal();
        that.reloadInformation();
        Axios.post(
          `/api/email/?slug=clinical-review-${set_clinical_review ? 'initiated' : 'completed'}&caseId=${that.state.selected_case_id}&method=standard&provider=${
            window.location.origin
          }`
        );
      })
      .catch(function (err) {
        that.closeClinicalReviewModal();
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Opens the doctor clarification modal
   * @function
   */
  openDoctorClarificationModal = () => {
    this.setState({
      doctor_clarification: { ...this.state.clinical_review, modal: true },
    });
  };

  /**
   * Closes the doctor clarification modal
   * @function
   */
  closeDoctorClarificationModal = (event) => {
    this.setState({
      doctor_clarification: {
        ...this.state.doctor_clarification,
        modal: false,
        in_progress: false,
        note: '',
        note_char_count: 0,
      },
      showFalseDeModal: false,
    });
  };

  /**
   * Handles event when user types in note for doctor clarification
   * @function
   * @param {String} content - Contains the html version of the content
   * @param {Object} delta - Contains the change made since the last instance of react quill
   * @param {String} source - Identify who made the change, default is user
   * @param {Object} editor - Contains the editor object and its helper functions
   */
  onDoctorClarificationNoteChange = (content, delta, source, editor) => {
    this.setState({
      doctor_clarification: {
        ...this.state.doctor_clarification,
        note: content,
        note_char_count: getQuillHTMLTextContentLength(content),
        in_progress: true,
      },
    });
  };

  /**
   * On focus function for doctor clarification note
   * @function
   */
  onFocusDoctorClarification = () => {
    this.setState({
      doctor_clarification: { ...this.state.doctor_clarification, in_progress: true },
    });
  };

  removeStagedFlag = () => {
    Axios.put(`/apiv3/staged/${this.state.selected_case_id}/unflag_staged`)
    .then((res) => {
      this.setState({
        showRemoveStagedModal: false,
      });
      this.reloadInformation()
    })
    .catch((err) => {
      handleHttpRequestError(err, this);
      if (err && err.response && err.response.status === 409) {
        this.setState({
          refresh: true,
          showRemoveStagedModal: false,
        });
      }
    });
  }

  removeFalseDeFlag = () => {
    Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/unflag_false_de`)
      .then((res) => {
        this.setState({
          showRemoveFalseDeModal: false,
        });
        this.reloadInformation()
      })
      .catch((err) => {
        handleHttpRequestError(err, this);
        if (err && err.response && err.response.status === 409) {
          this.setState({
            refresh: true,
            showRemoveFalseDeModal: false,
          });
        }
      });
  };

  removeEarlyDeFlag = () => {
    Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/unflag_early_de`)
      .then((res) => {
        this.setState({
          case_details: res.data,
          showRemoveEarlyDeModal: false,
        });
      })
      .catch((err) => {
        handleHttpRequestError(err, this);
        if (err && err.response && err.response.status === 409) {
          this.setState({
            refresh: true,
            showRemoveEarlyDeModal: false,
          });
        }
      });
  };

  flagStaged = () => {
    const data = { note: this.state.staged.note, category: this.state.staged.category };
    const case_data = this.state.case_details.cases.find((c) => c.case_id === this.state.selected_case_id);
    Axios.put(`/apiv3/staged/${this.state.selected_case_id}/flag_staged`, data).then((res) => {
      this.setState({
        showStagedModal: false,
        staged: {
          category: '',
          note: '',
        },
      });
      this.reloadInformation()
    })
    .catch((err) => {
      handleHttpRequestError(err, this);
      if (err && err.response && err.response.status === 409) {
        this.setState({ refresh: true, showStagedModal: false });
      }
    });  
  }

  flagFalseDe = () => {
    const data = { status_comment: this.state.doctor_clarification.note};
    const case_data = this.state.case_details.cases.find((c) => c.case_id === this.state.selected_case_id);
    const case_status = case_data.case_statuses.find((s) => s.current_ind );
    if(case_status.case_status === 'Hold') {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/flag_false_de`, data)
        .then((res) => {
          this.setState({
            case_details: res.data,
            showFalseDeModal: false,
          });
        })
        .catch((err) => {
          handleHttpRequestError(err, this);
          if (err && err.response && err.response.status === 409) {
            this.setState({ refresh: true, showDeClarificationModal: false });
          }
        });  
    } else {
      this.setState({false_de: true, showFalseDeModal: false, status_comment: 'DE Review'});
      this.onHoldModalClick()
    }
  };

  flagEarlyDe = () => {
    const set_clinical_review = !this.state.clinical_review.selected_flag;
    const data = {
      clinical_review: set_clinical_review,
      note: this.state.clinical_review.note,
      early_de: true,
    };
    const case_data = this.state.case_details.cases.find((c) => c.case_id === this.state.selected_case_id);
    if (case_data.clinical_review) {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/flag_early_de`)
        .then((res) => {
          this.setState({ showEarlyDeClinicalReviewModal: false, showEarlyDeModal: false });
          this.reloadInformation();
          Axios.post(
            `/api/email/?slug=clinical-review-${set_clinical_review ? 'initiated' : 'completed'}&caseId=${
              this.state.selected_case_id
            }&method=standard&provider=${window.location.origin}`
          );
        })
        .catch((err) => {
          this.setState({ refresh: true, showEarlyDeClinicalReviewModal: false, showEarlyDeModal: false });
          handleHttpRequestError(err, this);
        });
    } else if (this.state.clinical_review.note.length) {
      Axios.put(`/apiv3/case/${this.state.case_details.case_id}/flags/clinicalreview`, data)
        .then((res) => {
          this.setState({ showEarlyDeClinicalReviewModal: false, clinical_review: {
            modal: false,
            note: '',
            note_char_count: 0,
            in_progress: false,
            selected_flag: false,
          } });
          this.reloadInformation();
          Axios.post(
            `/api/email/?slug=clinical-review-${set_clinical_review ? 'initiated' : 'completed'}&caseId=${
              this.state.selected_case_id
            }&method=standard&provider=${window.location.origin}`
          );
        })
        .catch((err) => {
          this.setState({ refresh: true, showEarlyDeClinicalReviewModal: false, clinical_review: {
            modal: false,
            note: '',
            note_char_count: 0,
            in_progress: false,
            selected_flag: false,
          } });
          handleHttpRequestError(err, this);
        });
    } else {
      this.setState({ showEarlyDeClinicalReviewModal: true, showEarlyDeModal: false, clinical_review: {
        ...this.state.clinical_review,
        selected_flag: this.getSelectedCaseClinicalReview(),
      }});
    }
  };

  /**
   * Handles event when user clicks confirm on Doctor Clarification modal
   * @function
   */
  onRequestDoctorClarificationConfirm = () => {
    const that = this;
    const set_clarification_status = that.getCaseStatus() !== 'Doctor Provide Clarification';
    const data = { status_comment: that.state.doctor_clarification.note };

    Axios.post(
      `/apiV2/caseaction/${that.state.selected_case_id}/${set_clarification_status ? 'request_doctor_clarification' : 'resolve_doctor_clarification'}`,
      data
    )
      .then(function (res) {
        that.closeDoctorClarificationModal();
        that.setState({
          case_details: res.data,
        });
        if (set_clarification_status) {
          Axios.post(
            `/api/email/?slug=doctor-clarification-requested&caseId=${that.state.selected_case_id}&method=standard&provider=${window.location.origin}`
          );
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({ doctor_clarification: { ...that.state.doctor_clarification, modal: false } });
        }
      });
  };

  onCloseRequestDismiss = (event) => {
    this.setState({
      showCloseRequestModal: false,
    });
    modalClear();
  };

  onCloseRequestConfirm = (event) => {
    const that = this;
    let form_data = new FormData();
    form_data.append('status_comment', this.state.status_comment);

    Axios.post(`/apiV2/praction/${this.state.selected_progress_id}/close`, form_data)
      .then(function (res) {
        let progress_notes = [];
        res.data.progress_record
          .slice(0)
          .reverse()
          .map((progress_record, index) => {
            let status = '';
            if (progress_record.status === 'Request Closed') {
              status = progress_record.status;
            }
            if (progress_record.notes && progress_record.notes.length > 0) {
              progress_record.notes.forEach((note) =>
                progress_notes.push({
                  id: note.id,
                  no: res.data.progress_record.length - index,
                  type: progress_record.record_type,
                  note_text: note.note_text,
                  user: note.user,
                  date: note.date,
                  status: status,
                  generic: note.generic,
                  created_date: note.created_date,
                  created_by_id: note.created_by_id,
                })
              );
            }

            return '';
          });
        progress_notes = _.orderBy(progress_notes, 'created_date', ['desc']);
        that.setState({
          case_details: res.data,
          showCloseRequestModal: false,
          progress_notes: progress_notes,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showCloseRequestModal: false,
          });
        }
      });
  };

  onUnflagPrecheckDismiss = (event) => {
    this.setState({
      showUnflagPrecheckModal: false,
    });
    modalClear();
  };

  onFileCommentsDismiss = (event) => {
    this.setState({
      showFileCommentsModal: false,
    });
    modalClear();
  };

  onProgressRecordDismiss = (event) => {
    this.setState({
      showProgressRecordModal: false,
    });
    modalClear();
  };

  onExpediteNoteChange = (event) => {
    const expedite_note = removeEmoji(textFieldCheck(event.target.value));
    this.setState({
      expedite_note: removeEmoji(expedite_note),
    });
  };

  onExpediteAdditionalNoteChange = (event) => {
    const additional_note = textFieldCheck(event.target.value);

    this.setState({
      expedite_additional_note: removeEmoji(additional_note),
    });
  };

  onExpediteTargetShipDateChange = (event) => {
    let expedite_target_ship_date = textFieldCheck(event.target.value);

    this.setState({
      expedite_target_ship_date: expedite_target_ship_date,
    });
  };

  onStatusCommentChange = (event) => {
    const status_comment = textFieldCheck(event.target.value);

    this.setState({
      status_comment: removeEmoji(status_comment),
      doctor_note: '',
    });
  };

  onReasonMessageChange = (event) => {
    const reason_message = textFieldCheck(event.target.value);

    this.setState({
      reason_message: removeEmoji(reason_message),
    });
  };

  onDoctorNoteChange = (event) => {
    const doctor_note = removeEmoji(textFieldCheck(event.target.value));

    this.setState({
      doctor_note: removeEmoji(doctor_note),
    });
  };

  onCaseAddressChange = (event) => {
    const address_id = event.target.dataset.id;
    this.setState({
      new_address_id: address_id,
    });
  };

  onUploadMsgChange = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  onNotesModalClick = (event) => {
    this.setState({ showNotesModal: true });
  };

  onIrNotesModalClick = (event) => {
    this.setState({ showIrNotesModal: true });
  };

  onProgressModalClick = (event) => {
    this.setState({ showProgressNotesModal: true });
  };

  onNotesModalDismiss = (event) => {
    this.setState({ showNotesModal: false });
    modalClear();
  };

  onIrNotesModalDismiss = (event) => {
    this.setState({ showIrNotesModal: false });
    modalClear();
  };

  onProgressNotesModalDismiss = (event) => {
    this.setState({ showProgressNotesModal: false });
    modalClear();
  };

  onStatusModalClick = (event) => {
    this.setState({ showStatusModal: true });
  };

  onStatusModalDismiss = (event) => {
    this.setState({ showStatusModal: false });
    modalClear();
  };

  onSetupModalClick = (event) => {
    // Check if state is current
    const that = this;
    const setup_no = parseInt(event.currentTarget.dataset.setupno);

    ApiServiceInstance.fetchCaseDetails(this.state.selected_case_id)
      .then((res) => {
        let selected_setup = that.getSelectedSetupByCaseId(that.state.selected_case_id);

        if (selected_setup.length === setup_no) {
          that.setState({ showSetupModal: true });
        } else {
          that.setState({ refresh: true });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({ showSetupModal: false });
        }
      });
  };

  onSetupModalDismiss = (event) => {
    this.setState({ showSetupModal: false });
    modalClear();
  };

  onSetupDeletionModalDismiss = (event) => {
    this.setState({ showSetupDeletionModal: false });
    modalClear();
  };

  onFocusAddress = (event) => {
    let ir_selected_address_id = this.state.ir_selected_address_id;
    if (this.state.selected_tab === 'item_request') {
      ir_selected_address_id = this.state.ir_selected_address_id;
    }
    this.setState({
      edit_address: true,
      ir_selected_address_id: ir_selected_address_id,
    });
  };
  /**
   * Checks if a case should show the target override option
   * @param {Object} case case details
   * @returns {Boolean} Override Option Status
   */
  hasTargetOverrideOption = (case_details) => {
    return (
      !isCaseBlocked(case_details.status_code) &&
      this.canOverrideExternalTargetDate(case_details) &&
      case_details.ship_status !== 'DELIVERED' &&
      !this.isExpediated(case_details)
    );
  };
  /**
   * Checks if an item request option should be shown
   * @param {Object} item_request - current item request option
   * @returns {Boolean} Item Request Option
   */
  isNonBlockingIRStatus = (item_request) => {
    const blocking_status = ['Item Request On Hold', 'Item Request Cancelled', 'INBRACE Reviewing Request', 'Item Request Shipped'];
    return item_request && _.intersection([item_request.ireq_status], blocking_status).length === 0;
  };
  /**
   * Checks if a case should show the target override option
   * @param {Object} case case details
   * @returns {Boolean} Override Option Status
   */
  isExpediated = (my_case) => {
    return my_case?.expedite_ind;
  };
  /**
   * Checks if a case can have external target date overriden based on case status
   * and expedited status
   * @returns {Boolean} Override Status
   */
  canOverrideExternalTargetDate = (case_details) => {
    if (!case_details) return false;
    const excluded_statuses = [
      'Case Shipped',
      'Cancelled',
      'Setup Conversion Process',
      'Folder Generation Success',
      'Segmentation Process',
      'STATUS_SHIP',
      'STATUS_INBRACE_CHECK',
      'STATUS_INBRACE_SETUP',
      'Setup Review',
      'Setup Ready for Upload',
      'Revise Setup',
      'Setup Review',
      'Setup Ready for Upload',
      'Setup Conversion Process',
      'Setup Conversion Failed',
      'Setup Ready for Release',
      'STATUS_DOCTOR_APPROVAL',
      'STATUS_DOCTOR_UPLOAD',
      'Provider Edit Review',
    ];
    return !excluded_statuses.includes(case_details.status_code) && !case_details.expedite_ship_ind;
  };

  filterFilesById(id) {
    let result = [];

    _.each(this.state.rawFiles, function (file) {
      if (file.case_id === id) {
        result.push(file);
      }
    });

    result = sortRawFiles(result);
    return result;
  }

  getFilesUndoButton(cases) {
    const setup_process = this.getSelectedSetupByCaseId(cases.case_id);
    if (cases.file_review_process.log.length > 1 && setup_process.length === 0 && this.state.case_details.role === 'Admin') {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoFiles} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    }
  }

  getFilesZipButton(cases) {
    if (cases.status_code !== 'STATUS_DOCTOR_UPLOAD') {
      return (
        <span className="pull-right btn-light-link" onClick={this.onZipFiles}>
          <i className="fa fa-file-archive-o" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
        </span>
      );
    }
  }

  getSetupUndoButton(cases) {
    if (
      (cases.status_code === 'STATUS_DOCTOR_APPROVAL' || cases.status_code === 'STATUS_INBRACE_SETUP') &&
      cases.setup_process.length > 0 &&
      cases.setup_process[0].files.id &&
      cases.status_code !== 'STATUS_MANUFACTURE' &&
      cases.setup_process.length > 0 &&
      cases.setup_process[0].files.id &&
      cases.status_code !== 'STATUS_SHIP' &&
      this.state.case_details.role === 'Admin'
    ) {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoSetupFiles} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    } else if (
      cases.status_code === 'STATUS_INBRACE_SETUP' &&
      cases.setup_process.length === 1 &&
      !cases.setup_process[0].files.id &&
      this.state.case_details.role === 'Admin'
    ) {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoFilesEnd} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    }
  }

  getProgressZipButton(record_type) {
    return (
      <span className="pull-right btn-light-link progress-zip-download" data-type={record_type} onClick={this.onZipProgressFiles}>
        <i className="fa fa-file-archive-o" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
      </span>
    );
  }

  getRecordsButton(cases) {
    if (cases.status_code === 'STATUS_NEW_SUB' || cases.status_code === 'STATUS_INBRACE_CHECK') {
      return (
        <div>
          <button type="button" className="btn btn-light" onClick={this.onFileApproveClick}>
            Records Complete
          </button>
          <button type="button" className="btn btn-light" onClick={this.onFileRejectConfirmClick}>
            Records Incomplete
          </button>
        </div>
      );
    }
  }

  getItemRequestsButton(item_request) {
    if (item_request.ireq_status === 'INBRACE Reviewing Request') {
      return (
        <div>
          <div className="bpp-10-padding" />
          <div className="text-center">
            <SecurityClearance mode="ALL" permission_list={['IREQ_APPROVE']}>
              <button type="button" className="btn btn-light" onClick={this.onIrApproveClick}>
                Approve Request
              </button>
            </SecurityClearance>
            <SecurityClearance mode="ALL" permission_list={['IREQ_UPDATE']}>
              <button type="button" className="btn btn-light" onClick={this.onUpdateItemRequestModalClick}>
                Update Request
              </button>
            </SecurityClearance>
            <SecurityClearance mode="ALL" permission_list={['IREQ_CANCEL']}>
              <button type="button" className="btn btn-light" onClick={this.onCancelCaseModalClick}>
                Cancel Request
              </button>
            </SecurityClearance>
          </div>
        </div>
      );
    }
  }

  getItemRequestsHold(item_request) {
    if (item_request.ireq_status === 'Item Request On Hold') {
      return (
        <div>
          <div className="bpps__line-divison-cancel" />
          <div>
            <div className="bold-text">Reason for Hold</div>
            <div>{this.getIrHoldStatusInformation(item_request, this.state.case_details.item_request_status, 'comments')}</div>
          </div>
        </div>
      );
    }
  }

  getItemRequestsCancel(item_request) {
    if (item_request.ireq_status === 'Item Request Cancelled') {
      return (
        <div>
          <div className="bpps__line-divison-cancel" />
          <div>
            <div className="bold-text">Reason for Cancellation</div>
            <div>{this.getIrCancelledStatusInformation(item_request, this.state.case_details.item_request_status, 'comments')}</div>
          </div>
        </div>
      );
    }
  }

  getIrHoldStatusInformation(item_request, item_request_status, info = '') {
    let current_request_status = [];
    item_request_status.forEach((status) => {
      if (status.ireq_id === item_request.ireq_id) {
        current_request_status.push(status);
      }
    });
    let request_date = '';
    let request_status = '';
    current_request_status.forEach((s) => {
      if (s.ireq_status === 'Item Request On Hold') {
        request_date = convertDate(s.created_date);
        request_status = s.comment;
      }
    });

    if (info === 'date') {
      return request_date;
    } else {
      return request_status;
    }
  }

  getIrCancelledStatusInformation(item_request, item_request_status, info = '') {
    let current_request_status = [];
    item_request_status.forEach((status) => {
      if (status.ireq_id === item_request.ireq_id) {
        current_request_status.push(status);
      }
    });
    let request_date = '';
    let request_status = '';
    current_request_status.forEach((s) => {
      if (s.ireq_status === 'Item Request Cancelled') {
        request_date = convertDate(s.created_date);
        request_status = s.comment;
      }
    });

    if (info === 'date') {
      return request_date;
    } else {
      return request_status;
    }
  }

  toggleActionPopup = () => {
    let popup = document.getElementById('actionPopup');
    if (popup) {
      popup.classList.toggle('show');
    }
  };

  getLatestCase = () => {
    let result = [];
    let case_id = this.state.selected_case_id;
    let plans = this.state.case.plans;

    result.push(case_id);

    _.each(plans, (plan) => {
      result.push(plan.case_id);
    });

    result = _.uniq(result);
    result = result.sort();

    return result[result.length - 1];
  };

  getCases = () => {
    let result = [];
    let case_id = this.state.selected_case_id;
    let plans = this.state.case.plans;

    _.each(plans, (plan) => {
      if (plan.case_id !== case_id) {
        result.push(plan.case_id);
      }
    });

    result = _.uniq(result);

    let links = [];
    _.each(result, (r) => {
      links.push(`<a href="/business/portal/case/${r}">${r}</a>`);
    });

    return links.join(', ');
  };

  /**
   * Handles opening the setup viewer window
   * @function
   * @param {Object} event - The click event
   */
  onSetupClick = (event) => {
    window.open(`/setup/${this.state.selected_case_id}/${event.target.dataset.setup}`);
  };

  onUpload = (data) => {
    const that = this;

    if (data && data[0] && (data[0].folder === 'setup' || data[0].folder === 'tx' || data[0].folder === 'bpg')) {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      Axios.post(`/apiV2/casefiles/${this.state.selected_case_id}/add`, form_data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(that.state.selected_case_id),
            selected_tx: that.getSelectedTxByCaseId(res.data, that.state.selected_case_id),
            selected_vbpc: that.getSelectedVbpcByCaseId(res.data, that.state.selected_case_id),
            upload_in_progress: false,
          });

          that.props.fetchIfuState(that.state.selected_case_id);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  getUploadDataPath(href) {
    const starting_path = href.indexOf('/', 0) === 0 ? 1 : 0;
    return href.substring(starting_path, href.length);
  }

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();

    const file_type = event.currentTarget.dataset.type;
    const that = this;

    if (file_type === 'setup' || file_type === 'tx' || file_type === 'bpg') {
      let form_data = new FormData();

      form_data.append(
        'files',
        JSON.stringify([
          {
            file_type: file_type,
            folder: file_type,
            incomplete_id: '',
            location: 'delete',
            mime_type: 'application/x-zip-compressed',
            original_filename: '',
            upload_data: this.getUploadDataPath(event.currentTarget.dataset.href),
          },
        ])
      );

      Axios.post(`/apiV2/casefiles/${this.state.selected_case_id}/remove`, form_data)
        .then((res) => {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(that.state.selected_case_id),
            selected_tx: that.getSelectedTxByCaseId(res.data, that.state.selected_case_id),
            selected_vbpc: that.getSelectedVbpcByCaseId(res.data, that.state.selected_case_id),
            showSetupDeletionModal: false,
          });

          document.body.className = document.body.className.replace('modal-open', '');

          that.props.fetchIfuState(that.state.selected_case_id);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showSetupDeletionModal: false,
            });
          }
        });
    }
  };

  selectProgressRecord = (event) => {
    const progress_id = event.target.dataset.progress_id;
    const progress_status = event.target.dataset.status;
    const progress_type = event.target.dataset.type;
    const progress_revision = event.target.dataset.revision;
    if (parseInt(progress_id) !== this.state.selected_progress_id) {
      this.setState({
        selected_progress_id: parseInt(progress_id),
        selected_cst: this.getSelectedCSTByProgressRecord(this.state.case_details, progress_id),
        selected_progress_status: progress_status,
        selected_progress_type: progress_type,
        selected_progress_revision: progress_revision,
        // csq_loaded: false,
        // csq_loading: true
      });
    }
  };

  selectItemRequest = (event) => {
    let request_id = event.target.dataset.request_id;
    this.setState({
      selected_request_id: request_id,
      ir_selected_address_id: this.getSelectedAddressByRequestId(this.state.case_details, request_id),
      templateNo: {
        80001: '',
        80002: '',
        80003: '',
        80004: '',
        80005: '',
        80006: '',
        80009: '',
        80010: '',
        80011: '',
        80012: '',
        80013: '',
        80014: '',
        80027: '',
        80028: '',
      },
    });
    this.props.fetchAwb(request_id);
  };
  onChangeTabDismiss = () => {
    this.setState({
      showChangeTabModal: false,
    });
    modalClear();
  };
  onChangeTabClick = () => {
    let case_id = this.state.changeTabCaseId;
    const current_path = this.props.history.location.pathname;
    const data_tab = this.state.changeTabDataTab;
    let new_path = current_path;
    let selected_tab = this.state.selected_tab;
    let selected_request_id = this.state.selected_request_id;
    let selected_progress_id = this.state.selected_progress_id;
    if (case_id) {
      new_path = current_path.replace(this.state.selected_case_id, case_id);
      new_path = new_path.replace('/item', '').replace('/progress', '');
      selected_tab = 'case';
      this.props.fetchProductionTxGuide(case_id);
      this.props.setProductionTxGuideTabConfig(
        this.getCaseStatusByCaseId(case_id),
        this.getCadStatus(case_id),
        this.getCaseByCaseId(this.state.case_details, case_id)
      );
    } else if (data_tab) {
      case_id = this.state.selected_case_id;

      if (data_tab === 'item_request') {
        if (!current_path.includes('/item')) {
          new_path = current_path.replace('/progress', '') + '/item';
        }
      } else if (data_tab === 'progress') {
        if (!current_path.includes('/progress')) {
          new_path = current_path.replace('/item', '') + '/progress';
        }
      }
      selected_tab = data_tab;
    }
    this.props.fetchCaseFileData(case_id);
    if (!selected_request_id && this.state.case_details.item_request && this.state.case_details.item_request.length > 0) {
      selected_request_id = this.state.case_details.item_request[this.state.case_details.item_request.length - 1].ireq_id;
    }

    if (!selected_progress_id && this.state.case_details.progress_record && this.state.case_details.progress_record.length > 0) {
      selected_progress_id = this.state.case_details.progress_record[this.state.case_details.progress_record.length - 1].progress_record;
    }

    this.props.history.replace(new_path);

    if (data_tab === 'item_request') {
      this.props.fetchAwb(selected_request_id);
    }
    // if (this.state.item_request_mode === 'update') {
    //   this.props.history.push({ state: { updateIr: 'true' } });
    // }

    // if (this.state.progress_record_mode === 'update') {
    //   this.props.history.push({ state: { updatePr: 'true' } });
    // }

    this.setState({
      showChangeTabModal: false,
      selected_case_id: case_id,
      selected_tab: selected_tab,
      selected_request_id: selected_request_id,
      selected_progress_id: selected_progress_id,
      selected_address_id: this.getSelectedAddressByCaseId(this.state.case_details, case_id),
      selected_is_historical: getHistoricalStatus(this.state.case_details, case_id),
      selected_setup: this.getSelectedSetupByCaseId(case_id),
      selected_tx: this.getSelectedTxByCaseId(this.state.case_details, case_id),
      selected_vbpc: this.getSelectedVbpcByCaseId(this.state.case_details, case_id),
      item_request_mode: 'view',
      progress_record_mode: 'view',
    });

    this.props.setSelectedDDMSubmittedDate(this.getSelectedDDMSubmittedDateByCaseId(this.state.case_details, case_id));
    this.props.setSelectedDDMReceivedDate(this.getSelectedDDMReceivedDateByCaseId(this.state.case_details, case_id));
    this.props.setSelectedProductionReceivedDate(this.getSelectedProductionReceivedDateByCaseId(this.state.case_details, case_id));
    modalClear();
  };
  selectCase = (event) => {
    let case_id = event.target.dataset.case_id;
    if (
      this.state.item_request_mode === 'update' ||
      this.state.item_request_mode === 'create' ||
      this.state.progress_record_mode === 'update' ||
      this.state.progress_record_mode === 'create'
    ) {
      this.setState({
        showChangeTabModal: true,
        changeTabCaseId: case_id,
        changeTabDataTab: event.target.dataset.tab,
      });
    } else {
      const current_path = this.props.history.location.pathname;
      const data_tab = event.target.dataset.tab;
      let new_path = current_path;
      let selected_tab = this.state.selected_tab;
      let selected_request_id = this.state.selected_request_id;
      let selected_progress_id = this.state.selected_progress_id;
      if (case_id) {
        this.props.fetchCaseFileData(case_id);
        new_path = current_path.replace(this.state.selected_case_id, case_id);
        new_path = new_path.replace('/item', '').replace('/progress', '');
        selected_tab = 'case';
      } else if (data_tab) {
        case_id = this.state.selected_case_id;

        if (data_tab === 'item_request') {
          if (!current_path.includes('/item')) {
            new_path = current_path.replace('/progress', '') + '/item';
          }
        } else if (data_tab === 'progress') {
          if (!current_path.includes('/progress')) {
            new_path = current_path.replace('/item', '') + '/progress';
          }
        }
        selected_tab = data_tab;
      }

      if (!selected_request_id && this.state.case_details.item_request && this.state.case_details.item_request.length > 0) {
        selected_request_id = this.state.case_details.item_request[this.state.case_details.item_request.length - 1].ireq_id;
      }

      if (!selected_progress_id && this.state.case_details.progress_record && this.state.case_details.progress_record.length > 0) {
        selected_progress_id = this.state.case_details.progress_record[this.state.case_details.progress_record.length - 1].progress_record;
      }

      this.props.history.replace(new_path);
      if (this.state.item_request_mode === 'update') {
        this.props.history.push({ state: { updateIr: 'true' } });
      }

      if (this.state.progress_record_mode === 'update') {
        this.props.history.push({ state: { updatePr: 'true' } });
      }

      this.setState({
        selected_case_id: case_id,
        selected_tab: selected_tab,
        selected_request_id: selected_request_id,
        selected_progress_id: selected_progress_id,
        selected_address_id: this.getSelectedAddressByCaseId(this.state.case_details, case_id),
        selected_is_historical: getHistoricalStatus(this.state.case_details, case_id),
        selected_setup: this.getSelectedSetupByCaseId(case_id),
        selected_tx: this.getSelectedTxByCaseId(this.state.case_details, case_id),
        selected_vbpc: this.getSelectedVbpcByCaseId(this.state.case_details, case_id),
      });

      this.props.setSelectedDDMSubmittedDate(this.getSelectedDDMSubmittedDateByCaseId(this.state.case_details, case_id));
      this.props.setSelectedDDMReceivedDate(this.getSelectedDDMReceivedDateByCaseId(this.state.case_details, case_id));
      this.props.setSelectedProductionReceivedDate(this.getSelectedProductionReceivedDateByCaseId(this.state.case_details, case_id));

      this.props.fetchAwb(selected_request_id);

      this.props.fetchProductionTxGuide(case_id);
      this.props.setProductionTxGuideTabConfig(
        this.getCaseStatusByCaseId(case_id),
        this.getCadStatus(case_id),
        this.getCaseByCaseId(this.state.case_details, case_id)
      );

      this.props.fetchSegmentationState(case_id);
      this.props.fetchDdmState(case_id);
      this.props.fetchIfuState(case_id);
      //Replace route-id
    }
  };

  getLatestCaseIDFromCases() {
    let cases = this.state.case_details.cases;
    return cases[cases.length - 1].case_id;
  }

  getInitialCaseIDFromCases() {
    let cases = this.state.case_details.cases;
    return cases[0].case_id;
  }

  /**
   * Sets new edit address when select address drop down changes.
   *
   * @param {Object} event - DropDown Event type object
   * @function
   */
  onSelectedAddress = (event) => {
    const old_address = this.state.selected_tab === 'case' ? this.state.selected_address_id : this.state.ir_selected_address_id;
    const is_old_address = parseInt(old_address) === parseInt(event.target.value);

    this.setState({
      edit_address_id: event.target.value,
      edit_selected_address_id: event.target.value,
      edit_ir_selected_address_id: event.target.value,
      edit_address: is_old_address ? false : true,
    });
  };
  onUpdateAddressClick = (event) => {
    this.setState({
      showExpediteRequest: false,
      showExpediteApproval: false,
      showExpediteDetailsModal: false,
      expedite_note: '',
      expedite_additional_note: '',
      expedite_target_ship_date: '',
      showExpediteCancel: false,
      showUpdateAddressModal: true,
      edit_address_id: this.state.selected_tab === 'case' ? this.state.selected_address_id : this.state.ir_selected_address_id,
      edit_selected_address_id: this.state.selected_address_id,
      edit_ir_selected_address_id: this.state.ir_selected_address_id,
    });
  };

  onSelectedAddressChange = (event) => {
    this.setState({
      edit_address_id: parseInt(event.target.value),
      edit_selected_address_id: parseInt(event.target.value),
      edit_ir_selected_address_id: parseInt(event.target.value),
    });
  };

  /**
   * Closes modals and resets all the edit address state.
   *
   * @param {Object} event - Button Event type object
   * @function
   */
  onUpdateAddressCancel = (event) => {
    this.setState({
      edit_address_id: '',
      edit_selected_address_id: '',
      edit_ir_selected_address_id: '',
      showUpdateAddressModal: false,
    });
  };

  onUpdateAddressConfirm = (event) => {
    const that = this;
    let data = new FormData();
    data.append('address_id', this.state.edit_address_id);
    if (window.$('.select-address')) {
      window.$('.select-address').prop('disabled', true);
    }
    if (window.$('.select-address-modal')) {
      window.$('.select-address-modal').prop('disabled', true);
    }

    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/address_change`, data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            ir_selected_address_id: that.getSelectedAddressByRequestId(res.data, that.state.selected_request_id),
            showUpdateAddressModal: false,
          });

          if (window.$('.select-address')) {
            window.$('.select-address').prop('disabled', false);
            window.$('.select-address').selectpicker('refresh');
          }
          Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/address_change`, data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            selected_address_id: that.getSelectedAddressByCaseId(res.data, that.state.selected_case_id),
            showUpdateAddressModal: false,
          });

          if (window.$('.select-address')) {
            window.$('.select-address').prop('disabled', false);
            window.$('.select-address').selectpicker('refresh');
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  getStatusDateForVerification(cases) {
    if (
      (cases.status_code === 'STATUS_INBRACE_CHECK' ||
        cases.status_code === 'Awaiting Folder Generation Process' ||
        cases.status_code === 'Folder Generation Process' ||
        cases.status_code === 'Folder Generation Failed' ||
        cases.status_code === 'Folder Generation Success' ||
        cases.status_code === 'Segmentation Process' ||
        cases.status_code === 'INBRACE Verifying Records') &&
      cases.file_review_process.log.length > 2
    ) {
      let date_index = cases.file_review_process.log.length - 2;

      for (let i = cases.file_review_process.log.length - 1; i >= 0; i--) {
        if (cases.file_review_process.log[i].status_code === 'STATUS_DOCTOR_UPLOAD') {
          date_index = i;
          break;
        }
      }

      return cases.file_review_process.log[date_index].date;
    }

    return;
  }

  onAddressConfirm() {
    const that = this;
    let data = new FormData();
    data.append('address_id', this.state.edit_address_id);
    window.$('.select-address').prop('disabled', true);
    // window.$('.select-address').selectpicker('refresh');
    if (this.state.selected_tab === 'item_request') {
      Axios.post(`/apiV2/iraction/${this.state.selected_request_id}/address_change`, data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            ir_selected_address_id: that.getSelectedAddressByRequestId(res.data, that.state.selected_request_id),
            edit_address: false,
          });
          //window.$('.select-address').value = that.getSelectedAddressByRequestId(res.data, that.state.selected_request_id);
          window.$('.select-address').prop('disabled', false);
          window.$('.select-address').selectpicker('refresh');
          Axios.post(`/apiV2/iraction/${that.state.selected_request_id}/invoice`);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      Axios.post(`/apiV2/caseaction/${this.state.selected_case_id}/address_change`, data)
        .then(function (res) {
          that.setState({
            case_details: res.data,
            selected_address_id: that.getSelectedAddressByCaseId(res.data, that.state.selected_case_id),
            edit_address: false,
          });
          //window.$('.select-address').value = that.getSelectedAddressByCaseId(res.data, that.state.selected_case_id);
          window.$('.select-address').prop('disabled', false);
          window.$('.select-address').selectpicker('refresh');
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  }
  /**
   * Manually refreshing upon discarding the result of the address currently selected
   * @function
   */
  onAddressDiscard = () => {
    let that = this;

    if (this.state.selected_tab === 'item_request') {
      this.setState(
        {
          edit_address: false,
          edit_address_id: '',
          edit_address_info: '',
          edit_ir_selected_address_id: '',
          ir_selected_address_id: that.state.ir_selected_address_id,
        },
        () => {
          window.$('.select-address').val(that.state.ir_selected_address_id);
          window.$('.select-address').selectpicker('refresh');
        }
      );
    } else {
      this.setState(
        {
          edit_address: false,
          edit_address_info: '',
          selected_address_id: that.state.selected_address_id,
        },
        () => {
          window.$('.select-address').val(that.state.selected_address_id);
          window.$('.select-address').selectpicker('refresh');
        }
      );
    }
  };
  /**
   * Manually refreshing address selection via jquery due to styling select dropdown
   * @function
   */
  refreshAddressSelection = () => {
    window.$('.select-address').selectpicker('refresh');
  };
  // onTrackingDiscard() {
  //   if (this.state.selected_tab === 'item_request') {
  //     this.setState({
  //       edit_tracking: false,
  //       edit_tracking_info: '',
  //       ir_selected_tracking_info: this.state.ir_selected_tracking_info,
  //     });
  //   } else {
  //     this.setState({
  //       edit_tracking: false,
  //       edit_tracking_info: '',
  //       selected_tracking_info: this.state.selected_tracking_info,
  //     });
  //   }
  // }

  hasDropboxLink() {
    return false;
  }

  hasAssignTo() {
    return false;
  }

  onFirstNameChange = (event) => {
    let first_name = textFieldCheck(event.target.value);
    first_name = first_name
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    this.setState({
      patient_firstname: first_name,
    });
  };

  onLastNameChange = (event) => {
    let last_name = textFieldCheck(event.target.value);
    last_name = last_name
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    this.setState({
      patient_lastname: last_name,
    });
  };

  onDobChange = (event) => {
    let dob = textFieldCheck(event.target.value);

    this.setState({
      patient_dob: dob,
      patient_dob_error: Moment(dob).isBefore('1900-01-01') || !Moment(dob).isValid() || Moment(dob).isAfter(new Date()),
    });
  };

  onSexChange = (event) => {
    let sex = textFieldCheck(event.target.value);

    this.setState({
      patient_sex: sex,
    });
  };

  onPatientDetailSaveClick = (event) => {
    const that = this;
    let form_data = new FormData();
    form_data.append('case_id', this.state.case_details.case_id);
    form_data.append('patient_id', this.state.case_details.patient.id);
    form_data.append('first_name', this.state.patient_firstname);
    form_data.append('last_name', this.state.patient_lastname);
    form_data.append('dob', this.state.patient_dob);
    form_data.append('sex', this.state.patient_sex);
    form_data.append('patient_ref', this.state.patient_ref);
    form_data.append('attribution_response', JSON.stringify(this.state.attributions));

    let form_data_rename = new FormData();
    form_data_rename.append('case_id', this.state.case_details.case_id);
    form_data_rename.append('patient_id', this.state.case_details.patient.id);
    form_data_rename.append('new_first_name', this.state.patient_firstname);
    form_data_rename.append('new_last_name', this.state.patient_lastname);
    form_data_rename.append('old_first_name', this.state.case_details.patient.first_name);
    form_data_rename.append('old_last_name', this.state.case_details.patient.last_name);

    that.setState({ editPatientDetailsPending: true }, () => {
      Axios.post(`/apiV2/patientdetail/${this.state.case_details.patient.id}`, form_data)
        .then(function (res) {
          modalClose('editPatientDetails');
          that.setState({
            case_details: res.data,
            showEditPatientDetailsModal: false,
            editPatientDetailsPending: false,
          });

          that.renamePatientFolder(form_data_rename);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          if (err && err.response && err.response.status === 409) {
            that.setState({
              showEditPatientDetailsModal: false,
            });
          }
          that.setState({ editPatientDetailsPending: false });
        });
    });
  };

  getIRSubmissionDate(item_request) {
    return convertDate(item_request.created_date);
  }

  getProgressDisplayType = (progress_record) => {
    let display_value = 'Progress Record';
    if (progress_record.revision_id === 'CSR1.0' && progress_record.csq_data) {
      let csq_data = JSON.parse(progress_record.csq_data);
      let record_type = csq_data.questions.filter(function (a) {
        return a.friendly_name === 'submission_type';
      })[0].answer_text;
      if (record_type) {
        display_value = record_type;
      }
    }

    switch (progress_record.record_type) {
      case 'Progress Record':
        break;
      case 'CSQ':
        display_value = display_value + ' and Clinical Support Questionnaire';
        break;

      case 'CSR':
        display_value = display_value + ' and Clinical Support Request';
        break;
      default:
    }

    return display_value;
  };

  getShippingAddressFromId(address_id, addresses) {
    let add = '';
    addresses.forEach(function (address) {
      if (address.id === address_id) {
        add = address;
      }
    });
    return add;
  }

  /**
   * Retrieves target completion dates for case or ireq
   * @function
   * @param {string} id - Case or Item request id
   * @returns {Object} - Target completion dates
   */
  getTargetDates = (id) => {
    let target_dates = {
      smile_design_target_date: '',
      appliance_design_target_date: '',
      internal_target_ship_date: '',
      external_target_ship_date: '',
      preferred_shipping: '',
    };

    const case_or_ireq = id.includes('-IR') ? this.getIreqByIreqId(id) : this.getCaseByCaseId(this.state.case_details, id);

    if (case_or_ireq) {
      target_dates.smile_design_target_date = case_or_ireq.smile_design_target_date;
      target_dates.appliance_design_target_date = case_or_ireq.appliance_design_target_date;
      target_dates.internal_target_ship_date = case_or_ireq.internal_target_ship_date;
      target_dates.external_target_ship_date = case_or_ireq.external_target_ship_date;
      target_dates.preferred_shipping = case_or_ireq.preferred_shipping;
    }

    return target_dates;
  };

  /**
   * Returns permissions for action based on type of the record and status code
   * @function
   * @return {string} Permission code string
   */
  loadShippingUpdatePermissionByType = () => {
    if (this.state.selected_tab === 'case') {
      return 'CASE_SHIPPING_DETAILS_UPDATE';
    } else if (this.state.selected_tab === 'item_request') {
      return 'IREQ_SHIPPING_DETAILS_UPDATE';
    } else {
      return '';
    }
  };
  /**
   * Displays the shipping container for an item request
   * @function
   * @param {Object} item_request - The item request object
   * @param {Object} user_roles_and_permissions - The permissions object
   * @return {JSX} JSX element of the IR shipping container
   */
  displayIrShippingInformation(item_request, user_roles_and_permissions) {
    if (item_request) {
      let is_production_wo_present = true;
      let item_parts_quantity = [];
      this.state.case_details.item_request_details.forEach((item_request_detail) => {
        if (item_request_detail.ireq_id === item_request.ireq_id) {
          if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
            is_production_wo_present = false;
          }
          item_parts_quantity.push(item_request_detail);
        }
      });

      const ireq_parts = item_parts_quantity.filter((ip) => {
        return ip.description.toLowerCase().includes('smartwire');
      });

      let latest_status = {};
      if (this.props.awb && this.props.awb.ireq_automation_status) {
        latest_status = this.props.awb.ireq_automation_status.filter((status) => {
          return status.current_ind === true;
        })[0];
      }

      const status = latest_status && latest_status.status ? latest_status.status : '';
      let production_compete =
        ireq_parts.length === 0 ||
        status === 'IREQ AWB Override Process' ||
        status === 'IREQ AWB Outputs Approved' ||
        status === 'IREQ AWB Rework' ||
        status === 'IREQ AWB Manual Transfer' ||
        status === 'IREQ AWB Manual Rework'
          ? true
          : false;

      this.state.case_details.item_request_details.forEach((item_request_detail) => {
        if (item_request_detail.ireq_id === item_request.ireq_id) {
          if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
            is_production_wo_present = false;
          }
        }
      });

      if (
        item_request.ireq_status !== 'Item Request Shipped' &&
        item_request.ireq_status !== 'Item Request On Hold' &&
        item_request.ireq_status !== 'Item Request Cancelled'
      ) {
        try {
          if (window.$('.select-address')) {
            window.$('.select-address').selectpicker();
          }
        } catch (error) {
          console.log(error);
        }
      }

      if (item_request.ireq_status === 'Item Request Shipped') {
        window.$('.select-address').selectpicker('hide');
      }

      return (
        <IrShippingContainer
          item_request={item_request}
          case_details={this.state.case_details}
          onSelectedAddress={this.onSelectedAddress}
          getShippingAddressFromId={this.getShippingAddressFromId}
          edit_tracking={this.props.edit_tracking}
          edit_ir_selected_address_id={this.state.edit_ir_selected_address_id}
          ir_selected_address_id={this.state.ir_selected_address_id}
          production_compete={production_compete}
          is_production_wo_present={is_production_wo_present}
          edit_address={this.state.edit_address}
          onAddressConfirm={this.onAddressConfirm}
          onAddressDiscard={this.onAddressDiscard}
          ir_edit_in_progress={this.state.ir_edit_in_progress}
          awb={this.props.awb}
          reload_information={this.reloadInformation}
          user_roles_and_permissions={user_roles_and_permissions}
          ir_label_generation_process={this.state.ir_label_generation_process}
          templateNo={this.state.templateNo}
          gen_2={this.getGen2Flag()}
          wire_selection_guide={this.state.wire_selection_guide}
          ir_ws_status={this.state.ir_ws_status}
          patient_info={this.state.case_details.patient}
        ></IrShippingContainer>
      );
    }
  }

  /**
   * Show Target Date Override Modal
   */
  onTargetDateOverrideModalClick = (event) => {
    this.setState({
      show_target_date_override_modal: true,
    });
  };

  /**
   * Hide Target Date Override Modal
   */
  onTargetDateOverrideModalDismiss = (event) => {
    this.setState({
      show_target_date_override_modal: false,
      ext_override_errors: {
        note: false,
        date: false,
        message: '',
      },
      external_target_date_override: {
        note: '',
        date: '',
      },
    });
  };

  /**
   * Determines if target date override form has errors
   * @returns {Boolean} Error Status
   */
  hasTargetOverrideErrors = () => {
    return this.hasEmptyFieldError() || this.hasInvalidDateError();
  };

  /**
   * Checks if Target Date Override has Empty Fields
   * @returns {Boolean}
   */
  hasEmptyFieldError = () => {
    const { date, note } = this.state.external_target_date_override;
    return !date || !note;
  };

  /**
   * Sets empty field error state
   */
  setEmptyFieldError = () => {
    this.setState((prev_state) => ({
      ...prev_state,
      ext_override_errors: {
        ...prev_state.ext_override_errors,
        date: !prev_state.external_target_date_override.date,
        note: !prev_state.external_target_date_override.note,
        message: 'Please fill out the highlighted fields',
      },
    }));
  };

  /**
   * Checks if Target Date Override has an invalid date
   * @returns {Boolean} Invalid date status
   */
  hasInvalidDateError = () => {
    const { date: curr_date } = this.state.external_target_date_override;
    const type = this.state.selected_tab;
    const min_date =
      type === 'item_request'
        ? this.getSelectedIRTargetShipDates()
        : this.state.case_details.cases[this.state.case_details.cases.length - 1].external_target_ship_date;

    const is_date_valid = isValidDate(curr_date, min_date, Moment(min_date).add(100, 'years'));
    return !is_date_valid;
  };

  /**
   * Sets invalid date error status
   */
  setInvalidDateError = () => {
    this.setState((prev_state) => ({
      ...prev_state,
      ext_override_errors: {
        ...prev_state.ext_override_errors,
        date: true,
        note: !prev_state.external_target_date_override.note,
        message: 'Date must be after the current external estimated ship date',
      },
    }));
  };

  /**
   * Set error status on Target Override Modal
   */
  setTargetOverrideError = () => {
    if (this.hasEmptyFieldError()) {
      this.setEmptyFieldError();
    }
    if (this.hasInvalidDateError()) {
      this.setInvalidDateError();
    }
  };

  /**
   * Update Target Date
   */
  onTargetDateOverride = () => {
    let type = this.state.selected_tab;
    const original_date =
      type === 'item_request'
        ? this.getSelectedIRTargetShipDates()
        : this.state.case_details.cases[this.state.case_details.cases.length - 1].external_target_ship_date;

    if (this.hasTargetOverrideErrors()) {
      this.setTargetOverrideError();
      return false;
    }

    this.setState({
      ext_override_in_progress: true,
    });

    const ireq_id = this.state.selected_request_id ? this.state.selected_request_id : null;

    Axios.put('/apiv3/targetdateoverride', {
      type: type,
      case_id: this.state.case_details.case_id,
      ireq_id: ireq_id,
      note: this.state.external_target_date_override.note,
      date: this.state.external_target_date_override.date,
      original_date: original_date,
    })
      .then((res) => {
        this.reloadInformation();
        if (type === 'item_request') {
          Axios.post(
            `/api/email/?slug=ir-estimated-ship-date-manual-override&ireqId=${this.state.selected_request_id}&method=standard&provider=${window.location.origin}`
          );
        } else {
          Axios.post(
            `/api/email/?slug=case-estimated-ship-date-manual-override&caseId=${this.state.selected_case_id}&method=standard&provider=${window.location.origin}`
          );
        }
        this.setState((prev_state) => {
          const new_state = { ...prev_state };
          new_state.external_target_date_override = { note: '', date: '' };
          new_state.show_target_date_override_modal = false;
          new_state.ext_override_in_progress = false;
          new_state.ext_override_errors = {
            note: false,
            date: false,
            message: '',
          };
          return new_state;
        });
      })
      .catch((error) => {
        this.setState({
          external_target_date_override: { case_id: '', note: '', message: '' },
          show_target_date_override_modal: false,
          ext_override_in_progress: false,
          ext_override_errors: {
            note: false,
            date: false,
            message: '',
          },
          refresh: true,
        });
      });
  };

  /**
   * Handle override note text area change
   */
  onExtOverrideNoteChange = (event) => {
    const { value, maxLength } = event.target;
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      new_state.external_target_date_override.note = removeEmoji(value);
      new_state.ext_override_errors.note = false;
      if (!new_state.ext_override_errors.note && !new_state.ext_override_errors.date) {
        new_state.ext_override_errors.message = '';
      }
      if (value.length === maxLength) {
        new_state.ext_override_errors.note = true;
        new_state.ext_override_errors.message = 'The character limit has been reached';
      }
      return new_state;
    });
  };

  /**
   * Handle Override Date area change
   * @param {*} event
   */
  onExtOverrideDateChange = (event) => {
    const value = event.target.value;
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      new_state.external_target_date_override.date = value;
      new_state.ext_override_errors.date = false;
      if (!new_state.ext_override_errors.note && !new_state.ext_override_errors.date) {
        new_state.ext_override_errors.message = '';
      }
      return new_state;
    });
  };

  /**
   * Convert DB date to mm/dd/yyyy format
   * @param {String} date
   * @returns {String} date
   */
  showCurrentEstimatedShipDate = (date) => {
    if (!date) return 'N/A';
    return Moment(date).format('MM/DD/YYYY');
  };

  /**
   * Select the item request needed target ship date
   */
  getSelectedIRTargetShipDates = () => {
    const selected_request_id = this.state.selected_request_id;
    const item_request = this.state.case_details?.item_request.filter((ir) => {
      return ir.ireq_id === selected_request_id;
    });

    return item_request[0].target_ship_date;
  };
  /**
   * Create Target Date Override Modal Body
   */
  displayTargetDateOverrideModalBody = () => {
    const current_target_date =
      this.state.selected_tab === 'case'
        ? this.state.case_details?.cases[this.state.case_details.cases.length - 1]?.external_target_ship_date
        : this.getSelectedIRTargetShipDates();
    const day_after_current = Moment(current_target_date).add(1, 'days').format('YYYY-MM-DD');

    return (
      <div className="external-target-override-modal-body">
        <div className="form-group">
          <label className="inline-label">Current External Estimated Ship Date: </label>
          <div className="inline-input">{this.showCurrentEstimatedShipDate(current_target_date)}</div>
        </div>
        <div className="form-group">
          <label>New External Estimated Ship Date: </label>
          <TextBox
            id={this.props.date_input_id}
            type="date"
            placeholder="mm/dd/yyyy"
            className={`ship-date-input ${this.state.datearea_warning_active || this.state.ext_override_errors.date ? 'warning-border' : ''}`}
            onChange={this.onExtOverrideDateChange}
            onFocus={this.props.onFocus}
            value={this.state.external_target_date_override.date ? this.state.external_target_date_override.date : ''}
            min={day_after_current ? day_after_current : getMinDate()}
            max={moment(current_target_date).add(1, 'years').format('YYYY-MM-DD')}
            disabled={this.props.disabledEdit}
          />
        </div>
        <div className="form-group">
          <label className="inline-label">Internal Note: </label>
          <textarea
            className={`modal-textarea inline-input ${this.state.ext_override_errors.note && 'modal-textarea-error'}`}
            maxLength={500}
            onChange={this.onExtOverrideNoteChange}
            placeholder="Please specify details for updating the external estimated ship date..."
            rows={this.state.dropdown ? '6' : '4'}
            value={this.state.external_target_date_override.note}
            onBlur={this.onTextAreaBlur}
          />
        </div>
        <div className="override-error-message">{this.state.ext_override_errors.message}</div>
      </div>
    );
  };

  displayIrCadProcess(item_request) {
    let item_parts_quantity = [];
    let is_production_wo_present = true;

    this.state.case_details.item_request_details.forEach((item_request_detail) => {
      if (item_request_detail.ireq_id === item_request.ireq_id) {
        if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
          is_production_wo_present = false;
        }
        item_parts_quantity.push(item_request_detail);
      }
    });

    if (this.state.ir_edit_in_progress && is_production_wo_present) {
      is_production_wo_present = false;
    }

    if (
      item_request &&
      (item_request.ireq_status === 'INBRACE Manufacturing Item Request' ||
        (item_request.label_generation.length > 0 && item_request.ireq_status === 'Item Request Shipped'))
    ) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
              <div className="cad-container">
                <div className="bold-text business-dark-heading">{'Labels'}</div>
                <div className="business-dark-theme">
                  <div className="business-dark-theme-content">
                    <IRCADContainer
                      case_id={this.state.selected_case_id}
                      ireq_id={this.state.selected_request_id}
                      item_request={item_request}
                      item_parts_quantity={item_parts_quantity}
                      user_roles_and_permissions={user_roles_and_permissions}
                      ref_no={is_production_wo_present}
                      update_wo={this.updateIrProductionWo}
                      reload_information={this.reloadInformation}
                      restartLabelGeneration={this.irRestartLabelGeneration}
                      gen_2={this.getGen2Flag()}
                      patient_info={this.state.case_details.patient}
                    />
                  </div>
                </div>
              </div>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }

  displayIrProductionProcess(item_request, isGen2) {
    let item_parts_quantity = [];
    let is_production_wo_present = true;
    this.state.case_details.item_request_details.forEach((item_request_detail) => {
      if (item_request_detail.ireq_id === item_request.ireq_id) {
        if (!item_request_detail.production_wo || item_request_detail.production_wo.length === 0) {
          is_production_wo_present = false;
        }
        item_parts_quantity.push(item_request_detail);
      }
    });

    if (this.state.ir_edit_in_progress && is_production_wo_present) {
      is_production_wo_present = false;
    }
    const ireq_parts = item_parts_quantity.filter((ip) => {
      return ip.description.toLowerCase().includes('smartwire');
    });
    if (
      !isGen2 &&
      item_request &&
      ireq_parts.length > 0 &&
      (item_request.ireq_status === 'INBRACE Manufacturing Item Request' ||
        (item_request.label_generation.length > 0 && item_request.ireq_status === 'Item Request Shipped'))
    ) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
              <div className="cad-container">
                <div className="bold-text business-dark-heading">{'AWB'}</div>
                <div className="business-dark-theme">
                  <div className="business-dark-theme-content">
                    <IrProductionContainer
                      case_id={this.state.selected_case_id}
                      ireq_id={this.state.selected_request_id}
                      item_request={item_request}
                      item_parts_quantity={item_parts_quantity}
                      user_roles_and_permissions={user_roles_and_permissions}
                      ref_no={is_production_wo_present}
                      reload_information={this.reloadInformation}
                      ireq_status={item_request.ireq_status}
                      awb={this.props.awb}
                      patient_info={this.state.case_details.patient}
                    />
                  </div>
                </div>
              </div>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }

  displayIRWireSelectionSection = (item_request, isGen2) => {
    const isActive =
      _.intersection(
        [item_request.ireq_status],
        [
          'Item Request Submitted',
          'INBRACE Reviewing Request',
          'Item Request Cancelled',
          'Item Request Uncancelled',
          'Item Request On Hold',
          'Item Request Unheld',
        ]
      ).length === 0;
    const ir_details = this.state.case_details.item_request_details.filter((detail) => detail.ireq_id === item_request.ireq_id);
    const ir_smartwires = ir_details.filter((item) => item.part_category === 'smartwire');
    const hasASmartWire = ir_smartwires.length > 0;

    return isGen2 && isActive && hasASmartWire ? (
      <div className="cad-container">
        <WSWorkflow
          item_request={item_request}
          ireq_smartwires={ir_smartwires}
          updateTemplateNo={this.updateTemplateNo}
          hasWsGuide={this.hasWsGuide}
          setWsStatus={this.setWsStatus}
          patient_info={this.state.case_details.patient}
        />
      </div>
    ) : null;
  };

  /**
   * Displays Single-Tooth IDB Tray generation component for IRs
   * @function
   * @returns {JSX} - JSX for single-tooth IDB
   */
  displayIRSingleToothIdb = (item_request) => {
    const inactive_statuses = ['Item Request Cancelled', 'Item Request On Hold'];
    const is_active = !inactive_statuses.includes(item_request.ireq_status);
    return is_active ? <IrSingleToothIdb ireq_id={this.state.selected_request_id} shipping_approved={item_request.shipping_approved_ind} /> : null;
  };

  updateTemplateNo = (template, value) => {
    let new_no = _.cloneDeep(this.state.templateNo);
    if (template in new_no) {
      new_no[template] = value;
    }

    this.setState({
      templateNo: new_no,
    });
  };

  hasWsGuide = (value) => {
    this.setState({
      wire_selection_guide: value,
    });
  };

  setWsStatus = (value) => {
    this.setState({
      ir_ws_status: value,
    });
  };

  getTemplateNoValue = (part_no, part_category) => {
    if (part_category === 'smartwire') {
      const { templateNo } = this.state;

      if (part_no in templateNo) {
        return templateNo[part_no] ? templateNo[part_no] : 'N/A';
      }
    }

    return 'N/A';
  };

  displayIRSubmissionProcess(item_request, isGen2) {
    const ITEM_PART_LOOKUP = getLookup(item_request.case_id, true); // nomenclature lookup
    const isInWork = item_request.ireq_status === 'Item Request Shipped' || item_request.ireq_status === 'INBRACE Manufacturing Item Request';
    const isDE = item_request.ireq_id && (item_request.ireq_id.indexOf('-DE') >= 0 || item_request.ireq_id.indexOf('-R') >= 0);
    let request_reasons = JSON.parse(item_request.ireq_reason);
    let item_parts_quantity = [];

    this.state.case_details.item_request_details.forEach((item_request_detail) => {
      if (item_request_detail.ireq_id === item_request.ireq_id) {
        item_parts_quantity.push(item_request_detail);
      }
    });

    let arch_segments_options = [];
    request_reasons.forEach(function (reason) {
      if (reason.arch_segments_options) {
        let options = reason.arch_segments_options.filter(function (arch_segments_option) {
          return arch_segments_option.active === true;
        });
        options.forEach(function (arch_segments_option) {
          arch_segments_options.push(arch_segments_option.title);
        });
      }
      // Update verbiage only for Gen2
      if (isGen2) {
        // Overtime, the code below won't be necessary since we are recording with the right verbiage now
        // These labels come from the database, and shouldn't be necessary if we update every row
        // Since that would be too heavy, we update only on the frontend
        if (isDE) {
          reason.label = reason.label.replace('Original', 'Original DE');
        } else {
          reason.label = reason.label.replace('Original', 'Initial');
        }
        reason.name_label = reason.name_label.replace('IDB Required', 'IDB Tray Required');
        reason.label = reason.label.replace('Upper', 'Upper Initial').replace('Lower', 'Lower Initial');
      }
    });
    if (item_request) {
      return (
        <div className="business-dark-theme">
          <div className="business-dark-theme-content">
            <div className="business-dark-group">
              <div className="bpp-cozy-text-headlines cozy-paragraph">
                <span className="bold-text">
                  Reason for Item Request
                  <br />
                </span>
                <div className="special-5-padding" />
                {request_reasons.map((reason) => {
                  const idb_updated =
                    reason.category === 'idb_required' &&
                    (hasSingleToothSelection(reason) || hasArchSegmentSelection(reason)) &&
                    !this.props.is_historical_single_tooth_idb;
                  if (reason.booleanValue) {
                    return (
                      <span key={reason.id}>
                        <div data-id={reason.id} className="review-reasons">
                          {idb_updated ? (
                            <>
                              {hasSingleToothSelection(reason) ? (
                                <li data-id={reason.id}>{`${reason.name_label}: ${
                                  isGen2 ? reason.single_tooth_selection_text : reason.label
                                } (${reason.selectedTeeth.sort().reverse().join(', ')})`}</li>
                              ) : null}
                              {hasArchSegmentSelection(reason) ? (
                                <li data-id={reason.id}>{`${reason.name_label}: ${reason.arch_segment_selection_text} (${arch_segments_options.join(
                                  ', '
                                )})`}</li>
                              ) : null}
                            </>
                          ) : (
                            <li data-id={reason.id}>
                              {reason.name_label}:{' '}
                              {reason.segment_preference_text
                                ? reason.segment_preference_text.replace(':', '')
                                : isDE && reason.label_de
                                ? reason.label_de.replace(':', '')
                                : reason.label.replace(':', '')}
                              {reason.selectedTeeth && reason.selectedTeeth.length > 0 ? ' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')' : null}
                              {reason.segment_preference &&
                              reason.segment_preference === 'arch_segments' &&
                              arch_segments_options &&
                              arch_segments_options.length > 0
                                ? ' (' + arch_segments_options.join(', ') + ')'
                                : null}
                              {reason.value ? ` - "${reason.value}"` : null}
                            </li>
                          )}
                        </div>
                      </span>
                    );
                  }
                })}
              </div>
              <div className={item_request.ireq_status === 'INBRACE Reviewing Request' ? 'bpp-cozy-text-headlines' : null}>
                <div className="item-request bold-text">
                  <span className="item-details-part-id">Part No.</span>
                  <span className="item-details-description">Item</span>
                  {isGen2 && isInWork ? <span className="item-details-template">Template Part No.</span> : null}
                  <span className="item-details-quantity text-center">Qty</span>
                  {isInWork ? <span className="item-details-woref">WO Ref</span> : null}
                </div>
                <div className="bpps__line-divison" />
                {item_parts_quantity.map((item_part, idx) =>
                  item_part.quantity > 0 ? (
                    <div className="item-request" key={idx}>
                      <div className="item-details-part-id">{item_part.part_id}</div>
                      <div className="item-details-description">{ITEM_PART_LOOKUP[item_part.part_id]}</div>
                      {isGen2 && isInWork ? (
                        <span className="item-details-template">{this.getTemplateNoValue(item_part.part_id, item_part.part_category)}</span>
                      ) : null}
                      <div className="item-details-quantity text-center">{item_part.quantity}</div>
                      {isInWork ? (
                        <div className="item-details-woref">
                          <WorkOrderRef
                            ireqdetails_id={item_part.ireqdetails_id}
                            placeholder={'WO1234567'}
                            ref_no={item_part.production_wo}
                            update_wo={this.updateIrProductionWo}
                            permission={item_request.ireq_status === 'INBRACE Manufacturing Item Request' ? 'IREQ_PROD_WO_EDIT' : null}
                            classOverwrite="work-ref-order-ir"
                            edit_state={this.state.ir_edit_in_progress}
                            edit_in_progress={this.setIrEditInProgress}
                            shipping_approved_ind={item_request.shipping_approved_ind}
                            disabled={
                              this.getLatestIreqLabelGenerationStatus(item_request) === 'Label Generation Success' &&
                              item_parts_quantity.some((item) => !['80028', '80027'].includes(item.part_id))
                            }
                            ref_no_required={true}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>

              <SecurityClearance mode="ANY" permission_list={['IREQ_APPROVE', 'IREQ_UPDATE', 'IREQ_CANCEL']}>
                {this.getItemRequestsButton(item_request)}
              </SecurityClearance>
              {this.getItemRequestsCancel(item_request)}
              {this.getItemRequestsHold(item_request)}
            </div>
          </div>
        </div>
      );
    }
  }

  onIrLinkClick = (event) => {
    this.props.history.push(`/portal/${this.state.case_details.doctor.id}/item/${this.getLatestCaseIDFromCases()}`, {
      selectedTab: 'item',
      ireq_id: event.target.dataset.id,
      ir_ws_status: '',
    });
  };
  /**
   * Determines of a case has note or not
   * @function
   * @return {Boolean} Return true when case_details has
   */
  hasNotes = () => {
    const { selected_case_id, case_details } = this.state;
    const selected_case = this.getCaseByCaseId(case_details, selected_case_id);

    return selected_case && selected_case.notes && selected_case.notes.length > 0;
  };
  /**
   * Save notes into case details instead of using the indicator
   * @function
   * @param {Object} data - the entire notes array
   */
  setNotesIndicator = (data) => {
    let { selected_case_id, case_details } = this.state;
    let selected_case = this.getCaseByCaseId(case_details, selected_case_id);

    selected_case.notes = data;
    case_details = replaceCaseByCaseId(case_details, selected_case, selected_case_id);

    this.setState({
      case_details: case_details,
    });
  };

  setIrNotesIndicator = (value) => {
    let case_details = this.state.case_details;
    case_details.item_request.filter((c) => {
      return c.ireq_id === this.state.selected_request_id;
    })[0].notes_indicator = value;

    this.setState({
      case_details: case_details,
    });
  };

  setProgressNotesIndicator = (value) => {
    let progress_notes = value;

    this.setState({
      progress_notes: progress_notes,
    });
    this.reloadInformation();
  };

  isCaseTabActive = (case_id) => {
    return this.state.selected_case_id === case_id && this.state.selected_tab === 'case';
  };

  regenTXPDF = () => {
    Axios.post(`/apiV2/pdfregen/tx_plan/${this.state.selected_case_id}`).then(() => this.reloadInformation());
  };

  /**
   * Regenerates Production TX Guide pdf
   * @function
   */
  regenProductionTxGuide = () => {
    Axios.post(`/apiv3/productiontxguide/${this.state.selected_case_id}/generate`).then(() => this.reloadInformation());
  };

  renamePatientFolder = (form_data) => {
    Axios.post(`/apiV2/renamepatientfolder/${this.state.case_details.patient.id}`, form_data).then(this.regenTXPDF);
  };

  hasManufacturingStatusInLog = (logs) => {
    let hasManufacturingStatus = false;

    if (logs && logs.length > 0) {
      let foundManufacturingStatus = logs.filter((log) => {
        return log.status_code === 'INBRACE Manufacturing Case' || log.status_code === 'STATUS_MANUFACTURE';
      });

      hasManufacturingStatus = foundManufacturingStatus.length > 0;
    }

    return hasManufacturingStatus;
  };

  regenDEPDF = () => {
    Axios.post(`/apiV2/pdfregen/de_plan/${this.state.selected_case_id}`).then(() => this.reloadInformation());
  };

  hasAdditionalActionViewByPermission = (permissions, cases) => {
    return (
      (cases.status_code === 'STATUS_CANCEL' && this.isLatestCancelCase(cases) && userHasPermission('CASE_CANCEL_UNDO', permissions)) ||
      (cases.status_code !== 'STATUS_SHIP' &&
        cases.status_code !== 'STATUS_HOLD' &&
        cases.status_code !== 'STATUS_CANCEL' &&
        userHasPermission(
          isAfterSmileDesignApproval(cases.status_code) ? 'CASE_CANCEL_POST_SMILE_DESIGN_APPROVAL' : 'CASE_CANCEL_PRE_SMILE_DESIGN_APPROVAL',
          permissions
        )) ||
      (cases.status_code === 'STATUS_HOLD' && cases.status_code !== 'STATUS_CANCEL' && userHasPermission('CASE_HOLD_UNDO', permissions)) ||
      (cases.status_code !== 'STATUS_SHIP' &&
        cases.status_code !== 'STATUS_CANCEL' &&
        cases.status_code !== 'STATUS_HOLD' &&
        userHasPermission('CASE_HOLD', permissions)) ||
      (cases.status_code !== 'STATUS_SHIP' && !cases.case_id.includes('-') && userHasPermission('PATIENT_EDIT', permissions)) ||
      (cases.status_code === 'STATUS_SHIP' && userHasPermission('IREQ_SUBMIT', permissions)) ||
      (cases.precheck &&
        !cases.case_id.includes('-DE') &&
        !cases.case_id.includes('-R') &&
        cases.status_code !== 'STATUS_CANCEL' &&
        cases.status_code !== 'STATUS_SHIP' &&
        cases.status_code !== 'CAD Process' &&
        cases.status_code !== 'Quality Control Review' &&
        cases.status_code !== 'STATUS_MANUFACTURE' &&
        cases.status_code !== 'INBRACE Manufacturing Case' &&
        userHasPermission('CASE_PRECHECK_UNFLAG', permissions)) ||
      (!cases.precheck &&
        !cases.case_id.includes('-DE') &&
        !cases.case_id.includes('-R') &&
        cases.status_code !== 'STATUS_CANCEL' &&
        cases.status_code !== 'STATUS_SHIP' &&
        cases.status_code !== 'CAD Process' &&
        cases.status_code !== 'Quality Control Review' &&
        cases.status_code !== 'STATUS_MANUFACTURE' &&
        cases.status_code !== 'INBRACE Manufacturing Case' &&
        userHasPermission('CASE_PRECHECK_FLAG', permissions)) ||
      (cases.status_code !== 'STATUS_SHIP' &&
        cases.status_code !== 'STATUS_HOLD' &&
        cases.status_code !== 'STATUS_CANCEL' &&
        userHasPermission('CASE_DETAILS_VIEW', permissions))
    );
  };

  setConversion = (value) => {
    this.setState({
      ip_conversion: value,
    });
  };

  /**
   * Gets list of reasons for cancel and hold
   * @function
   * @param category {String} - category of the call
   * @return {Array} - List of reasons for the hold or cancel based on category
   */
  getHoldAndCancelReasons = (category) => {
    let that = this;
    Axios.get(`/apiV2/categoryreason/${that.state.selected_case_id}/${category}`)
      .then((res) => {
        let category_reasons = res.data.category_reasons;
        console.log('category_reasons', category_reasons);

        const current_case = this.state.case_details.cases.filter((c) => {
          return c.case_id === this.state.selected_case_id;
        })[0];
        if (
          category === 'hold' &&
          ((current_case.status_code !== 'STATUS_MANUFACTURE' &&
            current_case.status_code !== 'CAD Process' &&
            current_case.status_code !== 'Quality Control Review') ||
            current_case.case_id.includes('-DE'))
        ) {
          category_reasons = category_reasons.filter((reason) => {
            return reason.reason !== 'Credit Hold';
          });
        }
        if (category === 'hold' && that.state.selected_case_id.indexOf('-DE') === -1) {
          category_reasons = category_reasons.filter((reason) => {
            return reason.reason !== 'DE Hold';
          });
        } else if (category === 'cancel' && this.getCaseStatus() !== 'STATUS_DOCTOR_APPROVAL') {
          category_reasons = category_reasons.filter((reason) => {
            return reason.reason !== '120 day case expiration';
          });
        } 
        if (category === 'cancel' && current_case.case_id.includes('-DE')) {
          category_reasons.push({category: 'Cancel', reason: 'False DE'}, {category: 'Cancel', reason: 'Early DE'});
        }

        this.setState({
          hold_cancel_reasons: category_reasons,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  isUploading = (state) => {
    this.setState({
      upload_in_progress: state,
    });
  };

  /**
   * Returns whether the case's treatment plan can be editted and user has permission
   * @function
   * @param {Object} case_detail - A single case's detail
   * @param {Array} permissions - The entire permission list
   * @return {Boolean} Whether the case's treatment plan can be editted
   */
  canEditTXPlan(case_detail, permissions) {
    // only allow edit treatment plan before setup approval
    const allowEditStatus = [
      'Folder Generation Success',
      'Segmentation Process',
      'STATUS_INBRACE_CHECK',
      'STATUS_INBRACE_SETUP',
      'Setup Review',
      'Revise Setup',
      'Setup Ready for Upload',
      'Setup Conversion Process',
      'Setup Conversion Failed',
      'Setup Ready for Release',
      'STATUS_DOCTOR_APPROVAL',
      'INBRACE Preparing Setup',
      'Doctor Approve or Revise Setup',
      'INBRACE Checking Files',
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
      'Stage I',
      'Quality Control Stage I',
    ];
    const rev = case_detail && case_detail.submission_process && case_detail.submission_process.treatment_plan_revision;
    const isRev2 = rev === 'TX2.0' || rev === 'TX3.0' || rev === 'DE2.0' || rev === 'DE3.0';

    let case_status = case_detail.status_code;

    if (case_status === 'STATUS_HOLD' || case_status === 'Doctor Provide Clarification') {
      case_status = this.getPreviousCaseStatus(case_detail);
    }
    if (case_status === 'CAD Process') {
      case_status = this.getCadStatus(case_detail.case_id);
    }

    return isRev2 && allowEditStatus.includes(case_status) && userHasPermission('TX_PLAN_EDIT', permissions);
  }

  /**
   * Returns the previous case status of the case
   * @function
   * @param {Object} case_detail - A single case's detail
   * @return {String} The previous case status
   */
  getPreviousCaseStatus(case_detail) {
    const { case_statuses } = case_detail;

    let nonblocking_statuses = _.cloneDeep(case_statuses).filter((cases) => {
      return (
        cases.case_status !== 'Uncancelled' &&
        cases.case_status !== 'Cancelled' &&
        cases.case_status !== 'STATUS_CANCEL' &&
        cases.case_status !== 'STATUS_UNCANCEL' &&
        cases.case_status !== 'Hold' &&
        cases.case_status !== 'Unhold' &&
        cases.case_status !== 'STATUS_HOLD' &&
        cases.case_status !== 'STATUS_UNHOLD' &&
        cases.case_status !== 'Doctor Provide Clarification' &&
        cases.case_status !== 'Doctor Clarification Resolved'
      );
    });
    if (nonblocking_statuses && nonblocking_statuses[nonblocking_statuses.length - 1]) return nonblocking_statuses[nonblocking_statuses.length - 1].case_status;

    return case_detail.status_code;
  }

  /**
   * Returns the most recent label generation status for an item request
   * @function
   * @param {Object} item_request - Item request details
   * @return {String} The latest label generation status
   */
  getLatestIreqLabelGenerationStatus(item_request) {
    return item_request.label_generation.length > 0 ? item_request.label_generation[0].status : '';
  }

  /**
   * Returns clinical review flag for the selected case
   * @function
   * @return {Boolean} Clinical review indicator
   */
  getSelectedCaseClinicalReview() {
    return this.getCaseByCaseId(this.state.case_details, this.state.selected_case_id).clinical_review;
  }

  /**
   * Determines if user can flag/unflag clinical review
   * @function
   * @param {Object} cases - Case information
   * @param {Object} user_roles_and_permissions - User roles/permissions information
   * @return {Boolean} True or False
   */
  canFlagClinicalReview(cases, user_roles_and_permissions) {
    const invalid_statuses = [
      'STATUS_SHIP',
      'Awaiting Folder Generation Process',
      'Folder Generation Process',
      'CAD Process',
      'STATUS_MANUFACTURE',
      'STATUS_CANCEL',
      'Quality Control Review',
      'INBRACE Manufacturing Case',
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
    ];

    const has_permission = userHasPermission(
      cases.clinical_review ? 'CASE_CLINICAL_REVIEW_UNFLAG' : 'CASE_CLINICAL_REVIEW_FLAG',
      user_roles_and_permissions.permissions
    );

    return has_permission && invalid_statuses.indexOf(cases.status_code) === -1 && invalid_statuses.indexOf(this.getPreviousCaseStatus(cases)) === -1;
  }

  /**
   * Determines if user can request doctor clarification
   * @function
   * @param {Object} cases - Case information
   * @param {Object} user_roles_and_permissions - User roles/permissions information
   * @return {Boolean} True or False
   */
  canRequestDoctorClarification(cases, user_roles_and_permissions) {
    const invalid_statuses = ['STATUS_SHIP', 'STATUS_HOLD', 'STATUS_CANCEL'];

    const has_permission = userHasPermission(
      cases.status_code === 'Doctor Provide Clarification' ? 'CASE_CLARIFICATION_RESOLVE' : 'CASE_CLARIFICATION_REQUEST',
      user_roles_and_permissions.permissions
    );

    return has_permission && invalid_statuses.indexOf(cases.status_code) === -1;
  }

  /**
   * Determines if user can skip Production TX Guide
   * @function
   * @param {Object} cases - Case information
   * @param {Object} user_roles_and_permissions - User roles/permissions information
   * @return {Boolean} True or False
   */
  canSkipProductionTxGuide(cases, user_roles_and_permissions) {
    const invalid_statuses = ['STATUS_SHIP', 'STATUS_MANUFACTURE', 'STATUS_HOLD', 'STATUS_CANCEL', 'Quality Control Review', 'Doctor Provide Clarification'];
    const has_permission = userHasPermission('FORM_3420_SKIP', user_roles_and_permissions.permissions);
    return (
      has_permission &&
      invalid_statuses.indexOf(cases.status_code) === -1 &&
      !(this.getCadStatus(this.state.selected_case_id) === 'Stage III' && this.props.ifu_state && this.props.ifu_state.ifu_screen === 'result')
    );
  }

  /**
   * Handles event when user switches between treatment guide tabs
   * @function
   * @param {Object} event - Event object
   */
  onProductionTxGuideTabSelect(event, cases) {
    const tab = event.currentTarget.dataset.tab;
    this.props.onProductionTxGuideTabSelect(tab);
  }

  /**
   * Opens or closes skip Production TX Guide modal
   * @function
   * @param {Boolean} open - Indicator for open or close modal
   */
  toggleSkipProductionTxGuideModal(open) {
    this.setState({
      skip_production_tx_guide_modal: open,
    });
  }

  /**
   * Handles event when user skips or resumes Production TX Guide
   * @function
   */
  onSkipProductionTreatmentGuideConfirm = () => {
    const that = this;
    const data = {
      skip_production_tx_guide: !this.getSkipProductionTxGuide(),
    };
    Axios.put(`/apiv3/case/${that.state.case_details.case_id}/flags/skipproductiontxguide`, data)
      .then(function (res) {
        that.props.setProductionTxGuideTabConfig(
          that.getCaseStatus(),
          that.getCadStatus(that.state.selected_case_id),
          that.getCaseByCaseId(that.state.case_details, that.state.selected_case_id),
          !that.getSkipProductionTxGuide()
        );
        that.toggleSkipProductionTxGuideModal(false);
        that.reloadInformation();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({ skip_production_tx_guide_modal: false });
        }
      });
  };

  /**
   * Retrieves case's skip_production_tx_guide flag
   * @function
   * @return {Boolean} True or false
   */
  getSkipProductionTxGuide = () => {
    for (const cases of this.state.case_details.cases) {
      if (cases.case_id === this.state.selected_case_id) {
        return cases.skip_production_tx_guide;
      }
    }
    return false;
  };

  /**
   * Opens or closes manual upload Production Tx Guide modal
   * @function
   * @param {Boolean} open - Indicator for open or close modal
   */
  toggleUploadProductionTxGuideModal(open) {
    this.setState({
      upload_production_tx_guide_modal: open,
      production_tx_guide_manual_file: open ? [] : this.state.production_tx_guide_manual_file,
    });
  }

  /**
   * Handles event after user manually uploads Production Tx Guide
   * @function
   */
  onProductionTxGuideUpload() {
    const that = this;
    const case_id = this.state.selected_case_id;
    Axios.post(`/apiv3/productiontxguide/${case_id}`).then((res) => {
      if (res && res.data) {
        that.setState({
          production_tx_guide_manual_file: [res.data.file],
        });
        that.reloadInformation();
      }
    });
  }

  /**
   * Opens modal and generates DHR form
   * @function
   */
  openDHRFormModal() {
    this.setState({
      dhr_form_modal: true,
    });
    const that = this;
    Axios.post(`/apiv3/productiontxguide/${this.state.selected_case_id}/generatedhrform`).then((res) => {
      if (res && res.data) {
        that.setState({
          dhr_form_file_path: res.data.file_path,
        });
      }
    });
  }

  /**
   * Closes DHR Form modal
   * @function
   */
  closeDHRFormModal() {
    this.setState({
      dhr_form_modal: false,
      dhr_form_file_path: '',
    });
  }

  /**
   * Displays DHR Form modal
   * @function
   */
  displayDHRFormModal() {
    const patient_info = this.state.case_details.patient;
    return (
      <Modal
        preset="pdf_viewer"
        header_text={'DHR Forms - ' + removeCaseIdInitialNumber(this.state.selected_case_id)}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={this.state.dhr_form_file_path}
        onCloseButtonClick={this.closeDHRFormModal}
        theme="bpp"
        case_id={this.state.selected_case_id}
        pdf_type="production_tx_guide"
        original_filename={getPDFNameTemplate(this.state.selected_case_id, patient_info, 'DHR.pdf')}
      />
    );
  }

  /**
   * Retrieves case status by Case ID
   * @param {String} case_id - Case ID
   * @returns {String} - Case status
   */
  getCaseStatusByCaseId(case_id) {
    for (const cases of this.state.case_details.cases) {
      if (cases.case_id === case_id) {
        return cases.status_code;
      }
    }
    return '';
  }

  isCaseAfterApplianceDesign(case_details, case_id) {
    for (const cases of case_details.cases) {
      if (cases.case_id === case_id) {
        let case_status = cases.status_code;
        const after_appliance_design_statuses = ['STATUS_MANUFACTURE', 'STATUS_SHIP'];
        if (isCaseBlocked(case_status)) {
          case_status = this.getPreviousCaseStatus(cases);
        }
        return after_appliance_design_statuses.indexOf(case_status) !== -1;
      }
    }
    return false;
  }

  /**
   * Retrieves case gen2 idenification
   * @returns {Boolean} - Case status
   */
  getGen2Flag = () => {
    if (this.state.case_details?.cases) {
      for (const cases of this.state.case_details.cases) {
        if (cases.case_id === this.state.selected_case_id) {
          return cases.gen_2;
        }
      }
    }

    return false;
  };

  updateProductionAssigned = (assigned) => this.setState({ production_assigned_to: assigned });

  /**
   * Displays target ship date
   * @function
   * @returns {JSX} JSX for target ship date
   */
  displayIrTargetShipDate = (item_request) => {
    return (
      <div className="target-date pull-right">
        <span>Target Ship Date: </span>
        <span>{item_request.internal_target_ship_date ? convertDate(item_request.internal_target_ship_date) : 'N/A'}</span>
      </div>
    );
  };

  /**
   * Displays target ship date
   * @function
   * @param {object} item_request - Item request object
   * @returns {boolean} True or false
   */
  showIrTargetShipDate = (item_request) => {
    const invalid_statuses = ['Item Request Shipped', 'Item Request On Hold', 'Item Request Cancelled'];
    return item_request.internal_target_ship_date && invalid_statuses.indexOf(item_request.ireq_status) === -1;
  };

  /**
   * Sends email to assigned user for case
   * @function
   * @param {string} slug - Email slug
   * @param {number} note_id - Note id of created note for case action, if any
   */
  sendAssignedUserEmail = (slug, note_id = null) => {
    Axios.post(
      `/api/email/?slug=${slug}&caseId=${removeCaseIdInitialNumber(this.state.selected_case_id)}${
        note_id ? `&note_id=${note_id}` : ''
      }&method=standard&doctorId=${this.state.case_details.doctor.id}&provider=${window.location.origin}`
    );
  };

  /**
   *Hide convert to gen 2.0 modal and reload information
   * @function
   */
  closeModalAndReload = () => {
    this.reloadInformation();
    this.setState({ convert_case_generation_modal: false });
  };

  /**
   * Show and hide convert to gen 2.0 modal
   * @function
   */
  toggleConvertGenModal = () => {
    this.setState({ convert_case_generation_modal: !this.state.convert_case_generation_modal });
  };

  /**
   * Return the message for unable to reverse cancelled case modal
   * @function
   * @return {JSX} - Message element
   */
  getUnableToReverseMsg = () => {
    let path = redirectToCaseDetail(this.state.case_details.converted_case_id, this.props.history.location.pathname, false);
    return (
      <div>
        Unable to reverse cancellation. Revert case
        <a href={path}> {this.state.case_details.converted_case_id} </a>
        to resume this case.
      </div>
    );
  };

  /**
   * Get generation conversion type
   * @function
   * @return {String} - Conversion type
   */
  getGenConversionType = () => {
    if (this.state.case_details.another_gen_case_exists) {
      return 'resume_original_case';
    } else {
      return this.getGen2Flag() ? 'convert_to_gen_1' : 'convert_to_gen_2';
    }
  };

  /**
   * Get latest case status code
   * @function
   * @return {String} - Case Status
   */
  getLatestCaseStatusCode() {
    return this.state.case_details.cases[this.state.case_details.cases.length - 1].status_code;
  }

  /**
   * Return the message for unable to archive case folder msg
   * @function
   * @return {JSX} - Message element
   */
  getUnabletoArchiveMsg = () => {
    const case_status = this.getLatestCaseStatusCode();
    const archive = case_status === 'STATUS_CANCEL';

    return <p>Unable to automatically {archive ? 'archive' : 'unarchive'} patient folder in Dropbox</p>;
  };

  /**
   * Determine if should display case conversion btns
   * @function
   * @param {Object} - Case object
   * @param {Array} - Array of user permissions
   * @return {Boolean} - True if not DE or item request and is active case and has conversion permission.
   */
  shouldDisplayConversionBtns = (cases, permissions) => {
    const not_de = !cases.case_id.includes('-DE') && !cases.case_id.includes('-R');
    const has_conversion_permission = userHasPermission(cases.gen_2 ? 'CONVERT_CASE_TO_GEN_1' : 'CONVERT_CASE_TO_GEN_2', permissions);
    const allowed_to_convert = cases.gen_2 ? true : this.state.case_details.doctor.gen_2;
    const converted_case_existed = this.state.case_details.another_gen_case_exists;
    const active_case =
      cases.status_code !== 'STATUS_CANCEL' &&
      cases.status_code !== 'STATUS_HOLD' &&
      cases.status_code !== 'STATUS_SHIP' &&
      cases.status_code !== 'Doctor Provide Clarification';

    return (
      (not_de && active_case && has_conversion_permission && allowed_to_convert) ||
      (converted_case_existed && not_de && active_case && has_conversion_permission)
    );
  };

  /**
   * Return the element for smile simulation ID
   * @function
   * @param {Boolean} - If user has view smile detail bpp permission
   * @return {JSX} - Message element
   */
  displaySmileSimulationID = (has_view_permission) => {
    const element = (
      <span className="linked_id_div">
        <span className="bold-text">Smile Simulation ID: </span>
        {has_view_permission ? (
          <Link to={{ pathname: `/business/portal/smile/${this.state.smile_id}` }}>{this.state.smile_id}</Link>
        ) : (
          <>{this.state.smile_id}</>
        )}
      </span>
    );

    return this.state.smile_id ? element : null;
  };
  /**
   * Get case descriptions
   * @function
   * @param {Object} - Contains the case data
   */
  getCaseStatusDescription = (cases) => {
    const status = translateCaseStatus(cases);
    const isHold = status === 'Hold';
    const isCancelled = status === 'Cancelled';
    const isHeldStatus = isHold || isCancelled;
    const description = isHold ? cases.hold_description : cases.cancel_description;
    const description_long = description && description.length > 50;
    const displayMessage = isHeldStatus ? `${status} - ${description}` : `${new_branding_words(status)}`;

    return (
      <span className={isHeldStatus && description_long ? 'restrict-text' : ''}>
        <span className="bold-text">Status: </span>
        <span>{displayMessage}</span>
        {cases.false_de ? <span className="case-tag false-de">False DE</span> : null}
        {cases.early_de ? <span className="case-tag early-de">Early DE</span> : null}
        {cases.staged ? <span className="case-tag staged-de">Staged</span> : null}
      </span>
    );
  };

  getSelectedCaseFiles() {
    const case_id = this.state.selected_case_id;
    const selected_case_files = this.props.case_file_list?.[case_id]?.submission_process ?? {};

    return selected_case_files;
  }

  getTxDePlanPDFUrl() {
    const selected_case_files = this.getSelectedCaseFiles();
    const plan = isCaseDE(this.state.selected_case_id) ? 'de_plan_files' : 'treatment_plan_files';
    const txplan_id = selected_case_files[plan]?.length ? selected_case_files[plan].at(-1).id : '';
    const txplan_revision = selected_case_files.treatment_plan_revision;

    const is_rev2 = txplan_revision === 'TX2.0' || txplan_revision === 'TX3.0' || txplan_revision === 'DE2.0' || txplan_revision === 'DE3.0';
    if (is_rev2 && txplan_id) {
      return `/media/pdf/?file_id=${txplan_id}`;
    }
    return '';
  }

  getTxDePlanPDFFiletype() {
    const file_rev = this.getSelectedCaseFiles().treatment_plan_revision;
    if (file_rev === 'TX2.0') {
      return this.getGen2Flag() ? 'F021-18_Treatment_Plan.pdf' : '3421-01_Treatment_Plan.pdf';
    } else if (file_rev === 'TX3.0') {
      return this.getGen2Flag() ? 'F021-18_Treatment_Plan.pdf' : '3421-01_Treatment_Plan.pdf';
    } else if (file_rev === 'DE2.0') {
      return this.getGen2Flag() ? 'F021-10_Digital_Enhancement.pdf' : '3420-03_Digital_Enhancement.pdf';
    } else if (file_rev === 'DE3.0') {
      return this.getGen2Flag() ? 'F021-10_Digital_Enhancement.pdf' : '3420-03_Digital_Enhancement.pdf';
    } else {
      return '';
    }
  }
  /**
   * Get form info for IRs
   * @function
   * @returns {string} - The correct form given the type of generation
   */
  getIRFormType = () => {
    const gen_2 = this.getGen2Flag();
    if (gen_2) {
      return 'F021-21_IR.pdf';
    }

    return '3402-01_IR.pdf';
  };
  getTxDePlanPDFFileName() {
    const case_id = this.state.selected_case_id;
    const patient = this.state.case_details.patient;
    const file_type = this.getTxDePlanPDFFiletype();

    return getPDFNameTemplate(case_id, patient, file_type);
  }

  getProdTxPlanPDFUrl() {
    const case_id = this.state.selected_case_id;
    const cases = this.getCaseByCaseId(this.state.case_details, case_id);
    const prod_tx_guide_id = cases.production_tx_guide_file_id;
    const prod_manual_guide_id = cases.production_tx_guide_manual_file_id;
    const pdf_id = prod_manual_guide_id ? prod_manual_guide_id : prod_tx_guide_id;
    if (pdf_id) {
      return `/media/pdf/?file_id=${pdf_id}`;
    }
    return '';
  }

  /**
   * Retrieves production tx guide filename
   * @function
   * @param {object} cases - Case data
   * @returns {string} - File name
   */
  getProdTxPlanPDFFileName(cases) {
    const case_id = this.state.selected_case_id;
    const patient = this.state.case_details.patient;
    const form = cases.gen_2 ? 'FM-068' : 'F021-15';
    const file_type = `${form}_Production_Treatment_Guide.pdf`;

    return getPDFNameTemplate(case_id, patient, file_type);
  }

  /**
   * Determines if wire selection should be displayed
   * @function
   * @param {object} cases - Contains the case data
   * @param {string} cad_status - Current CAD status
   * @param {string} case_status - Current case status
   * @returns {boolean} - True or false
   */
  shouldDisplayWireSelection = (cases, cad_status, case_status) => {
    if (cases.ws_version === '1.0') {
      const invalid_cad_statuses = ['Stage I', 'Quality Control Stage I'];
      return (
        (this.hasACadStatus(this.state.selected_case_id, 'Stage II') || this.hasACadStatus(this.state.selected_case_id, 'Stage III')) &&
        invalid_cad_statuses.indexOf(cad_status) === -1 &&
        cases.gen_2
      );
    }
    const valid_case_statuses = ['STATUS_MANUFACTURE', 'STATUS_SHIP', 'INBRACE Manufacturing Case', 'Case Shipped'];
    return (
      cases.gen_2 &&
      (valid_case_statuses.indexOf(case_status) !== -1 ||
        (isCaseBlocked(case_status) ? valid_case_statuses.indexOf(this.getPreviousCaseStatus(cases)) !== -1 : false))
    );
  };

  /**
   * Determines if shipping section should be displayed
   * @function
   * @param {object} cases - Contains the case data
   * @returns {boolean} - True or false
   */
  shouldDisplayShipping = (cases) => {
    return (
      cases.manufacturing_process &&
      cases.manufacturing_process.log.length > 0 &&
      this.hasManufacturingStatusInLog(cases.manufacturing_process.log) &&
      !(
        cases.gen_2 &&
        cases.ws_version === 1.0 &&
        this.props.wire_selection_status !== 'WS Complete' &&
        this.props.wire_selection_status !== 'WS Override' &&
        this.props.wire_selection_status !== 'WS Redo' &&
        this.props.wire_selection_status !== ''
      )
    );
  };

  /**
   * Determines if production section should be displayed
   * @function
   * @param {object} cases - Contains the case data
   * @returns {boolean} - True or false
   */
  shouldDisplayProduction = (cases) => {
    const is_ws_complete = this.props.wire_selection_status === 'WS Complete' || this.props.wire_selection_status === 'WS Override';
    return cases.gen_2 && cases.ws_version && cases.ws_version !== '1.0' ? cases.ws_rework_no || is_ws_complete : true;
  };

  /**
   * Retrieves requested treatment for selected case
   * @function
   * @param {object} case_details - Details of cases
   * @param {string} case_id - Selected Case id
   * @returns {object} - Requested treatment
   */
  getSelectedRequestedTreatmentByCaseId(case_details, case_id) {
    const requested_treatment = case_details.cases.find((caseItem) => caseItem.case_id === case_id)?.requested_treatment || '';
    return requested_treatment;
  }

  getProgressRecordsWithoutDrafts = () => {
    return this.state.case_details.progress_record.filter((record) => record.status !== 'Draft Progress Record');
  };

  render() {
    // Get case/IR expedite process logs for Expedite Details modal
    let expedite_type = 'Case';
    let expedite_process = {
      status_code: '',
    };
    let shipping_approved_ind = false;

    if (this.state.selected_tab === 'item_request') {
      expedite_type = 'Item Request';
    }

    const isGen2 = this.getGen2Flag();

    if (this.state.selected_tab === 'case') {
      if (this.state.case_details?.cases?.length > 0) {
        const current_case = this.state.case_details.cases.filter((c) => {
          return c.case_id === this.state.selected_case_id;
        })[0];

        let expedite_process_log = current_case.expedite_process;

        shipping_approved_ind = current_case.shipping_approved_ind;

        if (
          expedite_process_log &&
          expedite_process_log.log &&
          expedite_process_log.log.length > 0 &&
          expedite_process_log.log[expedite_process_log.log.length - 1].current
        ) {
          expedite_process = expedite_process_log.log[expedite_process_log.log.length - 1];
        }
      }
    } else if (this.state.selected_tab === 'item_request') {
      if (this.state.case_details.item_request.length > 0) {
        let expedite_process_log = this.state.case_details.item_request.filter((c) => {
          return c.ireq_id === this.state.selected_request_id;
        });
        shipping_approved_ind = expedite_process_log[0].shipping_approved_ind;

        if (expedite_process_log.length > 0) {
          expedite_process_log = expedite_process_log[0].expedite_process;
        }
        if (
          expedite_process_log &&
          expedite_process_log.log &&
          expedite_process_log.log.length > 0 &&
          expedite_process_log.log[expedite_process_log.log.length - 1].current
        ) {
          expedite_process = expedite_process_log.log[expedite_process_log.log.length - 1];
        }
      }
    }

    const target_dates = this.getTargetDates(expedite_type === 'Case' ? this.state.selected_case_id : this.state.selected_request_id);
    const case_status = this.getCaseStatus();
    const cad_status = this.getCadStatus(this.state.selected_case_id);

    const submission_files = this.getSelectedSubmissionFiles();
    const case_files = this.props.case_file_list?.[this.state.selected_case_id];
    const doctor_name = getDoctorAssignedNameFromCaseDetails(this.state.case_details);
    const patient_name = this.state.case_details.patient.first_name + ' ' + this.state.case_details.patient.last_name;

    const tx_DE_pdf_url = this.getTxDePlanPDFUrl();
    const prod_pdf_url = this.getProdTxPlanPDFUrl();
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ||
              userHasPermission('IREQ_VIEW', user_roles_and_permissions.permissions) ? (
              <div>
                <Helmet>
                  <title>Case Detail {removeCaseIdInitialNumber(this.state.selected_case_id)} | InBrace Business Portal</title>
                </Helmet>
                <div className="main-content">
                  <div className="dark-options dark-options-offset">
                    <div className="page-heading page-return">
                      {userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                        <Link
                          to={{
                            pathname: '/business/portal/',
                            search: this.props.location.state && this.props.location.state.search ? this.props.location.state.search : '',
                          }}
                        >
                          <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to Case List
                        </Link>
                      ) : null}
                    </div>
                    <div className="page-heading">Case Details</div>
                    <div className="page-info">
                      <div className="row">
                        <div className="col-lg-3">
                          <span className="bold-text">Case ID: </span>
                          <div className="copy-text">
                            {userHasPermission('IPP_VIEW', user_roles_and_permissions.permissions) ? (
                              <Link
                                to={{
                                  pathname: `/portal/${getDoctorAssignedIdFromCaseDetails(this.state.case_details)}/case/${this.getLatestCaseIDFromCases()}`,
                                }}
                              >
                                {removeCaseIdInitialNumber(this.state.selected_case_id)}
                              </Link>
                            ) : (
                              removeCaseIdInitialNumber(this.state.selected_case_id)
                            )}
                            <ClipboardCopy copy_text={removeCaseIdInitialNumber(this.state.selected_case_id)} theme="dark"></ClipboardCopy>

                            {this.state.case_details.cases[0].epp ? <span className="case-epp">EPP</span> : null}
                            {this.state.case_details.cases[0].gen_2 ? <span className="case-gen2">Gen 2.0</span> : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <span className="bold-text">Doctor Name: </span>{' '}
                          <div className="copy-text restrict-text">
                            {userHasPermission('ACCOUNT_IPP_VIEW', user_roles_and_permissions.permissions) ? (
                              <Link to={{ pathname: `/business/portal/accounts/edit/${getDoctorAssignedIdFromCaseDetails(this.state.case_details)}` }}>
                                {doctor_name}
                              </Link>
                            ) : (
                              <span>{doctor_name}</span>
                            )}
                            <ClipboardCopy copy_text={doctor_name} theme="dark"></ClipboardCopy>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <span className="bold-text">Patient Name: </span>
                          <div className="copy-text restrict-text">
                            <span className="fs-exclude">{patient_name}</span>
                            <ClipboardCopy copy_text={patient_name} theme="dark"></ClipboardCopy>
                          </div>
                        </div>
                        <div className="col-lg-1">
                          <span className="bold-text">Sex: </span> <div className="copy-text">{convertSex(this.state.case_details.patient.sex)}</div>
                        </div>
                        <div className="col-lg-2">
                          <span className="bold-text">DOB:</span> <div className="copy-text">{convertDate(this.state.case_details.patient.dob)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="page-tab-container" id="arch" data-arch={this.getSelectedCaseArch(this.state.case_details, this.state.selected_case_id)}>
                      <SecurityClearance mode="ALL" permission_list={['CASE_DETAILS_VIEW']}>
                        {this.state.case_details.cases.map((cases, index) => {
                          if (cases.record_type === 'Initial Submission') {
                            return (
                              <div
                                key={index}
                                className={this.isCaseTabActive(cases.case_id) ? 'page-tab page-active' : 'page-tab'}
                                data-case_id={cases.case_id}
                                onClick={this.selectCase}
                              >
                                {cases.is_historical ? cases.case_id : 'Initial'}
                              </div>
                            );
                          } else if (cases.record_type === 'Digital Enhancement' && cases.status_code !== 'STATUS_DRAFT_DE') {
                            return (
                              <div
                                key={index}
                                className={this.isCaseTabActive(cases.case_id) ? 'page-tab page-active' : 'page-tab'}
                                data-case_id={cases.case_id}
                                onClick={this.selectCase}
                              >
                                {cases.is_historical || this.state.selected_is_historical
                                  ? removeCaseIdInitialNumber(cases.case_id)
                                  : cases.case_id.indexOf('-R') >= 0
                                  ? 'R'
                                  : 'DE'}
                                {cases.is_historical || this.state.selected_is_historical ? null : cases.record_type_index > 1 ? cases.record_type_index : ''}
                              </div>
                            );
                          }

                          return null;
                        })}
                      </SecurityClearance>

                      <SecurityClearance mode="ALL" permission_list={['PROGRESS_VIEW']}>
                        {this.state.case_details.progress_record && this.state.case_details.progress_record.length > 0 ? (
                          <div
                            data-tab="progress"
                            className={this.state.selected_tab === 'progress' ? 'page-tab page-active' : 'page-tab'}
                            onClick={this.selectCase}
                          >
                            Progress Records
                          </div>
                        ) : null}
                      </SecurityClearance>

                      <SecurityClearance mode="ALL" permission_list={['IREQ_VIEW']}>
                        {(this.state.case_details.item_request && this.state.case_details.item_request.length > 0) ||
                        this.state.item_request_mode === 'create' ? (
                          <div
                            data-tab="item_request"
                            className={this.state.selected_tab === 'item_request' ? 'page-tab page-active' : 'page-tab'}
                            onClick={this.selectCase}
                          >
                            Item Request
                          </div>
                        ) : null}
                      </SecurityClearance>

                      {this.state.case_details.cases.map((cases, index) => {
                        if (this.isCaseTabActive(cases.case_id)) {
                          return (
                            <div key={index} className="page-tab-content">
                              <div className="row page-top-content-no-border">
                                <div className="col-lg-6">
                                  {this.getCaseStatusDescription(cases)}
                                  {cases.precheck ? <span className="case-precheck">Precheck</span> : null}
                                  {cases.clinical_review && cases.status_code !== 'STATUS_CANCEL' ? (
                                    <span className="case-clinical-review">Clinical Review</span>
                                  ) : null}
                                  {cases.expedite_ind ? <span className="case-expedite-check">Expedited</span> : null}
                                  {expedite_process.status_code === 'Case Expedite Request Submitted' ? (
                                    <span className="case-expedite-check">Expedite Request Pending</span>
                                  ) : null}
                                  {this.displaySmileSimulationID(userHasPermission('SMILE_DETAILS_VIEW', user_roles_and_permissions.permissions))}
                                </div>
                                <div className="col-lg-6">
                                  <span className="bold-text">ERP ID: </span>
                                  <span>{cases.shipping_address.erp_id ? cases.shipping_address.erp_id : 'N/A'}</span>
                                  {cases.internal_target_ship_date && isNotBlockedOrInactive(cases.status_code) && (
                                    <React.Fragment>
                                      <span className="bold-text case-target-ship-date">Target Ship Date: </span>
                                      <span>{convertDate(cases.internal_target_ship_date)}</span>
                                    </React.Fragment>
                                  )}
                                  {userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                    <div className="pull-right">
                                      {userHasPermission('SALESFORCE_VIEW', user_roles_and_permissions.permissions) ? (
                                        <div className="btn-action btn-modal" data-toggle="tooltip" data-placement="top" title="Salesforce">
                                          {this.state.salesforce_url ? (
                                            <a className="btn-modal" href={this.state.salesforce_url} target="_blank" rel="noopener noreferrer">
                                              <FontAwesomeIcon icon={['fab', 'salesforce']} />
                                            </a>
                                          ) : (
                                            <a
                                              className="btn-modal"
                                              href={'https://inbrace.lightning.force.com/lightning/r/Patient_Case__c/case_not_found/view'}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <FontAwesomeIcon icon={['fab', 'salesforce']} />
                                            </a>
                                          )}
                                        </div>
                                      ) : null}

                                      <div
                                        className="btn-action btn-modal"
                                        onClick={this.onNotesModalClick}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Notes"
                                      >
                                        <i className="fa fa-file-text" aria-hidden="true" />
                                        {this.hasNotes() ? <span className="indicator-dot" /> : null}
                                      </div>
                                      <div
                                        className="btn-action btn-modal"
                                        onClick={this.onStatusModalClick}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Status History"
                                      >
                                        <i className="fa fa-clock-o" aria-hidden="true" />
                                      </div>

                                      {this.hasAdditionalActionViewByPermission(user_roles_and_permissions.permissions, cases) &&
                                      cases.status_code !== 'Awaiting Folder Generation Process' &&
                                      cases.status_code !== 'Folder Generation Process' ? (
                                        <div className="btn-action btn-modal dropdown" data-toggle="tooltip" data-placement="top" title="Additional Action">
                                          <i
                                            className="fa fa-list-ul dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                            aria-hidden="true"
                                          />
                                          <ul className="dropdown-menu dropdown-menu-offset" aria-labelledby="dropdownMenu1">
                                            {this.hasAssignTo() ? (
                                              <li>
                                                {/* eslint-disable-next-line */}
                                                <a>Assign To</a>
                                              </li>
                                            ) : null}

                                            {this.hasAssignTo() ? (
                                              <li>
                                                {/* eslint-disable-next-line */}
                                                <a>Unassign</a>
                                              </li>
                                            ) : null}

                                            {cases.status_code === 'STATUS_CANCEL' &&
                                            this.isLatestCancelCase(cases) &&
                                            userHasPermission('CASE_CANCEL_UNDO', user_roles_and_permissions.permissions) ? (
                                              <li onClick={this.onReverseCancellationModalClick}>
                                                {/* eslint-disable-next-line */}
                                                <a>Reverse Cancellation</a>
                                              </li>
                                            ) : null}
                                            {cases.status_code !== 'STATUS_SHIP' &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            cases.status_code !== 'Awaiting Folder Generation Process' &&
                                            cases.status_code !== 'Folder Generation Process' &&
                                            userHasPermission(
                                              isAfterSmileDesignApproval(cases.status_code)
                                                ? 'CASE_CANCEL_POST_SMILE_DESIGN_APPROVAL'
                                                : 'CASE_CANCEL_PRE_SMILE_DESIGN_APPROVAL',
                                              user_roles_and_permissions.permissions
                                            ) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onCancelCaseModalClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Cancel</a>
                                              </li>
                                            ) : null}

                                            {cases.status_code === 'STATUS_HOLD' &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            userHasPermission('CASE_HOLD_UNDO', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onReverseHoldModalClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Unhold</a>
                                              </li>
                                            ) : null}

                                            {cases.status_code !== 'STATUS_SHIP' &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            cases.status_code !== 'STATUS_HOLD' &&
                                            cases.status_code !== 'Awaiting Folder Generation Process' &&
                                            cases.status_code !== 'Folder Generation Process' &&
                                            cases.status_code !== 'Doctor Provide Clarification' &&
                                            userHasPermission('CASE_HOLD', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onHoldModalClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Hold</a>
                                              </li>
                                            ) : null}

                                            {cases.status_code !== 'STATUS_SHIP' &&
                                            cases.status_code !== 'Awaiting Folder Generation Process' &&
                                            cases.status_code !== 'Folder Generation Process' &&
                                            !cases.case_id.includes('-') &&
                                            !cases.shipping_approved_ind &&
                                            userHasPermission('PATIENT_EDIT', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onEditPatientDetailsClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Edit Patient Details</a>
                                              </li>
                                            ) : null}

                                            {this.canEditTXPlan(cases, user_roles_and_permissions.permissions) && (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onEditTreatmentPlanClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>{this.state.selected_case_id.includes('-DE') ? 'Edit DE Form' : 'Edit Treatment Plan'}</a>
                                              </li>
                                            )}

                                            {cases.status_code !== 'STATUS_SHIP' &&
                                            cases.status_code !== 'STATUS_HOLD' &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            cases.status_code !== 'Doctor Provide Clarification' &&
                                            userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                              <li
                                                onClick={() => this.onExpediteDetailsClick(cases, target_dates)}
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Expedite Details</a>
                                              </li>
                                            ) : null}
                                            {(cases.status_code === 'STATUS_MANUFACTURE' || cases.status_code === 'STATUS_SHIP') &&
                                              userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) && (
                                                <li onClick={this.openDHRFormModal}>
                                                  {/* eslint-disable-next-line */}
                                                  <a>View DHR Form</a>
                                                </li>
                                              )}
                                            {cases.precheck &&
                                            !cases.case_id.includes('-DE') &&
                                            !cases.case_id.includes('-R') &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            cases.status_code !== 'STATUS_HOLD' &&
                                            cases.status_code !== 'STATUS_SHIP' &&
                                            cases.status_code !== 'CAD Process' &&
                                            cases.status_code !== 'Quality Control Review' &&
                                            cases.status_code !== 'STATUS_MANUFACTURE' &&
                                            cases.status_code !== 'INBRACE Manufacturing Case' &&
                                            cases.status_code !== 'Doctor Provide Clarification' &&
                                            userHasPermission('CASE_PRECHECK_UNFLAG', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onUnflagPrecheckClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Remove Precheck Label</a>
                                              </li>
                                            ) : null}
                                            {!cases.precheck &&
                                            !cases.case_id.includes('-DE') &&
                                            !cases.case_id.includes('-R') &&
                                            cases.status_code !== 'STATUS_CANCEL' &&
                                            cases.status_code !== 'STATUS_HOLD' &&
                                            cases.status_code !== 'STATUS_SHIP' &&
                                            !cases.approval_date &&
                                            cases.status_code !== 'Doctor Provide Clarification' &&
                                            userHasPermission('CASE_PRECHECK_FLAG', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onFlagPrecheckClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Mark as Precheck</a>
                                              </li>
                                            ) : null}
                                            {this.canFlagClinicalReview(cases, user_roles_and_permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.openClinicalReviewModal}
                                              >
                                                {/* eslint-disable-next-line */}
                                                {cases.clinical_review ? <a>Complete Clinical Review</a> : <a>Initiate Clinical Review</a>}
                                              </li>
                                            ) : null}
                                            {showFalseDeAction(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.setState({ showFalseDeModal: true })}
                                              >
                                                <a>Mark as False DE</a>
                                              </li>
                                            ) : null}
                                            {showEarlyDeAction(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.setState({ showEarlyDeModal: true })}
                                              >
                                                <a>Mark as Early DE</a>
                                              </li>
                                            ) : null}
                                            {showStagedAction(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.openStagedModal}
                                              >
                                                <a>Mark as Staged</a>
                                              </li>
                                            ) : null}
                                            {showUnflagEarlyDe(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.setState({ showRemoveEarlyDeModal: true })}
                                              >
                                                <a>Remove Early DE Flag</a>
                                              </li>
                                            ) : null}
                                            {showUnflagStaged(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.setState({ showRemoveStagedModal: true })}
                                              >
                                                <a>Remove Staged Flag</a>
                                              </li>
                                            ) : null}
                                            {showUnflagFalseDe(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.setState({ showRemoveFalseDeModal: true })}
                                              >
                                                <a>Remove False DE Flag</a>
                                              </li>
                                            ) : null}
                                            {this.canRequestDoctorClarification(cases, user_roles_and_permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.openDoctorClarificationModal}
                                              >
                                                {/* eslint-disable-next-line */}
                                                {cases.status_code === 'Doctor Provide Clarification' ? (
                                                  // eslint-disable-next-line
                                                  <a>Resolve Clarification Request</a>
                                                ) : (
                                                  // eslint-disable-next-line
                                                  <a>Request Doctor Clarification</a>
                                                )}
                                              </li>
                                            ) : null}
                                            {this.canSkipProductionTxGuide(cases, user_roles_and_permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress || this.props.ifu_upload_pending ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.toggleSkipProductionTxGuideModal(true)}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>{cases.skip_production_tx_guide ? 'Resume' : 'Skip'} Production Tx Guide</a>
                                              </li>
                                            ) : null}
                                            {cases.skip_production_tx_guide &&
                                            cases.status_code === 'STATUS_MANUFACTURE' &&
                                            !cases.shipping_approved_ind &&
                                            userHasPermission('FORM_3420_SKIP', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : () => this.toggleUploadProductionTxGuideModal(true)}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>{cases.production_tx_guide_manual_file_path ? 'Re-upload' : 'Upload'} Production Tx Guide</a>
                                              </li>
                                            ) : null}
                                            {cases.status_code === 'STATUS_SHIP' && userHasPermission('IREQ_SUBMIT', user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.onSubmitIrCaseClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>Submit Item Request</a>
                                              </li>
                                            ) : null}

                                            {!cases.case_id.includes('-DE') &&
                                            !cases.case_id.includes('-IR') &&
                                            !(this.getCaseStatus() === 'STATUS_CANCEL' || cases.approval_date) &&
                                            !cases.is_historical ? (
                                              !cases.gen_2 ? (
                                                userHasPermission('CASE_GEN2_FLAG', user_roles_and_permissions.permissions) ? (
                                                  <li
                                                    name="gen2"
                                                    className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                    onClick={this.state.upload_in_progress ? null : this.onFlagGenClick}
                                                  >
                                                    {/* eslint-disable-next-line */}
                                                    <a>Mark as Gen 2.0</a>
                                                  </li>
                                                ) : null
                                              ) : userHasPermission('CASE_GEN2_UNFLAG', user_roles_and_permissions.permissions) ? (
                                                <li
                                                  name="gen1"
                                                  className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                  onClick={this.state.upload_in_progress ? null : this.onFlagGenClick}
                                                >
                                                  {/* eslint-disable-next-line */}
                                                  <a>Mark as Gen 1.0</a>
                                                </li>
                                              ) : null
                                            ) : null}

                                            {userHasPermission('CASE_REVERSAL', user_roles_and_permissions.permissions) ? (
                                              <>
                                                <li
                                                onClick={this.state.selected_case_id.includes('-DE') ? this.regenDEPDF : this.regenTXPDF}
                                                >
                                                  {/* eslint-disable-next-line */}
                                                  <a>Regenerate Tx2.0 Plan</a>
                                                </li>
                                                <li onClick={() => this.regenProductionTxGuide(cases)}>
                                                  {/* eslint-disable-next-line */}
                                                  <a>Regenerate Production Tx Guide</a>
                                                </li>
                                              </>
                                            ) : null}
                                            {this.shouldDisplayConversionBtns(cases, user_roles_and_permissions.permissions) ? (
                                              <li
                                                className={this.state.upload_in_progress ? 'disabled-action' : null}
                                                onClick={this.state.upload_in_progress ? null : this.toggleConvertGenModal}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a>{cases.gen_2 ? 'Convert to Gen 1' : 'Convert to Gen 2.0'}</a>
                                              </li>
                                            ) : null}
                                            {this.hasTargetOverrideOption(cases) &&
                                              userHasPermission('UPDATE_EXTERNAL_TARGET_DATE', user_roles_and_permissions.permissions) && (
                                                <li onClick={this.onTargetDateOverrideModalClick}>
                                                  {/* eslint-disable-next-line */}
                                                  <a>Update External Est Ship Date</a>
                                                </li>
                                              )}
                                          </ul>
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 business-dark-lg-6-l">
                                  {userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                    <div>
                                      <div className="bold-text business-dark-heading">
                                        Patient Records
                                        <div
                                          className="pull-right btn-light-link"
                                          id="patient_files"
                                          data-case_id={cases.case_id}
                                          onClick={this.onFileCommentsClick}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Records Log"
                                        >
                                          <i className="fa fa-file-text-o" aria-hidden="true" />
                                        </div>
                                        <SecurityClearance mode="ALL" permission_list={['PATIENT_RECORDS_DOWNLOAD']}>
                                          {this.getFilesZipButton(cases)}
                                        </SecurityClearance>
                                        {this.getFilesUndoButton(cases)}
                                      </div>
                                      <div className="business-dark-theme">
                                        <div className="business-dark-theme-content case-detail-patient-records">
                                          {(!this.props.case_file_list_loading || submission_files.length > 0) && (
                                            <ThumbnailURLInterface
                                              files={submission_files}
                                              images={configLightBox(submission_files)}
                                              theme="dark"
                                              latest_status_date={this.getStatusDateForVerification(cases)}
                                              reload_thumbnail={this.state.reload_thumbnail}
                                              onThumbnailReLoad={this.onThumbnailReLoad}
                                            />
                                          )}
                                        </div>
                                        {this.props.segmentation_state &&
                                        this.props.segmentation_state.caseautomationstatus &&
                                        this.props.segmentation_state.caseautomationstatus.status === 'Repaired Scans Upload Success' &&
                                        this.props.segmentation_state.file.length > 0 ? (
                                          <div className="business-dark-theme-content process_tracker">
                                            <hr></hr>
                                            <div className="bold-text">Repaired Scans </div>
                                            <ThumbnailURLInterface
                                              files={this.props.segmentation_state.file}
                                              images={configLightBox(this.props.segmentation_state.file)}
                                              theme="dark"
                                              multinail="repaired_scans_success"
                                              reload_thumbnail={this.state.reload_thumbnail}
                                              onThumbnailReLoad={this.onThumbnailReLoad}
                                            />
                                          </div>
                                        ) : null}
                                        <ProcessTracker
                                          user_roles_and_permissions={user_roles_and_permissions}
                                          process="patient_records"
                                          last_stage={getCaseLastStatus(true, this.state.case_details, this.state.selected_case_id)}
                                          stage={this.getCaseStatus()}
                                          case_id={this.state.selected_case_id}
                                          project={this}
                                          cases={cases}
                                          case_files={this.getCaseFiles(this.state.selected_case_id)}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  <SetupContainer
                                    cases={cases}
                                    case_details={this.state.case_details}
                                    case_id={this.state.selected_case_id}
                                    case_files={this.getCaseFiles(this.state.selected_case_id)}
                                    get_case_status={this.getCaseStatus()}
                                    user_roles_and_permissions={user_roles_and_permissions}
                                    reload_information={this.reloadInformation}
                                    selected_tab={this.state.selected_tab}
                                    isUploading={this.isUploading}
                                    last_stage={getCaseLastStatus(true, this.state.case_details, this.state.selected_case_id)}
                                    cad_status={cad_status}
                                    upload_in_progress={this.state.upload_in_progress}
                                    patient_info={this.state.case_details.patient}
                                  />

                                  <CADContainer
                                    cases={cases}
                                    case_files={case_files}
                                    case_details={this.state.case_details}
                                    case_id={this.state.selected_case_id}
                                    get_case_status={this.getCaseStatus()}
                                    user_roles_and_permissions={user_roles_and_permissions}
                                    reload_information={this.reloadInformation}
                                    txplan_arch={this.getSelectedCaseArch(this.state.case_details, this.state.selected_case_id)}
                                    setConversion={this.setConversion}
                                    ip_conversion={this.state.ip_conversion}
                                    ref_no={cases.cad_wo}
                                    update_wo={this.updateCadWo}
                                    selected_tab={this.state.selected_tab}
                                    regenerate_production_tx_guide={this.regenProductionTxGuide}
                                    wire_selection_status={this.props.wire_selection_status}
                                    patient_info={this.state.case_details.patient}
                                    requested_treatment={cases.requested_treatment}
                                  />
                                  {this.shouldDisplayWireSelection(cases, cad_status, case_status) && (
                                    <TemplateContainer
                                      case_id={this.state.selected_case_id}
                                      doctor_id={this.state.case_details.doctor.id}
                                      patient_info={this.state.case_details.patient}
                                      case_status={this.getCaseStatus(false)}
                                      case_details={cases}
                                      arch={this.getProdTxPlanArchInfo(this.state.case_details, this.state.selected_case_id)}
                                      reload_information={this.reloadInformation}
                                      regenProductionTxGuide={this.regenProductionTxGuide}
                                    />
                                  )}
                                  {cases.gen_2 && cases.ws_version && cases.ws_version !== '1.0' && (
                                    <WireManufacturingContainer
                                      case_id={this.state.selected_case_id}
                                      case_details={cases}
                                      reloadInformation={this.reloadInformation}
                                      case_status={this.getCaseStatus(false)}
                                    />
                                  )}
                                  {this.shouldDisplayProduction(cases) && (
                                    <ProductionContainer
                                      cases={cases}
                                      case_details={this.state.case_details}
                                      case_id={this.state.selected_case_id}
                                      get_case_status={this.getCaseStatus()}
                                      user_roles_and_permissions={user_roles_and_permissions}
                                      reload_information={this.reloadInformation}
                                      txplan_arch={this.getSelectedCaseArch(this.state.case_details, this.state.selected_case_id)}
                                      setConversion={this.setConversion}
                                      ip_conversion={this.state.ip_conversion}
                                      isUploading={this.isUploading}
                                      ref_no={cases.production_wo}
                                      update_wo={this.updateProductionWo}
                                      last_stage={getCaseLastStatus(true, this.state.case_details, this.state.selected_case_id)}
                                      files={case_files}
                                      getLabelFilePath={this.getLabelFilePath}
                                      wire_selection_status={this.props.wire_selection_status}
                                      production_assigned_to={this.state.production_assigned_to}
                                      updateProductionAssigned={this.updateProductionAssigned}
                                      process_in_progress={this.setProcessInProgress}
                                      process_loading={this.state.process_loading}
                                      loading={this.props.case_file_list_loading && !case_files}
                                      patient_info={this.state.case_details.patient}
                                    />
                                  )}
                                  {this.shouldDisplayShipping(cases) ? (
                                    <ShippingContainer
                                      cases={cases}
                                      case_details={this.state.case_details}
                                      case_files={this.props.case_file_list?.[this.state.selected_case_id]}
                                      reload_information={this.reloadInformation}
                                      user_roles_and_permissions={user_roles_and_permissions}
                                      selected_case_id={this.state.selected_case_id}
                                      getCaseStatus={this.getCaseStatus()}
                                      getSelectedManufacturingCaseStatusDateByCaseId={this.getSelectedManufacturingCaseStatusDateByCaseId}
                                      getSelectedApprovedSetupCaseStatusDateByCaseId={this.getSelectedApprovedSetupCaseStatusDateByCaseId}
                                      onSelectedAddress={this.onSelectedAddress}
                                      edit_address={this.state.edit_address}
                                      onAddressConfirm={this.onAddressConfirm}
                                      onAddressDiscard={this.onAddressDiscard}
                                      selected_tx={this.state.selected_tx}
                                      selected_vbpc={this.state.selected_vbpc}
                                      ifu_state={this.props.ifu_state}
                                      onUpload={this.onUpload}
                                      onRemove={this.onRemove}
                                      selected_address_id={this.getSelectedAddressByCaseId(this.state.case_details, this.state.selected_case_id)}
                                      gen_2={isGen2}
                                      wire_selection_status={this.props.wire_selection_status}
                                      process_loading={this.state.process_loading}
                                      patient_info={this.state.case_details.patient}
                                    />
                                  ) : null}
                                </div>

                                <div className="col-lg-6 business-dark-lg-6-r business-dark-r-padding-top">
                                  <span
                                    className={`bold-text business-dark-heading treatment-tab${
                                      this.props.selected_production_tx_guide_tab === 'treatment_plan' ? ' treatment-tab__active' : ''
                                    }`}
                                    onClick={(e) => this.onProductionTxGuideTabSelect(e, cases)}
                                    data-tab="treatment_plan"
                                  >
                                    {this.state.selected_case_id.includes('-DE') ? 'DE Form' : 'Treatment Plan'}
                                  </span>
                                  {this.props.allowed_production_tx_guide_tabs.indexOf('segmentation') !== -1 && (
                                    <span
                                      className={`bold-text business-dark-heading treatment-tab${
                                        this.props.selected_production_tx_guide_tab === 'segmentation' ? ' treatment-tab__active' : ''
                                      }`}
                                      onClick={(e) => this.onProductionTxGuideTabSelect(e, cases)}
                                      data-tab="segmentation"
                                    >
                                      Segmentation
                                    </span>
                                  )}
                                  {this.props.allowed_production_tx_guide_tabs.indexOf('smile_design') !== -1 && (
                                    <span
                                      className={`bold-text business-dark-heading treatment-tab${
                                        this.props.selected_production_tx_guide_tab === 'smile_design' ? ' treatment-tab__active' : ''
                                      }`}
                                      onClick={(e) => this.onProductionTxGuideTabSelect(e, cases)}
                                      data-tab="smile_design"
                                    >
                                      Smile Design
                                    </span>
                                  )}
                                  {this.props.allowed_production_tx_guide_tabs.indexOf('stage_1') !== -1 && (
                                    <span
                                      className={`bold-text business-dark-heading treatment-tab${
                                        this.props.selected_production_tx_guide_tab === 'stage_1' ? ' treatment-tab__active' : ''
                                      }`}
                                      onClick={(e) => this.onProductionTxGuideTabSelect(e, cases)}
                                      data-tab="stage_1"
                                    >
                                      Appliance Design
                                    </span>
                                  )}
                                  {(cases.production_tx_guide_manual_file_path ||
                                    (!cases.skip_production_tx_guide && this.props.allowed_production_tx_guide_tabs.indexOf('production_tx_guide') !== -1)) && (
                                    <span
                                      className={`bold-text business-dark-heading treatment-tab${
                                        this.props.selected_production_tx_guide_tab === 'production_tx_guide' ? ' treatment-tab__active' : ''
                                      }`}
                                      onClick={(e) => this.onProductionTxGuideTabSelect(e, cases)}
                                      data-tab="production_tx_guide"
                                    >
                                      Production Treatment Guide
                                    </span>
                                  )}
                                  {this.props.selected_production_tx_guide_tab === 'treatment_plan' ? (
                                    <React.Fragment>
                                      <div key="tx_plan_window" className="plan-window" id="tx_plan_window">
                                        {cases.case_id.indexOf('-') >= 0 &&
                                        cases.submission_process.treatment_plan_revision !== 'DE2.0' &&
                                        cases.submission_process.treatment_plan_revision !== 'DE3.0' ? (
                                          <DePlan
                                            caseData={cases.submission_process.treatment_plan}
                                            arch={cases.submission_process.treatment_arch}
                                            caseId={cases.case_id}
                                            selectedPatient={{
                                              first_name: this.state.case_details.patient.first_name,
                                              last_name: this.state.case_details.patient.last_name,
                                              sex: this.state.case_details.patient.sex,
                                              dob: this.state.case_details.patient.dob,
                                            }}
                                            doctorfirstName={this.state.case_details.doctor.first_name}
                                            doctorlastName={this.state.case_details.doctor.last_name}
                                            bpp={true}
                                            is_historical={cases.is_historical}
                                            treatment_plan_revision={cases.submission_process.treatment_plan_revision}
                                          />
                                        ) : cases.submission_process.treatment_plan_revision === 'TX2.0' ||
                                          cases.submission_process.treatment_plan_revision === 'TX3.0' ||
                                          cases.submission_process.treatment_plan_revision === 'DE2.0' ||
                                          cases.submission_process.treatment_plan_revision === 'DE3.0' ? (
                                          tx_DE_pdf_url && <PDFViewerInterface fileUrl={tx_DE_pdf_url} filename={this.getTxDePlanPDFFileName()} />
                                        ) : (
                                          <InitialPlan
                                            caseData={cases.submission_process.treatment_plan}
                                            arch={cases.submission_process.treatment_arch}
                                            caseId={cases.case_id}
                                            selectedPatient={{
                                              first_name: this.state.case_details.patient.first_name,
                                              last_name: this.state.case_details.patient.last_name,
                                              sex: this.state.case_details.patient.sex,
                                              dob: this.state.case_details.patient.dob,
                                            }}
                                            doctorfirstName={this.state.case_details.doctor.first_name}
                                            doctorlastName={this.state.case_details.doctor.last_name}
                                            bpp={true}
                                            is_historical={cases.is_historical}
                                            treatment_plan_revision={cases.submission_process.treatment_plan_revision}
                                          />
                                        )}
                                      </div>
                                      <div id="my_MM" style={{ height: '1mm' }} />
                                    </React.Fragment>
                                  ) : this.props.selected_production_tx_guide_tab === 'production_tx_guide' ? (
                                    <React.Fragment>
                                      <div key="production_tx_guide_window" className="plan-window" id="production_tx_guide_window">
                                        {prod_pdf_url && <PDFViewerInterface fileUrl={prod_pdf_url} filename={this.getProdTxPlanPDFFileName(cases)} />}
                                      </div>
                                      <div id="my_MM" style={{ height: '1mm' }} />
                                    </React.Fragment>
                                  ) : (
                                    <div className="business-dark-theme treatment-guide">
                                      <ProductionTxGuide
                                        className="treatment-tab-container"
                                        case_id={this.state.selected_case_id}
                                        is_tab_view={true}
                                        stage={this.props.selected_production_tx_guide_tab}
                                        requested_treatment={cases.requested_treatment}
                                        requested_treatment_arch={
                                          !this.state.selected_case_id.includes('-DE') || cases.submission_process.treatment_plan_revision === 'DE3.0'
                                            ? cases.submission_process.treatment_arch.toLowerCase()
                                            : 'both'
                                        }
                                        case_status={this.getCaseStatus()}
                                        smile_design_logs={case_files?.setup_process ?? []}
                                        user_roles_and_permissions={user_roles_and_permissions}
                                        tx_plan={cases.submission_process.treatment_plan}
                                        gen_2={cases.gen_2}
                                        cad_status={cad_status}
                                        patient_info={this.state.case_details.patient}
                                        approval_date={cases.approval_date}
                                        submission_process={cases.submission_process}
                                        reloadInformation={this.reloadInformation}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return null;
                      })}

                      <SecurityClearance mode="ALL" permission_list={['IREQ_VIEW']}>
                        {this.state.case_details.item_request
                          .slice(0)
                          .reverse()
                          .map((item_request, index) => {
                            if (this.state.item_request_mode === 'view' && this.state.selected_tab === 'item_request') {
                              if (this.state.selected_tab === 'item_request' && this.state.selected_request_id === item_request.ireq_id) {
                                return (
                                  <div key={index} className="page-tab-content">
                                    <div className="row page-top-content">
                                      <div className="col-lg-6">
                                        <div className="item-heading">
                                          <div className="item-requestnumber">
                                            <span className="bold-text">No.</span>
                                          </div>
                                          <div className="item-requestid">
                                            <span className="bold-text">Item Request ID</span>
                                          </div>
                                          <div className="item-status">
                                            <span className="bold-text">Status</span>
                                          </div>
                                          <div className="item-submissiondate">
                                            <span className="bold-text">Submission Date</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <span className="bold-text">ERP ID: </span>
                                        <span>{item_request.erp_id ? item_request.erp_id : 'N/A'}</span>
                                        {this.showIrTargetShipDate(item_request) ? (
                                          <React.Fragment>
                                            <span className="bold-text case-target-ship-date">Target Ship Date: </span>
                                            <span>{convertDate(item_request.internal_target_ship_date)}</span>
                                          </React.Fragment>
                                        ) : null}{' '}
                                        <div className="pull-right">
                                          <UserPermissionsContext.Consumer>
                                            {(user_roles_and_permissions) => {
                                              return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                                <React.Fragment>
                                                  <div
                                                    className="btn-action btn-modal"
                                                    onClick={this.onIrNotesModalClick}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Notes"
                                                  >
                                                    <i className="fa fa-file-text" aria-hidden="true" />
                                                    {this.state.case_details.item_request.filter((c) => {
                                                      return c.ireq_id === this.state.selected_request_id;
                                                    })[0].notes_indicator ? (
                                                      <span className="indicator-dot" />
                                                    ) : null}
                                                  </div>
                                                  <div
                                                    className="btn-action btn-modal"
                                                    onClick={this.onStatusModalClick}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Status History"
                                                  >
                                                    <i className="fa fa-clock-o" aria-hidden="true" />
                                                  </div>
                                                </React.Fragment>
                                              ) : null;
                                            }}
                                          </UserPermissionsContext.Consumer>

                                          <div className="btn-action btn-modal dropdown" data-toggle="tooltip" data-placement="top" title="Additional Action">
                                            <i
                                              className="fa fa-list-ul dropdown-toggle"
                                              type="button"
                                              id="dropdownMenu1"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="true"
                                              aria-hidden="true"
                                            />
                                            <ul className="dropdown-menu dropdown-menu-offset" aria-labelledby="dropdownMenu1">
                                              {this.isApprovedIr(item_request) && !item_request.historical && !this.state.ir_edit_in_progress ? (
                                                <li data-id={item_request.ireq_id} onClick={this.onDownloadInvoice}>
                                                  {/* eslint-disable-next-line */}
                                                  <a>View Form</a>
                                                </li>
                                              ) : (
                                                <li className="disabled">
                                                  {/* eslint-disable-next-line */}
                                                  <a>View Form</a>
                                                </li>
                                              )}
                                              {item_request.ireq_status === 'Item Request Cancelled' && !this.state.ir_edit_in_progress ? (
                                                this.isLatestCancelIr(item_request) ? (
                                                  <SecurityClearance mode="ALL" permission_list={['IREQ_CANCEL_UNDO']}>
                                                    <li onClick={this.onReverseCancellationModalClick}>
                                                      {/* eslint-disable-next-line */}
                                                      <a>Reverse Cancellation</a>
                                                    </li>
                                                  </SecurityClearance>
                                                ) : (
                                                  <SecurityClearance mode="ALL" permission_list={['IREQ_CANCEL']}>
                                                    <li className="disabled">
                                                      {/* eslint-disable-next-line */}
                                                      <a>Cancel</a>
                                                    </li>
                                                  </SecurityClearance>
                                                )
                                              ) : item_request.ireq_status !== 'Item Request Shipped' &&
                                                item_request.ireq_status !== 'Item Request On Hold' &&
                                                !this.state.ir_edit_in_progress ? (
                                                <SecurityClearance mode="ALL" permission_list={['IREQ_CANCEL']}>
                                                  <li onClick={this.onCancelCaseModalClick}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Cancel</a>
                                                  </li>
                                                </SecurityClearance>
                                              ) : (
                                                <SecurityClearance mode="ALL" permission_list={['IREQ_CANCEL']}>
                                                  {item_request.ireq_status === 'Item Request Shipped' ? null : (
                                                    <li className="disabled">
                                                      {/* eslint-disable-next-line */}
                                                      <a>Cancel</a>
                                                    </li>
                                                  )}
                                                </SecurityClearance>
                                              )}

                                              {item_request.ireq_status === 'Item Request On Hold' &&
                                              item_request.ireq_status !== 'Item Request Cancelled' &&
                                              !this.state.ir_edit_in_progress ? (
                                                <SecurityClearance mode="ALL" permission_list={['IREQ_HOLD_UNDO']}>
                                                  <li onClick={this.onReverseHoldModalClick}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Unhold</a>
                                                  </li>
                                                </SecurityClearance>
                                              ) : item_request.ireq_status !== 'Item Request Shipped' &&
                                                item_request.ireq_status !== 'Item Request Cancelled' &&
                                                !this.state.ir_edit_in_progress ? (
                                                <SecurityClearance mode="ALL" permission_list={['IREQ_HOLD']}>
                                                  <li onClick={this.onHoldModalClick}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Hold</a>
                                                  </li>
                                                </SecurityClearance>
                                              ) : (
                                                <SecurityClearance mode="ALL" permission_list={['IREQ_HOLD']}>
                                                  {item_request.ireq_status === 'Item Request Shipped' ? null : (
                                                    <li className="disabled">
                                                      {/* eslint-disable-next-line */}
                                                      <a>Hold</a>
                                                    </li>
                                                  )}
                                                </SecurityClearance>
                                              )}

                                              <SecurityClearance mode="ALL" permission_list={['IREQ_SUBMIT']}>
                                                {!this.state.ir_edit_in_progress ? (
                                                  <li onClick={this.onSubmitIrClick}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Submit Item Request</a>
                                                  </li>
                                                ) : (
                                                  <li className="disabled">
                                                    {/* eslint-disable-next-line */}
                                                    <a>Submit Item Request</a>
                                                  </li>
                                                )}
                                              </SecurityClearance>
                                              {this.isNonBlockingIRStatus(item_request) ? (
                                                <SecurityClearance mode="ALL" permission_list={[]}>
                                                  <li onClick={() => this.onExpediteDetailsClick(item_request, target_dates)}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Expedite Details</a>
                                                  </li>
                                                </SecurityClearance>
                                              ) : null}
                                              {!isCaseBlocked(this.state.case_details.cases) &&
                                                userHasPermission('UPDATE_ITEM_REQUEST_TARGET_DATE', user_roles_and_permissions.permissions) &&
                                                this.isNonBlockingIRStatus(item_request) &&
                                                !this.isExpediated(item_request) && (
                                                  <li onClick={this.onTargetDateOverrideModalClick}>
                                                    {/* eslint-disable-next-line */}
                                                    <a>Update External Est Ship Date</a>
                                                  </li>
                                                )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-6 business-dark-lg-6-l">
                                        <div className="item-table">
                                          <div className="item-content">
                                            {this.state.case_details.item_request
                                              .slice(0)
                                              .reverse()
                                              .map((item_request, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className={this.state.selected_request_id === item_request.ireq_id ? 'selected-item-row' : 'item-row'}
                                                    data-request_id={item_request.ireq_id}
                                                    onClick={this.selectItemRequest}
                                                  >
                                                    <div className="item-requestnumber" data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                      <span data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                        {this.state.case_details.item_request.length - index}
                                                      </span>
                                                    </div>
                                                    <div className="item-requestid" data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                      <span data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                        {removeIreqIdInitialNumber(item_request.ireq_id)}
                                                      </span>
                                                    </div>
                                                    <div className="item-status" data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                      <span data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                        {new_branding_words(item_request.ireq_status)}
                                                      </span>
                                                      {item_request.expedite_ind ? (
                                                        <span
                                                          className="case-expedite-check ir"
                                                          data-request_id={item_request.ireq_id}
                                                          onClick={this.selectItemRequest}
                                                        >
                                                          Expedited
                                                        </span>
                                                      ) : null}
                                                      {item_request.expedite_process &&
                                                      item_request.expedite_process.status === 'Item Request Expedite Request Submitted' ? (
                                                        <span
                                                          className="case-expedite-check ir"
                                                          data-request_id={item_request.ireq_id}
                                                          onClick={this.selectItemRequest}
                                                        >
                                                          Expedite Pending
                                                        </span>
                                                      ) : null}
                                                    </div>
                                                    <div className="item-submissiondate" data-request_id={item_request.ireq_id}>
                                                      <span data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                        {convertDate(item_request.created_date)}
                                                      </span>
                                                      <span className="right-arrow" data-request_id={item_request.ireq_id} onClick={this.selectItemRequest}>
                                                        {this.state.selected_request_id === item_request.ireq_id ? (
                                                          <i
                                                            className="fa fa-caret-right"
                                                            aria-hidden="true"
                                                            data-request_id={item_request.ireq_id}
                                                            onClick={this.selectItemRequest}
                                                          />
                                                        ) : null}
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-6 business-dark-lg-6-r">
                                        <div>
                                          <div className="business-dark-heading">
                                            <span className="bold-text">Item Request {this.state.case_details.item_request.length - index}:</span>{' '}
                                            {userHasPermission('IPP_VIEW', user_roles_and_permissions.permissions) ? (
                                              <span className="ir-link" data-id={item_request.ireq_id} onClick={this.onIrLinkClick}>
                                                {removeIreqIdInitialNumber(item_request.ireq_id)}
                                              </span>
                                            ) : (
                                              <span>{removeIreqIdInitialNumber(item_request.ireq_id)}</span>
                                            )}
                                            <span className="selected-item-status">Status:</span> {new_branding_words(item_request.ireq_status)}
                                          </div>

                                          {this.displayIRSubmissionProcess(item_request, isGen2)}
                                          {this.displayIRSingleToothIdb(item_request)}
                                          {this.displayIRWireSelectionSection(item_request, isGen2)}
                                          {this.displayIrCadProcess(item_request)}
                                          {this.displayIrProductionProcess(item_request, isGen2)}

                                          {item_request.ireq_status === 'INBRACE Manufacturing Item Request' ||
                                          item_request.ireq_status === 'INBRACE Manufactured Item Request' ||
                                          item_request.ireq_status === 'Item Request Shipped' ? (
                                            <div>
                                              <div className="business-dark-heading">
                                                <span className="bold-text">
                                                  Shipping Details
                                                  {this.showIrTargetShipDate(item_request) &&
                                                    item_request.internal_target_ship_date &&
                                                    isTodayPastDate(item_request.internal_target_ship_date) && (
                                                      <i
                                                        className="fa fa-flag target-date-flag"
                                                        aria-hidden="true"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={`Missed Target Ship Date: ${convertDate(item_request.internal_target_ship_date)}`}
                                                      />
                                                    )}
                                                  {this.showIrTargetShipDate(item_request) && this.displayIrTargetShipDate(item_request)}
                                                </span>
                                              </div>
                                              <div className="business-dark-theme">
                                                <div className="business-dark-theme-content">
                                                  {this.displayIrShippingInformation(item_request, user_roles_and_permissions)}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            } else if (
                              this.state.item_request_mode === 'update' &&
                              this.state.selected_tab === 'item_request' &&
                              this.state.selected_request_id === item_request.ireq_id
                            ) {
                              return (
                                <div key={index}>
                                  <IrSubmission
                                    item_request={item_request}
                                    item_request_detail={this.state.case_details.item_request_details}
                                    mode={this.state.item_request_mode}
                                    casedetails={this.state.case_details}
                                    case_id={this.state.selected_case_id}
                                    onCancelClick={this.onIrSubmitCancelClick}
                                    onFinish={this.onIRSubmitFinish}
                                    status_comment={this.state.status_comment}
                                    doctor_first_name={this.state.case_details.doctor.first_name}
                                    doctor_last_name={this.state.case_details.doctor.last_name}
                                    user_roles_and_permissions={user_roles_and_permissions}
                                    gen_2={this.getGen2Flag()}
                                  />
                                </div>
                              );
                            }

                            return null;
                          })}
                      </SecurityClearance>

                      <SecurityClearance mode="ALL" permission_list={['PROGRESS_VIEW']}>
                        <div>
                          {this.state.selected_tab === 'progress' && this.state.progress_record_mode === 'view' ? (
                            <div className="page-tab-content">
                              <div className="row page-top-content">
                                <div className="col-lg-6">
                                  <div className="item-heading">
                                    <div className="progress-requestnumber">
                                      <span className="bold-text">No.</span>
                                    </div>
                                    <div className="progress-type">
                                      <span className="bold-text">Submission Type</span>
                                    </div>
                                    <div className="progress-status">
                                      <span className="bold-text">Status</span>
                                    </div>
                                    <div className="progress-submissiondate">
                                      <span className="bold-text">Submission Date</span>
                                    </div>
                                  </div>
                                </div>
                                <SecurityClearance mode="ANY" permission_list={['PROGRESS_DELETE', 'PROGRESS_EDIT']}>
                                  <div className="col-lg-6">
                                    <div className="pull-right">
                                      {this.state.case_details.progress_record.length > 0 ? (
                                        <div
                                          className="btn-action btn-modal"
                                          onClick={this.onProgressModalClick}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Notes"
                                        >
                                          <i className="fa fa-file-text" aria-hidden="true" />
                                          {this.state.progress_notes.length > 0 ? <span className="indicator-dot" /> : null}
                                        </div>
                                      ) : (
                                        <div className="btn-action disabled" data-toggle="tooltip" data-placement="top" title="Notes">
                                          <i className="fa fa-file-text" aria-hidden="true" />
                                        </div>
                                      )}
                                      <div className="btn-action btn-modal dropdown" data-toggle="tooltip" data-placement="top" title="Additional Action">
                                        <i
                                          className="fa fa-list-ul dropdown-toggle"
                                          type="button"
                                          id="dropdownMenu1"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="true"
                                          aria-hidden="true"
                                        />

                                        <ul className="dropdown-menu dropdown-menu-offset" aria-labelledby="dropdownMenu1">
                                          <SecurityClearance mode="ALL" permission_list={['PROGRESS_DELETE']}>
                                            {this.state.selected_progress_status && this.state.selected_progress_status !== 'Request Closed' ? (
                                              <li
                                                data-id={this.state.selected_progress_id}
                                                data-type={this.state.selected_progress_type}
                                                onClick={this.onDeleteProgressRecord}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a data-id={this.state.selected_progress_id} data-type={this.state.selected_progress_type}>
                                                  Delete Submission
                                                </a>
                                              </li>
                                            ) : (
                                              <li className="disabled">
                                                {/* eslint-disable-next-line */}
                                                <a> Delete Submission</a>
                                              </li>
                                            )}
                                          </SecurityClearance>
                                          <SecurityClearance mode="ALL" permission_list={['PROGRESS_EDIT']}>
                                            {this.state.selected_progress_status && this.state.selected_progress_status !== 'Request Closed' ? (
                                              <li
                                                data-id={this.state.selected_progress_id}
                                                data-type={this.state.selected_progress_type}
                                                data-revision={this.state.selected_progress_revision}
                                                onClick={this.onUpdateProgressRecordClick}
                                              >
                                                {/* eslint-disable-next-line */}
                                                <a
                                                  data-id={this.state.selected_progress_id}
                                                  data-type={this.state.selected_progress_type}
                                                  data-revision={this.state.selected_progress_revision}
                                                >
                                                  Update Submission
                                                </a>
                                              </li>
                                            ) : (
                                              <li className="disabled">
                                                {/* eslint-disable-next-line */}
                                                <a> Update Submission</a>
                                              </li>
                                            )}
                                          </SecurityClearance>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </SecurityClearance>
                              </div>
                              {this.state.case_details.progress_record.length > 0
                                ? this.state.case_details.progress_record
                                    .slice(0)
                                    .reverse()
                                    .map((progress_record, index) => {
                                      if (this.state.selected_tab === 'progress' && this.state.progress_record_mode === 'view') {
                                        if (this.state.selected_tab === 'progress' && this.state.selected_progress_id === progress_record.progress_id) {
                                          return (
                                            <div key={index} className="row">
                                              <div className="col-lg-6 business-dark-lg-6-l">
                                                <div className="item-table">
                                                  <div className="item-content">
                                                    {this.getProgressRecordsWithoutDrafts()
                                                      .slice(0)
                                                      .reverse()
                                                      .map((progress_record, index) => {
                                                        return (
                                                          <div
                                                            key={index}
                                                            className={
                                                              this.state.selected_progress_id === progress_record.progress_id ? 'selected-item-row' : 'item-row'
                                                            }
                                                            data-progress_id={progress_record.progress_id}
                                                            data-type={progress_record.record_type}
                                                            data-revision={progress_record.revision_id}
                                                            data-status={progress_record.status}
                                                            onClick={this.selectProgressRecord}
                                                          >
                                                            <div
                                                              className="progress-requestnumber"
                                                              data-progress_id={progress_record.progress_id}
                                                              data-type={progress_record.record_type}
                                                              data-revision={progress_record.revision_id}
                                                              data-status={progress_record.status}
                                                              onClick={this.selectProgressRecord}
                                                            >
                                                              <span
                                                                data-progress_id={progress_record.progress_id}
                                                                data-type={progress_record.record_type}
                                                                data-revision={progress_record.revision_id}
                                                                data-status={progress_record.status}
                                                                onClick={this.selectProgressRecord}
                                                              >
                                                                {this.getProgressRecordsWithoutDrafts().length - index}
                                                              </span>
                                                            </div>
                                                            <div
                                                              className="progress-type"
                                                              data-progress_id={progress_record.progress_id}
                                                              data-type={progress_record.record_type}
                                                              data-revision={progress_record.revision_id}
                                                              data-status={progress_record.status}
                                                              onClick={this.selectProgressRecord}
                                                            >
                                                              <span
                                                                data-progress_id={progress_record.progress_id}
                                                                data-type={progress_record.record_type}
                                                                data-revision={progress_record.revision_id}
                                                                data-status={progress_record.status}
                                                                onClick={this.selectProgressRecord}
                                                              >
                                                                {progress_record.record_type === 'CSR'
                                                                  ? 'Clinical Support Request'
                                                                  : progress_record.record_type === 'CSQ'
                                                                  ? 'Clinical Support Questionnaire'
                                                                  : 'Progress Record'}
                                                              </span>
                                                            </div>
                                                            <div
                                                              className="progress-status"
                                                              data-progress_id={progress_record.progress_id}
                                                              data-type={progress_record.record_type}
                                                              data-revision={progress_record.revision_id}
                                                              data-status={progress_record.status}
                                                            >
                                                              <span
                                                                data-progress_id={progress_record.progress_id}
                                                                data-type={progress_record.record_type}
                                                                data-revision={progress_record.revision_id}
                                                                data-status={progress_record.status}
                                                                onClick={this.selectProgressRecord}
                                                              >
                                                                {progress_record.status}
                                                              </span>
                                                            </div>

                                                            <div
                                                              className="progress-submissiondate"
                                                              data-progress_id={progress_record.progress_id}
                                                              data-type={progress_record.record_type}
                                                              data-revision={progress_record.revision_id}
                                                              data-status={progress_record.status}
                                                              onClick={this.selectProgressRecord}
                                                            >
                                                              <span
                                                                data-progress_id={progress_record.progress_id}
                                                                data-type={progress_record.record_type}
                                                                data-revision={progress_record.revision_id}
                                                                data-status={progress_record.status}
                                                                onClick={this.selectProgressRecord}
                                                              >
                                                                {convertDate(progress_record.created_date)}
                                                              </span>
                                                              <span
                                                                className="right-arrow"
                                                                data-progress_id={progress_record.progress_id}
                                                                data-type={progress_record.record_type}
                                                                data-revision={progress_record.revision_id}
                                                                data-status={progress_record.status}
                                                                onClick={this.selectProgressRecord}
                                                              >
                                                                {this.state.selected_progress_id === progress_record.selected_progress_id ? (
                                                                  <i
                                                                    className="fa fa-caret-right"
                                                                    aria-hidden="true"
                                                                    data-progress_id={progress_record.progress_id}
                                                                    onClick={this.selectProgressRecord}
                                                                  />
                                                                ) : null}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-6 business-dark-lg-6-r">
                                                {progress_record.revision_id === 'CSR1.0' ? (
                                                  <React.Fragment>
                                                    {userHasPermission('PROGRESS_ASSIGN', user_roles_and_permissions.permissions) &&
                                                    (progress_record.status === 'Request Pending' ||
                                                      progress_record.status === 'Request Updated' ||
                                                      progress_record.status === '------------') ? (
                                                      <div className="pr-dropdown">
                                                        <span className="bold-text">Assigned Specialist:</span>
                                                        <Select
                                                          name="cst_list"
                                                          placeholder="Select User"
                                                          value={this.state.saving_cst ? [{ label: 'Saving...' }] : this.state.selected_cst}
                                                          options={this.state.all_cst}
                                                          onChange={(e) => this.onChangeList(e, progress_record.progress_id)}
                                                          isDisabled={this.state.saving_cst}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="pr-dropdown">
                                                        <span className="bold-text">Assigned Specialist:</span>{' '}
                                                        <span className="pr-list-read">{this.getReadOnlyCST()}</span>
                                                      </div>
                                                    )}
                                                  </React.Fragment>
                                                ) : null}

                                                {progress_record.record_type === 'CSR' &&
                                                (progress_record.status === 'Request Pending' || progress_record.status === 'Request Updated') ? (
                                                  <React.Fragment>
                                                    {userHasPermission('CSR_CLOSE', user_roles_and_permissions.permissions) ? (
                                                      <div className="btn-room btn-inline">
                                                        <button type="button" className="btn btn-light btn-light-xwide" onClick={this.onCloseRequestClick}>
                                                          Close Request
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      <div className="btn-room btn-inline">
                                                        <button type="button" className="btn btn-light btn-light-xwide" disabled={true}>
                                                          Close Request
                                                        </button>
                                                      </div>
                                                    )}
                                                  </React.Fragment>
                                                ) : null}
                                                <div>
                                                  <div className="business-dark-heading">
                                                    <span className="bold-text">{this.getProgressDisplayType(progress_record)}</span>
                                                    {this.getProgressZipButton(progress_record.record_type)}
                                                  </div>
                                                  <div className="business-dark-theme">
                                                    <div className="business-dark-theme-content">
                                                      <div className="business-dark-theme-content">
                                                        {progress_record.files && parseInt(this.state.selected_progress_id) === progress_record.progress_id ? (
                                                          <ThumbnailURLInterface
                                                            files={progress_record.files}
                                                            images={configLightBox(progress_record.files)}
                                                            theme="dark"
                                                            hide_occlusion={true}
                                                          />
                                                        ) : null}
                                                        <div className="extra-padding" />

                                                        {this.getProgressRecordDetailDisplay(progress_record)}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div>
                                                  <div className="business-dark-heading">
                                                    <span className="bold-text">{'Clinical Notes'}</span>
                                                  </div>
                                                  <div className="business-dark-theme">
                                                    <div className="business-dark-theme-content">
                                                      {progress_record.notes && progress_record.notes.length === 0 ? (
                                                        <div>No Clinical Notes Found...</div>
                                                      ) : (
                                                        progress_record.notes
                                                          .filter(function (a) {
                                                            return a.generic === false;
                                                          })
                                                          .map((notes, index) => {
                                                            return (
                                                              <div key={index}>
                                                                <div className="notes-header">
                                                                  <span className="bold-text">
                                                                    <span className="notes-left">Created By: {notes.user}</span>
                                                                    <span className="notes-right">{notes.date}</span>
                                                                  </span>
                                                                </div>
                                                                <div className="notes-label">{notes.note_text}</div>
                                                              </div>
                                                            );
                                                          })
                                                      )}
                                                    </div>
                                                  </div>
                                                  <SecurityClearance mode="ALL" permission_list={['PROGRESS_NOTES_CREATE']}>
                                                    <div className="add-notes" onClick={this.addCsrNote}>
                                                      <span className="edit-position" onClick={this.addCsrNote}>
                                                        <i className="fa fa-plus-circle" aria-hidden="true" /> Create Clinical Notes{' '}
                                                      </span>
                                                    </div>
                                                  </SecurityClearance>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                      return null;
                                    })
                                : null}
                            </div>
                          ) : null}
                          {this.state.case_details.progress_record
                            .slice(0)
                            .reverse()
                            .map((progress_record, index) => {
                              if (
                                this.state.progress_record_mode === 'update' &&
                                this.state.selected_tab === 'progress' &&
                                this.state.new_files &&
                                this.state.selected_progress_id === progress_record.progress_id &&
                                this.state.selected_progress_revision === 'CSQ1.0'
                              ) {
                                return (
                                  <div key={index}>
                                    <PrSubmission
                                      id={progress_record.case_id}
                                      progress_record={progress_record}
                                      mode={this.state.progress_record_mode}
                                      status_comment={this.state.status_comment}
                                      new_files={this.state.new_files}
                                      casedetails={this.state.case_details}
                                      case_id={this.state.selected_case_id}
                                      onCancelClick={this.onPrSubmitCancelClick}
                                      onFinish={this.onPRSubmitFinish}
                                      update_mode={this.state.selected_progress_type === 'CSQ' ? 'csq_submit' : 'pr_submit'}
                                      type={this.state.selected_progress_type}
                                      doctor_first_name={this.state.case_details.doctor.first_name}
                                      doctor_last_name={this.state.case_details.doctor.last_name}
                                      CancelUpdate={this.onPrSubmitCancelClick}
                                      ReturnBack={this.onPrSubmitConfirm}
                                      gen_2={this.getGen2Flag()}
                                    />
                                  </div>
                                );
                              } else if (
                                this.state.progress_record_mode === 'update' &&
                                this.state.selected_tab === 'progress' &&
                                this.state.new_files &&
                                this.state.selected_progress_id === progress_record.progress_id &&
                                this.state.selected_progress_revision === 'CSR1.0'
                              ) {
                                return (
                                  <div key={index}>
                                    <CsrSubmission
                                      id={progress_record.case_id}
                                      progress_record={progress_record}
                                      mode={this.state.progress_record_mode}
                                      status_comment={this.state.status_comment}
                                      new_files={this.state.new_files}
                                      casedetails={this.state.case_details}
                                      case_id={this.state.selected_case_id}
                                      onCancelClick={this.onPrSubmitCancelClick}
                                      onFinish={this.onPRSubmitFinish}
                                      update_mode={this.state.selected_progress_type === 'CSQ' ? 'csq_submit' : 'pr_submit'}
                                      type={progress_record.record_type}
                                      doctor_first_name={this.state.case_details.doctor.first_name}
                                      doctor_last_name={this.state.case_details.doctor.last_name}
                                      CancelUpdate={this.onPrSubmitCancelClick}
                                      ReturnBack={this.onPrSubmitConfirm}
                                    />
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>
                      </SecurityClearance>

                      {this.state.item_request_mode === 'create' && this.state.selected_tab === 'item_request' ? (
                        <div>
                          <IrSubmission
                            item_request={null}
                            item_request_detail={this.state.case_details.item_request_details}
                            mode={this.state.item_request_mode}
                            casedetails={this.state.case_details}
                            case_id={this.state.ir_submit_case_id}
                            onCancelClick={this.onIrSubmitCancelClick}
                            onFinish={this.onIRSubmitFinish}
                            doctor_first_name={this.state.case_details.doctor.first_name}
                            doctor_last_name={this.state.case_details.doctor.last_name}
                            user_roles_and_permissions={user_roles_and_permissions}
                            gen_2={this.getGen2Flag()}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {this.state.status_message ? (
                  <div className="status__message--dark">
                    <i className="fa fa-spinner fa-spin" aria-hidden="true" /> {this.state.status_message}
                  </div>
                ) : null}

                <div>
                  {this.state.showCancelCaseModal && this.state.selected_tab !== 'item_request' ? (
                    <Modal
                      preset={this.state.selected_tab === 'item_request' ? 'decision-dialog' : 'decision-dialog-hold-cancel'}
                      header_text={this.getHeader('Cancel')}
                      message_text={this.getMessage('cancel')}
                      textarea_placeholder="Enter details for case cancellation..."
                      doctornote_placeholder="Please specify reason for cancellation to be displayed to doctor..."
                      textarea_warning_text={
                        this.state.selected_tab === 'item_request' ? 'Please specify reason for cancellation' : 'Please select reason for cancellation'
                      }
                      confirm_btn_text={this.state.selected_tab === 'item_request' ? 'Cancel Item Request' : 'Cancel Case'}
                      close_btn_text="Do Not Cancel"
                      label_dropdown="Reason for Cancellation:"
                      dropdown_error="Please specify reason for cancellation"
                      default_text="Select reason for cancellation"
                      onConfirmButtonClick={this.onCancelButtonClick}
                      onCloseButtonClick={this.onCancelCaseModalDismiss}
                      onTextAreaChange={this.onReasonMessageChange}
                      onDropdownChange={this.onStatusCommentChange}
                      onTextAreaDoctorNoteChange={this.onDoctorNoteChange}
                      theme="bpp"
                      hold_cancel_reasons={this.state.hold_cancel_reasons}
                      post_smile_design_case_cancel={isAfterSmileDesignApproval(this.getLatestCaseStatusCode()) && !isCaseDE(this.getLatestCaseIDFromCases())}
                      cancel_case_bottom_warning_text={"A $250 charge will be applied to the doctor's account for cancelling after Smile Design approval."}
                      in_progress={this.state.cancelCasePending}
                      in_progress_text="Please wait while we cancel the case"
                    />
                  ) : null}
                  {this.state.showCancelCaseModal && this.state.selected_tab === 'item_request' ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={this.getHeader('Cancel')}
                      message_text={this.getMessage('cancel')}
                      textarea_placeholder="Please specify reason for cancellation"
                      textarea_warning_text="Please specify reason for cancellation"
                      confirm_btn_text="Cancel Request"
                      close_btn_text="Do Not Cancel"
                      onConfirmButtonClick={this.onCancelButtonClick}
                      onCloseButtonClick={this.onCancelCaseModalDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showHoldModal && this.state.selected_tab !== 'item_request' ? (
                    <Modal
                      preset={this.state.selected_tab === 'item_request' ? 'decision-dialog' : 'decision-dialog-hold-cancel'}
                      header_text={this.getHeader('Hold')}
                      message_text={this.getMessage('hold')}
                      textarea_placeholder="Enter details for case hold..."
                      doctornote_placeholder="Please enter reason for holding case..."
                      textarea_warning_text="Please select reason for hold"
                      confirm_btn_text="Hold"
                      close_btn_text="Cancel"
                      label_dropdown="Reason for Hold:"
                      default_text="Select reason for hold"
                      onConfirmButtonClick={this.onHoldClick}
                      onCloseButtonClick={this.onHoldModalDismiss}
                      onTextAreaChange={this.onReasonMessageChange}
                      onTextAreaDoctorNoteChange={this.onDoctorNoteChange}
                      onDropdownChange={this.onStatusCommentChange}
                      theme="bpp"
                      dropdown_disabled={this.state.false_de}
                      selected_reason={this.state.false_de ? 'DE Review' : ''}
                      hold_cancel_reasons={this.state.hold_cancel_reasons}
                      case_id={this.state.selected_case_id}
                    />
                  ) : null}
                  {this.state.showHoldModal && this.state.selected_tab === 'item_request' ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={this.getHeader('Hold')}
                      message_text={this.getMessage('hold')}
                      textarea_placeholder="Please specify reason for hold"
                      textarea_warning_text="Please specify reason for hold"
                      confirm_btn_text="Hold"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onHoldClick}
                      onCloseButtonClick={this.onHoldModalDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showReverseCancellationModal ? (
                    <Modal
                      preset="cancellation"
                      header_text={
                        'Reverse Cancellation - ' +
                        (this.state.selected_tab === 'item_request'
                          ? removeIreqIdInitialNumber(this.state.selected_request_id)
                          : removeCaseIdInitialNumber(this.state.selected_case_id))
                      }
                      message_text={this.getReverseCancellationMessage()}
                      textarea_placeholder={this.getReverseCancellationPlaceHolderText()}
                      confirm_btn_text="Reverse"
                      close_btn_text="Do Not Reverse"
                      address_list={this.state.case_details.shipping_addresses}
                      address_selected={this.state.new_address_id}
                      address={this.state.showActiveAddreses}
                      onConfirmButtonClick={this.onReverseCancelStatusClick}
                      onCloseButtonClick={this.onReverseCancellationModalDismiss}
                      onAddressInputChange={this.onCaseAddressChange}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                      in_progress={this.state.reverseCancellationPending}
                      in_progress_text="Please wait while we reverse the cancellation"
                    />
                  ) : null}
                  {this.state.showReverseHoldModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={this.getHeader('Unhold')}
                      message_text={this.getMessage('unhold')}
                      textarea_placeholder="Enter comments here (optional)"
                      textarea_required={false}
                      confirm_btn_text="Reverse"
                      close_btn_text="Do Not Reverse"
                      onConfirmButtonClick={this.onReverseHoldStatusClick}
                      onCloseButtonClick={this.onReverseHoldModalDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showStatusModal ? (
                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                          <Modal
                            preset="status"
                            case_id={this.state.selected_case_id}
                            header_text={`Status History - ${this.state.selected_tab === 'item_request' ? 'Item Request ' : 'Case '}
                  ${
                    this.state.selected_tab === 'item_request'
                      ? removeIreqIdInitialNumber(this.state.selected_request_id)
                      : removeCaseIdInitialNumber(this.state.selected_case_id)
                  }`}
                            item_request_id={this.state.selected_request_id}
                            item_request_status={this.state.case_details.item_request_status}
                            selected_tab={this.state.selected_tab}
                            modal_size_class="modal-lg"
                            modal_body_class="text-left"
                            onCloseButtonClick={this.onStatusModalDismiss}
                            theme="bpp"
                          />
                        ) : null;
                      }}
                    </UserPermissionsContext.Consumer>
                  ) : null}
                  {this.state.showIrNotesModal ? (
                    <Modal
                      preset="notes"
                      ireq_id={this.state.selected_request_id}
                      header_text={`Notes - Item Request ${removeIreqIdInitialNumber(this.state.selected_request_id)}`}
                      modal_size_class="modal-lg"
                      modal_body_class="text-left"
                      onCloseButtonClick={this.onIrNotesModalDismiss}
                      setNotesIndicator={this.setIrNotesIndicator}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showNotesModal ? (
                    <Modal
                      preset="notes"
                      case_id={this.state.selected_case_id}
                      header_text={`Notes - Case ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      modal_size_class="modal-lg"
                      modal_body_class="text-left"
                      onCloseButtonClick={this.onNotesModalDismiss}
                      setNotesIndicator={this.setNotesIndicator}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showProgressNotesModal ? (
                    <Modal
                      preset="progress-notes"
                      case_id={this.state.selected_case_id}
                      user_id={this.state.user_id}
                      progress_id={this.state.selected_progress_id}
                      progress_notes={this.state.progress_notes}
                      header_text={`Clinical Notes - Case ${removeCaseIdInitialNumber(
                        this.state.case_details.base_id ? this.state.case_details.base_id : this.state.selected_case_id
                      )}`}
                      modal_class="modal-lg"
                      modal_body_class="text-left"
                      onCloseButtonClick={this.onProgressNotesModalDismiss}
                      setNotesIndicator={this.setProgressNotesIndicator}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showFileCommentsModal ? (
                    <Modal
                      preset="log"
                      log_type="patient"
                      case_id={this.state.selected_case_id}
                      selected_tab={this.state.selected_tab}
                      header_text="Patient Records Log"
                      onCloseButtonClick={this.onFileCommentsDismiss}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showSetupCommentsModal ? (
                    <Modal
                      preset="log"
                      log_type="setup"
                      case_id={this.state.selected_case_id}
                      selected_tab={this.state.selected_tab}
                      header_text="Smile Design Log"
                      onCloseButtonClick={this.onSetupCommentsDismiss}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showChangeTabModal ? (
                    <Modal
                      preset="decision"
                      header_text="Switch Tabs"
                      message_text="All unsaved work will be lost by switching tabs, would you like to continue?"
                      confirm_btn_text="Continue"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onChangeTabClick}
                      onCloseButtonClick={this.onChangeTabDismiss}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showApproveFileModal ? (
                    <Modal
                      preset="decision"
                      header_text="Approve Records"
                      message_text="Are you sure you want to approve the records?"
                      confirm_btn_text="Approve"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onApproveClick}
                      onCloseButtonClick={this.onFileApproveDismiss}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showUpdateProgressRecordModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={'Update Submission'}
                      message_text={'Are you sure you want to update this submission?'}
                      confirm_btn_text="Update"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onUpdateProgressRecord}
                      onCloseButtonClick={this.onEditDismiss}
                      theme="bpp"
                      textarea_placeholder="Please specify reason for updating"
                      textarea_warning_text="Please specify reason for updating"
                      onTextAreaChange={this.onStatusCommentChange}
                    />
                  ) : null}
                  {this.state.showCsrAddNotesMoal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={'Create Clinical Note - ' + removeCaseIdInitialNumber(this.state.selected_case_id)}
                      message_text={''}
                      confirm_btn_text="Create Note"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onAddCsrNotesClick}
                      onCloseButtonClick={this.AddCsrNotesDismiss}
                      theme="bpp"
                      bottom_text="**Clinical notes will not be seen by the doctor"
                      textarea_placeholder="Enter clinical notes here..."
                      textarea_warning_text="Please specify the notes"
                      onTextAreaChange={this.onStatusCommentChange}
                    />
                  ) : null}
                  {this.state.showDeleteProgressRecordModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={'Delete Submission'}
                      message_text={'Are you sure you want to delete this submission?'}
                      confirm_btn_text="Delete"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onDeleteClick}
                      onCloseButtonClick={this.onDeleteDismiss}
                      theme="bpp"
                      textarea_placeholder="Please specify reason for deleting"
                      textarea_warning_text="Please specify reason for deleting"
                      onTextAreaChange={this.onStatusCommentChange}
                    />
                  ) : null}
                  {this.state.showRejectFileConfirmModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text="Records Incomplete"
                      message_text="Are you sure you want to mark the records incomplete?"
                      textarea_placeholder="Specify reason for marking incomplete. EX: Scans not in occlusion."
                      textarea_warning_text="Please specify reason for marking incomplete"
                      confirm_btn_text="Incomplete"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onRejectAccept}
                      onCloseButtonClick={this.onFileRejectConfirmDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showViewIrInvoice ? (
                    <Modal
                      preset="pdf_viewer"
                      header_text={'Item Request Form ' + removeIreqIdInitialNumber(this.state.selected_request_id)}
                      modal_size_class="modal-lg modal-lg-pdf"
                      modal_body_class="modal-pdf"
                      ir_invoice_type="bpp"
                      ireq_id={this.state.selected_request_id}
                      onCloseButtonClick={this.onViewIrInvoicDismiss}
                      theme="bpp"
                      original_filename={getPDFNameTemplate(this.state.selected_request_id, this.state.case_details.patient, this.getIRFormType())}
                    />
                  ) : null}
                  {this.state.showSetupModal ? (
                    <Modal
                      preset="upload"
                      header_text="Upload Smile Design"
                      remove_btn={
                        (this.state.selected_setup[this.state.selected_setup.length - 1].files &&
                        this.state.selected_setup[this.state.selected_setup.length - 1].files.id
                          ? [this.state.selected_setup[this.state.selected_setup.length - 1].files]
                          : []
                        ).length > 0 && userHasPermission('SETUP_DELETE', user_roles_and_permissions.permissions)
                      }
                      remove_btn_text={'Remove Smile Design'}
                      case_id={this.state.selected_case_id}
                      folder="setup"
                      type="setup"
                      onUpload={this.onUpload}
                      upload_content={
                        this.state.selected_setup[this.state.selected_setup.length - 1].files &&
                        this.state.selected_setup[this.state.selected_setup.length - 1].files.id
                          ? [this.state.selected_setup[this.state.selected_setup.length - 1].files]
                          : []
                      }
                      onCloseButtonClick={this.onSetupModalDismiss}
                      onRemoveButtonClick={this.onRemove}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showEditPatientDetailsModal ? (
                    <Modal
                      preset="edit-patient"
                      header_text="Edit Patient Details"
                      edit_patient_warning_text="Please complete all required fields"
                      confirm_btn_text="Save"
                      close_btn_text="Cancel"
                      modal_body_class="text-left"
                      onCloseButtonClick={this.onEditPatientDetailsDismiss}
                      onConfirmButtonClick={this.onPatientDetailSaveClick}
                      patient_firstname={this.state.patient_firstname}
                      patient_lastname={this.state.patient_lastname}
                      patient_dob={this.state.patient_dob}
                      patient_dob_error={this.state.patient_dob_error}
                      patient_sex={this.state.patient_sex}
                      patient_ref={this.state.patient_ref}
                      onPatientFirstNameChange={this.onFirstNameChange}
                      onPatientLastNameChange={this.onLastNameChange}
                      onPatientDobChange={this.onDobChange}
                      onPatientSexChange={this.onSexChange}
                      theme="bpp"
                      case_id={this.state.selected_case_id}
                      in_progress={this.state.editPatientDetailsPending}
                      in_progress_text="Saving, please wait…"
                    />
                  ) : null}
                  {this.state.showEditTreatmentPlanModal && (
                    <Modal
                      preset="edit-tx-plan"
                      header_text={this.state.selected_case_id.includes('-DE') ? 'Edit DE Form' : 'Edit Treatment Plan'}
                      modal_size_class="modal-lg modal-lg-form"
                      modal_body_class="text-left"
                      case_id={this.state.selected_case_id}
                      save_tx_plan_changes={this.state.save_tx_plan_changes}
                      treatment_plan={this.getSelectedTreatmentPlan()}
                      files={this.getSelectedSubmissionFiles()}
                      reloadInformation={this.reloadInformation}
                      confirm_btn_text="Save"
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.onEditTreatmentPlanDismiss}
                      changeSaveValue={() => this.setState({ save_tx_plan_changes: false })}
                      onConfirmButtonClick={() => this.setState({ save_tx_plan_changes: true })}
                      theme="bpp"
                      gen_2={this.getGen2Flag()}
                    />
                  )}
                  {this.state.showExpediteDetailsModal ? (
                    <Modal
                      preset="expedite-details"
                      header_text={
                        (this.state.showExpediteRequest
                          ? 'Request Expedite - '
                          : this.state.showExpediteApproval
                          ? 'Approve Request - '
                          : this.state.showExpediteUpdate
                          ? 'Update Expedite Details - '
                          : 'Expedite Details - ') +
                        (expedite_type === 'Item Request'
                          ? removeIreqIdInitialNumber(this.state.selected_request_id)
                          : removeCaseIdInitialNumber(this.state.selected_case_id))
                      }
                      expedite_confirm_btn={
                        shipping_approved_ind
                          ? false
                          : (expedite_process.status_code === `${expedite_type} Expedite Request Submitted` &&
                              !userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_APPROVE`, user_roles_and_permissions.permissions) &&
                              !userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REJECT`, user_roles_and_permissions.permissions)) ||
                            ((expedite_process.status_code === `${expedite_type} Expedite Request Approved` ||
                              expedite_process.status_code === `${expedite_type} Expedite Request Updated`) &&
                              !userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_UPDATE`, user_roles_and_permissions.permissions)) ||
                            ((expedite_process.status_code === `${expedite_type} Expedite Request Cancelled` ||
                              expedite_process.status_code === `${expedite_type} Expedite Request Rejected` ||
                              expedite_process.status_code === '') &&
                              !userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REQUEST`, user_roles_and_permissions.permissions)) ||
                            (expedite_type === 'Case' &&
                              expedite_process.status_code === 'Case Expedite Request Submitted' &&
                              !isAfterSmileDesignApproval(case_status))
                          ? false
                          : true
                      }
                      confirm_btn_text={
                        this.state.showExpediteRequest
                          ? 'Submit Request'
                          : expedite_process.status_code === `${expedite_type} Expedite Request Submitted`
                          ? 'Approve'
                          : expedite_process.status_code === `${expedite_type} Expedite Request Approved` ||
                            expedite_process.status_code === `${expedite_type} Expedite Request Updated`
                          ? 'Update'
                          : 'Request Expedite'
                      }
                      close_btn_text={
                        this.state.showExpediteApproval
                          ? 'Cancel'
                          : expedite_process.status_code === `${expedite_type} Expedite Request Submitted` &&
                            (userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_APPROVE`, user_roles_and_permissions.permissions) ||
                              userHasPermission(`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REJECT`, user_roles_and_permissions.permissions)) &&
                            (expedite_type === 'Case' ? isAfterSmileDesignApproval(case_status) : true)
                          ? 'Reject'
                          : 'Close'
                      }
                      onCancelExpeditedClick={this.onExpediteDetailsCancel}
                      onRejectButtonClick={this.onExpediteDetailsReject}
                      onCloseButtonClick={this.onExpediteDetailsDismiss}
                      onConfirmButtonClick={
                        this.state.showExpediteApproval
                          ? (e) => this.onApproveConfirmExpediteClick(e, expedite_type)
                          : this.state.showExpediteRequest
                          ? (e) => this.onSubmitExpediteClick(e, expedite_type)
                          : this.state.showExpediteUpdate
                          ? (e) => this.onConfirmUpdateExpediteClick(e, expedite_type)
                          : expedite_process.status_code === `${expedite_type} Expedite Request Submitted`
                          ? this.onApproveExpediteClick
                          : expedite_process.status_code === `${expedite_type} Expedite Request Approved` ||
                            expedite_process.status_code === `${expedite_type} Expedite Request Updated`
                          ? (e) => this.onShowUpdateExpediteClick(e, expedite_type)
                          : this.onExpediteDetailsExpediteClick
                      }
                      onTextAreaChange={
                        this.state.showExpediteApproval || this.state.showExpediteUpdate ? this.onExpediteAdditionalNoteChange : this.onExpediteNoteChange
                      }
                      shipping_address={
                        expedite_type === 'Case'
                          ? this.getAddressByCaseId(this.state.case_details, this.state.selected_case_id)
                          : this.getAddressByIreqId(this.state.case_details, this.state.selected_request_id)
                      }
                      theme="bpp"
                      textarea_warning_text={
                        this.state.showExpediteApproval || this.state.showExpediteUpdate
                          ? 'Please fill out the required fields'
                          : 'Please enter an internal request note'
                      }
                      date_error_text="Target ship date should be greater than or equal to Appliance Design target date. Year should be greater than 2000."
                      expedite_priority={
                        expedite_process.status_code === `${expedite_type} Expedite Request Approved` ||
                        expedite_process.status_code === `${expedite_type} Expedite Request Updated`
                          ? 'Expedited'
                          : 'Standard'
                      }
                      expedite_additional_note={this.state.expedite_additional_note}
                      expedite_type={expedite_type}
                      expedite_note={this.state.expedite_note}
                      expedite_ship_ind={this.state.expedite_ship_ind}
                      expedite_target_ship_date={this.state.expedite_target_ship_date}
                      show_expedite_request={this.state.showExpediteRequest}
                      show_expedite_approval={this.state.showExpediteApproval}
                      show_expedite_update={this.state.showExpediteUpdate}
                      onExpediteDropdownChange={this.onExpediteDropdownChange}
                      onExpediteApprovalDropdownChange={this.onExpediteApprovalDropdownChange}
                      onExpediteTargetShipDateChange={this.onExpediteTargetShipDateChange}
                      expedite_process={expedite_process}
                      user_roles_and_permissions={user_roles_and_permissions}
                      update_btn_permission={this.loadShippingUpdatePermissionByType()}
                      confirm_btn_permission={
                        expedite_process.status_code === `${expedite_type} Expedite Request Submitted`
                          ? `${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_APPROVE`
                          : ''
                      }
                      close_btn_permission={
                        expedite_process.status_code === `${expedite_type} Expedite Request Submitted`
                          ? `${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REJECT`
                          : ''
                      }
                      cancel_permission={`${expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_CANCEL`}
                      onUpdateAddressClick={this.onUpdateAddressClick}
                      shipping_addresses={this.state.case_details.shipping_addresses}
                      selected_address_id={this.state.selected_tab === 'case' ? this.state.selected_address_id : this.state.ir_selected_address_id}
                      onSelectedAddressChange={this.onSelectedAddressChange}
                      shipping_approved_ind={shipping_approved_ind}
                      target_dates={target_dates}
                      case_status={case_status}
                      id={expedite_type === 'Case' ? this.state.selected_case_id : this.state.selected_request_id}
                      reloadInformation={this.reloadInformation}
                      initial_expedite_target_ship_date={this.state.initial_expedite_target_ship_date}
                      initial_expedite_ship_ind={this.state.initial_expedite_ship_ind}
                      initial_expedite_additional_note={this.state.initial_expedite_additional_note}
                      initial_preferred_shipping_input={this.state.initial_preferred_shipping_input}
                      edit_address_id={this.state.edit_address_id}
                      initial_smile_design_target_date={this.state.initial_smile_design_target_date}
                      initial_appliance_design_target_date={this.state.initial_appliance_design_target_date}
                      ir_selected_address_id={this.state.ir_selected_address_id}
                      no_updates_error_text="No updates have been made"
                    />
                  ) : null}
                  {this.state.showFalseDeModal ? (
                    <Modal
                      theme="bpp"
                      preset="standard-confirmation"
                      header_text={`${'Mark as False DE'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to mark this case as False DE?"
                      confirm_btn_text="Mark as False DE"
                      onConfirmButtonClick={this.flagFalseDe}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() => this.setState({ showFalseDeModal: false })}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.showEarlyDeModal ? (
                    <Modal
                      theme="bpp"
                      preset="standard-confirmation"
                      header_text={`${'Mark as Early DE'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to mark this case as Early DE?"
                      confirm_btn_text="Mark as Early DE"
                      onConfirmButtonClick={this.flagEarlyDe}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() => this.setState({ showEarlyDeModal: false })}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.showStagedModal ? (
                    <Modal
                      theme="bpp"
                      preset="decision-dialog-hold-cancel"
                      header_text={`${'Mark as Staged'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to mark this case as Staged?"
                      confirm_btn_text="Mark as Staged"
                      label_dropdown="Staged Category:"
                      onConfirmButtonClick={this.flagStaged}
                      onTextAreaChange={this.onStagedNoteChange}
                      onDropdownChange={this.onStagedDropdownChange}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() => this.setState({ showStagedModal: false })}
                      note_required={true}
                      textarea_placeholder="Enter Internal Note(s)"
                      default_text='Staged Category'
                      dropdown_items={STAGED_REASONS}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.showRemoveEarlyDeModal ? (
                    <Modal
                      preset="standard-confirmation"
                      header_text={`${'Remove Early DE Flag'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to remove the Early DE flag from this case?"
                      confirm_btn_text="Remove Early DE Flag"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.removeEarlyDeFlag}
                      onCloseButtonClick={() => this.setState({ showRemoveEarlyDeModal: false })}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showRemoveFalseDeModal ? (
                    <Modal
                      preset="standard-confirmation"
                      header_text={`${'Remove False DE Flag'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to remove the False DE flag from this case?"
                      confirm_btn_text="Remove False DE Flag"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.removeFalseDeFlag}
                      onCloseButtonClick={() => this.setState({ showRemoveFalseDeModal: false })}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showRemoveStagedModal ? (
                    <Modal
                      preset="standard-confirmation"
                      header_text={`${'Remove Staged Flag'} - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to remove the Staged flag from this case?"
                      confirm_btn_text="Remove Staged Flag"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.removeStagedFlag}
                      onCloseButtonClick={() => this.setState({ showRemoveStagedModal: false })}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showUpdateAddressModal ? (
                    <Modal
                      preset="update-address"
                      header_text={
                        'Update Shipping Address - ' +
                        (this.state.selected_tab === 'case'
                          ? removeCaseIdInitialNumber(this.state.selected_case_id)
                          : removeIreqIdInitialNumber(this.state.selected_request_id))
                      }
                      message_text={'Select new shipping address'}
                      shipping_address={
                        expedite_type === 'Case'
                          ? this.getAddressByCaseId(this.state.case_details, this.state.selected_case_id)
                          : this.getAddressByIreqId(this.state.case_details, this.state.selected_request_id)
                      }
                      shipping_addresses={this.state.case_details.shipping_addresses}
                      selected_address_id={this.state.selected_tab === 'case' ? this.state.selected_address_id : this.state.ir_selected_address_id}
                      theme="bpp"
                      confirm_btn_text="Update"
                      modal_body_class="select-address-body"
                      onSelectedAddressChange={this.onSelectedAddressChange}
                      onConfirmButtonClick={this.onUpdateAddressConfirm}
                      onCloseButtonClick={this.onUpdateAddressCancel}
                      confirm_btn_disabled={
                        this.state.selected_tab === 'case' && this.state.selected_address_id === this.state.edit_address_id
                          ? true
                          : this.state.selected_tab === 'item_request' && this.state.ir_selected_address_id === this.state.edit_address_id
                          ? true
                          : false
                      }
                    />
                  ) : null}
                  {this.state.showExpediteCancel ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={
                        'Cancel Expedite - ' +
                        (this.state.selected_tab === 'case'
                          ? removeCaseIdInitialNumber(this.state.selected_case_id)
                          : removeIreqIdInitialNumber(this.state.selected_request_id))
                      }
                      message_text="Are you sure you want to cancel this expedite?"
                      textarea_placeholder="Specify reason for cancelling this expedite..."
                      textarea_warning_text="Please specify reason for cancelling this expedite"
                      confirm_btn_text="Cancel Expedite"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={
                        this.state.selected_tab === 'case' ? (e) => this.onExpeditedCancelAccept(e, 'case') : (e) => this.onExpeditedCancelAccept(e, 'ireq')
                      }
                      onCloseButtonClick={this.onExpediteDetailsDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showExpediteReject ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={
                        'Reject Request - ' +
                        (this.state.selected_tab === 'case'
                          ? removeCaseIdInitialNumber(this.state.selected_case_id)
                          : removeIreqIdInitialNumber(this.state.selected_request_id))
                      }
                      message_text="Are you sure you want to reject this request?"
                      textarea_placeholder="Please specify reason for rejecting this request..."
                      textarea_warning_text="Please specify reason for rejecting this request"
                      confirm_btn_text="Reject"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={
                        this.state.selected_tab === 'case' ? (e) => this.onExpeditedRejectAccept(e, 'case') : (e) => this.onExpeditedRejectAccept(e, 'ireq')
                      }
                      onCloseButtonClick={this.onExpediteDetailsDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showApproveItemRequestModal ? (
                    <Modal
                      preset="decision"
                      header_text={'Approve Item Request - ' + removeIreqIdInitialNumber(this.state.selected_request_id)}
                      message_text="Are you sure you want to approve the item request?"
                      theme="bpp"
                      confirm_btn_text="Approve"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onIrApproveConfirm}
                      onCloseButtonClick={this.onIrApproveDismiss}
                      in_progress={this.state.approving}
                      in_progress_text={'Approving...'}
                    />
                  ) : null}
                  {this.state.showUpdateItemRequestModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={'Update Item Request - ' + removeIreqIdInitialNumber(this.state.selected_request_id)}
                      message_text={`Are you sure you want to update item request?`}
                      textarea_placeholder="Please specify reason for updating"
                      textarea_warning_text="Please specify reason for updating"
                      confirm_btn_text="Update Item Request"
                      close_btn_text="Do Not Update"
                      onConfirmButtonClick={this.onIrUpdateClick}
                      onCloseButtonClick={this.onIrUpdateConfirmDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.refresh ? (
                    <Modal
                      preset="action"
                      x_btn={false}
                      header_text="Session Expired"
                      message_text="Sorry, your session has expired. Please refresh."
                      confirm_btn_text="Refresh"
                      onConfirmButtonClick={onReloadPage}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.showFlagPrecheckModal ? (
                    <Modal
                      theme="bpp"
                      preset="decision"
                      header_text={`Mark as Precheck - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to mark this case as precheck?"
                      confirm_btn_text="Mark as Precheck"
                      onConfirmButtonClick={this.onFlagPrecheckConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.onFlagPrecheckDismiss}
                    />
                  ) : null}
                  {this.state.showUnflagPrecheckModal ? (
                    <Modal
                      theme="bpp"
                      preset="decision"
                      header_text={`Remove Precheck Label - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text="Are you sure you want to remove the precheck label from this case?"
                      confirm_btn_text="Remove Precheck Label"
                      onConfirmButtonClick={this.onUnflagPrecheckConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.onUnflagPrecheckDismiss}
                    />
                  ) : null}
                  {this.state.showProgressRecordModal ? (
                    <Modal
                      theme="bpp"
                      preset="pdf_viewer"
                      header_text={
                        this.state.selected_progress_revision === 'CSQ1.0'
                          ? `Clinical Support Question - ${this.getInitialCaseIDFromCases()}`
                          : `Clinical Support Request - ${this.getInitialCaseIDFromCases()}`
                      }
                      modal_size_class="modal-lg modal-lg-pdf"
                      modal_body_class="modal-pdf"
                      pdf_url={this.getProgressRecordPdfUrl()}
                      pdf_date={this.getProgressRecordPdfDate()}
                      onCloseButtonClick={this.onProgressRecordDismiss}
                      original_filename={getPDFNameTemplate(
                        this.getInitialCaseIDFromCases(),
                        this.state.case_details.patient,
                        this.state.selected_progress_revision === 'CSQ1.0' ? 'CSQ.pdf' : 'CSR.pdf'
                      )}
                    />
                  ) : null}
                  {this.state.showCloseRequestModal ? (
                    <Modal
                      preset="decision-dialog"
                      header_text={`Close Request`}
                      message_text="Are you sure you would like to close this Clinical Support Request?"
                      textarea_placeholder="Enter clinical note here..."
                      textarea_warning_text="Please specify clinical note for closing"
                      confirm_btn_text="Close Request"
                      close_btn_text="Cancel"
                      onConfirmButtonClick={this.onCloseRequestConfirm}
                      onCloseButtonClick={this.onCloseRequestDismiss}
                      onTextAreaChange={this.onStatusCommentChange}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.clinical_review.modal ? (
                    <Modal
                      theme="bpp"
                      preset="clinical-review"
                      header_text={`${this.state.clinical_review.selected_flag ? 'Complete' : 'Initiate'} Clinical Review - ${removeCaseIdInitialNumber(
                        this.state.selected_case_id
                      )}`}
                      message_text={
                        <div>
                          {this.state.clinical_review.selected_flag ? 'Enter completion notes for' : 'Enter reason for initiating'} clinical review{' '}
                          <b>to be shown to the doctor.</b>
                        </div>
                      }
                      confirm_btn_text={this.state.clinical_review.selected_flag ? 'Complete' : 'Initiate'}
                      confirm_btn_disabled={this.state.clinical_review.note_char_count === 0}
                      onConfirmButtonClick={this.onInitiateClinicalReviewConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.closeClinicalReviewModal}
                      onNoteChange={this.onClinicalReviewNoteChange}
                      note={this.state.clinical_review.note}
                      note_placeholder={this.state.clinical_review.selected_flag ? 'Enter note to the doctor...' : 'Enter reason for clinical review...'}
                      note_in_progress={this.state.clinical_review.in_progress}
                      onFocusNote={this.onFocusClinicalReview}
                      max_length={2500}
                      character_count={this.state.clinical_review.note_char_count}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.showEarlyDeClinicalReviewModal ? (
                    <Modal
                      theme="bpp"
                      preset="clinical-review"
                      header_text={`Initiate Clinical Review - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text={
                        <div>
                          {'Enter reason for initiating clinical review '}
                          <b>to be shown to the doctor.</b>
                        </div>
                      }
                      confirm_btn_text="Initiate"
                      confirm_btn_disabled={this.state.clinical_review.note_char_count === 0}
                      onConfirmButtonClick={this.flagEarlyDe}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() =>
                        this.setState({
                          showEarlyDeClinicalReviewModal: false,
                          clinical_review: { modal: false, note: '', note_char_count: 0, in_progress: false },
                        })
                      }
                      onNoteChange={this.onClinicalReviewNoteChange}
                      note={this.state.clinical_review.note}
                      note_placeholder={'Enter reason for clinical review...'}
                      note_in_progress={this.state.clinical_review.in_progress}
                      onFocusNote={this.onFocusClinicalReview}
                      max_length={2500}
                      character_count={this.state.clinical_review.note_char_count}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.showDeClarificationModal ? (
                    <Modal
                      theme="bpp"
                      preset="clinical-review"
                      header_text={`${
                        this.getCaseStatus() === 'Doctor Provide Clarification' ? 'Resolve Clarification Request' : 'Request Doctor Clarification'
                      } - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text={
                        this.getCaseStatus() === 'Doctor Provide Clarification' ? (
                          <div>Enter comments to resolve clarification request.</div>
                        ) : (
                          <div>
                            Enter reason for requesting doctor clarification <b>to be shown to the doctor.</b>
                          </div>
                        )
                      }
                      confirm_btn_text={this.getCaseStatus() === 'Doctor Provide Clarification' ? 'Resolve' : 'Request'}
                      confirm_btn_disabled={this.state.doctor_clarification.note_char_count === 0}
                      onConfirmButtonClick={this.flagFalseDe}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() =>
                        this.setState({
                          showDeClarificationModal: false,
                          doctor_clarification: {
                            modal: false,
                            note: '',
                            note_char_count: 0,
                            in_progress: false,
                          },
                        })
                      }
                      onNoteChange={this.onDoctorClarificationNoteChange}
                      note={this.state.doctor_clarification.note}
                      note_placeholder={
                        this.getCaseStatus() === 'Doctor Provide Clarification'
                          ? 'Enter comments here...'
                          : 'Enter reason for requesting doctor clarification...'
                      }
                      note_in_progress={this.state.doctor_clarification.in_progress}
                      onFocusNote={this.onFocusDoctorClarification}
                      max_length={2500}
                      character_count={this.state.doctor_clarification.note_char_count}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.doctor_clarification.modal ? (
                    <Modal
                      theme="bpp"
                      preset="clinical-review"
                      header_text={`${
                        this.getCaseStatus() === 'Doctor Provide Clarification' ? 'Resolve Clarification Request' : 'Request Doctor Clarification'
                      } - ${removeCaseIdInitialNumber(this.state.selected_case_id)}`}
                      message_text={
                        this.getCaseStatus() === 'Doctor Provide Clarification' ? (
                          <div>Enter comments to resolve clarification request.</div>
                        ) : (
                          <div>
                            Enter reason for requesting doctor clarification <b>to be shown to the doctor.</b>
                          </div>
                        )
                      }
                      confirm_btn_text={this.getCaseStatus() === 'Doctor Provide Clarification' ? 'Resolve' : 'Request'}
                      confirm_btn_disabled={this.state.doctor_clarification.note_char_count === 0}
                      onConfirmButtonClick={this.onRequestDoctorClarificationConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.closeDoctorClarificationModal}
                      onNoteChange={this.onDoctorClarificationNoteChange}
                      note={this.state.doctor_clarification.note}
                      note_placeholder={
                        this.getCaseStatus() === 'Doctor Provide Clarification'
                          ? 'Enter comments here...'
                          : 'Enter reason for requesting doctor clarification...'
                      }
                      note_in_progress={this.state.doctor_clarification.in_progress}
                      onFocusNote={this.onFocusDoctorClarification}
                      max_length={2500}
                      character_count={this.state.doctor_clarification.note_char_count}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.gen_update.modal ? (
                    <Modal
                      theme="bpp"
                      preset="clinical-review"
                      header_text={`Mark as Gen ${this.state.case_details.cases[0].gen_2 ? `1.0` : `2.0`} - ${removeCaseIdInitialNumber(
                        this.state.selected_case_id
                      )}`}
                      message_text={'Enter reason for technology update'}
                      confirm_btn_text={`Mark as Gen ${this.state.case_details.cases[0].gen_2 ? `1.0` : `2.0`}`}
                      confirm_btn_disabled={this.state.gen_update.note_char_count === 0}
                      onConfirmButtonClick={this.onFlagGenConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={this.onFlagGenCancel}
                      onNoteChange={this.onGenNoteChange}
                      note={this.state.gen_update.note}
                      note_placeholder={'Enter reason(s) here...'}
                      note_in_progress={this.state.gen_update.in_progress}
                      onFocusNote={this.onFocusGenNote}
                      max_length={2500}
                      character_count={this.state.gen_update.note_char_count}
                      className="overflow-visible"
                    />
                  ) : null}
                  {this.state.skip_production_tx_guide_modal && (
                    <Modal
                      theme="bpp"
                      preset="decision"
                      header_text={`${this.getSkipProductionTxGuide() ? 'Resume' : 'Skip'} Production Treatment Guide - ${removeCaseIdInitialNumber(
                        this.state.selected_case_id
                      )}`}
                      message_text={
                        this.getSkipProductionTxGuide() ? (
                          <div>Are you sure you want to resume the Production Treatment Guide for this case?</div>
                        ) : (
                          <div>
                            <div>Are you sure you want to skip the Production Treatment Guide for this case?</div>
                            <div>You can manually upload the Production Treatment Guide once QC review is complete in the Appliance Design step.</div>
                          </div>
                        )
                      }
                      confirm_btn_text={this.getSkipProductionTxGuide() ? 'Resume' : 'Skip'}
                      onConfirmButtonClick={this.onSkipProductionTreatmentGuideConfirm}
                      close_btn_text="Cancel"
                      onCloseButtonClick={() => this.toggleSkipProductionTxGuideModal(false)}
                    />
                  )}
                  {this.state.upload_production_tx_guide_modal && (
                    <Modal
                      preset="upload-pdf"
                      header_text={`Production Treatment Guide - ${removeCaseIdInitialNumber(this.props.ifu_state.case_id)}`}
                      modal_size_class="modal-lg modal-lg-pdf"
                      modal_body_class="modal-pdf"
                      case_id={this.state.selected_case_id}
                      folder="tx_guide_manual"
                      type="additional"
                      onUpload={this.onProductionTxGuideUpload}
                      upload_content={this.state.production_tx_guide_manual_file}
                      onCloseButtonClick={() => this.toggleUploadProductionTxGuideModal(false)}
                      theme="bpp"
                    />
                  )}
                  {this.state.dhr_form_modal && this.displayDHRFormModal()}
                  {this.state.convert_case_generation_modal === true ? (
                    <ConvertCaseGeneration
                      case_id={this.state.case_details.case_id}
                      doctor_id={this.state.case_details.doctor.id}
                      patient_name={this.state.case_details.patient.first_name + ' ' + this.state.case_details.patient.last_name}
                      closeModal={this.toggleConvertGenModal}
                      closeCancelCaseModalAndReload={this.closeModalAndReload}
                      history={this.props.history}
                      modal_theme="bpp"
                      convert_to={this.getGenConversionType()}
                      another_gen_case_exists={this.state.case_details.another_gen_case_exists}
                      gen_2={this.getGen2Flag()}
                      case_status={this.getLatestCaseStatusCode()}
                      refresh={() => {
                        this.setState({ refresh: true });
                      }}
                    />
                  ) : null}
                  {this.state.unable_to_reverse_modal ? (
                    <Modal
                      close_btn_text="Close"
                      header_text={'Unable to Reverse Cancellation - ' + this.state.case_details.case_id}
                      message_text={this.getUnableToReverseMsg()}
                      onCloseButtonClick={() => {
                        this.setState({ unable_to_reverse_modal: false });
                      }}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.unable_to_archive_folder ? (
                    <Modal
                      close_btn_text="Close"
                      header_text={`No Patient Folder Found - ${removeCaseIdInitialNumber(this.state.case_details.case_id)}`}
                      message_text={this.getUnabletoArchiveMsg()}
                      onCloseButtonClick={() => {
                        this.setState({ unable_to_archive_folder: false });
                      }}
                      theme="bpp"
                    />
                  ) : null}
                  {this.state.show_target_date_override_modal && (
                    <Modal
                      preset="external_target_date"
                      header_text={`Update External Est Ship Date - ${
                        this.state.selected_tab === 'item_request'
                          ? removeIreqIdInitialNumber(this.state.selected_request_id)
                          : removeCaseIdInitialNumber(this.state.selected_case_id)
                      }`}
                      close_btn_text="Cancel"
                      confirm_btn_text="Update"
                      theme="bpp"
                      onCloseButtonClick={this.onTargetDateOverrideModalDismiss}
                      onConfirmButtonClick={this.onTargetDateOverride}
                      modal_body={this.displayTargetDateOverrideModalBody()}
                      in_progress={this.state.ext_override_in_progress}
                      has_error={this.state.ext_override_errors.date || this.state.ext_override_errors.note}
                    />
                  )}
                </div>
              </div>
            ) : (
              <Redirect to="/business/portal" />
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    awb: getAwb(state),
    ifu_state: getIfuState(state),
    ddm_state: getDdmState(state),
    ifu_upload_pending: getUploadPending(state),
    selected_ddm_submitted_date: getSelectedDDMSubmittedDate(state),
    selected_ddm_received_date: getSelectedDDMReceivedDate(state),
    selected_production_received_date: getSelectedProductionReceivedDate(state),
    selected_production_tx_guide_tab: getSelectedProductionTxGuideTab(state),
    allowed_production_tx_guide_tabs: getAllowedProductionTxGuideTabs(state),
    segmentation_state: getSegmentationState(state),
    reload_thumbnail: getReloadThumbnail(state),
    appliance_design_target_date_input: getApplianceDesignTargetDateInput(state),
    smile_design_target_date_input: getSmileDesignTargetDateInput(state),
    preferred_shipping_input: getPreferredShippingInput(state),
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
    wire_manufacturing_status: getWireManufacturingStatus(state),
    wire_selection_status: getWireSelectionStatus(state),
    is_historical_single_tooth_idb: getIsHistoricalSingleToothIdb(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAwb: fetchAwb,
      fetchIfuState: fetchIfuState,
      fetchDdmState: fetchDdmState,
      fetchSegmentationState: fetchSegmentationState,
      setSelectedDDMSubmittedDate: setSelectedDDMSubmittedDate,
      setSelectedDDMReceivedDate: setSelectedDDMReceivedDate,
      setSelectedProductionReceivedDate: setSelectedProductionReceivedDate,
      onProductionTxGuideTabSelect: onProductionTxGuideTabSelect,
      setProductionTxGuideTabConfig: setProductionTxGuideTabConfig,
      fetchProductionTxGuide: fetchProductionTxGuide,
      changeReloadThumbnail: changeReloadThumbnail,
      clearTargetDateInputs: clearTargetDateInputs,
      setApplianceDesignTargetDateInput: setApplianceDesignTargetDateInput,
      setSmileDesignTargetDateInput: setSmileDesignTargetDateInput,
      setPreferredShippingInput: setPreferredShippingInput,
      fetchCaseFileData: fetchCaseFileData,
      setRequestedTreatment: setRequestedTreatment,
      fetchSingleToothIdb: fetchSingleToothIdb,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Business_Case));
