/**
 * File: case_details.js - Displays the specific details of a case
 * Copyright: (c) Copyright September 2018 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './case_details.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Prompt } from 'react-router-dom';
import moment from 'moment';
// Internal Components
import CreatePatientNotes from '../patient_notes/create_patient_notes';
import DisplayPatientNotes from '../patient_notes/display_patient_notes';
import InstructionViewer from './instruction_viewer';
import Modal from '../../components/modal/modal';
import LabelDropdownList from '../../components/dropdown/label_dropdown_list';
import NotFound from '../404/not_found';
import TrackShipmentModal from './track_shipment_modal';
import TreatmentPlan from './treatment_plan';
import VBPCViewer from './vbpc_viewer';
import SecurityClearance from '../../components/security/security_clearance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Exc } from '../assets/icons';
import TreatmentNavigatorBanner from './treatment_navigator/treatement_banner';
import TreatmentNavigator from './treatment_navigator/treatment_navigator';
import CancelCase from './cancel_case';
import ConvertCaseGeneration from './convert_case_generation';
import LockedIcon from '../../components/custom_lightbox/components/LockedIcon';
import { CaseStatus, CaseHistoryStatus } from '../../common/case/case_details.constants';

import { UserPermissionsContext } from '../../context/user_permission';
// Internal Functions
import {
  configLightBox,
  convertDate,
  formatCaseIdForApi,
  getRootPath,
  modalClear,
  removeCaseIdInitialNumber,
  removeIreqIdInitialNumber,
  setTokenHeader,
  textFieldCheck,
  removeEmoji,
} from '../../common/functions';
import {
  getBusinessRoleList,
  getCaseShippingAddress,
  getLastShippedCaseIdFromCaseDetail,
  getShippingAddress,
  getShippingAddressName,
  getShippingAddressId,
  isAfterAFGLaunch,
  onReloadPage,
  translateReviewProcessStatus,
  translateStatus,
  getPDFNameTemplate,
  getLastCaseStatusFromStatusesList,
} from '../../common/helpers';
import { getCaseIdFromUrl, convertAliasToCaseId } from '../../common/case/case_id';
import { handleHttpRequestError } from '../../common/error';
import { displayPSTDateTimeStamp } from '../../common/date';
import { isMobileDevice } from '../../common/browser';
import { getPdfURL, openPdfNewTab } from '../../common/pdf';
import { isPostApprovalStage } from '../../common/case/case_status';

// Redux
import { fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';
import {
  openEditPatientModal,
  closeEditPatientModal,
  onPatientRefChange,
  fetchDsoDoctorData,
  onDsoDoctorDropdownChange,
  onDsoAddressDropdownChange,
} from '../../redux/actions/ipp/case_details';
import {
  getEditPatientModal,
  getPatientRef,
  getDsoDoctors,
  getDsoAddresses,
  getDsoDoctorDropdownId,
  getDsoAddressDropdownId,
} from '../../redux/reducers/ipp/case_details';
import { userHasPermission } from '../../common/permission';

// nomenclature
import { getLookup } from '../../common/nomenclature';
import CardContainer from '../components/container/card_container';
import PatientInfo from './patient_info';
import CaseTabs from './case_tabs';
import Breadcrumb from '../components/breadcrumb';
import { getDoctorsIdFromRoute } from '../../common/route';
import CircleLoader from '../../components/loader/circle_loader';
import Button from '../components/buttons/button';
import CaseProgress from './case_progress';
import CollapsibleContainer from '../components/container/collapsible_container';
import CollapsibleTriangleContainer from '../components/container/collapsible_triangle_container';
import ScrollspyNav from './scroll_spy_nav';
import CaseHistory from './case_history';
import DOMPurify from 'dompurify';
import { scrollToTop } from '../../common/scroll';
import { fetchCaseFileData } from '../../redux/actions/ipp/case_details/case_files';
import { getCaseFileList, getCaseFileListLoading } from '../../redux/reducers/ipp/case_details/case_files';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';
import { PdfModal } from '../components/modals/pdf-modal';
import { ApiServiceInstance } from '../../common/api_service';
import { getJWTToken } from '../../common/token';
import { downloadFileWithSinglePart } from '../../components/wasm_viewer/filedownloader';
import { hasArchSegmentSelection, hasSingleToothSelection } from '../../common/ir_helpers';

class CaseDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      refresh: false,
      loading: true,
      submission_in_progress: false,
      doctor_id: '',
      case_details: {
        doctor: {},
        dso_doctor: {},
        patient: {},
        cases: [],
        item_request: [],
      },
      selected_plan_details: {
        case_id: '',
        patient_first_name: '',
        patient_last_name: '',
        patient_sex: '',
        patient_dob: '',
        doctor_first_name: '',
        doctor_last_name: '',
        treatment_plan: '',
        treatment_arch: '',
        treatment_plan_revision: '',
      },
      selected_tx: [],
      selected_vbpc: [],
      selected_tracking: [],
      selected_case_id: '',

      selected_tab: 'cases',
      scroll_id: '',
      selected_request_id: '',
      showViewPrCsq: false,
      selected_progress_id: '',
      selected_progress_ind: '',
      showTreatmentModal: false,
      rejectModal: false,
      rejectMessage: '',
      showTrackShipmentModal: false,
      showVBPCViewerModal: false,
      showTxInstructionViewerModal: false,
      showApproveSetupModal: false,
      doctor_account_role: '',
      doctor_account_id: '',
      doctor_account_referral: false,
      editPatientReferralCode: '',
      patientReferralCode: '',
      referralCodeData: {},
      showUnableToValidateReferralCode: false,
      doctor_first_name: '',
      doctor_last_name: '',
      assigned_doctor_gen2: false,

      approve_info: false,
      approve_ref: React.createRef(),

      patient_notes: false,
      patient_notes_id: '',
      patient_notes_delete_modal: false,
      patient_notes_subject: '',
      patient_notes_note_text: '',
      patient_notes_refresh: false,
      patient_notes_content: false,
      showUnsaveModal: false,
      draft_progress_id: '',
      showDeleteDraft: false,
      draft_case_id: '',
      show_patient_referral_code_modal: false,
      showInvalidReferralCode: false,
      showRedeemedReferralCode: false,
      showValidReferralCode: false,
      referral_code_url: '',
      confirmed_navigation: false,
      last_location: '',

      attributions: {},
      initial_attributions: {},
      attribution_questions: [],
      is_attribution_allowed: true,
      treatment_workflow: '',
      navigator_beta_tester: false,
      navigator_additional_actions: false,
      navigator_opted_in_before: false,
      cancel_case_modal: false,
      convert_case_generation_modal: false,
      user_role: '',
      is_admin_edit: false,
      smile_id: '',
    };

    this.onRejectClick.bind(this);
    this.onRejectDismiss.bind(this);
    this.onTabSelection.bind(this);
    this.onTreatmentModalDismiss.bind(this);
    this.onTrackIrShipmentModalClick.bind(this);
    this.onVBPCViewerModalDismiss.bind(this);
    this.onTxInstructionViewerModalDismiss.bind(this);
    this.onApproveSetupModalClick.bind(this);
    this.onApproveSetupModalDismiss.bind(this);
    this.getLastCompletedCaseId.bind(this);
    this.onAttributionOtherChange.bind(this);
  }

  getLastCompletedCaseId() {
    const cases = this.state.case_details && this.state.case_details.cases ? this.state.case_details.cases : '';
    return this.getLatestCaseStatusCode() === 'STATUS_DRAFT_DE' ? cases[cases.length - 2].case_id : cases[cases.length - 1].case_id;
  }

  getIRSubmissionDate(item_request) {
    return convertDate(item_request.created_date);
  }

  getPRSubmissionDate(progress_record) {
    return convertDate(progress_record.created_date);
  }

  getPRClosedDate(progress_record) {
    let close_date = '';
    if (progress_record.notes.length > 0) {
      progress_record.notes.forEach((note) => {
        if (note.note_text.includes('Request Closed')) {
          close_date = convertDate(note.created_date);
        }
      });
    }
    return close_date;
  }

  getPRDateTaken(progress_record) {
    return convertDate(progress_record.date_taken);
  }

  getIRSubmissionDateForStatus(request_status) {
    return convertDate(request_status.date);
  }

  componentDidMount() {
    this.reloadInformation();
    this.triggerCaseDownloadInSW();
    //Enable tooltip
    setTimeout(function () {
      this.$('[data-toggle="tooltip"]').tooltip();
    }, 0);
  }

  /**
   * Trigger a case download with the service worker in the background.
   *
   * @async
   * @function triggerCaseDownloadInSW
   * @throws {Error} Throws an error if the response to the download request does not contain a valid path.
   * @returns {Promise<void>} A Promise that resolves after successfully downloading and loading the case.
   */
  async triggerCaseDownloadInSW() {
    try {
      const caseId = getCaseIdFromUrl(this.props.location.pathname);
      const { data } = await Axios.get(`/apiv3/tps/download?case_id=${caseId}`);
      downloadFileWithSinglePart(data);
    } catch (e) {
      console.error('Error when trying to trigger a download in the background: ', e);
    }
  }

  UNSAFE_componentWillMount() {
    const pathname = this.props.location.pathname.split('/');
    const case_id = getCaseIdFromUrl(this.props.location.pathname);
    const sf_case_id_regex = /[A-Z0-9]{5,6}-DE$|[A-Z0-9]{5,6}-R$/;
    if (sf_case_id_regex.test(case_id)) {
      pathname.pop();
      pathname.push(convertAliasToCaseId(case_id));
      this.props.history.replace(pathname.join('/'));
    }
  }

  isValidURLToDoctorAndCase = (data) => {
    const doctor_id_from_route = parseInt(getDoctorsIdFromRoute());
    const doctor_id_from_case = data && data.doctor && data.doctor.id ? parseInt(data.doctor.id) : -1;
    const account_id_from_case = data && data.dso_doctor && data.dso_doctor.account_link_id ? parseInt(data.dso_doctor.account_link_id) : -2;
    const allowable_doctor_ids = data.allowed_ids;
    const allowed_ids = allowable_doctor_ids.filter((allowed_id) => parseInt(allowed_id['id']) === parseInt(doctor_id_from_route));

    return allowed_ids.length > 0 || doctor_id_from_route === doctor_id_from_case || doctor_id_from_route === account_id_from_case;
  };

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();

    if (this.props.history && this.props.history.location && this.props.history.location.state) {
      const { selectedTab, ireq_id, caselist_filter } = this.props.history.location.state;
      if (selectedTab) this.setState({ selected_tab: selectedTab });
      if (caselist_filter) this.setState({ caselist_filter: caselist_filter });
      if (ireq_id) this.setState({ scroll_id: ireq_id });

      this.props.history.replace({ state: null });
    }

    if (this.props.history && this.props.history.location && this.state.selected_tab === 'cases') {
      if (this.props.history.location.pathname.includes('/item/')) {
        this.setState({ selected_tab: 'item' });
      } else if (this.props.history.location.pathname.includes('/progress/')) {
        this.setState({ selected_tab: 'progress' });
      } else if (this.props.history.location.pathname.includes('/note/')) {
        this.setState({ selected_tab: 'note' });
      }
    }

    if (this.state.scroll_id && document.getElementById(this.state.scroll_id)) {
      window.scrollTo(0, document.getElementById(this.state.scroll_id).offsetTop - 65);
      this.setState({ scroll_id: '' });
    }
  }

  /**
   * Reloads all the case detail information
   * @function
   */
  reloadInformation() {
    setTokenHeader();
    const that = this;
    const case_id = getCaseIdFromUrl(that.props.history.location.pathname);
    const doctor_id = getDoctorsIdFromRoute();
    this.props.fetchCaseFileData(case_id, true);

    Axios.get(`/apiv3/keyval?key=referral_code_url`)
      .then(function (res) {
        if (res.data.data.length > 0 && res.data.data[0].current_ind) {
          that.setState({
            referral_code_url: res.data.data[0].value,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    Axios.get(`/apiv3/doctor/${doctor_id}`)
      .then(function (res) {
        that.setState({
          doctor_account_role: res.data.doctor[0].role,
          doctor_account_id: res.data.doctor[0].id,
          doctor_account_referral: res.data.doctor[0].referral,
          doctor_first_name: res.data.doctor[0].user__first_name,
          doctor_last_name: res.data.doctor[0].user__last_name,
          navigator_beta_tester: res.data.doctor[0].navigator,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    //New API
    ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        if (!that.isValidURLToDoctorAndCase(res.data)) {
          that.setState({
            error: true,
          });
          return;
        }

        let draft_progress_id = '';
        if (res.data.progress_record.length > 0) {
          const draft_progress_records = res.data.progress_record.filter(function (w) {
            return w.status.includes('Draft');
          });
          draft_progress_id = draft_progress_records.length > 0 ? draft_progress_records[0].progress_id : '';
        }

        const draft_de_case = res.data.cases.filter(function (c) {
          return c.status_code === 'STATUS_DRAFT_DE';
        });
        const referral_code_array = res.data.cases.filter(function (c) {
          return c.referral_code && c.referral_code.length > 0;
        });
        let patientReferralCode = that.state.patientReferralCode;
        if (referral_code_array.length > 0) {
          patientReferralCode = referral_code_array[0].referral_code;
        }

        let attribution_response = res.data.attribution_response;
        if (res.data.cases && res.data.cases.length > 0 && res.data.cases[0]['submission_date']) {
          attribution_response = that.isAttributionRequired(res.data.cases[0]['submission_date'], res.data.attribution_response, case_id);
          that.isAttributionAllowed(res.data.cases[0]['submission_date']);
        }

        attribution_response = this.clearAttributionWarnings(res.data.attribution_response);
        const draft_case_id = draft_de_case.length > 0 ? draft_de_case[0].case_id : '';
        that.setState({
          loading: false,
          doctor_id: res.data.doctor.id,
          case_details: res.data,
          draft_progress_id: draft_progress_id,
          draft_case_id: draft_case_id,
          patientReferralCode: patientReferralCode,
          attributions: attribution_response,
          assigned_doctor_gen2: res.data.doctor.gen_2,
          user_role: res.data.role,
          is_admin_edit: res.data.is_admin_edit,
          treatment_workflow: res.data.treatment_workflow,
          navigator_opted_in_before: res.data.treatment_workflow_opted_in_before,
          smile_id: res.data.smile_id,
          initial_attributions: _.cloneDeep(JSON.stringify(attribution_response)),
        });

        if (res.data.dso_doctor) {
          that.props.fetchDsoDoctorData(res.data.dso_doctor.account_link_id);
        }

        setTimeout(function () {
          scrollToTop();
          this.$('[data-toggle="tooltip"]').tooltip();
        }, 0);

        return res;
      })
      .then((caseDetails) => {
        const caseBase = caseDetails.data.cases.filter((singleCase) => singleCase.case_id === caseDetails.data.base_id)[0];
        const createdDate = caseBase.case_statuses.filter((status) => status.case_status === 'Case Submitted')[0].created_date;
        Axios.get('/apiv3/attributionquestions', {
          params: {
            release_date__lt: createdDate,
          },
        })
          .then((res) => {
            if (res && res.data && res.data.attribution_questions) {
              this.setState({ attribution_questions: res.data.attribution_questions });
            }
          })
          .catch(function (err) {
            handleHttpRequestError(err, that);
          });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  onBppIrLinkClick = (event) => {
    this.props.history.push({
      pathname: `/business/portal/case/${this.getLastCompletedCaseId()}/item`,
      state: { selectedReqId: event.currentTarget.dataset.id },
    });
  };

  onDoctorsUploadClick = (event) => {
    if (event && event.target && event.target.dataset && event.target.dataset.caseid) {
      let path = this.props.history.location.pathname;
      let redirectPath = getRootPath(path);
      let that = this;

      if (redirectPath) {
        this.props.history.push(`${redirectPath}/upload/${event.target.dataset.caseid}`);
      } else {
        Axios.get('/apiv3/doctor').then(function (result) {
          if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
            that.props.history.push(`/portal/${result.data.doctor[0].id}/upload/${event.target.dataset.caseid}`);
          }
        });
      }
    }
  };

  onTabSelection = (event) => {
    let tab = event.target.dataset.tab;
    this.selectUpdateTabSelection(tab);
  };

  selectUpdateTabSelection = (tab) => {
    const caseid = getCaseIdFromUrl(this.props.history.location.pathname);
    this.setState({ selected_tab: tab });
    if (tab === 'cases') {
      tab = 'case';
    }

    if (this.state.doctor_account_role.includes('DSO')) {
      this.props.history.push(`/portal/${this.state.doctor_account_id}/${tab}/${caseid}`);
    } else {
      this.props.history.push(`/portal/${this.state.doctor_id}/${tab}/${caseid}`);
    }
  };

  /**
   * Check attribution error
   * @function
   * @param {Object} attribution - Attribution question object
   */
  checkAttributionError = (attributions) => {
    let updated_attributions = attributions;
    updated_attributions.forEach((question_set) => {
      const attributions_error = question_set.required && question_set.question_response && question_set.question_response.length === 0;
      const attribution_other_error =
        question_set.required && question_set.question_response && question_set.question_response.includes('Other') && !question_set.other_response;

      question_set['attributions_error'] = attributions_error;
      question_set['attribution_other_error'] = attribution_other_error;
    });
    return updated_attributions;
  };

  /**
   * Check if attribution questions are required for the  case
   * @function
   * @param {string} submission_date - case submission date
   * @param {Object} attributions - Object of attribution questions contains question information
   * @param {String} case_id - Case id
   * @returns {Object} - New attribution object with the required data
   */
  isAttributionRequired = (submission_date, attributions, case_id) => {
    let new_attributions = this.getAttributionsFromQuestionSet(attributions, case_id);
    const case_start_date = new Date(submission_date);
    if (new_attributions) {
      new_attributions.forEach((question_set) => {
        const attributions_release_date = question_set.release_date ? new Date(question_set.release_date) : null;
        const is_question_set_empty = question_set && question_set.question_response ? question_set.question_response.length === 0 : true;
        if (attributions_release_date ? attributions_release_date.getTime() < case_start_date.getTime() : true) {
          question_set['required'] = true;
        } else if (!is_question_set_empty) {
          question_set['required'] = true;
        } else {
          question_set['required'] = false;
        }
      });
    }
    return new_attributions;
  };

  /**
   * Check if attribution questions are allowed
   * @function
   * @param {Object} current_attributions - current attribution object
   * @param {String} case_id - Case id
   * @return {Object} - New attribution created from attribution question set
   */
  getAttributionsFromQuestionSet = (current_attributions, case_id) => {
    let new_attributions = current_attributions;
    const categories = [...new Set(this.state.attribution_questions.map((q) => q.category))];
    categories.forEach((category) => {
      const category_exists = new_attributions.find((question_set) => question_set.question_category === category);
      if (!category_exists) {
        const release_date = this.state.attribution_questions.find((question) => question.category === category).release_date;
        let new_category = {
          case_id: case_id,
          other_response: null,
          question_category: category,
          question_response: [],
          release_date: release_date,
        };
        new_attributions.push(new_category);
      }
    });
    return new_attributions;
  };

  /**
   * Check if attribution questions are allowed
   * @function
   * @param {string} submission_date - case submission date
   */
  isAttributionAllowed = (submission_date) => {
    const earliest_date = new Date('07/05/2021');
    const case_start_date = new Date(submission_date);

    this.setState({ is_attribution_allowed: earliest_date.getTime() < case_start_date.getTime() });
  };
  /**
   * Opens a new window to the setup viewer
   * @function
   * @param {Object} case_details - contains the case details
   */
  onSetupClick = (case_details) => {
    const { case_id, is_wasm_viewer_enabled } = case_details;
    const setupURL = is_wasm_viewer_enabled ? `/smile_design/${case_id}` : `/setup/${case_id}`;
    if (this.shouldTriggerNavigationBlock()) {
      this.showUnsavedNoteModal(setupURL);
    } else {
      window.open(setupURL, '_top');
    }
  };

  /**
   * Handles when user approves the setup
   * @function
   * @param {Object} event - event handler when button is clicked
   */
  onApproveClick = (event) => {
    const that = this;

    Axios.post(`/apiV2/caseaction/${this.state.case_details.case_id}/approve`)
      .then((res) => {
        that.setState({
          case_details: res.data,
          showApproveSetupModal: false,
        });
        // Email doctor after setup approval
        if (that.state.case_details.case_id.toUpperCase().includes('-DE')) {
          Axios.post(
            `/api/email/?slug=setup-approval-email-de-1&caseId=${removeCaseIdInitialNumber(that.state.case_details.case_id)}&method=standard&provider=${
              window.location.origin
            }`
          );
        } else {
          Axios.post(
            `/api/email/?slug=setup-approval-email-case-1&caseId=${removeCaseIdInitialNumber(that.state.case_details.case_id)}&method=standard&provider=${
              window.location.origin
            }`
          );
        }

        //For updating navigation menu alerts
        that.props.history.push({ state: { refreshInfo: 'true' } });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            showApproveSetupModal: false,
          });
        }
      });
  };

  onRejectAccept = (event) => {
    const that = this;
    if (this.state.rejectMessage) {
      let form_data = new FormData();
      form_data.append('status_comment', this.state.rejectMessage);

      Axios.post(`/apiV2/caseaction/${this.state.case_details.case_id}/revision`, form_data)
        .then((res) => {
          that.setState({
            case_details: res.data,
            rejectModal: false,
          });

          //For updating navigation menu alerts
          that.props.history.push({ state: { refreshInfo: 'true' } });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              rejectModal: false,
            });
          }
        });
    } else {
      document.getElementById('setup-revise-message').classList.remove('warning-hide');
    }
  };
  /**
   * Returns the matched case_id
   * @function
   * @param {String} case_id - The case id to be looked up
   */
  getPlanDetails = (case_id) => {
    for (let i = 0; i < this.state.case_details.cases.length; i++) {
      if (this.state.case_details.cases[i].case_id === case_id) {
        return {
          case_id: case_id,
          patient_first_name: this.state.case_details.patient.first_name,
          patient_last_name: this.state.case_details.patient.last_name,
          patient_sex: this.state.case_details.patient.sex,
          patient_dob: this.state.case_details.patient.dob,
          doctor_first_name: this.state.case_details.doctor.first_name,
          doctor_last_name: this.state.case_details.doctor.last_name,
          treatment_plan: this.state.case_details.cases[i].submission_process.treatment_plan,
          treatment_arch: this.state.case_details.cases[i].submission_process.treatment_arch,
          treatment_plan_files: this.props.case_file_list[case_id].submission_process.treatment_plan_files,
          de_plan_files: this.props.case_file_list[case_id].submission_process.de_plan_files,
          treatment_plan_revision: this.state.case_details.cases[i].submission_process.treatment_plan_revision,
        };
      }
    }

    return {
      case_id: '',
      patient_first_name: '',
      patient_last_name: '',
      doctor_first_name: '',
      doctor_last_name: '',
      treatment_plan: '',
      treatment_arch: '',
    };
  };
  /**
   * Handles the track shipment button event for a case and sets the state to open the modal
   * @function
   * @param {Object} event - The event object
   */
  onTrackShipmentModalClick = (event) => {
    const case_id = event.target.dataset.case_id;
    const tracking_info = event.target.dataset.tracking_info;
    const shipping_address = event.target.dataset.shipping_address;
    const tracking_url = event.target.dataset.tracking_url;
    const hand_delivery_ind = event.target.dataset.hand_delivery_ind;

    this.setState({
      showTrackShipmentModal: true,
      selected_tracking: {
        case_id: case_id,
        tracking_info: tracking_info,
        shipping_address: shipping_address,
        tracking_url: tracking_url,
        hand_delivery_ind: hand_delivery_ind === 'true' ? true : false,
      },
    });
  };

  /**
   * Handles the track shipment button event for an item request and sets the state to open the modal
   * @function
   * @param {Object} event - The event object
   */
  onTrackIrShipmentModalClick = (event) => {
    const ireq_id = event.target.dataset.ireq_id;
    const tracking_info = event.target.dataset.tracking_info;
    const shipping_address = event.target.dataset.shipping_address;
    const tracking_url = event.target.dataset.tracking_url;
    const hand_delivery_ind = event.target.dataset.hand_delivery_ind;
    this.setState({
      showTrackShipmentModal: true,
      selected_tracking: {
        case_id: ireq_id,
        tracking_info: tracking_info,
        shipping_address: shipping_address,
        tracking_url: tracking_url,
        hand_delivery_ind: hand_delivery_ind === 'true' ? true : false,
      },
    });
  };

  onTrackShipmentModalDismiss = (event) => {
    this.setState({ showTrackShipmentModal: false });
  };

  onApproveSetupModalClick = (event) => {
    this.setState({ showApproveSetupModal: true });
  };

  onApproveSetupModalDismiss = (event) => {
    this.setState({ showApproveSetupModal: false });
  };

  onModalClickOutsideViewer = (event) => {
    let clientX = event.clientX;
    let clientY = event.clientY;
    let element = document.getElementsByClassName('modal-dialog');

    if (
      clientX < element[0].offsetLeft ||
      clientY < element[0].offsetTop ||
      clientX > element[0].offsetLeft + element[0].offsetWidth ||
      clientY > element[0].offsetTop + element[0].offsetHeight
    ) {
      element[0].children[0].children[0].children[0].click();
    }
  };
  /**
   * Handles setting the state on the onclick for TxInstruction
   *
   * @function
   * @param {event} event - The onClick event from the DOM
   */
  onTxInstructionViewerModalClick = (event) => {
    const target = event.target.dataset.caseid ? event.target : event.currentTarget;
    const id = target.dataset.id;
    const file_type = target.dataset.file_type;
    const upload_data = target.dataset.upload_data;
    const case_id = target.dataset.caseid;

    this.setState({
      showTxInstructionViewerModal: true,
      selected_tx: [{ id: id, file_type: file_type, upload_data: upload_data }],
      selected_case_id: case_id,
    });
  };

  onTxInstructionViewerModalDismiss = (event) => {
    this.setState({ showTxInstructionViewerModal: false });
  };
  /**
   * Handles setting the state on the onclick for VBPC
   *
   * @function
   * @param {event} event - The onClick event from the DOM
   */
  onVBPCViewerModalClick = (event) => {
    const target = event.target.dataset.caseid ? event.target : event.currentTarget;
    const id = target.dataset.id;
    const file_type = target.dataset.file_type;
    const upload_data = target.dataset.upload_data;
    const gen_2 = target.dataset.gen_2;
    const case_id = target.dataset.caseid;
    const post_afg = target.dataset.post_afg;

    this.setState({
      showVBPCViewerModal: true,
      selected_vbpc: [{ id: id, file_type: file_type, upload_data: upload_data, gen_2: gen_2, post_afg: post_afg }],
      selected_case_id: case_id,
    });
  };

  onVBPCViewerModalDismiss = (event) => {
    this.setState({ showVBPCViewerModal: false });
  };

  onRejectClick = (event) => {
    this.setState({
      rejectModal: true,
    });
  };

  onRejectDismiss = (event) => {
    this.setState({
      rejectModal: false,
    });
  };

  onRejectMsgChange = (event) => {
    this.setState({
      rejectMessage: event.target.value,
    });
  };

  /**
   * Get the PDF URL from treatment plan details.
   *
   * @param {Object} planDetails - The treatment plan details.
   * @param {string} planDetails.treatment_plan_revision - The revision of the treatment plan.
   * @param {Array} planDetails.treatment_plan_files - The files related to the treatment plan.
   * @param {Array} planDetails.de_plan_files - The files related to the de plan.
   * @returns {string} The PDF URL if found, or an empty string if not found.
   */
  getTreatmentPlanPdfURL = (planDetails) => {
    const { treatment_plan_revision, treatment_plan_files, de_plan_files } = planDetails;
    let pdfUrl = '';
    let uploadData = '';

    if (['TX2.0', 'TX3.0'].includes(treatment_plan_revision)) {
      uploadData = treatment_plan_files.length > 0 ? treatment_plan_files[0].upload_data : '';
    } else if (['DE2.0', 'DE3.0'].includes(treatment_plan_revision)) {
      uploadData = de_plan_files.length > 0 ? de_plan_files[0].upload_data : '';
    }

    if (uploadData) {
      pdfUrl = getPdfURL(uploadData);
    }

    return pdfUrl;
  };
  /**
   * Handles setting the state on the onclick for treatment plan
   *
   * @function
   * @param {event} event - The onClick event from the DOM
   */
  onTreatmentModalClick = (event) => {
    const target = event.target.dataset.caseid ? event.target : event.currentTarget;
    const caseId = target.dataset.caseid;
    const planDetails = this.getPlanDetails(caseId);
    const pdfUrl = this.getTreatmentPlanPdfURL(planDetails);
    const isMobile = isMobileDevice();
    const newState = {
      selected_plan_details: planDetails,
    };

    //On Mobile, open PDF in new tab, otherwise show modal
    if (isMobile && pdfUrl) {
      const token = getJWTToken();
      const header = { Authorization: 'Token ' + token };
      openPdfNewTab(pdfUrl, header);
    } else {
      newState.showTreatmentModal = true;
    }

    this.setState(newState);
  };

  onTreatmentModalDismiss = (event) => {
    this.setState({ showTreatmentModal: false });
  };

  onRedirectItemRequest = (event) => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;
    let case_id = getLastShippedCaseIdFromCaseDetail(this.state.case_details, event.currentTarget.dataset.caseid);

    if (redirectPath) {
      this.props.history.push(`${redirectPath}/itemrequest?caseid=${case_id}`);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}/itemrequest?caseid=${case_id}`);
        }
      });
    }
  };

  onRedirectCsq = (event) => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;
    let case_id = event.currentTarget.dataset.caseid;

    if (redirectPath) {
      this.props.history.push(`${redirectPath}/csqsubmit?caseid=${case_id}`);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}/csqsubmit?caseid=${case_id}`);
        }
      });
    }
  };

  onRedirectProgressRecord = (event) => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;
    let case_id = event.currentTarget.dataset.caseid;

    if (redirectPath) {
      this.props.history.push(`${redirectPath}/prssubmit?caseid=${case_id}`);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}/prssubmit?caseid=${case_id}`);
        }
      });
    }
  };

  /**
   * Starts Progress Record/Clinical Support Request submission process
   *
   * @function
   * @param {Object} event - Contains the event handler values when event is trigger
   */
  onRedirectCsr = (event) => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;
    let case_id = event.currentTarget.dataset.caseid;
    let progress_id = event.currentTarget.dataset.progressid;

    if (progress_id) {
      if (redirectPath) {
        that.props.history.push(`${redirectPath}/csrsubmit?caseid=${case_id}&progress_id=${progress_id}`);
      } else {
        Axios.get('/apiv3/doctor').then(function (result) {
          if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
            that.props.history.push(`/portal/${result.data.doctor[0].id}/csrsubmit?caseid=${case_id}&progress_id=${progress_id}`);
          }
        });
      }
    } else {
      if (redirectPath) {
        this.props.history.push(`${redirectPath}/csrsubmit?caseid=${case_id}`);
      } else {
        Axios.get('/apiv3/doctor').then(function (result) {
          if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
            that.props.history.push(`/portal/${result.data.doctor[0].id}/csrsubmit?caseid=${case_id}`);
          }
        });
      }
    }
  };

  /**
   * Redirects the user back to the case list.
   *
   * If there are any filter that was applied before, it will redirect the page
   * with the filter.
   * @function
   * @param {Object} event - Contains the event handler values when event is trigger
   * pass along with the history
   */
  onRedirectDigitalEnhancement = (event) => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;
    let case_id = event.currentTarget.dataset.caseid;

    let state = { caselist_filter: this.state.caselist_filter ? this.state.caselist_filter : '' };

    if (redirectPath) {
      if (this.state.case_details.dso_doctor) {
        this.props.history.push(`${redirectPath}/digital/overview?caseid=${case_id}&doctorid=${this.state.case_details.dso_doctor.id}`, state);
      } else {
        this.props.history.push(`${redirectPath}/digital/overview?caseid=${case_id}`);
      }
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          if (this.state.case_details.dso_doctor) {
            this.props.history.push(
              `/portal/${result.data.doctor[0].id}/digital/overview?caseid=${case_id}&doctorid=${this.state.case_details.dso_doctor.id}`,
              state
            );
          } else {
            that.props.history.push(`/portal/${result.data.doctor[0].id}/digital/overview?caseid=${case_id}`);
          }
        }
      });
    }
  };

  getShippingInfo = (caseid) => {
    let result = '';

    for (let i = 0; i < this.state.cases.length; i++) {
      if (caseid === this.state.cases[i].case_id) {
        result = this.state.cases[i].case_shipping_address.addr_all;
      }
    }

    return result;
  };

  getLatestCaseStatusCode() {
    return this.state.case_details.cases[this.state.case_details.cases.length - 1]['status_code'];
  }

  /**
   * Returns the previous non-blocking case status of the case
   * @function
   * @param {Object} cases - A single case's detail
   * @return {String} The previous case status
   */
  getLatestNonBlockingCaseStatusCode(cases) {
    const blocking_statuses = [
      'Uncancelled',
      'Cancelled',
      'STATUS_CANCEL',
      'STATUS_UNCANCEL',
      'Hold',
      'Unhold',
      'STATUS_HOLD',
      'STATUS_UNHOLD',
      'Doctor Provide Clarification',
      'Doctor Clarification Resolved',
    ];
    let nonblocking_statuses = _.cloneDeep(cases.case_statuses).filter((cases) => {
      return blocking_statuses.indexOf(cases.case_status) === -1;
    });
    if (nonblocking_statuses && nonblocking_statuses[nonblocking_statuses.length - 1]) return nonblocking_statuses[nonblocking_statuses.length - 1].case_status;

    return cases.status_code;
  }

  getInitialCaseStatusCode() {
    return this.state.case_details.cases[0]['status_code'];
  }

  hasAccessToBusinessPortal() {
    return _.intersection(getBusinessRoleList(), [this.state.case_details.role]).length > 0;
  }

  sortFilesByFileType(files) {
    const sort_order = ['photos', 'xrays', 'upperscans', 'lowerscans'];
    let files_order = [];

    for (let i = 0; i < sort_order.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].file_type === sort_order[i]) {
          files_order.push(files[j]);
        }
      }
    }

    return files_order;
  }

  isApprovedIr(item_request) {
    let is_approved_item_request = false;
    let current_request_status = [];
    this.state.case_details.item_request_status.forEach((status) => {
      if (status.ireq_id === item_request.ireq_id) {
        current_request_status.push(status);
      }
    });
    current_request_status.forEach((s) => {
      if (s.status === 'INBRACE Approved Item Request' || s.status === 'InBrace Approved Item Request') {
        is_approved_item_request = true;
      }
    });
    return is_approved_item_request;
  }

  onDownloadInvoice = (event) => {
    this.setState({ showViewIrInvoice: true, selected_request_id: event.target.dataset.id });
  };
  onViewIrInvoicDismiss = (event) => {
    this.setState({
      showViewIrInvoice: false,
    });
    modalClear();
  };
  /**
   * Set the state for the modal that is popping up
   *
   * @function
   * @param {object} event - Event of the onclick
   */
  onCSQFormClick = (event) => {
    this.setState({
      showViewPrCsq: true,
      selected_progress_id: event.currentTarget.dataset.id,
      selected_case_id: event.currentTarget.dataset.caseid,
      selected_progress_ind: event.currentTarget.dataset.index,
    });
  };
  onViewPrCsqDismiss = (event) => {
    this.setState({
      showViewPrCsq: false,
    });
    modalClear();
  };
  /**
   * Displays progress record data
   *
   * @function
   * @param {object} progress_record - Progress record object
   * @param {int} record_number - Count of PR/CSR
   * @return {JSX} returns jsx
   */
  displayPRSummary(progress_record, record_number) {
    let csq_data = progress_record.csq_data;
    let chief_concern = '';
    let treatment_notes = '';
    let upper_smartwire = '';
    let lower_smartwire = '';

    try {
      if (csq_data && progress_record.revision_id && progress_record.revision_id === 'CSR1.0') {
        csq_data = JSON.parse(progress_record.csq_data);

        treatment_notes = csq_data.questions.filter(function (a) {
          return a.friendly_name === 'treatment_notes';
        })[0].answer_text;
        upper_smartwire = csq_data.questions
          .filter(function (q) {
            return q.friendly_name === 'smartwire_type';
          })[0]
          .multiple_question.filter(function (q) {
            return q.friendly_name === 'upper_smartwire_type';
          })[0].answer_text;
        lower_smartwire = csq_data.questions
          .filter(function (q) {
            return q.friendly_name === 'smartwire_type';
          })[0]
          .multiple_question.filter(function (q) {
            return q.friendly_name === 'lower_smartwire_type';
          })[0].answer_text;
      } else if (csq_data && progress_record.revision_id === 'CSQ1.0') {
        csq_data = JSON.parse(progress_record.csq_data);
        chief_concern = csq_data.questions[0].answer_text;
      }

      return (
        <>
          <CollapsibleTriangleContainer collapsedHeight={265} containerClassName="case-detail__summary">
            <div className="case-detail__row case-detail__row--inner">
              <div className="case-detail__col">
                <h3>Summary</h3>
                {progress_record.record_type === 'CSQ' && progress_record.revision_id === 'CSQ1.0' && (
                  <>
                    <div className="medium">Chief Concern</div>
                    {chief_concern}
                  </>
                )}

                {progress_record.revision_id === 'CSQ1.0' && (
                  <>
                    <div className="medium">Date progress records were taken</div>
                    {this.getPRDateTaken(progress_record)}
                  </>
                )}

                {progress_record.revision_id === 'CSR1.0' && (
                  <>
                    <div className="medium">Treatment Notes</div>
                    {treatment_notes ? treatment_notes : 'N/A'}
                    <div className="medium">Current Upper Smartwire</div>
                    {upper_smartwire}
                    <div className="medium">Current Lower Smartwire</div>
                    {lower_smartwire}
                    <div className="medium">Date Progress Records Were Taken</div>
                    {this.getPRDateTaken(progress_record)}
                  </>
                )}
              </div>
              <div className="case-detail__col">
                <h3>Records</h3>
                <ThumbnailURLInterface
                  files={progress_record.files}
                  images={configLightBox(progress_record.files)}
                  multinail={`_${progress_record.progress_id}`}
                  hide_occlusion={true}
                  fit
                />
              </div>
              {(progress_record.record_type === 'CSQ' || progress_record.record_type === 'CSR') && (
                <div className="case-detail__col">
                  <h3>Documents</h3>
                  <button
                    key="pr-form"
                    className="case-detail__document-link"
                    data-id={progress_record.progress_id}
                    data-caseid={progress_record.case_id}
                    data-index={record_number}
                    onClick={this.onCSQFormClick}
                  >
                    <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                    Clinical Support Request Form
                  </button>
                </div>
              )}
            </div>
          </CollapsibleTriangleContainer>
          <div className="case-detail__row case-detail__row--no-border">
            {progress_record.record_type === 'CSR' ? this.displayPRCSRStatus(progress_record) : this.displayPRHistory(progress_record)}
          </div>
        </>
      );
    } catch {}
  }

  displayPRCSRStatus(progress_record) {
    const prItems = [
      {
        title: 'Progress Record and CSR Submitted',
        isCompleted: true,
        status: 'Submitted',
        date: this.getPRSubmissionDate(progress_record),
      },
      {
        title: 'CSR Review Process',
        isCompleted: progress_record.status === 'Request Closed',
        status: progress_record.status === 'Request Closed' ? 'Closed' : 'In Progress',
        date: progress_record.status === 'Request Closed' ? this.getPRClosedDate(progress_record) : '',
      },
    ];
    return (
      <>
        <h3>Status</h3>
        <CaseProgress items={prItems} />
      </>
    );
  }

  displayPRHistory(progress_record) {
    const history = [
      {
        date: this.getPRSubmissionDate(progress_record),
        status: 'Progress Record Submitted',
      },
    ];
    return (
      <>
        <h3>Progress Record History</h3>
        <CaseHistory items={history} />
      </>
    );
  }

  /**
   * Displays Ir Reasons
   * @param {Array} request_reasons
   * @param {Array} arch_segments_options
   * @returns {JSX} Reason list elements
   */
  displayRequestReasons = (request_reasons, arch_segments_options, is_historical_single_tooth_idb) => {
    return request_reasons.map((reason) => {
      const new_idb_properties = (hasSingleToothSelection(reason) || hasArchSegmentSelection(reason)) && !is_historical_single_tooth_idb;
      if (reason.booleanValue) {
        if (!new_idb_properties) {
          return (
            <li key={reason.id}>
              {reason.name_label}
              {': '}
              {reason.segment_preference_text ? reason.segment_preference_text.replace(':', '') : reason.label.replace(':', '')}
              {reason.selectedTeeth && reason.selectedTeeth.length > 0 ? ' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')' : null}
              {reason.segment_preference === 'arch_segments' && arch_segments_options.length > 0 && ' (' + arch_segments_options.join(', ') + ')'}
              {reason.value ? ` - "${reason.value}"` : null}
            </li>
          );
        } else {
          return (
            <React.Fragment key={reason.id}>
              {hasArchSegmentSelection(reason) ? (
                <li>
                  {`${reason.name_label}: ${reason.arch_segment_selection_text}`} {' (' + arch_segments_options.join(', ') + ')'}
                </li>
              ) : null}
              {hasSingleToothSelection(reason) ? (
                <li>
                  {`${reason.name_label}: ${reason.single_tooth_selection_text}`} {' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')'}
                </li>
              ) : null}
            </React.Fragment>
          );
        }
      }
    });
  };

  /**
   * Displays the Item Request Process from end to end
   * @param {Object} item_request - contains the set of data used to parse out
   * details about the entire item request.
   * @function
   */
  displayIRSummary(item_request) {
    const ITEM_PART_LOOKUP = getLookup(item_request.ireq_id); // nomenclature lookup
    let request_reasons = JSON.parse(item_request.ireq_reason);
    const shipping_address = item_request.delivered_address
      ? item_request.delivered_address
      : this.getShippingAddressFromAddressId(item_request.shipping_address__id);
    const item_parts_quantity = this.state.case_details.item_request_details.filter((detail) => detail.ireq_id === item_request.ireq_id);
    const isDE = item_request.ireq_id && (item_request.ireq_id.indexOf('-DE') >= 0 || item_request.ireq_id.indexOf('-R') >= 0);
    const arch_segments_options = [];
    request_reasons.forEach(function (reason) {
      if (reason.arch_segments_options) {
        reason.arch_segments_options.forEach((option) => {
          if (option.active) arch_segments_options.push(option.title);
        });
      }
      // Overtime, the code below won't be necessary since we are recording with the right verbiage now
      // These labels come from the database, and shouldn't be necessary if we update every row
      // Since that would be too heavy, we update only on the frontend
      if (item_request.gen_2) {
        if (isDE) {
          reason.label = reason.label.replace('Initial', 'Original DE');
          reason.name_label = reason.name_label.replace('IDB Required', 'IDB Tray Required');
        } else {
          reason.label = reason.label.replace('Original', 'Initial');
        }
        reason.label = reason.label.replace('Upper', 'Upper Initial').replace('Lower', 'Lower Initial');
      }
    });
    return (
      <div className="case-detail__row case-detail__row--flex">
        {!item_request.historical ? (
          <>
            <div className="case-detail__col">
              <h3>Item Request Summary</h3>
              <div className="medium">Reason for Item Request</div>
              <ul>{this.displayRequestReasons(request_reasons, arch_segments_options, item_request.is_historical_single_tooth_idb)}</ul>
              <div className="medium">Item (Quantity)</div>
              <ul>
                {item_parts_quantity.map(
                  (item_part) =>
                    item_part.quantity > 0 && (
                      <li key={item_part.part_id}>
                        {ITEM_PART_LOOKUP[item_part.part_id]} ({item_part.quantity})
                      </li>
                    )
                )}
              </ul>
            </div>
            {this.isApprovedIr(item_request) && !item_request.historical && (
              <div className="case-detail__col">
                <h3>Documents</h3>
                <div>
                  <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                  <button key="ir-form" className="case-detail__document-link" data-id={item_request.ireq_id} onClick={this.onDownloadInvoice}>
                    Item Request Form
                  </button>
                </div>
                {item_request.hand_delivery_ind ||
                (item_request.tracking_info &&
                  item_request.tracking_info !== '' &&
                  item_request.tracking_info !== 'NA' &&
                  item_request.tracking_info !== 'N/A') ? (
                  <div>
                    <FontAwesomeIcon className="shrink--icon" icon={['fas', 'truck']} />
                    <button
                      key="ir-tracking"
                      className="case-detail__document-link"
                      data-ireq_id={item_request.ireq_id}
                      data-tracking_info={item_request.tracking_info}
                      data-shipping_address={getShippingAddress(shipping_address)}
                      data-tracking_url={item_request.tracking_url}
                      data-hand_delivery_ind={item_request.hand_delivery_ind}
                      onClick={this.onTrackIrShipmentModalClick}
                    >
                      Track Shipment
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div className="case-detail__col">
            <span className="emphasis">{this.getIRSubmissionDate(item_request)} </span>
            Item Request Submitted (Historical Record)
          </div>
        )}
      </div>
    );
  }

  getIrShipStatusInformation(item_request) {
    const { item_request_status } = this.state.case_details;
    for (let s of item_request_status) {
      if (s.ireq_id === item_request.ireq_id && s.ireq_status === 'Item Request Shipped') {
        return { date: convertDate(s.created_date), status: s.ireq_status, isShipped: true };
      }
    }
    return {};
  }

  getIrManufacturingStatusInformation(item_request) {
    const { item_request_status } = this.state.case_details;

    let date = '';
    let status = '';
    let isCompleted = false;
    item_request_status.forEach((s) => {
      if (s.ireq_id === item_request.ireq_id) {
        if (s.status === 'Item Request Shipped') {
          date = s.created_date;
        } else if (
          (s.status === 'INBRACE Manufactured Item Request' || s.status === 'InBrace Fabricated Item Request') &&
          item_request.ireq_status === 'Item Request Shipped'
        ) {
          status = s.status;
          isCompleted = true;
        } else if (
          ((s.status === 'INBRACE Manufactured Item Request' || s.status === 'InBrace Fabricated Item Request') &&
            item_request.ireq_status !== 'Item Request Shipped') ||
          s.ireq_status === 'INBRACE Manufacturing Item Request'
        ) {
          date = s.created_date;
          status = 'INBRACE Manufacturing Item Request';
        }
      }
    });

    return { date: convertDate(date), status, isCompleted };
  }

  getIrHoldStatusInformation(item_request) {
    const { item_request_status } = this.state.case_details;
    const ireq_statuses = _.orderBy(item_request_status, ['created_date'], ['desc']);

    for (let s of ireq_statuses) {
      if (s.ireq_id === item_request.ireq_id && s.ireq_status === 'Item Request On Hold') {
        return { date: convertDate(s.created_date), comment: s.comment };
      }
    }
    return {};
  }

  getIrCancelledStatusInformation(item_request) {
    const { item_request_status } = this.state.case_details;
    const ireq_statuses = _.orderBy(item_request_status, ['created_date'], ['desc']);

    for (let s of ireq_statuses) {
      if (s.ireq_id === item_request.ireq_id && s.ireq_status === 'Item Request Cancelled') {
        return { date: convertDate(s.created_date), comment: s.comment };
      }
    }
    return {};
  }

  getRequestReviewStatusInformation(item_request) {
    const { item_request_status } = this.state.case_details;
    let date = '';
    let status = '';
    let isCompleted = false;
    item_request_status.forEach((s) => {
      if (s.ireq_id === item_request.ireq_id) {
        if (s.status === 'INBRACE Approved Item Request' || s.status === 'InBrace Approved Item Request') {
          date = convertDate(s.created_date);
          status = 'InBrace Approved Request';
          isCompleted = true;
        } else if (s.ireq_status === 'INBRACE Reviewing Request') {
          date = convertDate(s.created_date);
          status = 'InBrace Reviewing Request';
        }
      }
    });

    return { status, date, isCompleted };
  }

  /**
   * Displays the flags only if the case is a initial DSO case
   * @param {Object} cases - Object that contains all the cases detail and
   * related information
   * @function
   */
  displayDSOFlags(cases) {
    return this.isInitialCase(cases.case_id) && this.isDSOCase(cases) ? (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <div className="case-detail__row clearfix no-gutter">
                <div className="col-md-6">
                  <LabelDropdownList
                    id="dental-clearance"
                    label_name={'Dental Clearance'}
                    value={this.getDentalClearanceValue()}
                    readonly={this.isDentalClearanceLocked(cases) || !this.hasDSOFlagPermissions(user_roles_and_permissions)}
                    dropdown_list={[
                      { label: 'Complete', value: 'Complete' },
                      { label: 'Incomplete', value: 'Incomplete' },
                    ]}
                    onConfirmationButtonClick={this.saveDentalClearanceValue}
                  />
                </div>
                <div className="col-md-6">
                  <LabelDropdownList
                    id="financial-status"
                    label_name={'Financial Status'}
                    label_description={'Financial Status must be approved before a Smile Design can be approved.'}
                    value={this.getFinancialStatusValue()}
                    readonly={this.isFinancialStatusLocked(cases) || !this.hasDSOFlagPermissions(user_roles_and_permissions)}
                    dropdown_list={[
                      { label: 'Approved', value: 'Approved' },
                      { label: 'Unapproved', value: 'Unapproved' },
                    ]}
                    onConfirmationButtonClick={this.saveFinancialStatusValue}
                  />
                </div>
              </div>
            </SecurityClearance>
          );
        }}
      </UserPermissionsContext.Consumer>
    ) : null;
  }
  /**
   * Handle on click for treatment workflow banner confirm button
   * @function
   */
  onNavigatorConfirmClicked = (has_permission) => {
    if (has_permission) {
      const that = this;
      const case_id = this.getLatestNonDraftCaseId(this.state.case_details.cases);
      let fdata = new FormData();
      let case_ids = new FormData();
      let opt_in = !this.state.treatment_workflow;
      let data = [opt_in ? 'opt_in' : 'opt_out', this.state.treatment_workflow];
      fdata.append('treatment_workflow', data);

      Axios.post(`/apiV2/caseaction/${case_id}/treatment_workflow_action`, fdata)
        .then(() => {
          if (opt_in) {
            case_ids.append('case_ids', this.getAllCaseIds());
            Axios.put(`/apiv3/case/${case_id}/navigator/opt_in`, case_ids)
              .then((res) => {
                that.setState({ treatment_workflow: opt_in, navigator_additional_actions: false, navigator_opted_in_before: true });
              })
              .catch(function (err) {
                handleHttpRequestError(err, that);
              });
          } else {
            that.setState({ treatment_workflow: opt_in, navigator_additional_actions: false, navigator_opted_in_before: true });
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  /**
   * Get all case ids from case details
   * @function
   * @return {array} Array of case id strings
   */
  getAllCaseIds = () => {
    let case_ids = [];
    if (this.state.case_details && this.state.case_details.cases && this.state.case_details.cases.length > 0) {
      this.state.case_details.cases.forEach((cases) => {
        case_ids.push(cases.case_id);
      });
    }
    return case_ids;
  };

  /**
   * Handle on click for treatment workflow banner close button
   * @function
   */
  onNavigatorHideClicked = () => {
    const that = this;
    let fdata = new FormData();
    let data = ['hide_banner', this.state.treatment_workflow];
    const case_id = this.getLatestNonDraftCaseId(this.state.case_details.cases);
    that.setState({ treatment_workflow: null });
    fdata.append('treatment_workflow', data);
    Axios.post(`/apiV2/caseaction/${case_id}/treatment_workflow_action`, fdata).catch(function (err) {
      handleHttpRequestError(err, that);
    });
  };

  /**
   * display InBrace Treatment Workflow Banner
   * @function
   * @return {JSX} returns banner jsx
   */
  displayNavigatorBanner = (cases) => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => (
          <div>
            <TreatmentNavigatorBanner
              title={'InBrace Navigator'}
              content={
                "InBrace Navigator helps you track each patient's progress through treatment and provides a visual checklist for efficiently scheduling InBrace appointments. Using InBrace Navigator, you will learn how to minimize the number of appointments during treatment and you will gain workflow efficiencies."
              }
              confirm_btn_text={'Get Started'}
              close_btn_text={"Don't show me again"}
              right_img_url={process.env.PUBLIC_URL + '/static/img/Inbrace_Smile.png'}
              onConfirmClicked={() => {
                this.onNavigatorConfirmClicked(cases);
              }}
              onCloseClicked={this.onNavigatorHideClicked}
              has_edit_permission={this.hasAdditionalActionPermissions(user_roles_and_permissions.permissions, user_roles_and_permissions.user_id)}
              onDoubleClick={this.onDoubleClick}
            />
          </div>
        )}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Determing if should display treatment workflow banner
   * @function
   * @return {Boolean} True if is a gen 2.0 case and not a DE and case had not yet opted in, false otherwise
   */
  shouldDisplayNavigatorBanner = (cases) => {
    let case_list_without_draft = this.getAllCasesExludingDraftDe(cases);
    let latestRecord = case_list_without_draft ? case_list_without_draft[case_list_without_draft.length - 1] : false;
    if (latestRecord) {
      const is_shipped = latestRecord.status_code === 'STATUS_SHIP';
      let isOnCaseTab = this.state.selected_tab === 'cases';
      let bannerNotHidden = this.state.treatment_workflow !== null;
      let isDeAndNotShipped = this.isCaseDE(latestRecord) && !is_shipped;
      let isInitialCase = !this.isCaseDE(latestRecord) && is_shipped;
      let notOptedInTreatmentWorkflow = !this.state.treatment_workflow;
      let isGen2 = latestRecord.gen_2;
      let userIsBetaTester = this.state.navigator_beta_tester;
      let notOptedInBefore = !this.state.navigator_opted_in_before;

      return (
        isOnCaseTab && bannerNotHidden && (isDeAndNotShipped || isInitialCase) && notOptedInTreatmentWorkflow && isGen2 && userIsBetaTester && notOptedInBefore
      );
    }
    return false;
  };

  /**
   * Filter out draft de from case list
   * @function
   * @param {Object} cases - List of case and des
   * @return {Object} List of case and des without de draft
   */
  getAllCasesExludingDraftDe = (cases) => {
    return cases.filter((c) => {
      return c.status_code !== 'STATUS_DRAFT_DE';
    });
  };

  /**
   * Determing if should display additional action navigator opt in out btns
   * @function
   * @param {Object} cases - Case information
   * @return {Boolean} True if is a gen 2.0 case and not a DE and case had not yet opted in, false otherwise
   */
  shouldDisplayNavigatorBtns = (cases) => {
    let case_list_without_draft = this.getAllCasesExludingDraftDe(cases);
    let latestRecord = case_list_without_draft ? case_list_without_draft[case_list_without_draft.length - 1] : false;
    if (latestRecord) {
      const is_shipped = latestRecord.status_code === 'STATUS_SHIP';
      let isOnCaseTab = this.state.selected_tab === 'cases';
      let isDeAndNotShipped = this.isCaseDE(latestRecord) && !is_shipped;
      let isDeAndShipped = this.isCaseDE(latestRecord) && is_shipped;
      let isInitialCase = !this.isCaseDE(latestRecord) && is_shipped;
      let isGen2 = latestRecord.gen_2;
      let userIsBetaTester = this.state.navigator_beta_tester;
      let optedInBefore = this.state.navigator_opted_in_before;

      return isOnCaseTab && (isDeAndNotShipped || isInitialCase || (isDeAndShipped && optedInBefore)) && isGen2 && userIsBetaTester;
    }
    return false;
  };

  /**
   * Determing if should display additional action cancel case btn
   * @function
   * @param {Object} cases - Case information
   * @return {Boolean} True if is an active case
   */
  shouldDisplayCancelCaseBtn = (cases) => {
    const case_list = this.getAllCasesExludingDraftDe(cases);
    let latestRecord = case_list ? case_list[case_list.length - 1] : false;
    if (latestRecord) {
      return (
        latestRecord.status_code !== 'STATUS_SHIP' &&
        latestRecord.status_code !== 'STATUS_HOLD' &&
        latestRecord.status_code !== 'STATUS_CANCEL' &&
        latestRecord.status_code !== 'Doctor Provide Clarification'
      );
    }
    return false;
  };

  /**
   * Determing if should display convert to Gen 2.0 btn
   * @function
   * @param {Object} cases - Case information
   * @return {Boolean} True if has no de, case is gen 1, is an active case and doctor has gen 2.0 flag as true
   */
  shouldDisplayConvertGen2Btn = (cases) => {
    let case_list_without_draft = this.getAllCasesExludingDraftDe(cases);
    let latestRecord = case_list_without_draft ? case_list_without_draft[case_list_without_draft.length - 1] : false;
    if (latestRecord) {
      const has_no_de = latestRecord.record_type === 'Initial Submission';
      const case_is_gen_1 = !latestRecord.gen_2;
      const is_active_case =
        latestRecord.status_code !== 'STATUS_SHIP' &&
        latestRecord.status_code !== 'STATUS_HOLD' &&
        latestRecord.status_code !== 'STATUS_CANCEL' &&
        latestRecord.status_code !== 'Doctor Provide Clarification';
      return is_active_case && has_no_de && this.state.assigned_doctor_gen2 && case_is_gen_1;
    }
    return false;
  };

  /**
   * Determing if user can perform additional action
   * @function
   * @param {Array} permissions List of user permissions
   * @param {Int} user_id User id
   * @return {Boolean} True if is a gen 2.0 case and not a DE and case had not yet opted in, false otherwise
   */
  hasAdditionalActionPermissions = (permissions, user_id) => {
    const has_edit_permission = userHasPermission('IPP_EDIT', permissions);
    const has_elevated_permission = userHasPermission('DSO_ADMIN', permissions) || userHasPermission('DSO_DOCTOR_OVERRIDE', permissions);
    const is_assigned_dso_doctor = this.state.case_details.doctor && this.state.case_details.doctor.user_id === user_id;
    return (has_elevated_permission || is_assigned_dso_doctor || this.state.is_admin_edit) && has_edit_permission;
  };

  /**
   * Determing if should display additional action btn
   * @function
   * @return {Boolean} True if  not in folder generation process and has at least one option
   */
  shouldDisplayAdditionalAction = () => {
    const case_status = this.getLatestCaseStatusCode();
    const case_list = this.state.case_details.cases;
    const should_display_navigator_btns = this.shouldDisplayNavigatorBtns(case_list);
    const should_display_cancel_case_btns = this.shouldDisplayCancelCaseBtn(case_list);
    const should_display_convert_gen2_btns = this.shouldDisplayConvertGen2Btn(case_list);
    const not_waiting_folder_generation = case_status !== 'Awaiting Folder Generation Process';
    const not_folder_generation = case_status !== 'Folder Generation Process';
    let isOnCaseTab = this.state.selected_tab === 'cases';

    return (
      not_folder_generation &&
      not_waiting_folder_generation &&
      isOnCaseTab &&
      (should_display_cancel_case_btns || should_display_convert_gen2_btns || should_display_navigator_btns)
    );
  };

  /**
   * Display aditional action drop down list
   * @function
   * @return {JSX} Returns additional actions jsx
   */
  displayAdditionalActionsDropDown = () => {
    let has_edit_permission = false;
    const case_list = this.state.case_details.cases;
    const should_display_navigator_btns = this.shouldDisplayNavigatorBtns(case_list);
    const should_display_cancel_case_btns = this.shouldDisplayCancelCaseBtn(case_list);
    const should_display_convert_gen2_btns = this.shouldDisplayConvertGen2Btn(case_list);

    const addition_btn = (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => (
          <div className="addtional_action_div">
            {(has_edit_permission = this.hasAdditionalActionPermissions(user_roles_and_permissions.permissions, user_roles_and_permissions.user_id))}
            <button className="btn btn--primary additional_action_btn" onClick={this.onAdditionalActionClick}>
              Additional Actions <i className="fa fa-caret-down additional_action_icon" aria-hidden="true" />
            </button>

            <ul
              className={
                this.state.navigator_additional_actions
                  ? has_edit_permission
                    ? 'navigator_dropdown_list'
                    : 'navigator_dropdown_lock'
                  : 'navigator_dropdown_list_hide'
              }
              onMouseLeave={this.onAdditionalActionClick}
            >
              {should_display_navigator_btns ? (
                <li
                  onDoubleClick={this.onDoubleClick}
                  onClick={() => {
                    this.onNavigatorConfirmClicked(has_edit_permission);
                  }}
                >
                  {has_edit_permission ? null : <LockedIcon />}
                  {this.state.treatment_workflow ? 'Opt out InBrace Navigator' : 'Opt into InBrace Navigator'}
                </li>
              ) : null}
              {should_display_cancel_case_btns ? (
                <li onDoubleClick={this.onDoubleClick} disabled={!has_edit_permission} onClick={has_edit_permission ? this.toggleCancelCaseModal : ''}>
                  {has_edit_permission ? '' : <LockedIcon />}
                  Cancel Case
                </li>
              ) : null}
              {should_display_convert_gen2_btns ? (
                <li onDoubleClick={this.onDoubleClick} disabled={!has_edit_permission} onClick={has_edit_permission ? this.toggleConvertGenModal : ''}>
                  {has_edit_permission ? null : <LockedIcon />}
                  Convert to Gen 2.0
                </li>
              ) : null}
            </ul>
          </div>
        )}
      </UserPermissionsContext.Consumer>
    );

    return this.shouldDisplayAdditionalAction() ? addition_btn : null;
  };

  getSelectedSubmissionFiles = (case_id) => {
    return this.props.case_file_list[case_id]?.submission_process?.files ?? [];
  };

  displayCaseSummary(cases) {
    const show_setup_btn = this.shouldShowSetupBtn(cases);
    const submission_files = this.getSelectedSubmissionFiles(cases.case_id);

    return (
      <CollapsibleTriangleContainer collapsedHeight={200} containerClassName="case-detail__summary">
        <div className="case-detail__row case-detail__row--inner">
          <div className="case-detail__col">
            <h3>Case Summary</h3>
            {cases.case_id.indexOf('-') >= 0 && cases.submission_process.treatment_description_2 === '' ? null : (
              <>
                <div className="medium">{cases.case_id.indexOf('-') >= 0 ? 'Treatment Plan Setup' : 'Treatment Instructions'}</div>
                {cases.submission_process.treatment_description_2
                  ? cases.submission_process.treatment_description_2
                    ? cases.submission_process.treatment_description_2.replace('(specify in item 7)', '').replace('unknown ', '')
                    : cases.submission_process.treatment_description_2.replace('(specify in item 7)', '').replace('unknown ', '')
                  : 'No treatment found for this case'}
              </>
            )}
            {cases.submission_process.treatment_description_3 && cases.submission_process.treatment_description_3 !== 'NA. ' ? (
              <>
                <div className="medium">
                  {cases.case_id.indexOf('-') >= 0 && cases.submission_process.treatment_description_3 ? 'Treatment Instructions' : null}
                </div>
                {cases.submission_process.treatment_description_3 ? cases.submission_process.treatment_description_3 : ''}
              </>
            ) : null}
            {cases.submission_process.treatment_description_4 ? (
              <>
                <div className="medium">
                  {cases.case_id.indexOf('-') >= 0 && cases.submission_process.treatment_description_4 ? 'Treatment Instructions' : null}
                </div>
                {cases.submission_process.treatment_description_4 ? cases.submission_process.treatment_description_4 : ''}
              </>
            ) : null}
            {cases.case_id.indexOf('-') >= 0 && cases.submission_process.treatment_description_1 === ''
              ? null
              : cases.submission_process.treatment_description_1 &&
                cases.submission_process.treatment_description_1 !== 'NA' && (
                  <span className="text-wrappable">
                    <div className="medium">
                      {cases.case_id.indexOf('-') >= 0
                        ? 'Reason for Digital Enhancement'
                        : cases.submission_process.treatment_plan_revision === 'TX3.0'
                        ? 'Treatment Goals and Additional Instructions'
                        : 'Patient Chief Complaint'}
                    </div>
                    {cases.submission_process.treatment_description_1
                      ? cases.submission_process.treatment_description_1
                          .replace('(specify in item 7, where and what refinement is needed)', '')
                          .replace('(specify in item 7, where and what digital enhancement is needed)', '')
                      : 'No chief complaint filled out'}
                  </span>
                )}
          </div>
          <div className="case-detail__col">
            <h3>Records</h3>

            {!this.props.case_file_list_loading && (
              <ThumbnailURLInterface
                files={submission_files}
                images={configLightBox(submission_files)}
                multinail={`_${cases.case_id}`}
                fit
                has_setup={show_setup_btn}
                setupClick={() => this.onSetupClick(cases)}
                case_id={cases.case_id}
              />
            )}
          </div>
          <div className="case-detail__col">
            <h3>Documents</h3>
            {this.displayDocumentsList(cases)}
          </div>
        </div>
      </CollapsibleTriangleContainer>
    );
  }

  displayDocumentsList(cases) {
    const instructionsFile =
      this.props.case_file_list?.[cases.case_id]?.ship_process.find((file) => file.file_type.includes('tx_regenerated')) ||
      this.props.case_file_list?.[cases.case_id]?.ship_process.find((file) => file.file_type.includes('tx'));
    return (
      <>
        {cases.is_historical && cases.submission_process.treatment_plan_revision === 'TX0.0' ? null : (
          <div>
            <FontAwesomeIcon icon={['fas', 'file-pdf']} />
            <button key="tx0-form" className="case-detail__document-link" data-caseid={cases.case_id} onClick={this.onTreatmentModalClick}>
              {cases.case_id.indexOf('-DE') >= 0 ? 'Digital Enhancement Form' : 'Treatment Plan'}{' '}
              {isMobileDevice() && <FontAwesomeIcon icon={['fas', 'external-link-alt']} />}
            </button>
          </div>
        )}
        {cases.status_code === 'STATUS_SHIP'
          ? this.props.case_file_list?.[cases.case_id]?.ship_process.map((file, ind) => {
              if (instructionsFile && file.file_type === instructionsFile.file_type) {
                return (
                  <div key={ind}>
                    <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                    <button
                      key={file.id}
                      className="case-detail__document-link"
                      data-id={file.id}
                      data-file_type={file.file_type}
                      data-upload_data={file.upload_data}
                      data-caseid={cases.case_id}
                      onClick={this.onTxInstructionViewerModalClick}
                    >
                      {this.isCaseDE(cases) ? 'DE Instructions' : 'Tx Instructions'}
                    </button>
                  </div>
                );
              } else if (file.file_type.indexOf('vbpc') >= 0 || file.file_type.indexOf('bpg') >= 0) {
                const post_afg = isAfterAFGLaunch(file.created_date) && file.file_type.indexOf('bpg_manual') >= 0;
                return (
                  <div key={ind}>
                    <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                    <button
                      key={file.id}
                      className={`${isAfterAFGLaunch(file.created_date) ? 'case-detail__document-two-lines' : ''} case-detail__document-link`}
                      data-id={file.id}
                      data-file_type={file.file_type}
                      data-upload_data={file.upload_data}
                      data-caseid={cases.case_id}
                      data-gen_2={cases.gen_2}
                      data-post_afg={post_afg}
                      onClick={this.onVBPCViewerModalClick}
                    >
                      {cases.gen_2 ? (
                        <>Adhesive Fill Guide</>
                      ) : (
                        <>
                          Bracket Positioning Guide
                          {post_afg ? (
                            <>
                              <br />
                              Adhesive Fill Guide
                            </>
                          ) : null}
                        </>
                      )}
                    </button>
                  </div>
                );
              } else if (file.file_type.indexOf('afg') >= 0) {
                return (
                  <div key={ind}>
                    <FontAwesomeIcon icon={['fas', 'file-pdf']} />
                    <button
                      key={file.id}
                      className="case-detail__document-link"
                      data-id={file.id}
                      data-file_type={file.file_type}
                      data-upload_data={file.upload_data}
                      data-caseid={cases.case_id}
                      data-gen_2={cases.gen_2}
                      onClick={this.onVBPCViewerModalClick}
                    >
                      Adhesive Fill Guide
                    </button>
                  </div>
                );
              }

              return null;
            })
          : null}
        {cases.hand_delivery_ind ||
        (cases.ship_process.tracking_number &&
          cases.ship_process.tracking_number !== '' &&
          cases.ship_process.tracking_number !== 'NA' &&
          cases.ship_process.tracking_number !== 'N/A') ? (
          <div>
            <FontAwesomeIcon className="shrink--icon" icon={['fas', 'truck']} />
            <button
              key="tx-track"
              className="case-detail__document-link"
              data-case_id={cases.case_id}
              data-tracking_info={cases.ship_process.tracking_number}
              data-shipping_address={getCaseShippingAddress(cases)}
              data-tracking_url={cases.tracking_url}
              data-hand_delivery_ind={cases.hand_delivery_ind}
              onClick={this.onTrackShipmentModalClick}
            >
              Track Shipment
            </button>
          </div>
        ) : null}
      </>
    );
  }

  /**
   * Displays the digital enhancement information
   * @function
   * @param {Object} cases - Object that contains all the cases detail and
   * related information
   * @return {JSX} returns jsx
   */
  displayDraftDigitalEnhancement(cases) {
    return (
      <>
        <div className="case-detail__row">
          <h2 className="case-detail__header">{translateStatus(cases.status_code)}</h2>
        </div>
        <div className="case-detail__row">
          {this.displayDEResumeDraft(cases)}
          {this.displayDeleteDraft()}
          <div className="encap--button-height">
            <Exc className="alert--icon alert--icon__no-float" />
          </div>
        </div>
      </>
    );
  }

  displayDEResumeDraft(cases) {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const hasIPPEditPermission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
          const disabled = !hasIPPEditPermission || this.sameDsoDoctorWhoRecordBelongsTo();
          return (
            <Button onClick={this.onRedirectDigitalEnhancement} data-caseid={formatCaseIdForApi(cases.case_id)} disabled={disabled}>
              Resume
            </Button>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }

  /**
   * Displays the remove draft modal
   * @function
   */
  onModalRemoveDraftShow = () => {
    this.setState({ showDeleteDraft: true });
  };

  /**
   * Redeem referral code in marketo
   *
   *@param {Object} event - button click event.
   * @function
   */
  redeemReferralCode = (event) => {
    const api = `/apiv3/referralcode/${this.state.patientReferralCode}/redeem`;
    const json = { referral_code: this.state.patientReferralCode, referral_id: this.state.referralCodeData.id };
    this.putCall(api, json, this.closeValidReferralCodeModal);
  };

  /**
   * Close referral code modal
   *
   *@param {Object} event - button click event.
   * @function
   */
  closeValidReferralCodeModal = () => {
    let case_details = this.state.case_details;
    const case_id = this.state.case_details.case_id;
    this.state.case_details.cases.filter(function (c) {
      return c.case_id === case_id;
    })[0].referral_code = this.state.patientReferralCode;
    this.setState({
      showValidReferralCode: false,
      case_details: case_details,
    });
  };

  /**
   * Close redeemed referral code modal
   *
   *@param {Object} event - button click event.
   * @function
   */
  onRedeemedReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showRedeemedReferralCode: false,
    });
  };
  onRedeemedReferralCodeDismiss = () => {
    this.setState(
      {
        editPatientReferralCode: '',
        showRedeemedReferralCode: false,
      },
      modalClear()
    );
  };
  /**
   * Close invalid referral code modal
   *
   *@param {Object} event - button click event.
   * @function
   */
  onInvalidReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showInvalidReferralCode: false,
    });
  };
  onInvalidReferralCodeDismiss = () => {
    this.setState(
      {
        editPatientReferralCode: '',
        showInvalidReferralCode: false,
      },
      modalClear()
    );
  };
  /**
   * Close unable to validate referral code modal
   *
   *@param {Object} event - button click event.
   * @function
   */
  onUnableToValidateReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showUnableToValidateReferralCode: false,
    });
  };
  onUnableToValidateReferralCodeDismiss = () => {
    this.setState(
      {
        editPatientReferralCode: '',
        showUnableToValidateReferralCode: false,
      },
      modalClear()
    );
  };
  /**
   * Close valid referral code modal
   *
   *@param {Object} event - button click event.
   * @function
   */
  onValidReferralCodeDismiss = (event) => {
    this.setState({
      editPatientReferralCode: '',
      patientReferralCode: '',
      showValidReferralCode: false,
    });
  };

  /**
   * Closes the remove draft modal
   * @function
   */
  onModalRemoveProgressDismiss = () => {
    this.setState({ showDeleteDraft: false });
  };

  /**
   * Removes appropriate draft i.e. digital enhancement or progress record
   * @param {Object} event - event for the delete button
   * @function
   */
  onRemoveDraft = (event) => {
    const that = this;
    if (this.state.draft_progress_id && this.state.selected_tab === 'progress') {
      let selected_progress_id = this.state.draft_progress_id;
      const status_comment = 'Delete Draft';
      let data = {
        status_comment,
      };
      Axios.delete(`/apiV2/praction/${selected_progress_id}/delete_record`, { data })
        .then(function (res) {
          that.setState({
            case_details: res.data,
            showDeleteDraft: false,
            draft_progress_id: '',
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else if (this.state.draft_case_id && this.state.selected_tab === 'cases') {
      const draft_case_id = this.state.draft_case_id;
      Axios.delete(`/api/digital/?id=${draft_case_id}`)
        .then(function (result) {
          that.setState({
            showDeleteDraft: false,
            draft_case_id: '',
          });
          that.props.history.replace(that.props.history.location.pathname.replace(draft_case_id, that.getLastCompletedCaseId()));
          that.reloadInformation();
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
    // updates navbar case count
    const doctorId = getDoctorsIdFromRoute();
    this.props.fetchLoggedDoctorCaseCount({ doctorId });
  };

  /**
   * Displays delete draft button
   * @function
   * @return {JSX} returns jsx
   */
  displayDeleteDraft() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const hasIPPEditPermission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
          const disabled = !hasIPPEditPermission || this.sameDsoDoctorWhoRecordBelongsTo();
          return (
            <Button theme="revise" onClick={this.onModalRemoveDraftShow} disabled={disabled}>
              Delete
            </Button>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }

  /**
   * Provides if the record is created by same DSO doctor who is trying to access it
   * @function
   * @return {Boolean} returns flag if the record belongs to right DSO doctor
   */
  sameDsoDoctorWhoRecordBelongsTo = () => {
    return (
      this.state.case_details.dso_doctor &&
      this.state.doctor_account_role === 'DSO_Doctor' &&
      this.state.case_details.dso_doctor.id !== this.state.doctor_account_id
    );
  };

  /**
   * Displays progress record draft jsx
   * @function
   * @param {object} progress_record - progress record object
   * @return {JSX} returns jsx
   */
  displayDraftProgressRecord(progress_record) {
    return (
      <>
        <div className="case-detail__row">
          <h2 className="case-detail__header">{translateStatus(progress_record.status)}</h2>
        </div>
        <div className="case-detail__row">
          {this.displayPRResumeDraft()}
          {this.displayDeleteDraft()}
          <div className="encap--button-height">
            <Exc className="alert--icon alert--icon__no-float" />
          </div>
        </div>
      </>
    );
  }

  displayPRResumeDraft() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const hasIPPEditPermission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
          const disabled = !hasIPPEditPermission || this.sameDsoDoctorWhoRecordBelongsTo();
          return (
            <Button
              onClick={this.onRedirectCsr}
              data-caseid={formatCaseIdForApi(this.state.case_details.case_id)}
              data-firstname={this.state.case_details.patient.first_name}
              data-lastname={this.state.case_details.patient.last_name}
              data-sex={this.state.case_details.patient.sex}
              data-dob={convertDate(this.state.case_details.patient.dob)}
              data-progressid={this.state.draft_progress_id}
              disabled={disabled}
            >
              Resume
            </Button>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }

  getFileReviewLog(cases) {
    const statusToIgnore = [CaseStatus.AwaitingFolderGeneration, CaseStatus.FolderGeneration, CaseStatus.FolderGeneratioFailed, CaseStatus.FolderGenerationOk];

    const copyLogs = _.cloneDeep(cases.file_review_process.log);
    const filteredLogs = copyLogs.filter((log_item) => {
      return !statusToIgnore.includes(log_item.status_code);
    });

    const lastLog = cases.file_review_process.log[cases.file_review_process.log.length - 1];
    // Remove Case Submitted / Digital Enhancement Submitted
    if (filteredLogs.length > 1) {
      filteredLogs[1].date = filteredLogs[0].date;
      filteredLogs.shift();
    }

    for (let i = 0; i < filteredLogs.length; i++) {
      if (filteredLogs[i].status_code === 'Segmentation Process' && filteredLogs[i + 1] && filteredLogs[i + 1].status_code === 'STATUS_DOCTOR_UPLOAD') {
        filteredLogs[i].status_code = 'Records Incomplete';
      } else if (filteredLogs[i].status_code === 'Segmentation Process') {
        filteredLogs.splice(i, 1);
      }
    }

    // Appending INBRACE Verifying Records
    const isVerifyingRecords = filteredLogs.at(-1)?.status_code === 'STATUS_DOCTOR_UPLOAD' && lastLog.status_code !== 'STATUS_DOCTOR_UPLOAD';
    if (isVerifyingRecords || filteredLogs.length === 0) {
      lastLog.status_code = 'STATUS_INBRACE_CHECK';

      filteredLogs.push(lastLog);
    }
    return filteredLogs;
  }

  filterSetupProcess(setup_process) {
    let filter_setup = _.cloneDeep(setup_process);
    let new_log = [];

    for (let i = 0; i < filter_setup.length; i++) {
      new_log = filter_setup[i].log.filter((log_item) => {
        return (
          log_item.status_code !== 'Revise Setup' &&
          log_item.status_code !== 'Setup Review' &&
          log_item.status_code !== 'Setup Ready for Upload' &&
          log_item.status_code !== 'Setup Conversion Process' &&
          log_item.status_code !== 'Setup Conversion Failed' &&
          log_item.status_code !== 'Setup Ready for Release'
        );
      });

      filter_setup[i].log = new_log;
    }

    return filter_setup;
  }

  setupCommentUserDisplay = (log) => {
    const doctor_display = `Dr. ${log.text_created_by_last_name}`;
    const dso_display = `${log.text_created_by_first_name} ${log.text_created_by_last_name}`;
    const bpp_display = 'InBrace';

    return log.role === 'Business' ? bpp_display : log.role === 'DSO' ? dso_display : doctor_display;
  };

  isCaseComplete(cases) {
    return cases.status_code === 'STATUS_SHIP';
  }

  isCaseResumedAfter(targetHistoryEntry, history) {
    const unholdEntries = history.filter((entry) => entry.step === CaseStatus.StatusUnhold || entry.step === CaseStatus.DoctorClarificationResolved);
    const targetEntries = history.filter((entry) => entry.step === targetHistoryEntry);

    if (targetEntries.length < 1) return false; // can't be unhold after if target status doesnt exists
    if (unholdEntries.length < 1) return false; // can't be unhold after if unhold status doesnt exists

    const lastUnholdEntry = unholdEntries.pop();
    const firstTargetEntry = targetEntries.shift();

    const targetEntryDate = moment(firstTargetEntry.date);
    const unholdDate = moment(lastUnholdEntry.date);

    return unholdDate.isAfter(targetEntryDate);
  }

  isCaseResumedAfterStatus(targetStatusName, caseStatuses) {
    const unholdStatuses = caseStatuses.filter((cs) => cs.case_status === CaseStatus.Unhold || cs.case_status === CaseStatus.DoctorClarificationResolved);
    const targetStatuses = caseStatuses.filter((cs) => cs.case_status === targetStatusName);

    if (targetStatuses.length < 1) return false; // can't be unhold after if target status doesnt exists
    if (unholdStatuses.length < 1) return false; // can't be unhold after if unhold status doesnt exists

    const lastUnholdStatus = unholdStatuses.pop();
    const lastTargetStatus = targetStatuses.pop();

    const targetStatusDate = moment(lastTargetStatus.created_date);
    const unholdDate = moment(lastUnholdStatus.created_date);

    return unholdDate.isAfter(targetStatusDate);
  }

  isCaseDE(cases) {
    return cases.case_id && (cases.case_id.indexOf('-DE') >= 0 || cases.case_id.indexOf('-R') >= 0);
  }

  lastDECancel() {
    let is_cancel = false;

    if (
      this.state.case_details &&
      this.state.case_details.cases &&
      this.state.case_details.cases.length > 1 &&
      this.state.case_details.cases[this.state.case_details.cases.length - 1].status_code === 'STATUS_CANCEL'
    ) {
      is_cancel = true;
    }

    return is_cancel;
  }

  onConfirmClickEditPatientModal = (patient_ref) => {
    let that = this;
    let form_data = new FormData();
    this.setState({ submission_in_progress: true, initial_attributions: _.cloneDeep(JSON.stringify(this.state.attributions)) });
    this.clearAttributionOthers();

    form_data.append('case_id', this.state.case_details.case_id);
    form_data.append('patient_id', this.state.case_details.patient.id);
    form_data.append('first_name', this.state.case_details.patient.first_name);
    form_data.append('last_name', this.state.case_details.patient.last_name);
    form_data.append('dob', this.state.case_details.patient.dob);
    form_data.append('sex', this.state.case_details.patient.sex);
    form_data.append('patient_ref', patient_ref);
    form_data.append('attribution_response', JSON.stringify(this.state.attributions));

    if (this.state.case_details.dso_doctor && this.props.dso_doctor_dropdown_id !== this.state.case_details.dso_doctor.id) {
      form_data.append('doctor_id', this.props.dso_doctor_dropdown_id);
    }
    if (this.state.case_details.dso_doctor && this.props.dso_address_dropdown_id !== this.getShippingAddressFromCaseId('get_id')) {
      form_data.append('address_id', this.props.dso_address_dropdown_id);
    }

    Axios.post(`/apiV2/patientdetail/${this.state.case_details.patient.id}`, form_data)
      .then(function (res) {
        that.setState({
          case_details: res.data,
          submission_in_progress: false,
        });
        that.props.closeEditPatientModal();

        if (that.state.doctor_account_role.includes('DSO')) {
          that.props.history.push({ state: { refreshInfo: 'true' } });
        }
      })
      .catch(function (err) {
        if (err && err.response && err.response.status === 409) {
          that.props.closeEditPatientModal();
          that.setState({ submission_in_progress: false });
        }
      });
  };

  /**
   * Handle close edit patient modal
   * @function
   */
  closeEditPatientModal = () => {
    this.props.closeEditPatientModal();
    this.setState({ attributions: JSON.parse(this.state.initial_attributions) });
  };

  /**
   * Clear the attribution other response if question response does not include other
   * @function
   */
  clearAttributionOthers = () => {
    let new_attributions = this.state.attributions;
    new_attributions.forEach((q_set) => {
      if (q_set && q_set.question_response) {
        if (!q_set.question_response.includes('Other')) {
          q_set.other_response = null;
        }
      }
    });
    this.setState({ attributes: new_attributions });
  };

  getShippingAddressFromCaseId = (mode) => {
    for (let i = 0; i < this.state.case_details.cases.length; i++) {
      if (this.state.case_details.cases[i].case_id === this.state.case_details.case_id) {
        if (mode === 'get_name') {
          return getShippingAddressName(this.state.case_details.cases[i].shipping_address);
        } else if (mode === 'get_address') {
          return getShippingAddress(this.state.case_details.cases[i].shipping_address);
        } else {
          return getShippingAddressId(this.state.case_details.cases[i].shipping_address);
        }
      }
    }
    return null;
  };
  /**
   * Makes a put to call to the database and set new state
   * @function
   * @param {String} api - Contains the api path to be used in the put call
   * @param {Object} json - Object of the values to be sent to backend for updates
   * @param {Function} callback -The callback function to be called after
   * success or failed
   */
  putCall = (api, json, callback) => {
    let that = this;

    Axios.put(api, json)
      .then((res) => {
        that.updateStateForFlag(json);
        callback();
      })
      .catch((error) => {
        handleHttpRequestError(error, that);
        callback();
      });
  };
  /**
   * Makes a put request to save the dental clearance flag
   * @function
   * @param {String} value - String of the value of the frontend
   * @param {Function} callback - A function that gets called after everything
   * gets success or failed
   */
  saveDentalClearanceValue = (value, callback) => {
    const api = `/apiv3/case/${this.state.case_details.case_id}/flags/dentalclearance`;
    const json = { dental_clearance: value === 'Complete' };
    this.putCall(api, json, callback);
  };
  /**
   * Makes a put request to save the financial status flag
   * @function
   * @param {String} value - String of the value of the frontend
   * @param {Function} callback - A function that gets called after everything
   * gets success or failed
   */
  saveFinancialStatusValue = (value, callback) => {
    const api = `/apiv3/case/${this.state.case_details.case_id}/flags/financialapproval`;
    const json = { financial_approval: value === 'Approved' };
    this.putCall(api, json, callback);
  };
  /**
   * Set only on the initial case dental clearance value
   * @function
   * @param {Object} json - Contains the dental_clearance flag value
   */
  setStateForDentalClearanceValue = (json) => {
    let case_details = this.state.case_details;
    case_details.cases[0].dental_clearance = json['dental_clearance'];
    this.setState({ case_details: case_details });
  };
  /**
   * Set only on the initial case financial approval
   * @function
   * @param {Object} json - Contains the dental_clearance flag value
   */
  setStateForFinancialStatusValue = (json) => {
    let case_details = this.state.case_details;
    case_details.cases[0].financial_approval = json['financial_approval'];
    this.setState({ case_details: case_details });
  };
  /**
   * Set only on the initial case financial approval
   * @function
   * @param {Object} json - Contains the dental_clearance flag value
   */
  updateStateForFlag = (json) => {
    json.hasOwnProperty('dental_clearance') ? this.setStateForDentalClearanceValue(json) : this.setStateForFinancialStatusValue(json);
  };
  /**
   * Retrieve initial case financial status flag value
   * @function
   */
  getFinancialStatusValue = () => {
    const case_details = this.state.case_details;
    const value = case_details && case_details.cases && case_details.cases[0] && case_details.cases[0].financial_approval === true ? 'Approved' : 'Unapproved';
    return value;
  };
  /**
   * Retrieve initial case dental clearance flag value
   * @function
   */
  getDentalClearanceValue = () => {
    const case_details = this.state.case_details;
    const value = case_details && case_details.cases && case_details.cases[0] && case_details.cases[0].dental_clearance === true ? 'Complete' : 'Incomplete';
    return value;
  };
  /**
   * Checks case id is initial case
   * @function
   * @param {String} case_id - contains the case id
   */
  isInitialCase = (case_id) => {
    return case_id.indexOf('-') === -1 ? true : false;
  };
  /**
   * Check to see if financial status flag is still available
   * @function
   * @param {Object} cases - contains the case details
   */
  isFinancialStatusLocked = (cases) => {
    return this.isSetupApproved(cases) || cases.status_code === 'STATUS_CANCEL';
  };
  /**
   * Check to see if dental cleareance flag is still available
   * @function
   * @param {Object} cases - contains the case details
   */
  isDentalClearanceLocked = (cases) => {
    return this.isCaseShipped(cases) || cases.status_code === 'STATUS_CANCEL';
  };
  /**
   * Determines if a setup is approved by looking at the manufacturing process log
   * @function
   * @param {Object} cases - contains the case details
   */
  isSetupApproved = (cases) => {
    return cases?.manufacturing_process?.log?.length > 0;
  };
  /**
   * Determines if case is shipped
   * @function
   * @param {Object} cases - contains the case details
   */
  isCaseShipped = (cases) => {
    return cases && cases.ship_process && cases.ship_process.log && cases.ship_process.log.length > 0;
  };
  /**
   * Checks to see if case is a part of a dso
   * @function
   * @param {Object} cases - contains the case details
   */
  isDSOCase = (cases) => {
    return cases && cases.is_dso_case;
  };
  /**
   * Checks to see if a DSO initial case is finanical status is unapproved
   * @function
   * @param {Object} cases - contains the case details
   */
  isInitialDSOCaseFinancialStatusUnapproved = (cases) => {
    return this.isInitialCase(cases.case_id) && this.isDSOCase(cases) && this.getFinancialStatusValue() !== 'Approved';
  };
  /**
   * When mouse enters the approve button area show message only for dso initial case
   * @function
   * @param {Object} event - Contains the event handler values of the approve button
   */
  onApproveButtonMouseEnter = (event) => {
    const financial_approval =
      event && event.target && event.target.dataset && event.target.dataset.financial_approval ? event.target.dataset.financial_approval : false;

    if (financial_approval && financial_approval !== 'false') {
      this.setState({ approve_info: true });
    }
  };
  /**
   * When mouse leaves the approve button area hide message
   * @function
   */
  onApproveButtonMouseLeave = () => {
    this.setState({ approve_info: false });
  };
  /**
   * Checks to see if the user has Flag Permission (only DSO)
   * @function
   * @param {Object} user_roles_and_permissions - contains the user role and permission
   */
  hasDSOFlagPermissions = (user_roles_and_permissions) => {
    const request_id = this.state.case_details && this.state.case_details.request_id ? this.state.case_details.request_id : '';
    const dso_doctor__user_id =
      this.state.case_details && this.state.case_details.dso_doctor && this.state.case_details.dso_doctor.user_id
        ? this.state.case_details.dso_doctor.user_id
        : '';
    return (
      userHasPermission('DSO_ADMIN', user_roles_and_permissions.permissions) ||
      userHasPermission('DSO_DOCTOR_OVERRIDE', user_roles_and_permissions.permissions) ||
      (request_id === dso_doctor__user_id && request_id !== '')
    );
  };

  /**
   * Opens the Create Note Window
   * @function
   */
  openPatientReferralCode = () => {
    this.setState({
      show_patient_referral_code_modal: true,
    });
  };
  /**
   * Opens the Create Note Window
   * @function
   */
  openCreateNote = () => {
    this.setState({
      patient_notes: true,
      patient_notes_refresh: this.state.patient_notes ? true : false,
    });
  };
  /**
   * Closes the Create Note Window and set new notes
   * @function
   */
  closeCreateNote = (res) => {
    const patient_notes =
      this.state.case_details && this.state.case_details.patient_notes && res && res.data && res.data.patient_notes ? res.data.patient_notes : [];
    let case_details = this.state.case_details;

    if (patient_notes && patient_notes.length > 0) {
      case_details.patient_notes = patient_notes;
    }

    this.setState({
      case_details: case_details,
      patient_notes: false,
      patient_notes_id: '',
      patient_notes_subject: '',
      patient_notes_note_text: '',
      patient_notes_refresh: false,
      patient_notes_content: false,
      last_location: '',
    });
  };
  /**
   * Checks to see if there is at least 1 patient notes
   * @function
   */
  hasPatientNotes = () => {
    return this.state.case_details && this.state.case_details.patient_notes && this.state.case_details.patient_notes.length > 0;
  };

  /**
   * Determines if user is allowed to edit/delete note
   * @function
   * @param {number} note_creator_id - Creator id associated with the patient note
   * @param {object} user_roles_and_permissions - Current user's roles/permissions
   * @returns {boolean} - True or false
   */
  canUpdateNote = (note_creator_id, user_roles_and_permissions) => {
    const has_ipp_permission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
    const user_role = user_roles_and_permissions.doctor_role;
    return (
      has_ipp_permission &&
      ((user_role === 'DSO_Doctor' || user_role === 'Doctor' ? this.state.doctor_id === user_roles_and_permissions.doctor_id : true) ||
        note_creator_id === user_roles_and_permissions.user_id)
    );
  };

  /**
   * Set patient notes details into the patient notes to be edit or delete
   * @param {Object} event - event handler for edit / delete note buttons
   * @function
   */
  setNote = (event, state) => {
    const patient_notes_id =
      event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.note_id ? parseInt(event.currentTarget.dataset.note_id) : '';
    const patient_notes = this.state.case_details && this.state.case_details.patient_notes ? this.state.case_details.patient_notes : [];
    const patient_notes_note_text = patient_notes.filter((note) => {
      return note.id === patient_notes_id;
    });

    if (patient_notes.length > 0 && patient_notes_id && patient_notes_note_text.length > 0) {
      this.setState({
        ...state,
        patient_notes_id: patient_notes_id,
        patient_notes_subject: patient_notes_note_text[0].subject,
        patient_notes_note_text: patient_notes_note_text[0].note_text,
      });
    }
  };
  /**
   * Show patient notes box with information filled out to be edited
   * @param {Object} event - event for the edit note button
   * @function
   */
  setNoteToEdit = (event) => {
    this.setNote(event, { patient_notes: true });
  };
  /**
   * Show delete dialog to be confirmed
   * @param {Object} event - event for the delete note button
   * @function
   */
  setNoteToDelete = (event) => {
    this.setNote(event, { patient_notes_delete_modal: true });
  };
  /**
   * Call api after delete note is confirm
   * @param {Object} event - event for the confirmation button the modal dialog for deleting a note
   * @function
   */
  onConfirmDeleteNote = (event) => {
    let that = this;
    const patient_note_id = this.state.patient_notes_id;

    Axios.delete(`/apiv3/patientnotes/${patient_note_id}`)
      .then((res) => {
        const patient_notes = res && res.data && res.data.patient_notes ? res.data.patient_notes : [];
        that.onCloseDeleteNote(event, patient_notes);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };
  /**
   * Close delete note Modal, set and clear content as approriate
   * @param {Object} event - event for the confirmation button the modal dialog for deleting a note
   * @param {Object} patient_notes - contains the new patient notes to be set in state
   * @function
   */
  onCloseDeleteNote = (event, patient_notes = '') => {
    let case_details = this.state.case_details;

    if (patient_notes) {
      case_details.patient_notes = patient_notes;
    }

    this.setState({
      case_details: case_details,
      patient_notes_delete_modal: false,
      patient_notes_id: '',
      patient_notes_subject: '',
      patient_notes_note_text: '',
      patient_notes_refresh: false,
      patient_notes_content: false,
      last_location: '',
    });

    modalClear();
  };

  displayPatientActions(permissions) {
    const isInitialShipped = this.getInitialCaseStatusCode() === 'STATUS_SHIP';
    const isLatestShipped = this.getLatestCaseStatusCode() === 'STATUS_SHIP';
    const hasDSOPermissions =
      !this.state.case_details.dso_doctor ||
      userHasPermission('DSO_ADMIN', permissions) ||
      userHasPermission('DSO_DOCTOR_OVERRIDE', permissions) ||
      this.state.doctor_account_role !== 'DSO_Doctor' ||
      this.state.case_details.dso_doctor.id === this.state.doctor_account_id;
    const hasIPPEditPermission = userHasPermission('IPP_EDIT', permissions);

    const enabled = isInitialShipped && hasIPPEditPermission && hasDSOPermissions;
    const de_enabled = (isLatestShipped || this.lastDECancel() || this.state.draft_case_id) && hasDSOPermissions && hasIPPEditPermission;

    return (
      <div className="patient-action-container">
        <button
          className="patient-action"
          disabled={!enabled}
          onClick={this.onRedirectCsr}
          data-caseid={formatCaseIdForApi(this.state.case_details.case_id)}
          data-firstname={this.state.case_details.patient.first_name}
          data-lastname={this.state.case_details.patient.last_name}
          data-sex={this.state.case_details.patient.sex}
          data-dob={convertDate(this.state.case_details.patient.dob)}
          data-progressid={this.state.draft_progress_id}
        >
          <FontAwesomeIcon icon={['fas', 'plus-square']} />
          <span className="indent">{this.state.draft_progress_id ? 'Resume Progress Record' : 'Progress Record'}</span>
        </button>

        <button
          className="patient-action"
          disabled={!de_enabled}
          onClick={this.onRedirectDigitalEnhancement}
          data-caseid={this.state.draft_case_id ? this.state.draft_case_id : formatCaseIdForApi(this.state.case_details.case_id)}
          data-firstname={this.state.case_details.patient.first_name}
          data-lastname={this.state.case_details.patient.last_name}
          data-sex={this.state.case_details.patient.sex}
          data-dob={convertDate(this.state.case_details.patient.dob)}
        >
          <FontAwesomeIcon icon={['fas', 'plus-square']} />
          <span className="indent">{this.state.draft_case_id ? 'Resume Digital Enhancement' : 'Digital Enhancement'}</span>
        </button>

        <button
          className="patient-action"
          disabled={!enabled}
          onClick={this.onRedirectItemRequest}
          data-caseid={formatCaseIdForApi(this.state.case_details.case_id)}
          data-firstname={this.state.case_details.patient.first_name}
          data-lastname={this.state.case_details.patient.last_name}
          data-sex={this.state.case_details.patient.sex}
          data-dob={convertDate(this.state.case_details.patient.dob)}
        >
          <FontAwesomeIcon icon={['fas', 'plus-square']} />
          <span className="indent">Item Request</span>
        </button>

        {/* {this.state.case_details.cases.filter(function (c) {
          return c.referral_code && c.referral_code.length > 0;
        }).length === 0 && this.state.doctor_account_referral ? (
          <button className="patient-action" onClick={this.openPatientReferralCode} disabled={!hasIPPEditPermission}>
            <FontAwesomeIcon icon={['fas', 'plus-square']} />
            <span className="indent">Patient Referral Code</span>
          </button>
        ) : null} */}
      </div>
    );
  }

  displayCaseHeader(cases, case_ind) {
    const last_ind = this.state.case_details.cases.length - 1;
    const de_index = last_ind - case_ind === 1 ? '' : last_ind - case_ind;
    return (
      <div className="case-detail__row clearfix no-gutter">
        <div className="col-sm-6">
          <h2 className="case-detail__header" case_status={translateStatus(this.getLatestCaseStatusCode(), 'ipp')}>
            {case_ind === last_ind ? 'Initial Case' : `Digital Enhancement ${de_index}`}{' '}
          </h2>
          <span className="case-detail__id">
            {this.hasAccessToBusinessPortal() ? (
              <a href={`/business/portal/case/${this.getLastCompletedCaseId()}`}>{removeCaseIdInitialNumber(cases.case_id)}</a>
            ) : (
              removeCaseIdInitialNumber(cases.case_id)
            )}
          </span>
          {cases.gen_2 && <span className="case-tag case-gen2">GEN 2.0</span>}
        </div>
        {cases.status_code !== 'STATUS_DRAFT_DE' && (
          <div className="col-sm-6 case-detail__address">
            <span className="emphasis">Location </span>
            <span>{getShippingAddress(cases.delivered_address ? cases.delivered_address : cases.shipping_address)}</span>
          </div>
        )}
      </div>
    );
  }

  /**
   * Get next case record
   * @function
   * @param {Object} cases - Current case information
   * @return {Object} Current case information, null if none
   */
  getNextRecord = (cases) => {
    let case_index = cases && cases.record_type_index !== null ? cases.record_type_index : null;
    let nextRecord = null;
    if (this.state.case_details && this.state.case_details.cases && this.state.case_details.cases.length > 0) {
      if (case_index !== null && this.state.case_details.cases[case_index + 1]) {
        nextRecord = this.state.case_details.cases[case_index + 1];
      }
    }

    return nextRecord;
  };

  /**
   * Determines if case has a shipped DE
   * @function
   * @returns {boolean} - True or false
   */
  hasShippedDE = () => {
    if (this.state.case_details && this.state.case_details.cases) {
      for (const cases of this.state.case_details.cases) {
        if (cases.case_id.includes('-DE') && cases.status_code === 'STATUS_SHIP') {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Determine if should display navigator or case status
   * @function
   * @param {Object} cases - Case information
   * @param {Number} case_ind - Index of this case
   * @return {JSX} JSX element of case progress bar or navigator
   */
  displayInBraceNavigator = (cases, case_ind) => {
    let case_is_shipped = cases.status_code === 'STATUS_SHIP';
    let nextRecord = this.getNextRecord(cases);
    if (this.state.treatment_workflow && case_is_shipped) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => (
            <div className="case-detail__row case-detail__row--no-border">
              <TreatmentNavigator
                cases={cases}
                navigator_opted_in_before={this.state.navigator_opted_in_before}
                nextRecord={nextRecord}
                isCaseDE={this.isCaseDE(cases)}
                has_edit_permission={userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions)}
                has_shipped_de={this.hasShippedDE()}
              />
            </div>
          )}
        </UserPermissionsContext.Consumer>
      );
    } else {
      return this.displayCaseStatus(cases, case_ind);
    }
  };

  /**
   * Displays case progress bar
   * @function
   * @param {Object} cases - Case information
   * @param {Number} case_ind - Index of this case
   * @return {JSX} JSX element of case progress bar
   */
  displayCaseStatus(cases, case_ind) {
    const last_ind = this.state.case_details.cases.length - 1;
    const caseItems = [
      {
        title: `${case_ind === last_ind ? 'Case' : 'DE'} Submission`,
        isCompleted: true,
        status: 'Submitted',
        date: convertDate(cases.submission_date),
      },
      this.getFileReviewStatus(cases),
      this.getSetupProcessStatus(cases),
      ...this.getManufacturingStatus(cases),
    ];

    const holdStatus = this.getHoldStatus(cases);
    const cancelStatus = this.getCancelStatus(cases);
    const pendingClarificationStatus = this.getClarificationPendingStatus(cases, false);
    for (const [i, item] of caseItems.entries()) {
      const isLast = i === caseItems.length - 1;
      if (!item.isCompleted || isLast) {
        const isWaitingReview = item.note === 'InBrace Reviewing Design';
        const isHeld = holdStatus.isHold || cancelStatus.isCancelled || pendingClarificationStatus.isPendingClarification;
        Object.assign(item, holdStatus, cancelStatus, pendingClarificationStatus, { current: true });
        item.wasInReview = isHeld && isWaitingReview ? true : false;
        break;
      }
    }

    return (
      <div className="case-detail__row case-detail__row--no-border">
        <h3 className="case-detail__status-header">
          Status
          {cases.clinical_review && !cancelStatus.isCancelled && (
            <span className="clinical-tag" data-toggle="tooltip" data-placement="top" title="Doctor reached out with a few questions.">
              Clinical Review
            </span>
          )}
        </h3>
        <CaseProgress items={caseItems} hideProgressBar={cancelStatus.isCancelled}>
          {caseItems[1].actionRequired && !caseItems[1].isHold && this.displayFileReviewButton(cases)}
          {this.displayReviewSetupButton(cases, caseItems[2])}
        </CaseProgress>
      </div>
    );
  }

  getFirstSetupProcessDate = (setup_process) => {
    if (setup_process && setup_process[0] && setup_process[0].log && setup_process[0].log.length > 0) {
      return setup_process[0].log[0].date;
    }

    return '';
  };

  getFileReviewStatus(cases) {
    const setup_process = this.getCaseFiles(cases.case_id)?.setup_process ?? [];
    const is_complete = setup_process && setup_process.length > 0;
    const file_review_log = this.getFileReviewLog(cases);
    const last_log = file_review_log.length > 0 && file_review_log[file_review_log.length - 1];
    const status_code = cases.status_code === 'STATUS_DOCTOR_UPLOAD' ? cases.status_code : last_log && last_log.status_code;
    const action_required = status_code === 'STATUS_DOCTOR_UPLOAD' && cases.status_code !== 'STATUS_HOLD' && cases.status_code !== 'STATUS_CANCEL';
    const status = translateReviewProcessStatus(status_code, is_complete);
    const translatedStatus = status === CaseHistoryStatus.StartedRecordVerification ? 'InBrace Verifying Records' : status;
    const date = is_complete ? convertDate(this.getFirstSetupProcessDate(setup_process)) : last_log && convertDate(last_log.date);

    return {
      title: 'Records Review',
      isCompleted: is_complete,
      actionRequired: action_required,
      status: is_complete ? 'Completed' : action_required ? status : 'In Progress',
      date: is_complete || action_required ? date : '',
      note: is_complete || action_required ? '' : translatedStatus,
    };
  }

  getSetupProcessStatus(cases) {
    const setup_process = this.getCaseFiles(cases.case_id)?.setup_process ?? [];
    const post_approval_ipp_stage = this.getPostApprovalIppStage(setup_process);
    const { manufacturing_process } = cases;
    const is_complete = (manufacturing_process?.log && manufacturing_process.log.length > 0) || post_approval_ipp_stage === 'Fabrication';
    const latest_log = this.getLatestSetupLog(setup_process);
    const in_revision = setup_process.length > 1;

    let status_info = {};
    if (latest_log) {
      const action_required = latest_log.status_code === 'STATUS_DOCTOR_APPROVAL';
      const date = convertDate(cases.approval_date) ?? convertDate(latest_log.date);
      const inbrace_reviewing_design = post_approval_ipp_stage === 'InBrace Reviewing Design';
      const status = is_complete ? 'Approved' : action_required ? 'Review Smile Design' : 'In Progress';
      const note = is_complete || action_required ? '' : inbrace_reviewing_design ? 'InBrace Reviewing Design' : 'InBrace Preparing Smile Design';

      status_info = {
        isCompleted: is_complete,
        actionRequired: !is_complete && action_required,
        status: status,
        date: is_complete || action_required ? date : '',
        note: note,
        revision: in_revision,
      };
    }

    return { title: 'Smile Design', ...status_info };
  }

  getManufacturingStatus(cases) {
    const { manufacturing_process, ship_process, target_ship_date, expedite_ind, status_code, setup_process } = cases;
    const post_approval_ipp_stage = this.getPostApprovalIppStage(setup_process);
    const is_complete = this.isCaseComplete(cases);
    const has_manufacturing_process = (manufacturing_process?.log && manufacturing_process.log.length > 0) || post_approval_ipp_stage === 'Fabrication';
    const has_ship_process = ship_process && ship_process.log && ship_process.log.length > 0;
    const date = convertDate(is_complete && has_ship_process && ship_process.log[0].date);
    const is_cancel_or_held = status_code === 'STATUS_HOLD' || status_code === 'STATUS_CANCEL';
    const ship_note =
      has_manufacturing_process && !is_cancel_or_held ? (
        <div>
          {expedite_ind ? 'Expedited ' : ''}Est. Ship Date <br />
          {convertDate(target_ship_date)}
        </div>
      ) : (
        ''
      );

    const manufacturing_note = (
      <div>
        InBrace Fabricating Case <br />
        <div className="show-on-mobile show-on-small-desktop">{ship_note}</div>
      </div>
    );

    const status_info = has_manufacturing_process
      ? {
          status: is_complete ? 'Completed' : 'In Progress',
          date: date,
          note: is_complete ? '' : manufacturing_note,
        }
      : {};

    return [
      {
        title: 'Fabrication',
        isCompleted: is_complete,
        ...status_info,
      },
      {
        title: 'Shipping',
        isCompleted: is_complete,
        status: is_complete ? 'Shipped' : '',
        date: date,
        note: is_complete ? '' : ship_note,
      },
    ];
  }

  getHoldStatus(cases) {
    if (cases.status_code === 'STATUS_HOLD') {
      const date = convertDate(cases.hold_process.log[cases.hold_process.log.length - 1].date);
      return {
        isHold: true,
        actionRequired: false,
        status: 'Hold: Contact InBrace Concierge to remove hold',
        date: date,
        note: '',
        last_status: this.getLatestNonBlockingCaseStatusCode(cases),
      };
    }
    return {};
  }

  getCancelStatus(cases) {
    if (cases.status_code === 'STATUS_CANCEL') {
      const date = convertDate(cases.cancel_process.log[cases.cancel_process.log.length - 1].date);
      return {
        isCancelled: true,
        actionRequired: false,
        status: 'Case Cancelled',
        date: date,
        note: '',
        last_status: this.getLatestNonBlockingCaseStatusCode(cases),
      };
    }
    return {};
  }

  /**
   * Gets current state and display information for Doctor Provide Clarification status
   * @function
   * @param {Object} casese - Case information
   * @return {Object} Information for Doctor Provide Clarification status
   */
  getClarificationPendingStatus(cases, translate = true) {
    if (cases.status_code === 'Doctor Provide Clarification') {
      const date = convertDate(cases.doctor_clarification_process.log[cases.doctor_clarification_process.log.length - 1].date);
      return {
        isPendingClarification: true,
        actionRequired: false,
        status: translate ? CaseHistoryStatus.InbraceRequestedClarification : 'Pending Clarification',
        date: date,
        note: '',
        last_status: this.getLatestNonBlockingCaseStatusCode(cases),
      };
    }
    return {};
  }

  displayCurrentStatus(cases) {
    return (
      <>
        {this.displayFileReviewActionRequired(cases)}
        {this.displayHoldStatus(cases)}
        {this.displayCancelStatus(cases)}
        {this.displayClarificationPendingStatus(cases)}
      </>
    );
  }

  displayFileReviewActionRequired(cases) {
    const file_review_log = this.getFileReviewLog(cases);
    const last_log = file_review_log.length > 0 && file_review_log[file_review_log.length - 1];
    const action_required =
      last_log && last_log.status_code === 'STATUS_DOCTOR_UPLOAD' && cases.status_code !== 'STATUS_HOLD' && cases.status_code !== 'STATUS_CANCEL';
    const status = translateReviewProcessStatus(last_log.status_code);
    const translatedStatus = status === 'Upload New Records' ? CaseHistoryStatus.InbraceRequestedNewRecords : status;

    if (action_required) {
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{convertDate(last_log.date)}</span>
            <span>{translatedStatus}</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">InBrace</div>
                <div className="ql-editor">
                  <p>{last_log.text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  displayFileReviewButton(cases) {
    return (
      <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
        <Button onClick={this.onDoctorsUploadClick} data-caseid={cases.case_id} disabled={this.sameDsoDoctorWhoRecordBelongsTo()}>
          Upload
        </Button>
      </SecurityClearance>
    );
  }

  displayReviewSetupButton(cases, caseItem) {
    const waiting_doctor_approval = caseItem.last_status === 'Doctor Approve or Revise Setup';
    const is_in_revision = caseItem.revision && caseItem.current && caseItem.note !== 'InBrace Reviewing Design';
    const action_required = caseItem.actionRequired;
    const waiting_inbrace_review = caseItem.note === 'InBrace Reviewing Design';
    const wasInReview = caseItem.wasInReview;
    const should_display = waiting_doctor_approval || is_in_revision || action_required || waiting_inbrace_review || wasInReview;

    if (should_display)
      return (
        <Button data-case_id={cases.case_id} onClick={() => this.onSetupClick(cases)}>
          {action_required ? 'Review' : 'Smile Design Viewer'}
        </Button>
      );
  }

  displayHoldStatus(cases) {
    if (cases.status_code === 'STATUS_HOLD') {
      const last_log = cases.hold_process.log[cases.hold_process.log.length - 1];
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{convertDate(last_log.date)}</span>
            <span>{CaseHistoryStatus.InbraceCaseOnHold}</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">InBrace</div>
                <div className="ql-editor">{this.translateStatusText(last_log, cases, cases.status_code)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Get status comment for status history
   * @function
   * @param {Object} last_log - Log about the holding status
   * @param {Object} cases - Case information
   * @param {Object} status_code - Case status code
   * @return {JSX} - returns paragraph text for the status comment
   */
  translateStatusText(last_log, cases, status_code) {
    if (last_log) {
      switch (status_code) {
        case 'STATUS_HOLD':
          if (last_log.text === 'Post-Smile Design approval cancellation fee not accepted by doctor') {
            return (
              <p>
                A request has been made to cancel the case post-Smile Design approval and you have not accepted the $250 cancellation fee. Your Business Growth
                Consultant will be contacting you within the next 1-2 business days to discuss the request.
              </p>
            );
          } else {
            return (
              <>
                <p>{last_log.text}</p>
                <p>Please email {this.getEmailAnchor(cases)} or call us at (877) 258-8677.</p>
              </>
            );
          }
        case 'STATUS_CANCEL':
          const converted_case_id = this.state.case_details.converted_case_id;
          if (last_log.text === 'Converted to Gen 2.0') {
            let new_case_link = getRootPath(this.props.history.location.pathname) + '/case/' + converted_case_id;
            return (
              <p>
                Conversion from Gen 1 to Gen 2.0 requested. Case has been cancelled and a new Gen 2.0 case has been created{' '}
                {cases && converted_case_id ? <a href={new_case_link}>{converted_case_id}</a> : null}
              </p>
            );
          } else if (last_log.text === 'Converted to Gen 1') {
            let new_case_link = getRootPath(this.props.history.location.pathname) + '/case/' + converted_case_id;
            return (
              <p>
                Conversion from Gen 2.0 to Gen 1 requested. Case has been cancelled and a new Gen 1 case has been created{' '}
                {cases && converted_case_id ? <a href={new_case_link}>{converted_case_id}</a> : null}
              </p>
            );
          } else {
            return <p>{last_log.text}</p>;
          }

        default:
          return <p>{last_log.text}</p>;
      }
    }
  }

  getEmailAnchor(cases) {
    const email = 'support@inbrace.com';
    const subject = `${cases.case_id}: ${CaseHistoryStatus.InbraceCaseOnHold}`;
    const href = `mailto:${email}?subject=${subject}`;
    return (
      <a className="midnight" href={href}>
        support@inbrace.com
      </a>
    );
  }

  displayCancelStatus(cases) {
    if (cases.status_code === 'STATUS_CANCEL') {
      const last_log = cases.cancel_process.log[cases.cancel_process.log.length - 1];
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{convertDate(last_log.date)}</span>
            <span>Case Cancelled</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">{this.setupCommentUserDisplay(last_log)}</div>
                <div className="ql-editor">{this.translateStatusText(last_log, cases, cases.status_code)}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  displayClarificationPendingStatus(cases) {
    if (cases.status_code === 'Doctor Provide Clarification') {
      const last_log = cases.doctor_clarification_process.log[cases.doctor_clarification_process.log.length - 1];
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{convertDate(last_log.date)}</span>
            <span>{CaseHistoryStatus.InbraceRequestedClarification}</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">InBrace</div>
                <span
                  className="ql-editor ql-table quill-formatted ql-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(last_log.text, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                  }}
                />
                <p>Please email {this.getEmailAnchorPendingClarification(cases)} or call us at (877) 258-8677.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getEmailAnchorPendingClarification(cases) {
    const email = 'support@inbrace.com';
    const subject = `${cases.case_id}: ${CaseHistoryStatus.InbraceRequestedClarification}`;
    const href = `mailto:${email}?subject=${subject}`;
    return (
      <a className="midnight" href={href}>
        {email}
      </a>
    );
  }

  /**
   * Normalizes the given history array to only have one entry with shipdate being displayed.
   * @param {Array} history - Pre-sorted history array
   * @param {Object} cases - cases object containing details for the given case
   * @returns Normalized history array containing only one entry with shipdate
   */
  normalizeShipDate(history, cases) {
    const _history = _.cloneDeep(history);

    let previousStep = 'Records Verification';
    let idxOfItemToHaveShipDate = null;
    const shipDate = this.getEstimatedShipDate(cases);
    _history.forEach((item, idx) => {
      const isResumed = item.step === CaseStatus.StatusUnhold || item.step === CaseStatus.DoctorClarificationResolved;
      if (isResumed) {
        const isResumedAfterFabrication = this.isCaseResumedAfter('Fabrication Process', _history);

        const shouldOverrideShipDate = shipDate && isResumedAfterFabrication;
        // if we have a resumed status that matches these conditions above
        // it means that the shipdate should be displayed under that status
        // so we store a reference to the position of that status in the array
        // and then update the position afterwards to have the shipdate.
        if (shouldOverrideShipDate) {
          idxOfItemToHaveShipDate = idx;
        }
        item.status += ` ${previousStep}`;
      } else if (item.step) {
        previousStep = item.step;
      }
    });

    if (idxOfItemToHaveShipDate) {
      // if the idxofitemtohaveshipdate property is set
      // it means that the shipdate should be displayed at that index only
      // so we clear any other shipdates on the list, and assign the shipdate value
      // to the given index.
      _history.forEach((item) => {
        item.status = item.status.replace(shipDate, '');
      });
      _history[idxOfItemToHaveShipDate].status += `${shipDate}`;
    }
    return _history;
  }
  /**
   * Updates the verbiage in the history array to match the new values.
   * !todo reevaluate the need for this function
   * @param {Array} history - Array containing all the entries to be displayed on the frontend.
   * @returns {Array} - Array containing all the entries with updated verbiages.
   */
  translateHistoryVerbiage(history) {
    const translationMappings = {
      'Upload New Records': CaseHistoryStatus.InbraceRequestedNewRecords,
      'Clinical Review Initiated': CaseHistoryStatus.InbraceStartedClinicalReview,
      'Clinical Review Completed': CaseHistoryStatus.InbraceCompletedClinicalReview,
    };

    history.forEach((entry) => {
      if (entry.status in translationMappings) {
        entry.status = translationMappings[entry.status];
      }
    });

    return history;
  }

  /**
   * Displays case history
   * @function
   * @param {Object} cases - Case information
   * @return {JSX} JSX element for case history
   */
  displayCaseHistory(cases) {
    const rawHistory = [
      {
        date: cases.submission_process.date,
        status: this.isCaseDE(cases) ? 'Digital Enhancement Submitted' : 'Case Submitted',
      },
      ...this.getFileReviewHistory(cases),
      ...this.getSetupProcessHistory(cases),
      ...this.getManufacturingHistory(cases),
      ...this.getShipingHistory(cases),
      ...this.getHoldHistory(cases),
      ...this.getCancelHistory(cases),
      ...this.getClinicalReviewHistory(cases),
      ...this.getDoctorClarificationHistory(cases),
    ];
    rawHistory.sort((a, b) => new Date(a.date) - new Date(b.date));

    const historyWithNormalizedShipDate = this.normalizeShipDate(rawHistory, cases);
    const translatedHistory = this.translateHistoryVerbiage(historyWithNormalizedShipDate);

    return (
      <CollapsibleContainer collapsedText="Show Full History" openedText="Collapse History">
        <div className="case-detail__row case-detail__row--border-top">
          <h3>Case History</h3>
          <CaseHistory items={translatedHistory} />
        </div>
      </CollapsibleContainer>
    );
  }
  /**
   * Get the review history for a given case.
   *
   * @param {object} cases - The case object containing review process information.
   * @returns {Array} An array of review history entries.
   */
  getFileReviewHistory(cases) {
    const history = [];
    const fileReviewProcess = cases.file_review_process;

    if (fileReviewProcess.log) {
      const logs = fileReviewProcess.log;
      for (let i = 0; i < logs.length; i++) {
        const currLog = logs[i];
        if (currLog.status_code === CaseStatus.AwaitingFolderGeneration) {
          history.push({
            step: 'Records Verification',
            date: currLog.date,
            status: CaseHistoryStatus.StartedRecordVerification,
            author: 'InBrace',
          });
        } else if (currLog.status_code === CaseStatus.Segmentation) {
          const isNextStatusDoctorUpload = logs[i + 1]?.status_code === CaseStatus.DoctorUpload;
          if (isNextStatusDoctorUpload) {
            history.push(
              {
                step: 'Records Verification',
                date: logs[i + 1].date,
                status: CaseHistoryStatus.RecordsIncomplete,
                author: 'InBrace',
              },
              {
                step: 'Records Verification',
                date: logs[i + 1].date,
                status: CaseHistoryStatus.InbraceRequestedNewRecords,
                author: 'InBrace',
                comment: logs[i + 1].text,
              }
            );
          }
        } else if (currLog.status_code === CaseStatus.InBraceCheck) {
          const setupProcess = this.getCaseFiles(cases.case_id)?.setup_process;
          const isComplete = setupProcess && setupProcess.length > 0;
          const date = this.getFirstSetupProcessDate(setupProcess);
          if (isComplete) {
            history.push({
              step: 'Records Verification',
              date: date,
              status: CaseHistoryStatus.CompletedRecordVerification,
              author: 'InBrace',
            });
          }
        }
      }
    }

    return history;
  }
  /**
   * Retrieves case files for a specific case by its ID.
   *
   * @param {string|number} case_id - The ID of the case for which to retrieve case files.
   * @returns {Object|null} - The case files for the specified case or null if not found.
   */
  getCaseFiles(case_id) {
    const case_files = this.props.case_file_list?.[case_id];
    return case_files;
  }
  /**
   * Retrieves the setup process history for a given set of cases.
   *
   * @param {Object} cases - The cases data object containing setup process and related information.
   * @param {number} cases.case_id - The case ID.
   * @param {Object[]} cases.manufacturing_process.log - Log of the manufacturing process.
   * @param {string} cases.approval_date - Approval date for the case.
   * @returns {Object[]} - An array representing the setup process history.
   */
  getSetupProcessHistory(cases) {
    const caseFiles = this.getCaseFiles(cases.case_id);
    const setupProcess = caseFiles?.setup_process || [];
    const filteredSetupProcess = this.filterSetupProcess(setupProcess);

    const isInManufacture = cases.manufacturing_process?.log?.length > 0;
    const isComplete = cases.approval_date || isInManufacture;
    const setupSize = filteredSetupProcess.length > 0 ? filteredSetupProcess.length - 1 : 0;

    const history = [];
    const step = 'Smile Design';
    let latestRejectionIndex = -1;

    filteredSetupProcess.forEach((setup, index) => {
      if (setup.setup_status === 'Internally Rejected' || setup.setup_status === 'Rejected') {
        latestRejectionIndex = index;
      }
    });

    filteredSetupProcess.forEach((setup, setupIndex) => {
      const isCurrentSetup = setupIndex === setupSize;
      const internallyRejectedSetup = setup.setup_status === 'Internally Rejected';
      const revisedSetup = setup.setup_status === 'Revised';
      const rejectedSetup = setup.setup_status === 'Rejected';
      const releasedSetup = setup.setup_status === 'Released';

      setup.log.forEach((log) => {
        let doctorLog = null;
        let author = '';
        let entry = null;
        if (log.status_code === CaseStatus.StatusDoctorApproval) {
          history.push({
            step: step,
            date: log.date,
            status: `Smile Design ${setup.index}: ${CaseHistoryStatus.InbraceCompletedSmileDesign}`,
            author: 'InBrace',
            comment: log.text !== 'undefined' ? log.text : '',
            isHTML: true,
          });
          if (rejectedSetup || internallyRejectedSetup || revisedSetup) {
            const nextSetup = filteredSetupProcess[setupIndex + 1];
            if (nextSetup) {
              doctorLog = nextSetup.log[0];
            } else {
              doctorLog = _.cloneDeep(filteredSetupProcess[setupIndex].log).pop();
            }
            author = this.setupCommentUserDisplay(doctorLog);
          }
          if (doctorLog) {
            const hasComment = doctorLog.text && doctorLog.text !== 'undefined' && doctorLog.text !== 'historical: unknown rejection reason';
            const shouldDisplayComment = hasComment && !internallyRejectedSetup;
            entry = {
              step: step,
              date: doctorLog.date,
              comment: shouldDisplayComment ? doctorLog.text : '',
              status: CaseHistoryStatus.ProviderRequestedRevision,
              author: author,
            };
          } else {
            if (!releasedSetup) {
              entry = {
                step: step,
                date: cases.approval_date ?? log.date,
                status: `Smile Design ${setup.index}: ${CaseHistoryStatus.ProviderApprovedDesign}`,
              };
            }
          }

          if (entry) {
            history.push(entry);
          }
        } else if (log.status_code === CaseStatus.ProviderEdit) {
          const holdStatus = this.getHoldStatus(cases);
          const cancelStatus = this.getCancelStatus(cases);
          const pendingClarificationStatus = this.getClarificationPendingStatus(cases, false);
          const isHeld = holdStatus.isHold || cancelStatus.isCancelled || pendingClarificationStatus.isPendingClarification || false;
          const isInProviderEditReview = (log.current || (!isComplete && isHeld)) && isCurrentSetup;

          let wasRejectedInPreviousSetup = false;

          if (latestRejectionIndex !== -1) {
            wasRejectedInPreviousSetup = latestRejectionIndex + 1 === setupIndex;
          }

          if (isInProviderEditReview) {
            history.push({
              step: step,
              date: log.date,
              status: `Smile Design ${setup.index} - ${CaseHistoryStatus.InbraceStartedSmileDesignReview}`,
              author: 'InBrace',
            });
          } else if (!log.current && setup.setup_status !== 'Internally Rejected') {
            if (!wasRejectedInPreviousSetup) {
              history.push({
                step: step,
                date: log.date,
                status: `Smile Design ${setup.index} - ${CaseHistoryStatus.InbraceStartedSmileDesignReview}`,
                author: 'InBrace',
              });
            }
            history.push({
              step: isComplete ? 'Fabrication Process' : step,
              date: filteredSetupProcess[setupIndex]?.log?.length >= 2 ? filteredSetupProcess[setupIndex]?.log[1]?.date : log.date,
              status: `Smile Design ${setup.index} - ${CaseHistoryStatus.InbraceApprovedSmileDesignReview}`,
            });
          }
        } else if (log.status_code === CaseStatus.InBraceSetup) {
          const statusText = CaseHistoryStatus.InbraceStartedSmileDesign;
          history.push({
            step: step,
            date: setup.log[0].date,
            status: `Smile Design ${setup.index}: ${statusText}`,
          });
        }
      });
    });
    return history;
  }
  /**
   * Get the manufacturing history for a set of cases.
   *
   * @param {Object} cases - The cases to retrieve manufacturing history for.
   * @returns {Array} An array of manufacturing history objects.
   */
  getManufacturingHistory(cases) {
    // if the case is approved and doesn't have provider edit it should go to fabricating stage even if it is in ifsupload
    const history = [];
    const inManufactureProcess = cases.manufacturing_process?.log?.length > 0 || cases.approval_date;

    if (inManufactureProcess) {
      const latestLog = this.getLatestSetupLog(cases.setup_process, true);
      const isComplete = this.isCaseComplete(cases);

      const shipDate = cases.ship_process.log[0]?.date;
      const estimatedShipDate = this.getEstimatedShipDate(cases);
      const isApprovalDate = new Date(cases.approval_date) > new Date(latestLog.date);
      history.push({
        step: 'Fabrication Process',
        date: isApprovalDate ? cases.approval_date : latestLog.date,
        status: `${CaseHistoryStatus.InbraceStartedFabrication}${estimatedShipDate}`,
      });
      if (isComplete) {
        history.push({
          step: 'Fabrication Process',
          date: shipDate,
          status: CaseHistoryStatus.InbraceCompletedFabrication,
        });
      }
    }

    return history;
  }

  getShipingHistory(cases) {
    const history = [];
    if (this.isCaseComplete(cases)) {
      history.push({
        date: cases.ship_process.log[0].date,
        status: `Case Shipped to: ${getCaseShippingAddress(cases)}`,
      });
    }
    return history;
  }

  getHoldHistory(cases) {
    const history = [];
    if (cases.hold_process && cases.hold_process.log && cases.hold_process.log.length > 0) {
      const logs = cases.hold_process.log;
      logs.forEach((log, index) => {
        if (log.status_code === 'STATUS_HOLD') {
          history.push({
            date: log.date,
            status: CaseHistoryStatus.InbraceCaseOnHold,
            author: 'InBrace',
            comment: log.text !== 'undefined' ? log.text : '',
          });
        } else if (log.status_code === 'STATUS_UNHOLD') {
          history.push({
            step: log.status_code,
            date: log.date,
            status: CaseHistoryStatus.InbraceResumed,
          });
        }
      });
    }

    return history;
  }

  getCancelHistory(cases) {
    const history = [];
    if (cases.cancel_process && cases.cancel_process.log && cases.cancel_process.log.length > 0) {
      const { log } = cases.cancel_process;
      const last_log = log[log.length - 1];
      if (last_log.status_code === 'STATUS_CANCEL') {
        history.push({
          date: last_log.date,
          status: 'Case Cancelled',
          author: this.setupCommentUserDisplay(last_log),
          comment: last_log.text !== 'undefined' ? last_log.text : '',
        });
      }
    }
    return history;
  }

  /**
   * Retrieves history for clinical review process for a case
   * @function
   * @param {Object} cases - Selected case information
   * @return {List} List of clinical review history
   */
  getClinicalReviewHistory(cases) {
    const history = [];
    if (cases.clinical_review_process && cases.clinical_review_process.length > 0) {
      for (const log of cases.clinical_review_process) {
        history.push({
          date: log.date,
          status: log.type,
          author: 'InBrace',
          comment: log.type !== 'Clinical Review Completed' ? log.text.substring(log.text.indexOf(' - ') + 3) : null,
        });
      }
    }
    return history;
  }

  /**
   * Retrieves history for Doctor Provide Clarification status
   * @function
   * @param {Object} cases - Case information
   * @return {Array} List of status history
   */
  getDoctorClarificationHistory(cases) {
    const history = [];
    if (cases.doctor_clarification_process && cases.doctor_clarification_process.log && cases.doctor_clarification_process.log.length > 0) {
      const logs = cases.doctor_clarification_process.log;
      logs.forEach((log, index) => {
        if (log.status_code === 'Doctor Provide Clarification') {
          history.push({
            date: log.date,
            status: logs.length - 1 === index ? CaseHistoryStatus.InbraceRequestedClarification : CaseHistoryStatus.InbraceRequestedClarification,
            author: 'InBrace',
            comment: log.text !== 'undefined' ? log.text : '',
          });
        } else if (log.status_code === 'Doctor Clarification Resolved') {
          history.push({
            step: log.status_code,
            date: log.date,
            status: CaseHistoryStatus.InbraceResumed,
          });
        }
      });
    }

    return history;
  }

  displayIRHeader(item_request, ir_ind) {
    const handleIppIrClick = (e) => this.hasAccessToBusinessPortal() && this.onBppIrLinkClick(e);
    const ippIrLinkClassName = this.hasAccessToBusinessPortal() ? 'ipp-ir-link clickable' : 'ipp-ir-link';
    return (
      <div className="case-detail__row clearfix no-gutter">
        <div className="col-sm-6">
          <h2 className="case-detail__header">Item Request {this.state.case_details.item_request.length - ir_ind} </h2>
          <span onClick={handleIppIrClick} data-id={item_request.ireq_id} className={ippIrLinkClassName}>
            {this.getItemRequestId(item_request)}
          </span>
          {item_request.gen_2 && <span className="case-tag case-gen2">GEN 2.0</span>}
        </div>
        <div className="col-sm-6 case-detail__address">
          <span className="emphasis">Location </span>
          <span>
            {getShippingAddress(
              item_request.delivered_address ? item_request.delivered_address : this.getShippingAddressFromAddressId(item_request.shipping_address__id)
            )}
          </span>
        </div>
      </div>
    );
  }

  getItemRequestId(item_request) {
    return removeIreqIdInitialNumber(item_request.ireq_id);
  }

  getShippingAddressFromAddressId(address_id) {
    for (let address of this.state.case_details.shipping_addresses) {
      if (address.id === address_id) return address;
    }
    return '';
  }

  displayIRStatus(item_request) {
    const irItems = [
      {
        title: 'Submission',
        isCompleted: true,
        status: 'Submitted',
        date: this.getIRSubmissionDate(item_request),
      },
      this.getIRReviewStatus(item_request),
      this.getIRManufacturingStatus(item_request),
      this.getIRShippingStatus(item_request),
    ];

    const holdStatus = this.getIrHoldStatus(item_request);
    const cancelStatus = this.getIrCancelStatus(item_request);

    for (const [i, item] of irItems.entries()) {
      const isLast = i === irItems.length - 1;
      if (!item.isCompleted || isLast) {
        Object.assign(item, holdStatus, cancelStatus, { current: true });
        break;
      }
    }

    return (
      <div className="case-detail__row">
        <h3>Status</h3>
        <CaseProgress items={irItems} />
      </div>
    );
  }

  getIRReviewStatus(item_request) {
    const { status, date, isCompleted } = this.getRequestReviewStatusInformation(item_request);
    return {
      title: 'Request Review',
      isCompleted: isCompleted,
      status: isCompleted ? 'Completed' : 'In Progress',
      date: isCompleted ? date : '',
      note: isCompleted ? '' : status,
    };
  }
  /**
   * Checks if the IR status is in the manufacturing stage
   * @function
   * @param {Object} item_request - Contains the item request information
   * @return {Boolean} Returns truthy if is the correct manufacturing status
   */
  isIRManufacturingStatus(item_request) {
    return item_request.ireq_status === 'INBRACE Manufacturing Item Request' || item_request.ireq_status === 'INBRACE Manufactured Item Request';
  }
  /**
   * Checks if the IR status is in the cancelled and held stage
   * @function
   * @param {Object} item_request - Contains the item request information
   * @return {Boolean} Returns truthy if is cancel and hold
   */
  isIRCancelledOrHeld(item_request) {
    return item_request.ireq_status === 'Item Request Cancelled' || item_request.ireq_status === 'Item Request On Hold';
  }
  /**
   * Creates the object needed for creating the view for manufacturing status
   * @function
   * @param {Object} item_request - Contains the item request information
   * @return {Object} Returns truthy if is cancel and hold
   */
  getIRManufacturingStatus(item_request) {
    const { expedite_ind, target_ship_date } = item_request;
    const isManufacturing = this.isIRManufacturingStatus(item_request);
    const isShipped = item_request.ireq_status === 'Item Request Shipped';
    const { date } = this.getIrManufacturingStatusInformation(item_request);
    const manufacturing_note = (
      <div>
        InBrace Fabricating Item Request <br />
        <div className="show-on-mobile show-on-small-desktop">
          {expedite_ind ? 'Expedited ' : ''}Est. Ship Date {convertDate(target_ship_date)}
        </div>
      </div>
    );

    return {
      title: 'Fabrication',
      isCompleted: isShipped,
      status: isManufacturing ? 'In Progress' : isShipped ? 'Completed' : '',
      date: isManufacturing ? '' : date,
      note: isManufacturing ? manufacturing_note : '',
    };
  }
  /**
   * Creates the object needed for creating the view for shipping status
   * @function
   * @param {Object} item_request - Contains the item request information
   * @return {Object} Returns truthy if is cancel and hold
   */
  getIRShippingStatus(item_request) {
    const { expedite_ind, target_ship_date } = item_request;
    const isManufacturing = this.isIRManufacturingStatus(item_request);
    const { date, isShipped } = this.getIrShipStatusInformation(item_request);
    const isPaused = this.isIRCancelledOrHeld(item_request);
    const ship_note = isManufacturing && !isPaused && (
      <div>
        {expedite_ind ? 'Expedited ' : ''}Est. Ship Date <br />
        {convertDate(target_ship_date)}
      </div>
    );

    return {
      title: 'Shipping',
      isCompleted: isShipped,
      status: isShipped ? 'Shipped' : '',
      date: date,
      note: isShipped ? '' : ship_note,
    };
  }

  getIrCancelStatus(item_request) {
    if (item_request.ireq_status === 'Item Request Cancelled') {
      const { date } = this.getIrCancelledStatusInformation(item_request);
      return {
        isCancelled: true,
        actionRequired: false,
        status: 'Request Cancelled',
        date: date,
        note: '',
      };
    }
    return {};
  }

  getIrHoldStatus(item_request) {
    if (item_request.ireq_status === 'Item Request On Hold') {
      const { date } = this.getIrHoldStatusInformation(item_request);
      return {
        isHold: true,
        actionRequired: false,
        status: 'Hold: Contact InBrace Concierge to remove hold',
        date: date,
        note: '',
      };
    }
    return {};
  }

  displayIRCurrentStatus(item_request) {
    if (item_request.ireq_status === 'Item Request Cancelled') {
      const { date, comment } = this.getIrCancelledStatusInformation(item_request);
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{date} </span>
            <span>Reason for Cancellation</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">InBrace</div>
                <div className="ql-editor">
                  <p>{comment}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (item_request.ireq_status === 'Item Request On Hold') {
      const { date, comment } = this.getIrHoldStatusInformation(item_request);
      return (
        <div className="case-detail__row">
          <div className="case-history__item">
            <span className="emphasis">{date} </span>
            <span>Reason for Hold</span>
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">InBrace</div>
                <div className="ql-editor">
                  <p>{comment}</p>
                  <p>Please email {this.getEmailAnchorIR(item_request)} or call us at (877) 258-8677.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getEmailAnchorIR = (item_request) => {
    const email = 'support@inbrace.com';
    const subject = `${item_request.ireq_id}: Item Request on Hold`;
    const href = `mailto:${email}?subject=${subject}`;
    return (
      <a className="midnight" href={href}>
        {email}
      </a>
    );
  };

  getLastProcessNameFromStatus = (statuses) => {
    let process_name = 'Item Request';

    const process_drive_statuses = statuses
      .slice()
      .reverse()
      .filter((status) => {
        return status.ireq_status === 'INBRACE Manufacturing Item Request' || status.ireq_status === 'INBRACE Reviewing Request';
      });

    if (process_drive_statuses[0]['ireq_status'] === 'INBRACE Manufacturing Item Request') {
      process_name = 'Fabrication';
    } else if (process_drive_statuses[0]['ireq_status'] === 'INBRACE Reviewing Request') {
      process_name = 'Request Review';
    }

    return process_name;
  };

  displayIRHistory(item_request) {
    const history = [];

    const ir_statuses = this.state.case_details.item_request_status.filter((s) => s.ireq_id === item_request.ireq_id);

    for (let i = 0; i < ir_statuses.length; i++) {
      const s = ir_statuses[i];
      const date = convertDate(s.created_date);
      let status = '';
      let comment = '';

      if (s.status === 'Item Request Submitted') {
        status = s.status;
      } else if (s.status === 'INBRACE Approved Item Request' || s.status === 'InBrace Approved Item Request') {
        status = 'InBrace Approved Request';
      } else if (
        s.ireq_status === 'INBRACE Reviewing Request' &&
        (s.status !== 'INBRACE Updated Item Request' || s.status !== 'InBrace Updated Item Request')
      ) {
        status = 'InBrace Reviewing Request';
      } else if (
        (s.status === 'INBRACE Manufactured Item Request' || s.status === 'Item Request Fabricating Completed') &&
        item_request.ireq_status === 'Item Request Shipped'
      ) {
        status = 'Fabrication Completed';
      } else if (
        ((s.status === 'INBRACE Manufactured Item Request' || s.status === 'Item Request Fabricating Completed') &&
          item_request.ireq_status !== 'Item Request Shipped') ||
        s.ireq_status === 'INBRACE Manufacturing Item Request'
      ) {
        status = 'InBrace Fabricating Item Request';
      } else if (s.ireq_status === 'Item Request Shipped') {
        const shipping_address = item_request.delivered_address
          ? item_request.delivered_address
          : this.getShippingAddressFromAddressId(item_request.shipping_address__id);
        status = `Item Request Shipped to: ${getShippingAddress(shipping_address)}`;
        history[history.length - 1]['date'] = date;
      } else if (s.ireq_status === 'Item Request On Hold') {
        status = i === ir_statuses.length - 1 ? s.ireq_status : 'Item Request Held';
        comment = s.comment;
      } else if (s.ireq_status === 'Item Request Unheld') {
        status = s.status.replace('INBRACE', 'InBrace').replace('Item Request', this.getLastProcessNameFromStatus(ir_statuses));
        i++;
      } else if (s.ireq_status === 'Item Request Cancelled') {
        status = s.ireq_status;
      } else if (s.ireq_status === 'Item Request Uncancelled') {
        history.pop();
        history.pop();
      }

      if (status) {
        history.push({ date, status, comment, author: 'InBrace' });
      }
    }

    return (
      <CollapsibleContainer collapsedText="Show Full History" openedText="Collapse History">
        <div className="case-detail__row case-detail__row--border-top">
          <CaseHistory items={history} />
        </div>
      </CollapsibleContainer>
    );
  }

  getScrollSpyIds() {
    if (this.state.selected_tab === 'cases') {
      return this.state.case_details.cases
        .slice()
        .reverse()
        .map((cases) => cases.case_id);
    } else if (this.state.selected_tab === 'progress') {
      return this.state.case_details.progress_record
        .slice()
        .reverse()
        .map((pr) => pr.progress_id);
    } else if (this.state.selected_tab === 'item') {
      return this.state.case_details.item_request
        .slice()
        .reverse()
        .map((ir) => ir.ireq_id);
    } else {
      return [];
    }
  }

  getNavigationItems() {
    return [
      {
        active: this.state.selected_tab === 'cases',
        data: 'cases',
        label: 'Case/DE',
        count: this.state.case_details && this.state.case_details.cases ? this.state.case_details.cases.length : 0,
        icon: <FontAwesomeIcon icon={['fas', 'folder']} />,
      },
      {
        active: this.state.selected_tab === 'progress',
        data: 'progress',
        label: 'Progress Records',
        count: this.state.case_details && this.state.case_details.progress_record ? this.state.case_details.progress_record.length : 0,
        hide: this.getInitialCaseStatusCode() !== 'STATUS_SHIP',
        icon: <FontAwesomeIcon icon={['fas', 'file-medical']} />,
      },
      {
        active: this.state.selected_tab === 'item',
        data: 'item',
        label: 'Item Requests',
        count: this.state.case_details && this.state.case_details.item_request ? this.state.case_details.item_request.length : 0,
        hide: this.getInitialCaseStatusCode() !== 'STATUS_SHIP',
        icon: <FontAwesomeIcon icon={['fas', 'box-open']} />,
      },
      {
        active: this.state.selected_tab === 'note',
        data: 'note',
        label: 'Patient Notes',
        count: this.state.case_details && this.state.case_details.patient_notes ? this.state.case_details.patient_notes.length : 0,
        icon: <FontAwesomeIcon icon={['fas', 'sticky-note']} />,
      },
    ];
  }

  getPatientName = () => {
    if (this.state.selected_plan_details && this.state.selected_plan_details.patient_first_name && this.state.selected_plan_details.patient_last_name) {
      return `${this.state.selected_plan_details.patient_first_name} ${this.state.selected_plan_details.patient_last_name}`;
    } else if (
      this.state.case_details &&
      this.state.case_details.patient &&
      this.state.case_details.patient.first_name &&
      this.state.case_details.patient.last_name
    ) {
      return `${this.state.case_details.patient.first_name} ${this.state.case_details.patient.last_name}`;
    }

    return '';
  };

  constructModalTitle(heading, name) {
    return `${heading}${name ? ` - ${name}` : ''}`;
  }
  /**
   * Returns prospect details
   * @param {Object} event - Click event
   * @function
   */
  onProspectDetailsClick = (event) => {
    const prospect_id = event.currentTarget.dataset.prospect_id;
    let rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/prospect/edit/${prospect_id}`;
    this.props.history.push(pathname);
  };

  /**
   * Redirect to smile simulation detail
   * @function
   */
  onSmileSimulationIdClick = () => {
    const smile_id = this.state.smile_id;
    let rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/smile/detail/${smile_id}`;
    this.props.history.push(pathname);
  };

  /**
   * Shows the unsave note modal
   * @function
   * @param {Object} location - The location object to navigate to
   */
  showUnsavedNoteModal = (location) => this.setState({ showUnsaveModal: true, last_location: location });

  /**
   * Navigates to last location when confirmed discard
   * @function
   */
  onDiscardNoteConfirm = () => {
    const { last_location } = this.state;
    if (last_location) {
      this.setState({ confirmed_navigation: true }, () => this.props.history.push(last_location));
    }
  };

  /**
   * Whether to block the navgiation or not
   * @param {Object} location - The location to check
   * @returns {Boolean} Whether to block or not
   */
  shouldBlockNavigation(location) {
    const url = location.pathname;
    const tabs = ['/case/', '/progress/', '/item/', '/note/'];
    for (let tab of tabs) {
      if (url.indexOf(tab) !== -1) return false;
    }
    return true;
  }

  /**
   * Handles navigating away from case detail when editing notes
   *  - Boolean value indicates whether to allow the navigation or not
   * @param {Object} nextLocation - The next location object
   * @returns {Boolean} Whether to block or not
   */
  handleBlockedNavigation = (nextLocation) => {
    const { confirmed_navigation } = this.state;
    if (!confirmed_navigation && this.shouldBlockNavigation(nextLocation)) {
      this.showUnsavedNoteModal(nextLocation);
      return false;
    }
    return true;
  };

  /**
   * Determine if there is any unsaved content before navigating away
   * @returns {Boolean} Whether to trigger block or not
   */
  shouldTriggerNavigationBlock = () => {
    return Boolean(this.state.patient_notes_content);
  };

  /**
   * @return {Object} - New attribution question object with error cleared
   */
  clearAttributionWarnings = (attributions) => {
    let new_attributions = attributions;
    if (new_attributions && new_attributions.length) {
      new_attributions.forEach((q_set) => {
        q_set.attributions_error = false;
        q_set.attribution_other_error = false;
      });
      this.setState({ attributions: new_attributions });
    }
    return new_attributions;
  };

  /**
   * Updates Attribution Selection
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onAttributionsChange = (event) => {
    let value = event.target.value;
    let category = event.target.attributes.getNamedItem('category').value;
    let category_release_date = event.target.attributes.getNamedItem('category_release_date').value;
    let attributions = this.clearAttributionWarnings(this.state.attributions);
    let current_category = attributions.find((att) => att.question_category === category);
    let checked_attributions = current_category ? current_category.question_response : [];

    if (!current_category) {
      let new_category = {
        case_id: this.state.case_details.case_id,
        other_response: null,
        question_category: category,
        question_response: [value],
        release_date: category_release_date,
      };
      attributions.push(new_category);
      current_category = attributions.find((att) => att.question_category === category);
    }

    if (_.intersection(checked_attributions, [value]).length > 0) {
      checked_attributions = _.difference(checked_attributions, [value]);
    } else {
      if (value === 'None') {
        checked_attributions = [value];
        current_category.other_response = null;
      } else {
        checked_attributions.push(value);
      }
    }

    checked_attributions.sort();
    checked_attributions = _.cloneDeep(checked_attributions);
    current_category.question_response = checked_attributions;
    current_category['required'] = true;
    this.setState({ attributions: attributions, is_attribution_required: true });
  };

  /**
   * Updates Attribution other input field
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onAttributionOtherChange = (event) => {
    let value = removeEmoji(textFieldCheck(event.target.value));
    let category = event.target.attributes.getNamedItem('category').value;
    let attributions = this.clearAttributionWarnings(this.state.attributions);

    attributions.find((question_set) => question_set.question_category === category).other_response = value;
    this.setState({ attributions: attributions });
  };

  /**
   * Toggle Navigator Additional Action dropdown menu
   *
   * @function
   */
  onAdditionalActionClick = () => {
    let newState = !this.state.navigator_additional_actions;
    this.setState({
      navigator_additional_actions: newState,
    });
  };

  /**
   * Handle btn double click
   * @param {string} e - Click event
   */
  onDoubleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * Handle btn double click
   * @param {Aarray} case_list - Array of case list
   * @return {String} lated none draft case id
   */
  getLatestNonDraftCaseId = (case_list) => {
    let none_draft_case_id = '';
    if (case_list && case_list.length > 0) {
      const cases = this.getAllCasesExludingDraftDe(case_list);
      let latestNonDraftRecord = cases ? cases[cases.length - 1] : false;
      none_draft_case_id = latestNonDraftRecord ? latestNonDraftRecord.case_id : '';
    }
    return none_draft_case_id;
  };

  /**
   * Show and hide cancel case modal
   * @function
   */
  toggleCancelCaseModal = () => {
    this.setState({ cancel_case_modal: !this.state.cancel_case_modal });
  };

  /**
   *Hide cancel case and convert to gen 2.0 modal and reload information
   * @function
   */
  closeModalAndReload = () => {
    this.reloadInformation();
    this.setState({ cancel_case_modal: false, convert_case_generation_modal: false });
  };

  /**
   * Show and hide convert to gen 2.0 modal
   * @function
   */
  toggleConvertGenModal = () => {
    this.setState({ convert_case_generation_modal: !this.state.convert_case_generation_modal });
  };

  /**
   * Get gen 2.0 flag
   * @function
   */
  getGen2Flag() {
    for (const cases of this.state.case_details.cases) {
      if (cases.case_id === this.getLastCompletedCaseId()) {
        return cases.gen_2;
      }
    }
    return false;
  }

  /**
   * Check if case should show setup btn
   * @function
   * @param {Object} cases - cases info object
   * @returns {Boolean} True if case is in manufature status
   */
  shouldShowSetupBtn(cases) {
    const validLastStatus = getLastCaseStatusFromStatusesList(cases.case_statuses);

    const isFinalDesignProcessStage = isPostApprovalStage(validLastStatus, false);
    const hasManufacturingLog = cases.manufacturing_process?.log?.length > 0;

    return hasManufacturingLog || isFinalDesignProcessStage;
  }

  /**
   * Get inbrace ifs and provider edit setup from setup process
   * @function
   * @param {Array} setup_process - Array of setups
   * @returns {Object} Object of inbrace ifs and provider edit setup
   */
  getFinalDesignSetups(setup_process) {
    const final_design_setups = setup_process.filter((setup) => {
      return setup.is_inbrace_ifs_setup || setup.provider_edit;
    });
    return final_design_setups.length ? final_design_setups[0] : '';
  }

  /**
   * Get setup IPP post approval stage
   * @function
   * @param {Array} setup_process - Array of setups
   * @returns {String} Object of InBrace IFS setup
   */
  getPostApprovalIppStage(setup_process) {
    let post_approval_stage = '';
    if (setup_process.length) {
      const latest_setup = setup_process[setup_process.length - 1];
      const latest_log = this.getLatestSetupLog(setup_process);
      const in_final_design_process_stage = isPostApprovalStage(latest_log.status_code, false);
      const provider_edit = latest_setup.provider_edit;
      if (provider_edit && latest_log.status_code === 'Provider Edit Review') {
        post_approval_stage = 'InBrace Reviewing Design';
      } else if (in_final_design_process_stage) {
        post_approval_stage = 'Fabrication';
      }
    }

    return post_approval_stage;
  }

  /**
   * Get latest setup log from the latest setup
   * @function
   * @param {Array} setup_process - Array of setups
   * @returns {String} setup status
   */
  getLatestSetupLog(setup_process, ignoreFinalDesign = false) {
    if (setup_process.length) {
      let filteredSetups = setup_process;
      if (ignoreFinalDesign) {
        filteredSetups = [];
        setup_process.forEach((setup) => {
          if (setup.log[0].status_code !== 'Final Design Upload') {
            filteredSetups.push(setup);
          }
        });
      }

      let latest_setup = filteredSetups[filteredSetups.length - 1];
      const has_log = latest_setup && latest_setup.log && latest_setup.log.length > 0;
      const latest_log = has_log ? latest_setup.log[latest_setup.log.length - 1] : '';
      return latest_log;
    }

    return '';
  }

  /**
   * Retrieves estimated ship date message
   * @function
   * @param {object} cases - Case data
   * @returns {string} - Estimated ship date
   */
  getEstimatedShipDate(cases) {
    const isComplete = this.isCaseComplete(cases);
    const isBlocked = cases.status_code === 'STATUS_CANCEL' || cases.status_code === 'STATUS_HOLD' || cases.status_code === 'Doctor Provide Clarification';
    const expedited = !isComplete && cases.expedite_ind ? 'Expedited ' : '';
    if (!cases.target_ship_date) return '';
    const shipDate = !isComplete && !isBlocked ? `. ${expedited}Estimated Ship Date From InBrace: ${convertDate(cases.target_ship_date)}` : '';
    return shipDate;
  }

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading || this.props.case_file_list_loading) {
      return <CircleLoader fullscreen withBackground />;
    } else if (this.state.case_details) {
      const cases = this.state.case_details.cases;
      // eslint-disable-next-line
      const prospect_case = cases.filter(function (c) {
        if (c.prospect_id) {
          return c;
        }
      });

      const prospect_id = prospect_case.length > 0 ? prospect_case[0].prospect_id : null;
      const prospect__doctor_id = prospect_case.length > 0 ? prospect_case[0].prospect__doctor_id : null;

      return (
        <div>
          <Helmet>
            <title>Case Detail {removeCaseIdInitialNumber(this.state.case_details.case_id)} | InBrace Smile Design Studio™</title>
          </Helmet>
          <Prompt when={this.shouldTriggerNavigationBlock()} message={this.handleBlockedNavigation} />
          <span className="case_detail_title_div">
            <h1 className="content__header">Case Details</h1>
            {this.displayAdditionalActionsDropDown()}
          </span>
          <div className="content-body">
            <div className="case-summary-container">
              <CardContainer className="pad-md">
                <PatientInfo
                  patient={this.state.case_details.patient}
                  dso_doctor={this.state.case_details.dso_doctor}
                  smile_id={this.state.smile_id}
                  onSmileSimulationIdClick={this.onSmileSimulationIdClick}
                  referral_code={
                    this.state.patientReferralCode &&
                    this.state.patientReferralCode.length > 0 &&
                    this.state.patientReferralCode !== 'REFERRAL' &&
                    this.state.case_details.cases.filter(function (c) {
                      return c.referral_code && c.referral_code.length > 0;
                    }).length > 0 ? (
                      <>Referral Code: {this.state.patientReferralCode}</>
                    ) : null
                  }
                  onEdit={() =>
                    this.props.openEditPatientModal(
                      this.state.case_details.patient.patient_ref,
                      this.state.case_details.dso_doctor ? this.state.case_details.dso_doctor.id : null,
                      this.state.case_details.dso_doctor ? this.getShippingAddressFromCaseId('get_id') : null
                    )
                  }
                />
              </CardContainer>
              <CardContainer className="pad-md">
                <h2>Submit</h2>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => this.displayPatientActions(user_roles_and_permissions.permissions)}
                </UserPermissionsContext.Consumer>
              </CardContainer>
              <CardContainer className="pad-md">
                <h2>Navigate</h2>
                <CaseTabs items={this.getNavigationItems()} onTabSelection={this.onTabSelection}>
                  <ScrollspyNav scrollTargetIds={this.getScrollSpyIds()} activeNavClass="case-breadcrumb--active" offset={-65}>
                    {this.state.selected_tab === 'cases' &&
                      this.state.case_details.cases
                        .slice(0)
                        .reverse()
                        .map((cases, case_ind) => {
                          const last_index = this.state.case_details.cases.length - 1;
                          const de_index = last_index - case_ind === 1 ? '' : last_index - case_ind;
                          const de = this.state.draft_case_id === cases.case_id ? 'Draft DE' : 'Digital Enhancement';
                          return (
                            <Breadcrumb key={case_ind} bread={cases.case_id}>
                              {case_ind === last_index ? 'Initial Case' : `${de} ${de_index}`}
                            </Breadcrumb>
                          );
                        })}

                    {this.state.selected_tab === 'progress' &&
                      this.state.case_details.progress_record
                        .slice(0)
                        .reverse()
                        .map((pr, pr_ind) => {
                          const draft = pr.progress_id === this.state.draft_progress_id ? 'DRAFT ' : '';
                          const num = draft ? '' : this.state.case_details.progress_record.length - pr_ind;
                          return (
                            <Breadcrumb key={pr_ind} bread={pr.progress_id}>
                              {`${draft}Progress Record ${num}`}
                            </Breadcrumb>
                          );
                        })}

                    {this.state.selected_tab === 'item' &&
                      this.state.case_details.item_request
                        .slice(0)
                        .reverse()
                        .map((item_request, ir_ind) => {
                          return (
                            <Breadcrumb key={ir_ind} bread={item_request.ireq_id}>
                              {`Item Request ${this.state.case_details.item_request.length - ir_ind}`}
                            </Breadcrumb>
                          );
                        })}
                  </ScrollspyNav>
                </CaseTabs>
              </CardContainer>

              {prospect_id &&
              prospect_id !== 99999999 &&
              prospect__doctor_id &&
              this.state.doctor_id &&
              parseInt(prospect__doctor_id) === parseInt(this.state.doctor_id) ? (
                <>
                  <Button data-prospect_id={prospect_id} onClick={this.onProspectDetailsClick}>
                    Go to Prospect Details
                  </Button>
                </>
              ) : null}
            </div>

            <div className="case-detail-container">
              {this.shouldDisplayNavigatorBanner(cases) ? this.displayNavigatorBanner(cases) : ''}
              {this.state.selected_tab === 'cases' &&
                this.state.case_details.cases
                  .slice(0)
                  .reverse()
                  .map((cases, case_ind) => {
                    const isSubmitted = cases && cases.submission_process && cases.submission_process.status_code;
                    return (
                      cases && (
                        <CardContainer type="top-border" id={cases.case_id} className="case-detail" key={case_ind}>
                          {cases.status_code === 'STATUS_DRAFT_DE' ? this.displayDraftDigitalEnhancement(cases) : this.displayCaseHeader(cases, case_ind)}
                          {this.displayDSOFlags(cases)}
                          {isSubmitted && (
                            <>
                              {this.displayCaseSummary(cases)}
                              {this.displayInBraceNavigator(cases, case_ind)}
                              {this.displayCurrentStatus(cases)}
                              {this.displayCaseHistory(cases)}
                            </>
                          )}
                        </CardContainer>
                      )
                    );
                  })}
              {this.state.selected_tab === 'item' &&
                (this.state.case_details.item_request && this.state.case_details.item_request.length > 0 ? (
                  this.state.case_details.item_request
                    .slice(0)
                    .reverse()
                    .map((item_request, ir_ind) => {
                      return (
                        <CardContainer key={ir_ind} type="top-border" className="case-detail" id={item_request.ireq_id}>
                          {this.displayIRHeader(item_request, ir_ind)}
                          {this.displayIRSummary(item_request)}
                          {this.displayIRStatus(item_request)}
                          {this.displayIRCurrentStatus(item_request)}
                          {this.displayIRHistory(item_request)}
                        </CardContainer>
                      );
                    })
                ) : (
                  <CardContainer type="top-border" className="case-view pad-lg">
                    <h2 className="case-header">No Item Request Found</h2>
                    {this.getInitialCaseStatusCode() === 'STATUS_SHIP' && !this.sameDsoDoctorWhoRecordBelongsTo() && (
                      <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                        <Button data-caseid={formatCaseIdForApi(this.state.case_details.case_id)} onClick={this.onRedirectItemRequest}>
                          Submit
                        </Button>
                      </SecurityClearance>
                    )}
                  </CardContainer>
                ))}

              {this.state.selected_tab === 'progress' &&
                (this.state.case_details.progress_record && this.state.case_details.progress_record.length > 0 ? (
                  this.state.case_details.progress_record
                    .slice(0)
                    .reverse()
                    .map((pr, pr_ind) => {
                      const record_number = this.state.case_details.progress_record.length - pr_ind;
                      return (
                        <CardContainer key={pr_ind} type="top-border" className="case-detail" id={pr.progress_id}>
                          {pr.status.includes('Draft') ? (
                            this.displayDraftProgressRecord(pr)
                          ) : (
                            <>
                              <div className="case-detail__row">
                                <h2>Progress Record {record_number}</h2>
                              </div>
                              {this.displayPRSummary(pr, record_number)}
                            </>
                          )}
                        </CardContainer>
                      );
                    })
                ) : (
                  <CardContainer type="top-border" className="case-view pad-lg">
                    <h2 className="case-header">No Progress Record Found</h2>
                    {this.getInitialCaseStatusCode() === 'STATUS_SHIP' && !this.sameDsoDoctorWhoRecordBelongsTo() && (
                      <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                        <Button data-caseid={formatCaseIdForApi(this.state.case_details.case_id)} onClick={this.onRedirectCsr}>
                          Submit
                        </Button>
                      </SecurityClearance>
                    )}
                  </CardContainer>
                ))}

              {this.state.selected_tab === 'note' &&
                (this.state.case_details.patient_notes && this.state.case_details.patient_notes.length > 0 ? (
                  <CardContainer type="top-border" className="case-detail">
                    <div className="case-detail__row">
                      <div className="patient-notes">
                        <h2 className="case-header">Patient Notes</h2>
                        <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                          <Button data-caseid={formatCaseIdForApi(this.state.case_details.case_id)} onClick={this.openCreateNote}>
                            Create Note
                          </Button>
                        </SecurityClearance>
                      </div>
                      <div>Please note patient notes are for your reference only and will not be reviewed by InBrace.</div>
                    </div>
                    {this.state.case_details.patient_notes
                      .slice(0)
                      .reverse()
                      .map((note, index) => {
                        return (
                          <div key={index} className="case-detail__row">
                            <div className="patient-notes">
                              <div>
                                <span className="emphasis">Created By </span>
                                {note.modified_by__first_name} {note.modified_by__last_name}
                              </div>
                              <div className="case-view-note-date">{displayPSTDateTimeStamp(note.modified_date)}</div>
                            </div>
                            <DisplayPatientNotes note={note} />
                            <UserPermissionsContext.Consumer>
                              {(user_roles_and_permissions) => {
                                return (
                                  this.canUpdateNote(note.created_by_id, user_roles_and_permissions) && (
                                    <div className="block--right">
                                      <Button data-note_id={note.id} onClick={this.setNoteToEdit} disabled={this.state.patient_notes}>
                                        Edit
                                      </Button>
                                      <Button theme="revise" data-note_id={note.id} onClick={this.setNoteToDelete} disabled={this.state.patient_notes}>
                                        Delete
                                      </Button>
                                    </div>
                                  )
                                );
                              }}
                            </UserPermissionsContext.Consumer>
                          </div>
                        );
                      })}
                  </CardContainer>
                ) : (
                  <CardContainer type="top-border" className="case-view pad-lg">
                    <div className="patient-notes">
                      <h2 className="case-header">Patient Notes</h2>
                      <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                        <Button data-caseid={formatCaseIdForApi(this.state.case_details.case_id)} onClick={this.openCreateNote}>
                          Create Note
                        </Button>
                      </SecurityClearance>
                    </div>
                    <div className="patient-notes__message">Please note patient notes are for your reference only and will not be reviewed by InBrace.</div>
                    <div>No notes found</div>
                  </CardContainer>
                ))}
            </div>
          </div>

          {this.state.showApproveSetupModal ? (
            <Modal
              preset="decision"
              header_text="Approve Smile Design"
              modal_class="modal__approval--size"
              modal_body_class="text-left"
              message_text={
                <span>
                  InBrace Case Approval represents your final and complete prescription order to Swift Health Systems, Inc. (Swift). As the treating physician,
                  diagnosis, prescription, ordering and administering this appliance is your sole responsibility, and you waive any and all claims against Swift
                  and Employees of Swift based on the failure of InBrace to achieve a desired outcome, either alone or in combination with other appliances. All
                  treatments submitted to Swift are governed by the details in effect at the time of each treatment submission and listed in the InBrace{' '}
                  <a className="midnight" href={`/portal/agreement`} target="_blank" rel="noopener noreferrer">
                    Customer Agreement
                  </a>
                  . Swift reserves the right to refuse any prescription. By clicking 'Approve', I confirm I have read and understand the InBrace Customer
                  Agreement.
                </span>
              }
              close_btn_text="Cancel"
              confirm_btn_text="Approve"
              onCloseButtonClick={this.onApproveSetupModalDismiss}
              onConfirmButtonClick={this.onApproveClick}
            />
          ) : null}

          {this.state.rejectModal ? (
            <Modal
              preset="decision-dialog"
              header_text="Revise Smile Design"
              message_text="Are you sure you want to revise this Smile Design?"
              textarea_placeholder="Enter comments here"
              textarea_warning_text="Comment field cannot be empty"
              confirm_btn_text="Revise"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onRejectAccept}
              onCloseButtonClick={this.onRejectDismiss}
              onTextAreaChange={this.onRejectMsgChange}
            />
          ) : null}

          {this.state.showTrackShipmentModal ? (
            <TrackShipmentModal
              selected_tab={this.state.selected_tab}
              caseid={this.state.selected_tracking.case_id}
              onButtonClick={this.onTrackShipmentModalDismiss}
              comments={this.state.selected_tracking.tracking_info}
              user={this.state.case_details.dso_doctor ? this.state.case_details.dso_doctor : this.state.case_details.doctor}
              patientsFirstName={this.state.case_details.patient.first_name}
              patientsLastName={this.state.case_details.patient.last_name}
              shippingAddress={this.state.selected_tracking.shipping_address}
              onModalClick={this.onModalClickOutsideViewer}
              tracking_url={this.state.selected_tracking.tracking_url}
              hand_delivery_ind={this.state.selected_tracking.hand_delivery_ind}
            />
          ) : null}
          {this.state.showTreatmentModal ? (
            this.state.selected_plan_details.treatment_plan_revision === 'TX2.0' || this.state.selected_plan_details.treatment_plan_revision === 'TX3.0' ? (
              <PdfModal
                show={true}
                onClose={this.onTreatmentModalDismiss}
                pdfUrl={
                  this.state.selected_plan_details.treatment_plan_files.length > 0 ? this.state.selected_plan_details.treatment_plan_files[0].upload_data : ''
                }
                fileName={getPDFNameTemplate(this.state.selected_plan_details.case_id, this.state.case_details.patient, 'Treatment_Plan.pdf')}
                title={this.constructModalTitle('Treatment Plan', this.getPatientName())}
              />
            ) : this.state.selected_plan_details.treatment_plan_revision === 'DE2.0' || this.state.selected_plan_details.treatment_plan_revision === 'DE3.0' ? (
              <PdfModal
                show={true}
                onClose={this.onTreatmentModalDismiss}
                pdfUrl={this.state.selected_plan_details.de_plan_files.length > 0 ? this.state.selected_plan_details.de_plan_files[0].upload_data : ''}
                fileName={getPDFNameTemplate(this.state.selected_plan_details.case_id, this.state.case_details.patient, 'Digital_Enhancement.pdf')}
                title={this.constructModalTitle('Digital Enhancement Form', this.getPatientName())}
              />
            ) : (
              <TreatmentPlan
                selectedPatient={{
                  first_name: this.state.selected_plan_details.patient_first_name,
                  last_name: this.state.selected_plan_details.patient_last_name,
                  sex: this.state.selected_plan_details.patient_sex,
                  dob: this.state.selected_plan_details.patient_dob,
                }}
                caseData={this.state.selected_plan_details.treatment_plan}
                caseId={this.state.selected_plan_details.case_id}
                arch={this.state.selected_plan_details.treatment_arch}
                doctorfirstName={this.state.selected_plan_details.doctor_first_name}
                doctorlastName={this.state.selected_plan_details.doctor_last_name}
                onModalDismiss={this.onTreatmentModalDismiss}
                onModalClick={this.onModalClickOutsideViewer}
                revision={this.state.selected_plan_details.treatment_plan_revision}
              />
            )
          ) : null}

          {this.state.showTxInstructionViewerModal ? (
            <InstructionViewer
              patientName={this.getPatientName()}
              onButtonClick={this.onTxInstructionViewerModalDismiss}
              photoUpload={this.state.selected_tx}
              onModalClick={this.onModalClickOutsideViewer}
              case_id={this.state.selected_case_id}
              patient_info={this.state.case_details.patient}
            />
          ) : null}
          {this.state.showVBPCViewerModal ? (
            <VBPCViewer
              patientName={this.getPatientName()}
              onButtonClick={this.onVBPCViewerModalDismiss}
              photoUpload={this.state.selected_vbpc}
              onModalClick={this.onModalClickOutsideViewer}
              case_id={this.state.selected_case_id}
              patient_info={this.state.case_details.patient}
            />
          ) : null}

          {this.state.showValidReferralCode === true ? (
            <Modal
              preset="decision-referralcode"
              header_text="Redeem Referral Code"
              message_text={
                <span>
                  Please review the information below linked to referral code <strong>{this.state.patientReferralCode}</strong>
                  <br />
                  Click <strong>Redeem</strong> to redeem this code.
                </span>
              }
              confirm_btn_text="Redeem"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onValidReferralCodeConfirm}
              onCloseButtonClick={this.onValidReferralCodeDismiss}
              referral_code_data={this.state.referralCodeData}
            />
          ) : null}

          {this.state.refresh ? (
            <Modal
              preset="action"
              x_btn={false}
              header_text="Session Expired"
              message_text="Sorry, your session has expired. Please refresh."
              confirm_btn_text="Refresh"
              onConfirmButtonClick={onReloadPage}
            />
          ) : null}
          {this.state.showViewIrInvoice ? (
            <Modal
              preset="pdf_viewer"
              header_text={this.constructModalTitle('Item Request Form', this.getPatientName())}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              ir_invoice_type="ipp"
              ireq_id={this.state.selected_request_id}
              onCloseButtonClick={this.onViewIrInvoicDismiss}
              theme="ipp"
              original_filename={getPDFNameTemplate(this.state.selected_request_id, this.state.case_details.patient, 'IR.pdf')}
            />
          ) : null}
          {this.state.showViewPrCsq ? (
            <Modal
              preset="pdf_viewer"
              header_text={this.constructModalTitle('Clinical Support Request Form', this.getPatientName())}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              progress_id={this.state.selected_progress_id}
              onCloseButtonClick={this.onViewPrCsqDismiss}
              theme="ipp"
              original_filename={getPDFNameTemplate(this.state.selected_case_id, this.state.case_details.patient, `CSR${this.state.selected_progress_ind}.pdf`)}
            />
          ) : null}
          {this.props.edit_patient_modal ? (
            <Modal
              preset="edit-patient"
              modal_body_class="text-left"
              modal_class="modal-content-scroll"
              header_text="Edit Patient Details"
              edit_patient_warning_text="Please complete all required fields"
              confirm_btn_text="Save"
              close_btn_text="Cancel"
              onCloseButtonClick={this.closeEditPatientModal}
              onConfirmButtonClick={() => this.onConfirmClickEditPatientModal(this.props.patient_ref)}
              patient_firstname={this.state.case_details.patient.first_name}
              patient_lastname={this.state.case_details.patient.last_name}
              patient_dob={this.state.case_details.patient.dob}
              patient_sex={this.state.case_details.patient.sex}
              patient_ref={this.props.patient_ref}
              onPatientRefChange={this.props.onPatientRefChange}
              theme="ipp"
              case_id={removeCaseIdInitialNumber(this.state.case_details.case_id)}
              shipping_address_name={this.getShippingAddressFromCaseId('get_name')}
              doctor_name={
                this.state.case_details.dso_doctor ? this.state.case_details.dso_doctor.first_name + ' ' + this.state.case_details.dso_doctor.last_name : null
              }
              dso_doctors={this.props.dso_doctors}
              dso_addresses={this.props.dso_addresses}
              onDsoDoctorDropdownChange={this.props.onDsoDoctorDropdownChange}
              onDsoAddressDropdownChange={this.props.onDsoAddressDropdownChange}
              dsoDoctorDropdownId={this.props.dso_doctor_dropdown_id}
              dsoAddressDropdownId={this.props.dso_address_dropdown_id}
              dso_doctor_info={this.state.case_details.dso_doctor ? this.state.case_details.dso_doctor : null}
              account_role={this.state.doctor_account_role}
              account_id={this.state.doctor_account_id}
              inactive_case={
                this.getLatestCaseStatusCode() === 'STATUS_SHIP' ||
                this.getLatestCaseStatusCode() === 'STATUS_CANCEL' ||
                this.getLatestCaseStatusCode() === 'STATUS_HOLD' ||
                this.getLatestCaseStatusCode() === 'STATUS_DRAFT_DE'
              }
              attributions={this.state.attributions}
              onAttributionsChange={this.onAttributionsChange}
              onAttributionOtherChange={this.onAttributionOtherChange}
              is_attribution_allowed={this.state.is_attribution_allowed}
              attribution_questions={this.state.attribution_questions}
              checkAttributionError={this.checkAttributionError}
              submission_in_progress={this.state.submission_in_progress}
              case_status={this.getLatestCaseStatusCode()}
            />
          ) : null}

          {this.state.patient_notes ? (
            <CreatePatientNotes
              onCloseClick={this.closeCreateNote}
              id={this.state.case_details.case_id}
              patient_notes_id={this.state.patient_notes_id}
              subject={this.state.patient_notes_subject}
              note_text={this.state.patient_notes_note_text}
              showUnsaveModal={this.state.showUnsaveModal}
              refresh={this.state.patient_notes_refresh}
              onUnsaveModalLeave={() => this.setState({ showUnsaveModal: false, last_location: '' })}
              onDiscardConfirm={this.onDiscardNoteConfirm}
              refreshOff={() => this.setState({ patient_notes_refresh: false })}
              getContent={(subject, notes_count) => {
                this.setState({
                  patient_notes_content: subject.length > 0 || notes_count > 0,
                });
              }}
              onCreateCallBack={() => {
                this.selectUpdateTabSelection('note');
              }}
              is_prospect={false}
            />
          ) : null}
          {this.state.patient_notes_delete_modal ? (
            <Modal
              preset="decision"
              header_text={'Delete Note'}
              modal_class="modal-content-warning"
              message_text={
                <div className="case-view-note-modal">
                  <span>Are you sure you want to delete this note?</span>
                  <DisplayPatientNotes
                    note={{
                      note_text: this.state.patient_notes_note_text,
                      subject: this.state.patient_notes_subject,
                    }}
                  />
                </div>
              }
              confirm_btn_text="Delete"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onConfirmDeleteNote}
              onCloseButtonClick={this.onCloseDeleteNote}
            />
          ) : null}
          {this.state.showDeleteDraft === true ? (
            <Modal
              preset="decision"
              header_text="Delete Draft"
              modal_class="modal-content-warning"
              message_text={<span>Are you sure you want to delete this draft submission? You will not be able to undo this action if you proceed.</span>}
              close_btn_text="Cancel"
              confirm_btn_text="Delete"
              onCloseButtonClick={this.onModalRemoveProgressDismiss}
              onConfirmButtonClick={this.onRemoveDraft}
            />
          ) : null}
          {this.state.cancel_case_modal === true ? (
            <CancelCase
              case_status={this.getLatestCaseStatusCode()}
              case_id={this.state.case_details.case_id}
              doctor_id={this.state.case_details.doctor.id}
              patient_name={this.getPatientName()}
              closeModal={this.toggleCancelCaseModal}
              refresh={() => {
                this.setState({ refresh: true });
              }}
              closeCancelCaseModalAndReload={this.closeModalAndReload}
            />
          ) : null}
          {this.state.convert_case_generation_modal === true ? (
            <ConvertCaseGeneration
              case_id={this.state.case_details.case_id}
              doctor_id={this.state.case_details.doctor.id}
              patient_name={this.getPatientName()}
              closeModal={this.toggleConvertGenModal}
              closeCancelCaseModalAndReload={this.closeModalAndReload}
              history={this.props.history}
              refresh={() => {
                this.setState({ refresh: true });
              }}
              modal_theme="ipp"
              another_gen_case_exists={this.state.case_details.another_gen_case_exists}
              convert_to={this.state.case_details.another_gen_case_exists ? 'resume_original_case' : 'convert_to_gen_2'}
              gen_2={this.getGen2Flag()}
              case_status={this.getLatestCaseStatusCode()}
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    edit_patient_modal: getEditPatientModal(state),
    patient_ref: getPatientRef(state),
    dso_doctors: getDsoDoctors(state),
    dso_addresses: getDsoAddresses(state),
    dso_doctor_dropdown_id: getDsoDoctorDropdownId(state),
    dso_address_dropdown_id: getDsoAddressDropdownId(state),
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      openEditPatientModal: openEditPatientModal,
      closeEditPatientModal: closeEditPatientModal,
      onPatientRefChange: onPatientRefChange,
      fetchDsoDoctorData: fetchDsoDoctorData,
      onDsoDoctorDropdownChange: onDsoDoctorDropdownChange,
      onDsoAddressDropdownChange: onDsoAddressDropdownChange,
      fetchCaseFileData: fetchCaseFileData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CaseDetails));
