import { userHasPermission } from '../../common/permission';

export const showFalseDeAction = (cases, permissions) => {
  const status = cases?.case_statuses.find(status => status.current_ind);
  return userHasPermission('FLAG_FALSE_DE', permissions) && cases?.case_id.includes('-DE') && !cases?.false_de && !['Case Shipped', 'Cancelled'].includes(status.case_status) && !cases?.early_de
};

export const showEarlyDeAction = (cases, permissions) => {
  let status = cases?.case_statuses.find(status => status.current_ind);
  if (status.case_status === 'Doctor Provide Clarification') status = cases.case_statuses[cases.case_statuses.length - 2]
  return userHasPermission('FLAG_EARLY_DE', permissions) && cases?.case_id.includes('-DE') && !cases?.early_de && !cases?.false_de && !cases.early_de && isBeforeDoctorApproval(status.case_status) && !cases.false_de
}

export const showStagedAction = (cases, permissions) => {
  const status = cases?.case_statuses.find(status => status.current_ind);
  return userHasPermission('FLAG_STAGED', permissions) && !cases?.staged && !['Case Shipped', 'Cancelled'].includes(status.case_status) && !cases?.false_de && !cases?.early_de
}

const isBeforeDoctorApproval = (caseStatus) => {
  return [
    "Digital Enhancement Submitted",
    "Awaiting Folder Generation Process",
    "Folder Generation Process",
    "Folder Generation Success",
    "Segmentation Process",
    "INBRACE Checking Files",
    "INBRACE Preparing Setup",
    "Setup Review",
    "Setup Ready for Upload",
    "Setup Conversion Process",
    "Setup Cnoversion Failed",
    "Setup Ready for Release",
    "Revise Setup",
    'Doctor Approve or Revise Setup',
    'Setup Conversion Failed'
  ].includes(caseStatus)
}

export const showUnflagFalseDe = (cases, permissions) => {
  let status = cases?.case_statuses.find(status => status.current_ind);
  return userHasPermission('UNFLAG_FALSE_DE', permissions) && cases?.false_de && status.case_status !== 'Cancelled'
}

export const showUnflagEarlyDe = (cases, permissions) => {
  let status = cases?.case_statuses.find(status => status.current_ind);
  return userHasPermission('UNFLAG_EARLY_DE', permissions) && cases?.early_de && status.case_status !== 'Cancelled'
}

export const showUnflagStaged = (cases, permissions) => {
  let status = cases?.case_statuses.find(status => status.current_ind);
  return userHasPermission('UNFLAG_STAGED', permissions) && cases?.staged && status.case_status !== 'Cancelled' && status.case_status !== 'Case Shipped'
}

/**
 * Overwrite the case_details
 * @function
 * @param {object} value - the note text value
 * @return {Object}
 */
export const replaceCaseByCaseId = (case_details, case_data, case_id) => {
  for (let i = 0; i < case_details.cases.length; i++) {
    if (case_details.cases[i].case_id === case_id) {
      case_details.cases[i] = case_data;
      break;
    }
  }

  return case_details;
};

export const getHistoricalStatus = (case_details) => {
  let is_historical = false;

  if (case_details && case_details.cases[0]) {
    is_historical = case_details.cases[0].is_historical;
  }
  return is_historical;
};
